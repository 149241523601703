/* tslint:disable */
/* eslint-disable */
/**
 * TaxInsight.Ksef.Services.Invoice.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ANSICode = {
    Japanese: 'Japanese',
    SimplifiedChinese: 'SimplifiedChinese',
    Korean: 'Korean',
    TraditionalChinese: 'TraditionalChinese',
    CentralEuropean: 'CentralEuropean',
    Cyrillic: 'Cyrillic',
    WesternEuropean: 'WesternEuropean',
    Greek: 'Greek',
    Turkish: 'Turkish',
    Hebrew: 'Hebrew',
    Arabic: 'Arabic',
    Baltic: 'Baltic',
    Vietnamese: 'Vietnamese'
} as const;

export type ANSICode = typeof ANSICode[keyof typeof ANSICode];


/**
 * 
 * @export
 * @interface ActiveExitInfo
 */
export interface ActiveExitInfo {
    /**
     * 
     * @type {string}
     * @memberof ActiveExitInfo
     */
    'endpoint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiveExitInfo
     */
    'authScheme'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiveExitInfo
     */
    'authToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiveExitInfo
     */
    'authKey'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiveExitInfo
     */
    'retryCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveExitInfo
     */
    'lastTryTimeStampUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiveExitInfo
     */
    'receptionId'?: string | null;
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof ActiveExitInfo
     */
    'statusCode'?: HttpStatusCode;
    /**
     * 
     * @type {string}
     * @memberof ActiveExitInfo
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActiveExitInfo
     */
    'isCompleted'?: boolean;
}


/**
 * 
 * @export
 * @interface AddUriBucketsSettingsDto
 */
export interface AddUriBucketsSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof AddUriBucketsSettingsDto
     */
    'accessKeyID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUriBucketsSettingsDto
     */
    'secretAccessKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUriBucketsSettingsDto
     */
    'sessionToken'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ArchiveFileExtension = {
    Xml: 'xml',
    Pdf: 'pdf'
} as const;

export type ArchiveFileExtension = typeof ArchiveFileExtension[keyof typeof ArchiveFileExtension];


/**
 * 
 * @export
 * @enum {string}
 */

export const ArchiveFileType = {
    Upo: 'Upo',
    Fv: 'Fv'
} as const;

export type ArchiveFileType = typeof ArchiveFileType[keyof typeof ArchiveFileType];


/**
 * 
 * @export
 * @interface AttachmentRM
 */
export interface AttachmentRM {
    /**
     * 
     * @type {string}
     * @memberof AttachmentRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentRM
     */
    'invoiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentRM
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentRM
     */
    'originalFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentRM
     */
    'systemFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachmentRM
     */
    'creationDateUtc'?: string | null;
}
/**
 * 
 * @export
 * @interface BatchDispatchRM
 */
export interface BatchDispatchRM {
    /**
     * 
     * @type {string}
     * @memberof BatchDispatchRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BatchDispatchRM
     */
    'organizationNip'?: string | null;
    /**
     * 
     * @type {BatchProcessingStatus}
     * @memberof BatchDispatchRM
     */
    'status'?: BatchProcessingStatus;
    /**
     * 
     * @type {string}
     * @memberof BatchDispatchRM
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BatchDispatchRM
     */
    'canBeDeleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BatchDispatchRM
     */
    'canBeSigned'?: boolean;
    /**
     * 
     * @type {SubjectType}
     * @memberof BatchDispatchRM
     */
    'dispatchType'?: SubjectType;
    /**
     * 
     * @type {string}
     * @memberof BatchDispatchRM
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchDispatchRM
     */
    'upoNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchDispatchRM
     */
    'scheduledDateUtc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BatchDispatchRM
     */
    'numberOfInvoices'?: number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const BatchProcessingStatus = {
    AwaitingSignature: 'AwaitingSignature',
    Signed: 'Signed',
    Processing: 'Processing',
    Sending: 'Sending',
    AwaitingUpo: 'AwaitingUpo',
    Archived: 'Archived',
    ErrorArchived: 'ErrorArchived',
    Unauthorized: 'Unauthorized',
    NoSignature: 'NoSignature',
    AwaitingScheduledDate: 'AwaitingScheduledDate',
    Duplicate: 'Duplicate'
} as const;

export type BatchProcessingStatus = typeof BatchProcessingStatus[keyof typeof BatchProcessingStatus];


/**
 * 
 * @export
 * @interface BillingExportMetadata
 */
export interface BillingExportMetadata {
    /**
     * 
     * @type {string}
     * @memberof BillingExportMetadata
     */
    'recordType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingExportMetadata
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingExportMetadata
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingExportMetadata
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingExportMetadata
     */
    'ksefNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingExportMetadata
     */
    'ksefDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingExportMetadata
     */
    'errorCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingExportMetadata
     */
    'errorDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingExportMetadata
     */
    'qrCodeText'?: string | null;
}
/**
 * 
 * @export
 * @interface BulkSearchDispatchProcessQuery
 */
export interface BulkSearchDispatchProcessQuery {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkSearchDispatchProcessQuery
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkSearchDispatchProcessQuery
     */
    'externalTrackingIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkSearchDispatchProcessQuery
     */
    'ksefNumbers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkSearchDispatchProcessQuery
     */
    'invoiceNumbers'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface BulkSearchReceptionProcessQuery
 */
export interface BulkSearchReceptionProcessQuery {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkSearchReceptionProcessQuery
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkSearchReceptionProcessQuery
     */
    'ksefNumbers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkSearchReceptionProcessQuery
     */
    'invoiceNumbers'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface BusinessError
 */
export interface BusinessError {
    /**
     * 
     * @type {string}
     * @memberof BusinessError
     */
    'timeStampUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessError
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BusinessError
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BusinessError
     */
    'detailedMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BusinessError
     */
    'ksefSessionReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BusinessError
     */
    'ksefElementReferenceNumber'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BusinessError
     */
    'data'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CompressionType = {
    Deflate: 'Deflate',
    GZip: 'GZip'
} as const;

export type CompressionType = typeof CompressionType[keyof typeof CompressionType];


/**
 * 
 * @export
 * @interface ContextIdentifier
 */
export interface ContextIdentifier {
    /**
     * 
     * @type {string}
     * @memberof ContextIdentifier
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContextIdentifier
     */
    'identifier'?: string | null;
}
/**
 * 
 * @export
 * @interface ContextName
 */
export interface ContextName {
    /**
     * 
     * @type {string}
     * @memberof ContextName
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContextName
     */
    'tradeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContextName
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateArchiveDto
 */
export interface CreateArchiveDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateArchiveDto
     */
    'ids'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CreateProcessCommandResult
 */
export interface CreateProcessCommandResult {
    /**
     * 
     * @type {string}
     * @memberof CreateProcessCommandResult
     */
    'dateReceivedUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProcessCommandResult
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProcessCommandResult
     */
    'xfxTrackingId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProcessCommandResult
     */
    'invoiceNo'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateProcessesFromTokensCommandResult
 */
export interface CreateProcessesFromTokensCommandResult {
    /**
     * 
     * @type {string}
     * @memberof CreateProcessesFromTokensCommandResult
     */
    'outputFile'?: string | null;
    /**
     * 
     * @type {Array<CreateProcessCommandResult>}
     * @memberof CreateProcessesFromTokensCommandResult
     */
    'bulkData'?: Array<CreateProcessCommandResult> | null;
}
/**
 * 
 * @export
 * @interface CreateProcessesFromZipCommandResult
 */
export interface CreateProcessesFromZipCommandResult {
    /**
     * 
     * @type {Array<CreateProcessCommandResult>}
     * @memberof CreateProcessesFromZipCommandResult
     */
    'bulkData'?: Array<CreateProcessCommandResult> | null;
}
/**
 * 
 * @export
 * @interface CredentialsRoleResponseTokenType
 */
export interface CredentialsRoleResponseTokenType {
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseTokenType
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseTokenType
     */
    'roleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseTokenType
     */
    'roleDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseTokenType
     */
    'startTimestamp'?: string | null;
    /**
     * 
     * @type {RoleGrantorIdentifier}
     * @memberof CredentialsRoleResponseTokenType
     */
    'roleGrantorIdentifier'?: RoleGrantorIdentifier;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CurrencyCode = {
    Aed: 'AED',
    Afn: 'AFN',
    All: 'ALL',
    Amd: 'AMD',
    Ang: 'ANG',
    Aoa: 'AOA',
    Ars: 'ARS',
    Aud: 'AUD',
    Awg: 'AWG',
    Azn: 'AZN',
    Bam: 'BAM',
    Bbd: 'BBD',
    Bdt: 'BDT',
    Bgn: 'BGN',
    Bhd: 'BHD',
    Bif: 'BIF',
    Bmd: 'BMD',
    Bnd: 'BND',
    Bob: 'BOB',
    Bov: 'BOV',
    Brl: 'BRL',
    Bsd: 'BSD',
    Btn: 'BTN',
    Bwp: 'BWP',
    Byn: 'BYN',
    Bzd: 'BZD',
    Cad: 'CAD',
    Cdf: 'CDF',
    Che: 'CHE',
    Chf: 'CHF',
    Chw: 'CHW',
    Clf: 'CLF',
    Clp: 'CLP',
    Cny: 'CNY',
    Cop: 'COP',
    Cou: 'COU',
    Crc: 'CRC',
    Cuc: 'CUC',
    Cup: 'CUP',
    Cve: 'CVE',
    Czk: 'CZK',
    Djf: 'DJF',
    Dkk: 'DKK',
    Dop: 'DOP',
    Dzd: 'DZD',
    Egp: 'EGP',
    Ern: 'ERN',
    Etb: 'ETB',
    Eur: 'EUR',
    Fjd: 'FJD',
    Fkp: 'FKP',
    Gbp: 'GBP',
    Gel: 'GEL',
    Ggp: 'GGP',
    Ghs: 'GHS',
    Gip: 'GIP',
    Gmd: 'GMD',
    Gnf: 'GNF',
    Gtq: 'GTQ',
    Gyd: 'GYD',
    Hkd: 'HKD',
    Hnl: 'HNL',
    Hrk: 'HRK',
    Htg: 'HTG',
    Huf: 'HUF',
    Idr: 'IDR',
    Ils: 'ILS',
    Imp: 'IMP',
    Inr: 'INR',
    Iqd: 'IQD',
    Irr: 'IRR',
    Isk: 'ISK',
    Jep: 'JEP',
    Jmd: 'JMD',
    Jod: 'JOD',
    Jpy: 'JPY',
    Kes: 'KES',
    Kgs: 'KGS',
    Khr: 'KHR',
    Kmf: 'KMF',
    Kpw: 'KPW',
    Krw: 'KRW',
    Kwd: 'KWD',
    Kyd: 'KYD',
    Kzt: 'KZT',
    Lak: 'LAK',
    Lbp: 'LBP',
    Lkr: 'LKR',
    Lrd: 'LRD',
    Lsl: 'LSL',
    Lyd: 'LYD',
    Mad: 'MAD',
    Mdl: 'MDL',
    Mga: 'MGA',
    Mkd: 'MKD',
    Mmk: 'MMK',
    Mnt: 'MNT',
    Mop: 'MOP',
    Mru: 'MRU',
    Mur: 'MUR',
    Mvr: 'MVR',
    Mwk: 'MWK',
    Mxn: 'MXN',
    Mxv: 'MXV',
    Myr: 'MYR',
    Mzn: 'MZN',
    Nad: 'NAD',
    Ngn: 'NGN',
    Nio: 'NIO',
    Nok: 'NOK',
    Npr: 'NPR',
    Nzd: 'NZD',
    Omr: 'OMR',
    Pab: 'PAB',
    Pen: 'PEN',
    Pgk: 'PGK',
    Php: 'PHP',
    Pkr: 'PKR',
    Pln: 'PLN',
    Pyg: 'PYG',
    Qar: 'QAR',
    Ron: 'RON',
    Rsd: 'RSD',
    Rub: 'RUB',
    Rwf: 'RWF',
    Sar: 'SAR',
    Sbd: 'SBD',
    Scr: 'SCR',
    Sdg: 'SDG',
    Sek: 'SEK',
    Sgd: 'SGD',
    Shp: 'SHP',
    Sll: 'SLL',
    Sos: 'SOS',
    Srd: 'SRD',
    Ssp: 'SSP',
    Stn: 'STN',
    Svc: 'SVC',
    Syp: 'SYP',
    Szl: 'SZL',
    Thb: 'THB',
    Tjs: 'TJS',
    Tmt: 'TMT',
    Tnd: 'TND',
    Top: 'TOP',
    Try: 'TRY',
    Ttd: 'TTD',
    Twd: 'TWD',
    Tzs: 'TZS',
    Uah: 'UAH',
    Ugx: 'UGX',
    Usd: 'USD',
    Usn: 'USN',
    Uyi: 'UYI',
    Uyu: 'UYU',
    Uyw: 'UYW',
    Uzs: 'UZS',
    Ves: 'VES',
    Vnd: 'VND',
    Vuv: 'VUV',
    Wst: 'WST',
    Xaf: 'XAF',
    Xag: 'XAG',
    Xau: 'XAU',
    Xba: 'XBA',
    Xbb: 'XBB',
    Xbc: 'XBC',
    Xbd: 'XBD',
    Xcd: 'XCD',
    Xdr: 'XDR',
    Xof: 'XOF',
    Xpd: 'XPD',
    Xpf: 'XPF',
    Xpt: 'XPT',
    Xsu: 'XSU',
    Xua: 'XUA',
    Xxx: 'XXX',
    Yer: 'YER',
    Zar: 'ZAR',
    Zmw: 'ZMW',
    Zwl: 'ZWL'
} as const;

export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];


/**
 * 
 * @export
 * @enum {string}
 */

export const DataSource = {
    SaleRepo: 'SaleRepo',
    PurchaseRepo: 'PurchaseRepo',
    OtherRepo: 'OtherRepo',
    DispatchSaleFailed: 'DispatchSaleFailed',
    DispatchSelfInvoicingFailed: 'DispatchSelfInvoicingFailed',
    DispatchOtherFailed: 'DispatchOtherFailed',
    ReceptionPurchaseRevoked: 'ReceptionPurchaseRevoked',
    ReceptionPurchaseP3Revoked: 'ReceptionPurchaseP3Revoked',
    ReceptionSelfInvoicingRevoked: 'ReceptionSelfInvoicingRevoked',
    DispatchSaleProcessing: 'DispatchSaleProcessing',
    DispatchSaleAcceptance: 'DispatchSaleAcceptance',
    DispatchSaleBatchToSend: 'DispatchSaleBatchToSend',
    DispatchSelfInvoicingProcessing: 'DispatchSelfInvoicingProcessing',
    DispatchSelfInvoicingAcceptance: 'DispatchSelfInvoicingAcceptance',
    DispatchSelfInvoicingBatchToSend: 'DispatchSelfInvoicingBatchToSend',
    ReceptionPurchaseProcessing: 'ReceptionPurchaseProcessing',
    ReceptionPurchaseAlert: 'ReceptionPurchaseAlert',
    ReceptionPurchaseP3Processing: 'ReceptionPurchaseP3Processing',
    ReceptionPurchaseP3Alert: 'ReceptionPurchaseP3Alert',
    ReceptionSelfInvoicingSale: 'ReceptionSelfInvoicingSale',
    ReceptionSelfInvoicingAlert: 'ReceptionSelfInvoicingAlert',
    ReceptionRepresentationProcessing: 'ReceptionRepresentationProcessing',
    ReceptionSaleProcessing: 'ReceptionSaleProcessing',
    ReceptionOtherProcessing: 'ReceptionOtherProcessing'
} as const;

export type DataSource = typeof DataSource[keyof typeof DataSource];


/**
 * 
 * @export
 * @interface DispatchArchiveProcessRM
 */
export interface DispatchArchiveProcessRM {
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'upoNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'dateSentToMfUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'hasUpo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'requiresBatchDispatch'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'batchId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'isInAnyBatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'requiresManualAcceptUsersStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'requiresManualAcceptUsersConfirmedStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'acceptanceStatusColumnForIdPurposes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'syncProcessId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DispatchArchiveProcessRM
     */
    'timeToTimeout'?: number;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'dateReceivedUtc'?: string;
    /**
     * 
     * @type {ProcessingStatus}
     * @memberof DispatchArchiveProcessRM
     */
    'status'?: ProcessingStatus;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'subject1VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'subject1Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'subject2VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'subject2Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'subject3VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'subject3Name'?: string | null;
    /**
     * 
     * @type {Subject3Role}
     * @memberof DispatchArchiveProcessRM
     */
    'subject3Role'?: Subject3Role;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'subject3RoleDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'subject3RoleOther'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DispatchArchiveProcessRM
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'issueDate'?: string | null;
    /**
     * 
     * @type {ManualAcceptStatus}
     * @memberof DispatchArchiveProcessRM
     */
    'manualAcceptStatus'?: ManualAcceptStatus;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'requiresManualAcceptTimeStampUtc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DispatchArchiveProcessRM
     */
    'requiresManualAcceptCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DispatchArchiveProcessRM
     */
    'requiresManualAcceptCountConfirmed'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DispatchArchiveProcessRM
     */
    'requiresManualAcceptUsers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DispatchArchiveProcessRM
     */
    'requiresManualAcceptUsersConfirmed'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'requiresManualAcceptSourceModuleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'requiresManualAcceptReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'ksefNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'ksefDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'sessionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'hasBusinessError'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'businessErrorTimeStampUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'activeExit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'activeExitCompleted'?: boolean | null;
    /**
     * 
     * @type {Array<ActiveExitInfo>}
     * @memberof DispatchArchiveProcessRM
     */
    'activeExitInfo'?: Array<ActiveExitInfo> | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'ksefContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'migrationContext'?: string | null;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof DispatchArchiveProcessRM
     */
    'currencyCode'?: CurrencyCode;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof DispatchArchiveProcessRM
     */
    'kind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'saleDate'?: string | null;
    /**
     * 
     * @type {P6Tag}
     * @memberof DispatchArchiveProcessRM
     */
    'saleDateTag'?: P6Tag;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'selfInvoicing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'external'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'receivedByExternalSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'tag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'tag2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'tag3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'tag4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'tag5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'tag6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'tag7'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'tag8'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'tag9'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'tag10'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'vatDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchArchiveProcessRM
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {BusinessError}
     * @memberof DispatchArchiveProcessRM
     */
    'businessError'?: BusinessError;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchArchiveProcessRM
     */
    'manualUpload'?: boolean | null;
}


/**
 * 
 * @export
 * @interface DispatchBulkSearchResponse
 */
export interface DispatchBulkSearchResponse {
    /**
     * 
     * @type {{ [key: string]: SearchDispatchProcessResult; }}
     * @memberof DispatchBulkSearchResponse
     */
    'ids'?: { [key: string]: SearchDispatchProcessResult; } | null;
    /**
     * 
     * @type {{ [key: string]: SearchDispatchProcessResult; }}
     * @memberof DispatchBulkSearchResponse
     */
    'externalTrackingIds'?: { [key: string]: SearchDispatchProcessResult; } | null;
    /**
     * 
     * @type {{ [key: string]: SearchDispatchProcessResult; }}
     * @memberof DispatchBulkSearchResponse
     */
    'ksefNumbers'?: { [key: string]: SearchDispatchProcessResult; } | null;
    /**
     * 
     * @type {{ [key: string]: SearchDispatchProcessResult; }}
     * @memberof DispatchBulkSearchResponse
     */
    'invoiceNumbers'?: { [key: string]: SearchDispatchProcessResult; } | null;
}
/**
 * 
 * @export
 * @interface DispatchProcessRM
 */
export interface DispatchProcessRM {
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'dateSentToMfUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'hasUpo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'requiresBatchDispatch'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'batchId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'isInAnyBatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'requiresManualAcceptUsersStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'requiresManualAcceptUsersConfirmedStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'acceptanceStatusColumnForIdPurposes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'syncProcessId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DispatchProcessRM
     */
    'timeToTimeout'?: number;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'dateReceivedUtc'?: string;
    /**
     * 
     * @type {ProcessingStatus}
     * @memberof DispatchProcessRM
     */
    'status'?: ProcessingStatus;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'subject1VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'subject1Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'subject2VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'subject2Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'subject3VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'subject3Name'?: string | null;
    /**
     * 
     * @type {Subject3Role}
     * @memberof DispatchProcessRM
     */
    'subject3Role'?: Subject3Role;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'subject3RoleDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'subject3RoleOther'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DispatchProcessRM
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'issueDate'?: string | null;
    /**
     * 
     * @type {ManualAcceptStatus}
     * @memberof DispatchProcessRM
     */
    'manualAcceptStatus'?: ManualAcceptStatus;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'requiresManualAcceptTimeStampUtc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DispatchProcessRM
     */
    'requiresManualAcceptCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DispatchProcessRM
     */
    'requiresManualAcceptCountConfirmed'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DispatchProcessRM
     */
    'requiresManualAcceptUsers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DispatchProcessRM
     */
    'requiresManualAcceptUsersConfirmed'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'requiresManualAcceptSourceModuleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'requiresManualAcceptReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'ksefNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'ksefDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'sessionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'hasBusinessError'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'businessErrorTimeStampUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'activeExit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'activeExitCompleted'?: boolean | null;
    /**
     * 
     * @type {Array<ActiveExitInfo>}
     * @memberof DispatchProcessRM
     */
    'activeExitInfo'?: Array<ActiveExitInfo> | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'ksefContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'migrationContext'?: string | null;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof DispatchProcessRM
     */
    'currencyCode'?: CurrencyCode;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof DispatchProcessRM
     */
    'kind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'saleDate'?: string | null;
    /**
     * 
     * @type {P6Tag}
     * @memberof DispatchProcessRM
     */
    'saleDateTag'?: P6Tag;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'selfInvoicing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'external'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'receivedByExternalSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'tag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'tag2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'tag3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'tag4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'tag5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'tag6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'tag7'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'tag8'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'tag9'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'tag10'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'vatDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DispatchProcessRM
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {BusinessError}
     * @memberof DispatchProcessRM
     */
    'businessError'?: BusinessError;
    /**
     * 
     * @type {boolean}
     * @memberof DispatchProcessRM
     */
    'manualUpload'?: boolean | null;
}


/**
 * 
 * @export
 * @interface DispatchSimpleBulkSearchResponse
 */
export interface DispatchSimpleBulkSearchResponse {
    /**
     * 
     * @type {{ [key: string]: SimplifiedMetadataDTO; }}
     * @memberof DispatchSimpleBulkSearchResponse
     */
    'ids'?: { [key: string]: SimplifiedMetadataDTO; } | null;
    /**
     * 
     * @type {{ [key: string]: SimplifiedMetadataDTO; }}
     * @memberof DispatchSimpleBulkSearchResponse
     */
    'externalTrackingIds'?: { [key: string]: SimplifiedMetadataDTO; } | null;
    /**
     * 
     * @type {{ [key: string]: SimplifiedMetadataDTO; }}
     * @memberof DispatchSimpleBulkSearchResponse
     */
    'ksefNumbers'?: { [key: string]: SimplifiedMetadataDTO; } | null;
    /**
     * 
     * @type {{ [key: string]: SimplifiedMetadataDTO; }}
     * @memberof DispatchSimpleBulkSearchResponse
     */
    'invoiceNumbers'?: { [key: string]: SimplifiedMetadataDTO; } | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DraftStatus = {
    InProgress: 'InProgress',
    Ready: 'Ready'
} as const;

export type DraftStatus = typeof DraftStatus[keyof typeof DraftStatus];


/**
 * 
 * @export
 * @interface EditUriBucketsSettingsDto
 */
export interface EditUriBucketsSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof EditUriBucketsSettingsDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditUriBucketsSettingsDto
     */
    'accessKeyID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUriBucketsSettingsDto
     */
    'secretAccessKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUriBucketsSettingsDto
     */
    'sessionToken'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EdmContainerElementKind = {
    None: 'None',
    EntitySet: 'EntitySet',
    ActionImport: 'ActionImport',
    FunctionImport: 'FunctionImport',
    Singleton: 'Singleton'
} as const;

export type EdmContainerElementKind = typeof EdmContainerElementKind[keyof typeof EdmContainerElementKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const EdmExpressionKind = {
    None: 'None',
    BinaryConstant: 'BinaryConstant',
    BooleanConstant: 'BooleanConstant',
    DateTimeOffsetConstant: 'DateTimeOffsetConstant',
    DecimalConstant: 'DecimalConstant',
    FloatingConstant: 'FloatingConstant',
    GuidConstant: 'GuidConstant',
    IntegerConstant: 'IntegerConstant',
    StringConstant: 'StringConstant',
    DurationConstant: 'DurationConstant',
    Null: 'Null',
    Record: 'Record',
    Collection: 'Collection',
    Path: 'Path',
    If: 'If',
    Cast: 'Cast',
    IsType: 'IsType',
    FunctionApplication: 'FunctionApplication',
    LabeledExpressionReference: 'LabeledExpressionReference',
    Labeled: 'Labeled',
    PropertyPath: 'PropertyPath',
    NavigationPropertyPath: 'NavigationPropertyPath',
    DateConstant: 'DateConstant',
    TimeOfDayConstant: 'TimeOfDayConstant',
    EnumMember: 'EnumMember',
    AnnotationPath: 'AnnotationPath'
} as const;

export type EdmExpressionKind = typeof EdmExpressionKind[keyof typeof EdmExpressionKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const EdmSchemaElementKind = {
    None: 'None',
    TypeDefinition: 'TypeDefinition',
    Term: 'Term',
    Action: 'Action',
    EntityContainer: 'EntityContainer',
    Function: 'Function'
} as const;

export type EdmSchemaElementKind = typeof EdmSchemaElementKind[keyof typeof EdmSchemaElementKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const EdmTypeKind = {
    None: 'None',
    Primitive: 'Primitive',
    Entity: 'Entity',
    Complex: 'Complex',
    Collection: 'Collection',
    EntityReference: 'EntityReference',
    Enum: 'Enum',
    TypeDefinition: 'TypeDefinition',
    Untyped: 'Untyped',
    Path: 'Path'
} as const;

export type EdmTypeKind = typeof EdmTypeKind[keyof typeof EdmTypeKind];


/**
 * 
 * @export
 * @interface ExceptionDetailType
 */
export interface ExceptionDetailType {
    /**
     * 
     * @type {number}
     * @memberof ExceptionDetailType
     */
    'exceptionCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExceptionDetailType
     */
    'exceptionDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface ExceptionResponse
 */
export interface ExceptionResponse {
    /**
     * 
     * @type {ExceptionType}
     * @memberof ExceptionResponse
     */
    'exception'?: ExceptionType;
}
/**
 * 
 * @export
 * @interface ExceptionType
 */
export interface ExceptionType {
    /**
     * 
     * @type {Array<ExceptionDetailType>}
     * @memberof ExceptionType
     */
    'exceptionDetailList'?: Array<ExceptionDetailType> | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionType
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionType
     */
    'serviceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionType
     */
    'serviceCtx'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionType
     */
    'serviceName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionType
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface ExportParameters
 */
export interface ExportParameters {
    /**
     * 
     * @type {Array<FrontColumn>}
     * @memberof ExportParameters
     */
    'columns'?: Array<FrontColumn> | null;
    /**
     * 
     * @type {string}
     * @memberof ExportParameters
     */
    'filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportParameters
     */
    'orderBy'?: string | null;
    /**
     * 
     * @type {OutputFileType}
     * @memberof ExportParameters
     */
    'outputType'?: OutputFileType;
    /**
     * 
     * @type {DataSource}
     * @memberof ExportParameters
     */
    'source'?: DataSource;
    /**
     * 
     * @type {string}
     * @memberof ExportParameters
     */
    'singleQtyText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExportParameters
     */
    'reportId'?: string | null;
}


/**
 * 
 * @export
 * @interface FrontColumn
 */
export interface FrontColumn {
    /**
     * 
     * @type {string}
     * @memberof FrontColumn
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontColumn
     */
    'caption'?: string | null;
}
/**
 * 
 * @export
 * @interface GetPrintFromXmlDto
 */
export interface GetPrintFromXmlDto {
    /**
     * 
     * @type {string}
     * @memberof GetPrintFromXmlDto
     */
    'xmlString'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetPrintFromXmlDto
     */
    'asFile'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HttpStatusCode = {
    Continue: 'Continue',
    SwitchingProtocols: 'SwitchingProtocols',
    Processing: 'Processing',
    EarlyHints: 'EarlyHints',
    Ok: 'OK',
    Created: 'Created',
    Accepted: 'Accepted',
    NonAuthoritativeInformation: 'NonAuthoritativeInformation',
    NoContent: 'NoContent',
    ResetContent: 'ResetContent',
    PartialContent: 'PartialContent',
    MultiStatus: 'MultiStatus',
    AlreadyReported: 'AlreadyReported',
    ImUsed: 'IMUsed',
    MultipleChoices: 'MultipleChoices',
    MovedPermanently: 'MovedPermanently',
    Found: 'Found',
    SeeOther: 'SeeOther',
    NotModified: 'NotModified',
    UseProxy: 'UseProxy',
    Unused: 'Unused',
    RedirectKeepVerb: 'RedirectKeepVerb',
    PermanentRedirect: 'PermanentRedirect',
    BadRequest: 'BadRequest',
    Unauthorized: 'Unauthorized',
    PaymentRequired: 'PaymentRequired',
    Forbidden: 'Forbidden',
    NotFound: 'NotFound',
    MethodNotAllowed: 'MethodNotAllowed',
    NotAcceptable: 'NotAcceptable',
    ProxyAuthenticationRequired: 'ProxyAuthenticationRequired',
    RequestTimeout: 'RequestTimeout',
    Conflict: 'Conflict',
    Gone: 'Gone',
    LengthRequired: 'LengthRequired',
    PreconditionFailed: 'PreconditionFailed',
    RequestEntityTooLarge: 'RequestEntityTooLarge',
    RequestUriTooLong: 'RequestUriTooLong',
    UnsupportedMediaType: 'UnsupportedMediaType',
    RequestedRangeNotSatisfiable: 'RequestedRangeNotSatisfiable',
    ExpectationFailed: 'ExpectationFailed',
    MisdirectedRequest: 'MisdirectedRequest',
    UnprocessableEntity: 'UnprocessableEntity',
    Locked: 'Locked',
    FailedDependency: 'FailedDependency',
    UpgradeRequired: 'UpgradeRequired',
    PreconditionRequired: 'PreconditionRequired',
    TooManyRequests: 'TooManyRequests',
    RequestHeaderFieldsTooLarge: 'RequestHeaderFieldsTooLarge',
    UnavailableForLegalReasons: 'UnavailableForLegalReasons',
    InternalServerError: 'InternalServerError',
    NotImplemented: 'NotImplemented',
    BadGateway: 'BadGateway',
    ServiceUnavailable: 'ServiceUnavailable',
    GatewayTimeout: 'GatewayTimeout',
    HttpVersionNotSupported: 'HttpVersionNotSupported',
    VariantAlsoNegotiates: 'VariantAlsoNegotiates',
    InsufficientStorage: 'InsufficientStorage',
    LoopDetected: 'LoopDetected',
    NotExtended: 'NotExtended',
    NetworkAuthenticationRequired: 'NetworkAuthenticationRequired'
} as const;

export type HttpStatusCode = typeof HttpStatusCode[keyof typeof HttpStatusCode];


/**
 * 
 * @export
 * @interface IEdmEntityContainer
 */
export interface IEdmEntityContainer {
    /**
     * 
     * @type {Array<IEdmEntityContainerElement>}
     * @memberof IEdmEntityContainer
     */
    'elements'?: Array<IEdmEntityContainerElement> | null;
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmEntityContainer
     */
    'schemaElementKind'?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainer
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainer
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmEntityContainerElement
 */
export interface IEdmEntityContainerElement {
    /**
     * 
     * @type {EdmContainerElementKind}
     * @memberof IEdmEntityContainerElement
     */
    'containerElementKind'?: EdmContainerElementKind;
    /**
     * 
     * @type {IEdmEntityContainer}
     * @memberof IEdmEntityContainerElement
     */
    'container'?: IEdmEntityContainer;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainerElement
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmExpression
 */
export interface IEdmExpression {
    /**
     * 
     * @type {EdmExpressionKind}
     * @memberof IEdmExpression
     */
    'expressionKind'?: EdmExpressionKind;
}


/**
 * 
 * @export
 * @interface IEdmModel
 */
export interface IEdmModel {
    /**
     * 
     * @type {Array<IEdmSchemaElement>}
     * @memberof IEdmModel
     */
    'schemaElements'?: Array<IEdmSchemaElement> | null;
    /**
     * 
     * @type {Array<IEdmVocabularyAnnotation>}
     * @memberof IEdmModel
     */
    'vocabularyAnnotations'?: Array<IEdmVocabularyAnnotation> | null;
    /**
     * 
     * @type {Array<IEdmModel>}
     * @memberof IEdmModel
     */
    'referencedModels'?: Array<IEdmModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IEdmModel
     */
    'declaredNamespaces'?: Array<string> | null;
    /**
     * 
     * @type {object}
     * @memberof IEdmModel
     */
    'directValueAnnotationsManager'?: object;
    /**
     * 
     * @type {IEdmEntityContainer}
     * @memberof IEdmModel
     */
    'entityContainer'?: IEdmEntityContainer;
}
/**
 * 
 * @export
 * @interface IEdmSchemaElement
 */
export interface IEdmSchemaElement {
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmSchemaElement
     */
    'schemaElementKind'?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmSchemaElement
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmSchemaElement
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmTerm
 */
export interface IEdmTerm {
    /**
     * 
     * @type {IEdmTypeReference}
     * @memberof IEdmTerm
     */
    'type'?: IEdmTypeReference;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'appliesTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'defaultValue'?: string | null;
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmTerm
     */
    'schemaElementKind'?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmType
 */
export interface IEdmType {
    /**
     * 
     * @type {EdmTypeKind}
     * @memberof IEdmType
     */
    'typeKind'?: EdmTypeKind;
}


/**
 * 
 * @export
 * @interface IEdmTypeReference
 */
export interface IEdmTypeReference {
    /**
     * 
     * @type {boolean}
     * @memberof IEdmTypeReference
     */
    'isNullable'?: boolean;
    /**
     * 
     * @type {IEdmType}
     * @memberof IEdmTypeReference
     */
    'definition'?: IEdmType;
}
/**
 * 
 * @export
 * @interface IEdmVocabularyAnnotation
 */
export interface IEdmVocabularyAnnotation {
    /**
     * 
     * @type {string}
     * @memberof IEdmVocabularyAnnotation
     */
    'qualifier'?: string | null;
    /**
     * 
     * @type {IEdmTerm}
     * @memberof IEdmVocabularyAnnotation
     */
    'term'?: IEdmTerm;
    /**
     * 
     * @type {object}
     * @memberof IEdmVocabularyAnnotation
     */
    'target'?: object;
    /**
     * 
     * @type {IEdmExpression}
     * @memberof IEdmVocabularyAnnotation
     */
    'value'?: IEdmExpression;
}
/**
 * 
 * @export
 * @interface InitTokenResponse
 */
export interface InitTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof InitTokenResponse
     */
    'timestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InitTokenResponse
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {SessionToken}
     * @memberof InitTokenResponse
     */
    'sessionToken'?: SessionToken;
}
/**
 * 
 * @export
 * @interface Int32ANSICodeKeyValuePair
 */
export interface Int32ANSICodeKeyValuePair {
    /**
     * 
     * @type {number}
     * @memberof Int32ANSICodeKeyValuePair
     */
    'key'?: number;
    /**
     * 
     * @type {ANSICode}
     * @memberof Int32ANSICodeKeyValuePair
     */
    'value'?: ANSICode;
}


/**
 * 
 * @export
 * @interface Int32CompressionTypeKeyValuePair
 */
export interface Int32CompressionTypeKeyValuePair {
    /**
     * 
     * @type {number}
     * @memberof Int32CompressionTypeKeyValuePair
     */
    'key'?: number;
    /**
     * 
     * @type {CompressionType}
     * @memberof Int32CompressionTypeKeyValuePair
     */
    'value'?: CompressionType;
}


/**
 * 
 * @export
 * @interface Int32DataSourceKeyValuePair
 */
export interface Int32DataSourceKeyValuePair {
    /**
     * 
     * @type {number}
     * @memberof Int32DataSourceKeyValuePair
     */
    'key'?: number;
    /**
     * 
     * @type {DataSource}
     * @memberof Int32DataSourceKeyValuePair
     */
    'value'?: DataSource;
}


/**
 * 
 * @export
 * @interface Int32StringKeyValuePair
 */
export interface Int32StringKeyValuePair {
    /**
     * 
     * @type {number}
     * @memberof Int32StringKeyValuePair
     */
    'key'?: number;
    /**
     * 
     * @type {string}
     * @memberof Int32StringKeyValuePair
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface InteractiveSessionStatus
 */
export interface InteractiveSessionStatus {
    /**
     * 
     * @type {number}
     * @memberof InteractiveSessionStatus
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof InteractiveSessionStatus
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof InteractiveSessionStatus
     */
    'pageOffset'?: number;
    /**
     * 
     * @type {Array<InvoiceStatus>}
     * @memberof InteractiveSessionStatus
     */
    'invoiceStatusList'?: Array<InvoiceStatus> | null;
    /**
     * 
     * @type {string}
     * @memberof InteractiveSessionStatus
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof InteractiveSessionStatus
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InteractiveSessionStatus
     */
    'processingCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InteractiveSessionStatus
     */
    'processingDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractiveSessionStatus
     */
    'elementReferenceNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface InteractiveSessionTerminationOutput
 */
export interface InteractiveSessionTerminationOutput {
    /**
     * 
     * @type {string}
     * @memberof InteractiveSessionTerminationOutput
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof InteractiveSessionTerminationOutput
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InteractiveSessionTerminationOutput
     */
    'processingCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InteractiveSessionTerminationOutput
     */
    'processingDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InteractiveSessionTerminationOutput
     */
    'elementReferenceNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface InvoiceDraftAggregate
 */
export interface InvoiceDraftAggregate {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'subject1VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'subject1Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'subject2VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'subject2Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'subject3VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'subject3Name'?: string | null;
    /**
     * 
     * @type {Subject3Role}
     * @memberof InvoiceDraftAggregate
     */
    'subject3Role'?: Subject3Role;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'subject3RoleDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDraftAggregate
     */
    'subject3RoleOther'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDraftAggregate
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'issueDate'?: string | null;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof InvoiceDraftAggregate
     */
    'currencyCode'?: CurrencyCode;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof InvoiceDraftAggregate
     */
    'kind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'saleDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDraftAggregate
     */
    'selfInvoicing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDraftAggregate
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof InvoiceDraftAggregate
     */
    'tokens'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceDraftAggregate
     */
    'keysForXml'?: Array<string> | null;
    /**
     * 
     * @type {DraftStatus}
     * @memberof InvoiceDraftAggregate
     */
    'status'?: DraftStatus;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'ksefContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'vatDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'numberingSeriesId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDraftAggregate
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface InvoiceDraftDto
 */
export interface InvoiceDraftDto {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof InvoiceDraftDto
     */
    'tokens'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceDraftDto
     */
    'keysForXml'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InvoiceDraftUpdateRequest
 */
export interface InvoiceDraftUpdateRequest {
    /**
     * 
     * @type {DraftStatus}
     * @memberof InvoiceDraftUpdateRequest
     */
    'expectedStatus'?: DraftStatus;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof InvoiceDraftUpdateRequest
     */
    'tokens'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceDraftUpdateRequest
     */
    'keysForXml'?: Array<string> | null;
}


/**
 * Data Transfer Object for invoice editing operations.
 * @export
 * @interface InvoiceEditDto
 */
export interface InvoiceEditDto {
    /**
     * Gets or sets the tokens for the invoice.
     * @type {{ [key: string]: any; }}
     * @memberof InvoiceEditDto
     */
    'tokens'?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface InvoiceForModules
 */
export interface InvoiceForModules {
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'ksefContext'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceForModules
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'issueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'number'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceForModules
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceForModules
     */
    'vatAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'subject1VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'subject1Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'subject2VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'subject2Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'subject3VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'subject3Name'?: string | null;
    /**
     * 
     * @type {Subject3Role}
     * @memberof InvoiceForModules
     */
    'subject3Role'?: Subject3Role;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'subject3RoleDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceForModules
     */
    'subject3RoleOther'?: boolean;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof InvoiceForModules
     */
    'currencyCode'?: CurrencyCode;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof InvoiceForModules
     */
    'kind'?: InvoiceKind;
    /**
     * 
     * @type {P6Tag}
     * @memberof InvoiceForModules
     */
    'saleDateTag'?: P6Tag;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'saleDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceForModules
     */
    'selfInvoicing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'tag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'tag2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'tag3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'tag4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'tag5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'tag6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'tag7'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'tag8'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'tag9'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'tag10'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'vatDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceForModules
     */
    'ksefDate'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceKind = {
    Vat: 'VAT',
    Kor: 'KOR',
    Zal: 'ZAL',
    Roz: 'ROZ',
    Upr: 'UPR',
    KorZal: 'KOR_ZAL',
    KorRoz: 'KOR_ROZ'
} as const;

export type InvoiceKind = typeof InvoiceKind[keyof typeof InvoiceKind];


/**
 * 
 * @export
 * @interface InvoiceStatus
 */
export interface InvoiceStatus {
    /**
     * 
     * @type {number}
     * @memberof InvoiceStatus
     */
    'processingCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceStatus
     */
    'processingDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceStatus
     */
    'elementReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceStatus
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceStatus
     */
    'ksefReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceStatus
     */
    'acquisitionTimestamp'?: string | null;
}
/**
 * 
 * @export
 * @interface JobParameter
 */
export interface JobParameter {
    /**
     * 
     * @type {string}
     * @memberof JobParameter
     */
    'targetId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobParameter
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobParameter
     */
    'resultFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobParameter
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const JobProcessingStatus = {
    Created: 'Created',
    Processing: 'Processing',
    Error: 'Error',
    Finished: 'Finished',
    FinishedWithoutResults: 'FinishedWithoutResults'
} as const;

export type JobProcessingStatus = typeof JobProcessingStatus[keyof typeof JobProcessingStatus];


/**
 * 
 * @export
 * @interface JobRM
 */
export interface JobRM {
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'jobType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'arguments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'dataSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'startDateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'endDateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'resultFileId'?: string | null;
    /**
     * 
     * @type {JobProcessingStatus}
     * @memberof JobRM
     */
    'status'?: JobProcessingStatus;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'resultFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobRM
     */
    'resultFileType'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ManualAcceptStatus = {
    NotRequired: 'NotRequired',
    AwaitingAccept: 'AwaitingAccept',
    Accepted: 'Accepted',
    Rejected: 'Rejected'
} as const;

export type ManualAcceptStatus = typeof ManualAcceptStatus[keyof typeof ManualAcceptStatus];


/**
 * 
 * @export
 * @interface ODataEntitySetInfo
 */
export interface ODataEntitySetInfo {
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    'title'?: string | null;
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataEntitySetInfo
     */
    'typeAnnotation'?: ODataTypeAnnotation;
}
/**
 * 
 * @export
 * @interface ODataFunctionImportInfo
 */
export interface ODataFunctionImportInfo {
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    'title'?: string | null;
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataFunctionImportInfo
     */
    'typeAnnotation'?: ODataTypeAnnotation;
}
/**
 * 
 * @export
 * @interface ODataServiceDocument
 */
export interface ODataServiceDocument {
    /**
     * 
     * @type {Array<ODataEntitySetInfo>}
     * @memberof ODataServiceDocument
     */
    'entitySets'?: Array<ODataEntitySetInfo> | null;
    /**
     * 
     * @type {Array<ODataSingletonInfo>}
     * @memberof ODataServiceDocument
     */
    'singletons'?: Array<ODataSingletonInfo> | null;
    /**
     * 
     * @type {Array<ODataFunctionImportInfo>}
     * @memberof ODataServiceDocument
     */
    'functionImports'?: Array<ODataFunctionImportInfo> | null;
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataServiceDocument
     */
    'typeAnnotation'?: ODataTypeAnnotation;
}
/**
 * 
 * @export
 * @interface ODataSingletonInfo
 */
export interface ODataSingletonInfo {
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    'title'?: string | null;
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataSingletonInfo
     */
    'typeAnnotation'?: ODataTypeAnnotation;
}
/**
 * 
 * @export
 * @interface ODataTypeAnnotation
 */
export interface ODataTypeAnnotation {
    /**
     * 
     * @type {string}
     * @memberof ODataTypeAnnotation
     */
    'typeName'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OutputFileType = {
    Csv: 'Csv',
    Xlsx: 'Xlsx'
} as const;

export type OutputFileType = typeof OutputFileType[keyof typeof OutputFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const P6Tag = {
    O: 'O',
    W: 'W'
} as const;

export type P6Tag = typeof P6Tag[keyof typeof P6Tag];


/**
 * 
 * @export
 * @enum {string}
 */

export const PipelineType = {
    Unspecified: 'Unspecified',
    Sales: 'Sales',
    SelfInvoicing: 'SelfInvoicing',
    Other: 'Other',
    PurchaseSubject2: 'PurchaseSubject2',
    PurchaseSubject3: 'PurchaseSubject3',
    SalesSelfInvoicing: 'SalesSelfInvoicing'
} as const;

export type PipelineType = typeof PipelineType[keyof typeof PipelineType];


/**
 * 
 * @export
 * @interface PostBatchDto
 */
export interface PostBatchDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PostBatchDto
     */
    'invoicesIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PostBatchDto
     */
    'senderVatNumber': string;
    /**
     * 
     * @type {SubjectType}
     * @memberof PostBatchDto
     */
    'batchDispatchType': SubjectType;
    /**
     * 
     * @type {string}
     * @memberof PostBatchDto
     */
    'userDbId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostBatchDto
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostBatchDto
     */
    'scheduledDateUtc'?: string | null;
}


/**
 * 
 * @export
 * @interface PostJobCreateArchiveDto
 */
export interface PostJobCreateArchiveDto {
    /**
     * 
     * @type {Array<JobParameter>}
     * @memberof PostJobCreateArchiveDto
     */
    'parameters'?: Array<JobParameter> | null;
    /**
     * 
     * @type {ArchiveFileType}
     * @memberof PostJobCreateArchiveDto
     */
    'archiveType'?: ArchiveFileType;
    /**
     * 
     * @type {ArchiveFileExtension}
     * @memberof PostJobCreateArchiveDto
     */
    'extension'?: ArchiveFileExtension;
}


/**
 * 
 * @export
 * @interface PostTagsRequest
 */
export interface PostTagsRequest {
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'invoiceId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostTagsRequest
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'tag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'tag2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'tag3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'tag4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'tag5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'tag6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'tag7'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'tag8'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'tag9'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostTagsRequest
     */
    'tag10'?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProcessingStatus = {
    NotStarted: 'NotStarted',
    Received: 'Received',
    Receiving: 'Receiving',
    Processing: 'Processing',
    ReadyForDispatch: 'ReadyForDispatch',
    Sending: 'Sending',
    KsefNumberReceived: 'KsefNumberReceived',
    Archived: 'Archived',
    ErrorArchived: 'ErrorArchived'
} as const;

export type ProcessingStatus = typeof ProcessingStatus[keyof typeof ProcessingStatus];


/**
 * 
 * @export
 * @interface ReceptionBulkSearchResponse
 */
export interface ReceptionBulkSearchResponse {
    /**
     * 
     * @type {{ [key: string]: ReceptionProcessRM; }}
     * @memberof ReceptionBulkSearchResponse
     */
    'ids'?: { [key: string]: ReceptionProcessRM; } | null;
    /**
     * 
     * @type {{ [key: string]: ReceptionProcessRM; }}
     * @memberof ReceptionBulkSearchResponse
     */
    'ksefNumbers'?: { [key: string]: ReceptionProcessRM; } | null;
    /**
     * 
     * @type {{ [key: string]: ReceptionProcessRM; }}
     * @memberof ReceptionBulkSearchResponse
     */
    'invoiceNumbers'?: { [key: string]: ReceptionProcessRM; } | null;
}
/**
 * 
 * @export
 * @interface ReceptionCheckupRM
 */
export interface ReceptionCheckupRM {
    /**
     * 
     * @type {string}
     * @memberof ReceptionCheckupRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceptionCheckupRM
     */
    'dateScheduledUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceptionCheckupRM
     */
    'vatNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionCheckupRM
     */
    'dateFromUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceptionCheckupRM
     */
    'dateToUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceptionCheckupRM
     */
    'syncStartUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionCheckupRM
     */
    'syncEndUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionCheckupRM
     */
    'sessionId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReceptionCheckupRM
     */
    'invoiceCountTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReceptionCheckupRM
     */
    'invoiceCountReceived'?: number;
    /**
     * 
     * @type {BusinessError}
     * @memberof ReceptionCheckupRM
     */
    'businessError'?: BusinessError;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionCheckupRM
     */
    'hasBusinessError'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionCheckupRM
     */
    'businessErrorTimeStampUtc'?: string | null;
    /**
     * 
     * @type {SubjectType}
     * @memberof ReceptionCheckupRM
     */
    'subjectType'?: SubjectType;
}


/**
 * 
 * @export
 * @interface ReceptionProcessRM
 */
export interface ReceptionProcessRM {
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'requiresManualAcceptUsersStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'requiresManualAcceptUsersConfirmedStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'acceptanceStatusColumnForIdPurposes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'syncProcessId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'batchId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'dateReceivedUtc'?: string;
    /**
     * 
     * @type {ProcessingStatus}
     * @memberof ReceptionProcessRM
     */
    'status'?: ProcessingStatus;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'subject1VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'subject1Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'subject2VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'subject2Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'subject3VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'subject3Name'?: string | null;
    /**
     * 
     * @type {Subject3Role}
     * @memberof ReceptionProcessRM
     */
    'subject3Role'?: Subject3Role;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'subject3RoleDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionProcessRM
     */
    'subject3RoleOther'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReceptionProcessRM
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'issueDate'?: string | null;
    /**
     * 
     * @type {ManualAcceptStatus}
     * @memberof ReceptionProcessRM
     */
    'manualAcceptStatus'?: ManualAcceptStatus;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'requiresManualAcceptTimeStampUtc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReceptionProcessRM
     */
    'requiresManualAcceptCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReceptionProcessRM
     */
    'requiresManualAcceptCountConfirmed'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReceptionProcessRM
     */
    'requiresManualAcceptUsers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReceptionProcessRM
     */
    'requiresManualAcceptUsersConfirmed'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'requiresManualAcceptSourceModuleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'requiresManualAcceptReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'ksefNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'ksefDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'sessionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionProcessRM
     */
    'hasBusinessError'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'businessErrorTimeStampUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionProcessRM
     */
    'activeExit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionProcessRM
     */
    'activeExitCompleted'?: boolean | null;
    /**
     * 
     * @type {Array<ActiveExitInfo>}
     * @memberof ReceptionProcessRM
     */
    'activeExitInfo'?: Array<ActiveExitInfo> | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'ksefContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'migrationContext'?: string | null;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof ReceptionProcessRM
     */
    'currencyCode'?: CurrencyCode;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof ReceptionProcessRM
     */
    'kind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'saleDate'?: string | null;
    /**
     * 
     * @type {P6Tag}
     * @memberof ReceptionProcessRM
     */
    'saleDateTag'?: P6Tag;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionProcessRM
     */
    'selfInvoicing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionProcessRM
     */
    'external'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionProcessRM
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionProcessRM
     */
    'receivedByExternalSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'tag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'tag2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'tag3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'tag4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'tag5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'tag6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'tag7'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'tag8'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'tag9'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'tag10'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'vatDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionProcessRM
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {BusinessError}
     * @memberof ReceptionProcessRM
     */
    'businessError'?: BusinessError;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionProcessRM
     */
    'manualUpload'?: boolean | null;
}


/**
 * 
 * @export
 * @interface ReceptionRepositoryProcessRM
 */
export interface ReceptionRepositoryProcessRM {
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'upoNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'requiresManualAcceptUsersStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'requiresManualAcceptUsersConfirmedStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'acceptanceStatusColumnForIdPurposes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'syncProcessId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'batchId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'dateReceivedUtc'?: string;
    /**
     * 
     * @type {ProcessingStatus}
     * @memberof ReceptionRepositoryProcessRM
     */
    'status'?: ProcessingStatus;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'subject1VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'subject1Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'subject2VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'subject2Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'subject3VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'subject3Name'?: string | null;
    /**
     * 
     * @type {Subject3Role}
     * @memberof ReceptionRepositoryProcessRM
     */
    'subject3Role'?: Subject3Role;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'subject3RoleDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionRepositoryProcessRM
     */
    'subject3RoleOther'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReceptionRepositoryProcessRM
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'issueDate'?: string | null;
    /**
     * 
     * @type {ManualAcceptStatus}
     * @memberof ReceptionRepositoryProcessRM
     */
    'manualAcceptStatus'?: ManualAcceptStatus;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'requiresManualAcceptTimeStampUtc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReceptionRepositoryProcessRM
     */
    'requiresManualAcceptCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReceptionRepositoryProcessRM
     */
    'requiresManualAcceptCountConfirmed'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReceptionRepositoryProcessRM
     */
    'requiresManualAcceptUsers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReceptionRepositoryProcessRM
     */
    'requiresManualAcceptUsersConfirmed'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'requiresManualAcceptSourceModuleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'requiresManualAcceptReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'ksefNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'ksefDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'sessionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionRepositoryProcessRM
     */
    'hasBusinessError'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'businessErrorTimeStampUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionRepositoryProcessRM
     */
    'activeExit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionRepositoryProcessRM
     */
    'activeExitCompleted'?: boolean | null;
    /**
     * 
     * @type {Array<ActiveExitInfo>}
     * @memberof ReceptionRepositoryProcessRM
     */
    'activeExitInfo'?: Array<ActiveExitInfo> | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'ksefContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'migrationContext'?: string | null;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof ReceptionRepositoryProcessRM
     */
    'currencyCode'?: CurrencyCode;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof ReceptionRepositoryProcessRM
     */
    'kind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'saleDate'?: string | null;
    /**
     * 
     * @type {P6Tag}
     * @memberof ReceptionRepositoryProcessRM
     */
    'saleDateTag'?: P6Tag;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionRepositoryProcessRM
     */
    'selfInvoicing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionRepositoryProcessRM
     */
    'external'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionRepositoryProcessRM
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionRepositoryProcessRM
     */
    'receivedByExternalSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'tag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'tag2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'tag3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'tag4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'tag5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'tag6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'tag7'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'tag8'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'tag9'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'tag10'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'vatDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReceptionRepositoryProcessRM
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {BusinessError}
     * @memberof ReceptionRepositoryProcessRM
     */
    'businessError'?: BusinessError;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionRepositoryProcessRM
     */
    'manualUpload'?: boolean | null;
}


/**
 * 
 * @export
 * @interface ReceptionSimpleBulkSearchResponse
 */
export interface ReceptionSimpleBulkSearchResponse {
    /**
     * 
     * @type {{ [key: string]: SimplifiedMetadataDTO; }}
     * @memberof ReceptionSimpleBulkSearchResponse
     */
    'ids'?: { [key: string]: SimplifiedMetadataDTO; } | null;
    /**
     * 
     * @type {{ [key: string]: SimplifiedMetadataDTO; }}
     * @memberof ReceptionSimpleBulkSearchResponse
     */
    'ksefNumbers'?: { [key: string]: SimplifiedMetadataDTO; } | null;
    /**
     * 
     * @type {{ [key: string]: SimplifiedMetadataDTO; }}
     * @memberof ReceptionSimpleBulkSearchResponse
     */
    'invoiceNumbers'?: { [key: string]: SimplifiedMetadataDTO; } | null;
}
/**
 * 
 * @export
 * @interface RoleGrantorIdentifier
 */
export interface RoleGrantorIdentifier {
    /**
     * 
     * @type {string}
     * @memberof RoleGrantorIdentifier
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleGrantorIdentifier
     */
    'identifier'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchDispatchProcessResult
 */
export interface SearchDispatchProcessResult {
    /**
     * 
     * @type {BusinessError}
     * @memberof SearchDispatchProcessResult
     */
    'businessError'?: BusinessError;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchDispatchProcessResult
     */
    'businessErrorMsgs'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'ksefNumberReceivedTimeStampUtc'?: string | null;
    /**
     * 
     * @type {XfxNotificationEvent}
     * @memberof SearchDispatchProcessResult
     */
    'ksefSubmissionStatus'?: XfxNotificationEvent;
    /**
     * 
     * @type {number}
     * @memberof SearchDispatchProcessResult
     */
    'ksefSubmissionStatusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'qrCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'dateSentToMfUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'hasUpo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'requiresBatchDispatch'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'batchId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'isInAnyBatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'requiresManualAcceptUsersStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'requiresManualAcceptUsersConfirmedStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'acceptanceStatusColumnForIdPurposes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'syncProcessId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchDispatchProcessResult
     */
    'timeToTimeout'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'dateReceivedUtc'?: string;
    /**
     * 
     * @type {ProcessingStatus}
     * @memberof SearchDispatchProcessResult
     */
    'status'?: ProcessingStatus;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'subject1VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'subject1Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'subject2VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'subject2Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'subject3VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'subject3Name'?: string | null;
    /**
     * 
     * @type {Subject3Role}
     * @memberof SearchDispatchProcessResult
     */
    'subject3Role'?: Subject3Role;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'subject3RoleDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'subject3RoleOther'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchDispatchProcessResult
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'issueDate'?: string | null;
    /**
     * 
     * @type {ManualAcceptStatus}
     * @memberof SearchDispatchProcessResult
     */
    'manualAcceptStatus'?: ManualAcceptStatus;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'requiresManualAcceptTimeStampUtc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchDispatchProcessResult
     */
    'requiresManualAcceptCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchDispatchProcessResult
     */
    'requiresManualAcceptCountConfirmed'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchDispatchProcessResult
     */
    'requiresManualAcceptUsers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchDispatchProcessResult
     */
    'requiresManualAcceptUsersConfirmed'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'requiresManualAcceptSourceModuleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'requiresManualAcceptReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'ksefNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'ksefDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'sessionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'hasBusinessError'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'businessErrorTimeStampUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'activeExit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'activeExitCompleted'?: boolean | null;
    /**
     * 
     * @type {Array<ActiveExitInfo>}
     * @memberof SearchDispatchProcessResult
     */
    'activeExitInfo'?: Array<ActiveExitInfo> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'ksefContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'migrationContext'?: string | null;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof SearchDispatchProcessResult
     */
    'currencyCode'?: CurrencyCode;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof SearchDispatchProcessResult
     */
    'kind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'saleDate'?: string | null;
    /**
     * 
     * @type {P6Tag}
     * @memberof SearchDispatchProcessResult
     */
    'saleDateTag'?: P6Tag;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'selfInvoicing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'external'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'receivedByExternalSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'tag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'tag2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'tag3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'tag4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'tag5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'tag6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'tag7'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'tag8'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'tag9'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'tag10'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'vatDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchDispatchProcessResult
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchDispatchProcessResult
     */
    'manualUpload'?: boolean | null;
}


/**
 * 
 * @export
 * @interface SearchReceptionProcessResult
 */
export interface SearchReceptionProcessResult {
    /**
     * 
     * @type {BusinessError}
     * @memberof SearchReceptionProcessResult
     */
    'businessError'?: BusinessError;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchReceptionProcessResult
     */
    'businessErrorMsgs'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'requiresManualAcceptUsersStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'requiresManualAcceptUsersConfirmedStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'acceptanceStatusColumnForIdPurposes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'syncProcessId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'externalTrackingId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'batchId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'dateReceivedUtc'?: string;
    /**
     * 
     * @type {ProcessingStatus}
     * @memberof SearchReceptionProcessResult
     */
    'status'?: ProcessingStatus;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'subject1VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'subject1Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'subject2VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'subject2Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'subject3VatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'subject3Name'?: string | null;
    /**
     * 
     * @type {Subject3Role}
     * @memberof SearchReceptionProcessResult
     */
    'subject3Role'?: Subject3Role;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'subject3RoleDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchReceptionProcessResult
     */
    'subject3RoleOther'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchReceptionProcessResult
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'issueDate'?: string | null;
    /**
     * 
     * @type {ManualAcceptStatus}
     * @memberof SearchReceptionProcessResult
     */
    'manualAcceptStatus'?: ManualAcceptStatus;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'requiresManualAcceptTimeStampUtc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchReceptionProcessResult
     */
    'requiresManualAcceptCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchReceptionProcessResult
     */
    'requiresManualAcceptCountConfirmed'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchReceptionProcessResult
     */
    'requiresManualAcceptUsers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchReceptionProcessResult
     */
    'requiresManualAcceptUsersConfirmed'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'requiresManualAcceptSourceModuleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'requiresManualAcceptReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'ksefNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'ksefDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'sessionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchReceptionProcessResult
     */
    'hasBusinessError'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'businessErrorTimeStampUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchReceptionProcessResult
     */
    'activeExit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchReceptionProcessResult
     */
    'activeExitCompleted'?: boolean | null;
    /**
     * 
     * @type {Array<ActiveExitInfo>}
     * @memberof SearchReceptionProcessResult
     */
    'activeExitInfo'?: Array<ActiveExitInfo> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'ksefContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'migrationContext'?: string | null;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof SearchReceptionProcessResult
     */
    'currencyCode'?: CurrencyCode;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof SearchReceptionProcessResult
     */
    'kind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'saleDate'?: string | null;
    /**
     * 
     * @type {P6Tag}
     * @memberof SearchReceptionProcessResult
     */
    'saleDateTag'?: P6Tag;
    /**
     * 
     * @type {boolean}
     * @memberof SearchReceptionProcessResult
     */
    'selfInvoicing'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchReceptionProcessResult
     */
    'external'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchReceptionProcessResult
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchReceptionProcessResult
     */
    'receivedByExternalSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'tag1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'tag2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'tag3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'tag4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'tag5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'tag6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'tag7'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'tag8'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'tag9'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'tag10'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'vatDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReceptionProcessResult
     */
    'schemaVersion'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchReceptionProcessResult
     */
    'manualUpload'?: boolean | null;
}


/**
 * 
 * @export
 * @interface SessionContext
 */
export interface SessionContext {
    /**
     * 
     * @type {ContextIdentifier}
     * @memberof SessionContext
     */
    'contextIdentifier'?: ContextIdentifier;
    /**
     * 
     * @type {ContextName}
     * @memberof SessionContext
     */
    'contextName'?: ContextName;
    /**
     * 
     * @type {Array<CredentialsRoleResponseTokenType>}
     * @memberof SessionContext
     */
    'credentialsRoleList'?: Array<CredentialsRoleResponseTokenType> | null;
}
/**
 * 
 * @export
 * @interface SessionRM
 */
export interface SessionRM {
    /**
     * 
     * @type {string}
     * @memberof SessionRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionRM
     */
    'sessionKind'?: string | null;
    /**
     * 
     * @type {InitTokenResponse}
     * @memberof SessionRM
     */
    'session'?: InitTokenResponse;
    /**
     * 
     * @type {string}
     * @memberof SessionRM
     */
    'sessionInitialisationTimeStampUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SessionRM
     */
    'scheduledForSessionTermination'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionRM
     */
    'scheduledForSessionTerminationTimeStampUtc'?: string | null;
    /**
     * 
     * @type {InteractiveSessionTerminationOutput}
     * @memberof SessionRM
     */
    'sessionTerminationDetails'?: InteractiveSessionTerminationOutput;
    /**
     * 
     * @type {boolean}
     * @memberof SessionRM
     */
    'sessionTerminationInitiated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionRM
     */
    'sessionTerminationInitiationTimestampUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SessionRM
     */
    'sessionTerminated'?: boolean;
    /**
     * 
     * @type {InteractiveSessionStatus}
     * @memberof SessionRM
     */
    'sessionStatus'?: InteractiveSessionStatus;
    /**
     * 
     * @type {string}
     * @memberof SessionRM
     */
    'lastSessionStatusCheckupTimestampUtc'?: string | null;
    /**
     * 
     * @type {ExceptionResponse}
     * @memberof SessionRM
     */
    'sessionException'?: ExceptionResponse;
    /**
     * 
     * @type {boolean}
     * @memberof SessionRM
     */
    'hasSessionException'?: boolean;
    /**
     * 
     * @type {UpoOutput}
     * @memberof SessionRM
     */
    'upo'?: UpoOutput;
    /**
     * 
     * @type {boolean}
     * @memberof SessionRM
     */
    'hasUpo'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionRM
     */
    'dateCreatedUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionRM
     */
    'organizationVatNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SessionRM
     */
    'activeInKsef'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SessionRM
     */
    'activeLocally'?: boolean | null;
}
/**
 * 
 * @export
 * @interface SessionToken
 */
export interface SessionToken {
    /**
     * 
     * @type {SessionContext}
     * @memberof SessionToken
     */
    'context'?: SessionContext;
    /**
     * 
     * @type {string}
     * @memberof SessionToken
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface SetReceivedInvoiceDto
 */
export interface SetReceivedInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof SetReceivedInvoiceDto
     */
    'invoiceId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetReceivedInvoiceDto
     */
    'receivedByExternalSystem'?: boolean | null;
}
/**
 * 
 * @export
 * @interface SimplifiedMetadataDTO
 */
export interface SimplifiedMetadataDTO {
    /**
     * 
     * @type {string}
     * @memberof SimplifiedMetadataDTO
     */
    'ksefNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedMetadataDTO
     */
    'ksefDate'?: string;
}
/**
 * 
 * @export
 * @interface StartPrepareBatchCommandResult
 */
export interface StartPrepareBatchCommandResult {
    /**
     * 
     * @type {string}
     * @memberof StartPrepareBatchCommandResult
     */
    'xfxTrackingId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Subject3Role = {
    Factor: 'Factor',
    Recipient: 'Recipient',
    OriginalSubject: 'Original_subject',
    AdditionalPurchaser: 'Additional_purchaser',
    InvoiceIssuer: 'Invoice_issuer',
    Payer: 'Payer',
    LocalGovernmentUnitIssuer: 'LocalGovernmentUnit_issuer',
    LocalGovernmentUnitRecipient: 'LocalGovernmentUnit_recipient',
    VatGroupMemberIssuer: 'VatGroupMember_issuer',
    VatGroupMemberRecipient: 'VatGroupMember_recipient'
} as const;

export type Subject3Role = typeof Subject3Role[keyof typeof Subject3Role];


/**
 * 
 * @export
 * @enum {string}
 */

export const SubjectType = {
    Subject1: 'Subject1',
    Subject2: 'Subject2',
    Subject3: 'Subject3'
} as const;

export type SubjectType = typeof SubjectType[keyof typeof SubjectType];


/**
 * 
 * @export
 * @interface UpoOutput
 */
export interface UpoOutput {
    /**
     * 
     * @type {string}
     * @memberof UpoOutput
     */
    'upo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpoOutput
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpoOutput
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpoOutput
     */
    'processingCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpoOutput
     */
    'processingDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpoOutput
     */
    'elementReferenceNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const XfxNotificationEvent = {
    InvoiceDispatchInprogress: 'INVOICE_DISPATCH_INPROGRESS',
    InvoiceDispatchSubmitted: 'INVOICE_DISPATCH_SUBMITTED',
    InvoiceDispatchTimeout: 'INVOICE_DISPATCH_TIMEOUT',
    InvoiceDispatchDowntime: 'INVOICE_DISPATCH_DOWNTIME',
    InvoiceDispatchRejected: 'INVOICE_DISPATCH_REJECTED',
    InvoiceDispatchUpoReceived: 'INVOICE_DISPATCH_UPO_RECEIVED',
    InvoiceReceptionError: 'INVOICE_RECEPTION_ERROR',
    InvoiceReceptionReceived: 'INVOICE_RECEPTION_RECEIVED',
    NoToken: 'NO_TOKEN'
} as const;

export type XfxNotificationEvent = typeof XfxNotificationEvent[keyof typeof XfxNotificationEvent];


/**
 * 
 * @export
 * @interface XfxProblemDetails
 */
export interface XfxProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {Array<string>}
     * @memberof XfxProblemDetails
     */
    'xfxDetails'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof XfxProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XfxProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof XfxProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XfxProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XfxProblemDetails
     */
    'instance'?: string | null;
}

/**
 * AliveApi - axios parameter creator
 * @export
 */
export const AliveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {boolean} [checkElasticsearch] 
         * @param {boolean} [checkKsefixApi] 
         * @param {boolean} [checkDb] 
         * @param {boolean} [checkBusinessLogDb] 
         * @param {boolean} [checkCamunda] 
         * @param {number} [minBackgroundWorkersCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveGet: async (checkElasticsearch?: boolean, checkKsefixApi?: boolean, checkDb?: boolean, checkBusinessLogDb?: boolean, checkCamunda?: boolean, minBackgroundWorkersCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/alive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (checkElasticsearch !== undefined) {
                localVarQueryParameter['CheckElasticsearch'] = checkElasticsearch;
            }

            if (checkKsefixApi !== undefined) {
                localVarQueryParameter['CheckKsefixApi'] = checkKsefixApi;
            }

            if (checkDb !== undefined) {
                localVarQueryParameter['CheckDb'] = checkDb;
            }

            if (checkBusinessLogDb !== undefined) {
                localVarQueryParameter['CheckBusinessLogDb'] = checkBusinessLogDb;
            }

            if (checkCamunda !== undefined) {
                localVarQueryParameter['CheckCamunda'] = checkCamunda;
            }

            if (minBackgroundWorkersCount !== undefined) {
                localVarQueryParameter['MinBackgroundWorkersCount'] = minBackgroundWorkersCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveReportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/alive/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveSimpleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/alive/simple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AliveApi - functional programming interface
 * @export
 */
export const AliveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AliveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {boolean} [checkElasticsearch] 
         * @param {boolean} [checkKsefixApi] 
         * @param {boolean} [checkDb] 
         * @param {boolean} [checkBusinessLogDb] 
         * @param {boolean} [checkCamunda] 
         * @param {number} [minBackgroundWorkersCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAliveGet(checkElasticsearch?: boolean, checkKsefixApi?: boolean, checkDb?: boolean, checkBusinessLogDb?: boolean, checkCamunda?: boolean, minBackgroundWorkersCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAliveGet(checkElasticsearch, checkKsefixApi, checkDb, checkBusinessLogDb, checkCamunda, minBackgroundWorkersCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAliveReportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAliveReportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAliveSimpleGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAliveSimpleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AliveApi - factory interface
 * @export
 */
export const AliveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AliveApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {boolean} [checkElasticsearch] 
         * @param {boolean} [checkKsefixApi] 
         * @param {boolean} [checkDb] 
         * @param {boolean} [checkBusinessLogDb] 
         * @param {boolean} [checkCamunda] 
         * @param {number} [minBackgroundWorkersCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveGet(checkElasticsearch?: boolean, checkKsefixApi?: boolean, checkDb?: boolean, checkBusinessLogDb?: boolean, checkCamunda?: boolean, minBackgroundWorkersCount?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiAliveGet(checkElasticsearch, checkKsefixApi, checkDb, checkBusinessLogDb, checkCamunda, minBackgroundWorkersCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveReportGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiAliveReportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveSimpleGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiAliveSimpleGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AliveApi - object-oriented interface
 * @export
 * @class AliveApi
 * @extends {BaseAPI}
 */
export class AliveApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {boolean} [checkElasticsearch] 
     * @param {boolean} [checkKsefixApi] 
     * @param {boolean} [checkDb] 
     * @param {boolean} [checkBusinessLogDb] 
     * @param {boolean} [checkCamunda] 
     * @param {number} [minBackgroundWorkersCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AliveApi
     */
    public apiAliveGet(checkElasticsearch?: boolean, checkKsefixApi?: boolean, checkDb?: boolean, checkBusinessLogDb?: boolean, checkCamunda?: boolean, minBackgroundWorkersCount?: number, options?: AxiosRequestConfig) {
        return AliveApiFp(this.configuration).apiAliveGet(checkElasticsearch, checkKsefixApi, checkDb, checkBusinessLogDb, checkCamunda, minBackgroundWorkersCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AliveApi
     */
    public apiAliveReportGet(options?: AxiosRequestConfig) {
        return AliveApiFp(this.configuration).apiAliveReportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AliveApi
     */
    public apiAliveSimpleGet(options?: AxiosRequestConfig) {
        return AliveApiFp(this.configuration).apiAliveSimpleGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AttachmentsApi - axios parameter creator
 * @export
 */
export const AttachmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {CreateArchiveDto} [createArchiveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAttachmentsArchivePost: async (tenantId: string, createArchiveDto?: CreateArchiveDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdAttachmentsArchivePost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/attachments/archive`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArchiveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAttachmentsIdDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdAttachmentsIdDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdAttachmentsIdDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAttachmentsIdGet: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdAttachmentsIdGet', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdAttachmentsIdGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAttachmentsPost: async (tenantId: string, file?: any, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdAttachmentsPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/attachments`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (invoiceId !== undefined) { 
                localVarFormParams.append('InvoiceId', invoiceId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentsApi - functional programming interface
 * @export
 */
export const AttachmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {CreateArchiveDto} [createArchiveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdAttachmentsArchivePost(tenantId: string, createArchiveDto?: CreateArchiveDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdAttachmentsArchivePost(tenantId, createArchiveDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdAttachmentsIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdAttachmentsIdDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdAttachmentsIdGet(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdAttachmentsIdGet(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdAttachmentsPost(tenantId: string, file?: any, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdAttachmentsPost(tenantId, file, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttachmentsApi - factory interface
 * @export
 */
export const AttachmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {CreateArchiveDto} [createArchiveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAttachmentsArchivePost(tenantId: string, createArchiveDto?: CreateArchiveDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdAttachmentsArchivePost(tenantId, createArchiveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAttachmentsIdDelete(id: string, tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdAttachmentsIdDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAttachmentsIdGet(id: string, tenantId: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiTenantIdAttachmentsIdGet(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAttachmentsPost(tenantId: string, file?: any, invoiceId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdAttachmentsPost(tenantId, file, invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttachmentsApi - object-oriented interface
 * @export
 * @class AttachmentsApi
 * @extends {BaseAPI}
 */
export class AttachmentsApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} tenantId 
     * @param {CreateArchiveDto} [createArchiveDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public apiTenantIdAttachmentsArchivePost(tenantId: string, createArchiveDto?: CreateArchiveDto, options?: AxiosRequestConfig) {
        return AttachmentsApiFp(this.configuration).apiTenantIdAttachmentsArchivePost(tenantId, createArchiveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public apiTenantIdAttachmentsIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return AttachmentsApiFp(this.configuration).apiTenantIdAttachmentsIdDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public apiTenantIdAttachmentsIdGet(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return AttachmentsApiFp(this.configuration).apiTenantIdAttachmentsIdGet(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} tenantId 
     * @param {any} [file] 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public apiTenantIdAttachmentsPost(tenantId: string, file?: any, invoiceId?: string, options?: AxiosRequestConfig) {
        return AttachmentsApiFp(this.configuration).apiTenantIdAttachmentsPost(tenantId, file, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AttachmentsOdataApi - axios parameter creator
 * @export
 */
export const AttachmentsOdataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAttachmentsodataGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdAttachmentsodataGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/attachmentsodata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataAttachmentsOdata: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataAttachmentsOdata', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataAttachmentsOdata', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/attachmentsodata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataAttachmentsOdataCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataAttachmentsOdataCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataAttachmentsOdataCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/attachmentsodata/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentsOdataApi - functional programming interface
 * @export
 */
export const AttachmentsOdataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentsOdataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdAttachmentsodataGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttachmentRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdAttachmentsodataGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataAttachmentsOdata(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttachmentRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataAttachmentsOdata(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataAttachmentsOdataCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttachmentRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataAttachmentsOdataCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttachmentsOdataApi - factory interface
 * @export
 */
export const AttachmentsOdataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentsOdataApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAttachmentsodataGet(tenantId: string, options?: any): AxiosPromise<Array<AttachmentRM>> {
            return localVarFp.apiTenantIdAttachmentsodataGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataAttachmentsOdata(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<AttachmentRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataAttachmentsOdata(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataAttachmentsOdataCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<AttachmentRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataAttachmentsOdataCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttachmentsOdataApi - object-oriented interface
 * @export
 * @class AttachmentsOdataApi
 * @extends {BaseAPI}
 */
export class AttachmentsOdataApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsOdataApi
     */
    public apiTenantIdAttachmentsodataGet(tenantId: string, options?: AxiosRequestConfig) {
        return AttachmentsOdataApiFp(this.configuration).apiTenantIdAttachmentsodataGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsOdataApi
     */
    public apiTenantIdOrganizationIdOdataAttachmentsOdata(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return AttachmentsOdataApiFp(this.configuration).apiTenantIdOrganizationIdOdataAttachmentsOdata(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsOdataApi
     */
    public apiTenantIdOrganizationIdOdataAttachmentsOdataCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return AttachmentsOdataApiFp(this.configuration).apiTenantIdOrganizationIdOdataAttachmentsOdataCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BatchApi - axios parameter creator
 * @export
 */
export const BatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if batch with specified billing Id exists
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchBillingGet: async (id: string, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBillingGet', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBillingGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBillingGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/batch/billing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiate preparing billing batch
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} billingId 
         * @param {string} senderVatNumber 
         * @param {SubjectType} batchDispatchType 
         * @param {string} [externalTrackingId] 
         * @param {string} [scheduledDateUtc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchBillingPost: async (tenantId: string, organizationId: string, billingId: string, senderVatNumber: string, batchDispatchType: SubjectType, externalTrackingId?: string, scheduledDateUtc?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBillingPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBillingPost', 'organizationId', organizationId)
            // verify required parameter 'billingId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBillingPost', 'billingId', billingId)
            // verify required parameter 'senderVatNumber' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBillingPost', 'senderVatNumber', senderVatNumber)
            // verify required parameter 'batchDispatchType' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBillingPost', 'batchDispatchType', batchDispatchType)
            const localVarPath = `/api/{tenantId}/{organizationId}/batch/billing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (billingId !== undefined) { 
                localVarFormParams.append('BillingId', billingId as any);
            }
    
            if (senderVatNumber !== undefined) { 
                localVarFormParams.append('SenderVatNumber', senderVatNumber as any);
            }
    
            if (batchDispatchType !== undefined) { 
                localVarFormParams.append('BatchDispatchType', new Blob([JSON.stringify(batchDispatchType)], { type: "application/json", }));
            }
    
            if (externalTrackingId !== undefined) { 
                localVarFormParams.append('ExternalTrackingId', externalTrackingId as any);
            }
    
            if (scheduledDateUtc !== undefined) { 
                localVarFormParams.append('ScheduledDateUtc', scheduledDateUtc as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given batch id
         * @param {string} batchId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchBusinessLogGet: async (batchId: string, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBusinessLogGet', 'batchId', batchId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBusinessLogGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchBusinessLogGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/batch/business-log`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete batch
         * @param {string} batchId 
         * @param {boolean} removeProcessingInvoices 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchDelete: async (batchId: string, removeProcessingInvoices: boolean, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchDelete', 'batchId', batchId)
            // verify required parameter 'removeProcessingInvoices' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchDelete', 'removeProcessingInvoices', removeProcessingInvoices)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchDelete', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchDelete', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/batch`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            if (removeProcessingInvoices !== undefined) {
                localVarQueryParameter['removeProcessingInvoices'] = removeProcessingInvoices;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiate preparing batch
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {PostBatchDto} [postBatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchPost: async (tenantId: string, organizationId: string, postBatchDto?: PostBatchDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/batch`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postBatchDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign a batch and schedule it in pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} file 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchSignPost: async (tenantId: string, organizationId: string, file: any, batchId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchSignPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchSignPost', 'organizationId', organizationId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchSignPost', 'file', file)
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchSignPost', 'batchId', batchId)
            const localVarPath = `/api/{tenantId}/{organizationId}/batch/sign`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (batchId !== undefined) { 
                localVarFormParams.append('BatchId', batchId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns unsigned data for given batchId
         * @param {string} batchId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchUnsigneddataGet: async (batchId: string, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchUnsigneddataGet', 'batchId', batchId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchUnsigneddataGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchUnsigneddataGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/batch/unsigneddata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns UPO for given batchId
         * @param {string} batchId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {boolean} [pretty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchUpoGet: async (batchId: string, tenantId: string, organizationId: string, pretty?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchUpoGet', 'batchId', batchId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchUpoGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdBatchUpoGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/batch/upo`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (batchId !== undefined) {
                localVarQueryParameter['batchId'] = batchId;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BatchApi - functional programming interface
 * @export
 */
export const BatchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BatchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if batch with specified billing Id exists
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdBatchBillingGet(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdBatchBillingGet(id, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initiate preparing billing batch
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} billingId 
         * @param {string} senderVatNumber 
         * @param {SubjectType} batchDispatchType 
         * @param {string} [externalTrackingId] 
         * @param {string} [scheduledDateUtc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdBatchBillingPost(tenantId: string, organizationId: string, billingId: string, senderVatNumber: string, batchDispatchType: SubjectType, externalTrackingId?: string, scheduledDateUtc?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartPrepareBatchCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdBatchBillingPost(tenantId, organizationId, billingId, senderVatNumber, batchDispatchType, externalTrackingId, scheduledDateUtc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given batch id
         * @param {string} batchId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdBatchBusinessLogGet(batchId: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdBatchBusinessLogGet(batchId, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete batch
         * @param {string} batchId 
         * @param {boolean} removeProcessingInvoices 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdBatchDelete(batchId: string, removeProcessingInvoices: boolean, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdBatchDelete(batchId, removeProcessingInvoices, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initiate preparing batch
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {PostBatchDto} [postBatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdBatchPost(tenantId: string, organizationId: string, postBatchDto?: PostBatchDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartPrepareBatchCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdBatchPost(tenantId, organizationId, postBatchDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign a batch and schedule it in pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} file 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdBatchSignPost(tenantId: string, organizationId: string, file: any, batchId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdBatchSignPost(tenantId, organizationId, file, batchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns unsigned data for given batchId
         * @param {string} batchId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdBatchUnsigneddataGet(batchId: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdBatchUnsigneddataGet(batchId, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns UPO for given batchId
         * @param {string} batchId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {boolean} [pretty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdBatchUpoGet(batchId: string, tenantId: string, organizationId: string, pretty?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdBatchUpoGet(batchId, tenantId, organizationId, pretty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BatchApi - factory interface
 * @export
 */
export const BatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BatchApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if batch with specified billing Id exists
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchBillingGet(id: string, tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdBatchBillingGet(id, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiate preparing billing batch
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} billingId 
         * @param {string} senderVatNumber 
         * @param {SubjectType} batchDispatchType 
         * @param {string} [externalTrackingId] 
         * @param {string} [scheduledDateUtc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchBillingPost(tenantId: string, organizationId: string, billingId: string, senderVatNumber: string, batchDispatchType: SubjectType, externalTrackingId?: string, scheduledDateUtc?: string, options?: any): AxiosPromise<StartPrepareBatchCommandResult> {
            return localVarFp.apiTenantIdOrganizationIdBatchBillingPost(tenantId, organizationId, billingId, senderVatNumber, batchDispatchType, externalTrackingId, scheduledDateUtc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given batch id
         * @param {string} batchId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchBusinessLogGet(batchId: string, tenantId: string, organizationId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdBatchBusinessLogGet(batchId, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete batch
         * @param {string} batchId 
         * @param {boolean} removeProcessingInvoices 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchDelete(batchId: string, removeProcessingInvoices: boolean, tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdBatchDelete(batchId, removeProcessingInvoices, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiate preparing batch
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {PostBatchDto} [postBatchDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchPost(tenantId: string, organizationId: string, postBatchDto?: PostBatchDto, options?: any): AxiosPromise<StartPrepareBatchCommandResult> {
            return localVarFp.apiTenantIdOrganizationIdBatchPost(tenantId, organizationId, postBatchDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign a batch and schedule it in pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} file 
         * @param {string} batchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchSignPost(tenantId: string, organizationId: string, file: any, batchId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdBatchSignPost(tenantId, organizationId, file, batchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns unsigned data for given batchId
         * @param {string} batchId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchUnsigneddataGet(batchId: string, tenantId: string, organizationId: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiTenantIdOrganizationIdBatchUnsigneddataGet(batchId, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns UPO for given batchId
         * @param {string} batchId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {boolean} [pretty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdBatchUpoGet(batchId: string, tenantId: string, organizationId: string, pretty?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdBatchUpoGet(batchId, tenantId, organizationId, pretty, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BatchApi - object-oriented interface
 * @export
 * @class BatchApi
 * @extends {BaseAPI}
 */
export class BatchApi extends BaseAPI {
    /**
     * 
     * @summary Check if batch with specified billing Id exists
     * @param {string} id 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public apiTenantIdOrganizationIdBatchBillingGet(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return BatchApiFp(this.configuration).apiTenantIdOrganizationIdBatchBillingGet(id, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initiate preparing billing batch
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} billingId 
     * @param {string} senderVatNumber 
     * @param {SubjectType} batchDispatchType 
     * @param {string} [externalTrackingId] 
     * @param {string} [scheduledDateUtc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public apiTenantIdOrganizationIdBatchBillingPost(tenantId: string, organizationId: string, billingId: string, senderVatNumber: string, batchDispatchType: SubjectType, externalTrackingId?: string, scheduledDateUtc?: string, options?: AxiosRequestConfig) {
        return BatchApiFp(this.configuration).apiTenantIdOrganizationIdBatchBillingPost(tenantId, organizationId, billingId, senderVatNumber, batchDispatchType, externalTrackingId, scheduledDateUtc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a collection of business events recorded for given batch id
     * @param {string} batchId 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public apiTenantIdOrganizationIdBatchBusinessLogGet(batchId: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return BatchApiFp(this.configuration).apiTenantIdOrganizationIdBatchBusinessLogGet(batchId, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete batch
     * @param {string} batchId 
     * @param {boolean} removeProcessingInvoices 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public apiTenantIdOrganizationIdBatchDelete(batchId: string, removeProcessingInvoices: boolean, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return BatchApiFp(this.configuration).apiTenantIdOrganizationIdBatchDelete(batchId, removeProcessingInvoices, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initiate preparing batch
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {PostBatchDto} [postBatchDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public apiTenantIdOrganizationIdBatchPost(tenantId: string, organizationId: string, postBatchDto?: PostBatchDto, options?: AxiosRequestConfig) {
        return BatchApiFp(this.configuration).apiTenantIdOrganizationIdBatchPost(tenantId, organizationId, postBatchDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign a batch and schedule it in pipeline
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {any} file 
     * @param {string} batchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public apiTenantIdOrganizationIdBatchSignPost(tenantId: string, organizationId: string, file: any, batchId: string, options?: AxiosRequestConfig) {
        return BatchApiFp(this.configuration).apiTenantIdOrganizationIdBatchSignPost(tenantId, organizationId, file, batchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns unsigned data for given batchId
     * @param {string} batchId 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public apiTenantIdOrganizationIdBatchUnsigneddataGet(batchId: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return BatchApiFp(this.configuration).apiTenantIdOrganizationIdBatchUnsigneddataGet(batchId, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns UPO for given batchId
     * @param {string} batchId 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {boolean} [pretty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BatchApi
     */
    public apiTenantIdOrganizationIdBatchUpoGet(batchId: string, tenantId: string, organizationId: string, pretty?: boolean, options?: AxiosRequestConfig) {
        return BatchApiFp(this.configuration).apiTenantIdOrganizationIdBatchUpoGet(batchId, tenantId, organizationId, pretty, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigS3Api - axios parameter creator
 * @export
 */
export const ConfigS3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigs3Get: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdConfigs3Get', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/configs3`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigs3IdDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdConfigs3IdDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdConfigs3IdDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/configs3/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {AddUriBucketsSettingsDto} [addUriBucketsSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigs3Post: async (tenantId: string, addUriBucketsSettingsDto?: AddUriBucketsSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdConfigs3Post', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/configs3`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUriBucketsSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {EditUriBucketsSettingsDto} [editUriBucketsSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigs3Put: async (tenantId: string, editUriBucketsSettingsDto?: EditUriBucketsSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdConfigs3Put', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/configs3`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUriBucketsSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigS3Api - functional programming interface
 * @export
 */
export const ConfigS3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigS3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdConfigs3Get(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdConfigs3Get(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdConfigs3IdDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdConfigs3IdDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {AddUriBucketsSettingsDto} [addUriBucketsSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdConfigs3Post(tenantId: string, addUriBucketsSettingsDto?: AddUriBucketsSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdConfigs3Post(tenantId, addUriBucketsSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {EditUriBucketsSettingsDto} [editUriBucketsSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdConfigs3Put(tenantId: string, editUriBucketsSettingsDto?: EditUriBucketsSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdConfigs3Put(tenantId, editUriBucketsSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigS3Api - factory interface
 * @export
 */
export const ConfigS3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigS3ApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigs3Get(tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdConfigs3Get(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigs3IdDelete(id: string, tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdConfigs3IdDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {AddUriBucketsSettingsDto} [addUriBucketsSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigs3Post(tenantId: string, addUriBucketsSettingsDto?: AddUriBucketsSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdConfigs3Post(tenantId, addUriBucketsSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {EditUriBucketsSettingsDto} [editUriBucketsSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigs3Put(tenantId: string, editUriBucketsSettingsDto?: EditUriBucketsSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdConfigs3Put(tenantId, editUriBucketsSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigS3Api - object-oriented interface
 * @export
 * @class ConfigS3Api
 * @extends {BaseAPI}
 */
export class ConfigS3Api extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigS3Api
     */
    public apiTenantIdConfigs3Get(tenantId: string, options?: AxiosRequestConfig) {
        return ConfigS3ApiFp(this.configuration).apiTenantIdConfigs3Get(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigS3Api
     */
    public apiTenantIdConfigs3IdDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return ConfigS3ApiFp(this.configuration).apiTenantIdConfigs3IdDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} tenantId 
     * @param {AddUriBucketsSettingsDto} [addUriBucketsSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigS3Api
     */
    public apiTenantIdConfigs3Post(tenantId: string, addUriBucketsSettingsDto?: AddUriBucketsSettingsDto, options?: AxiosRequestConfig) {
        return ConfigS3ApiFp(this.configuration).apiTenantIdConfigs3Post(tenantId, addUriBucketsSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} tenantId 
     * @param {EditUriBucketsSettingsDto} [editUriBucketsSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigS3Api
     */
    public apiTenantIdConfigs3Put(tenantId: string, editUriBucketsSettingsDto?: EditUriBucketsSettingsDto, options?: AxiosRequestConfig) {
        return ConfigS3ApiFp(this.configuration).apiTenantIdConfigs3Put(tenantId, editUriBucketsSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardDataApi - axios parameter creator
 * @export
 */
export const DashboardDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdDashboarddataDispatchReceptionAndErrorsGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdDashboarddataDispatchReceptionAndErrorsGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdDashboarddataDispatchReceptionAndErrorsGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/dashboarddata/dispatch-reception-and-errors`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdDashboarddataKsefixVersionGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdDashboarddataKsefixVersionGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdDashboarddataKsefixVersionGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/dashboarddata/ksefix-version`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdDashboarddataSalesVsPurchaseGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdDashboarddataSalesVsPurchaseGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdDashboarddataSalesVsPurchaseGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/dashboarddata/sales-vs-purchase`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdDashboarddataVatDueVsIncludedGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdDashboarddataVatDueVsIncludedGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdDashboarddataVatDueVsIncludedGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/dashboarddata/vat-due-vs-included`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardDataApi - functional programming interface
 * @export
 */
export const DashboardDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdDashboarddataDispatchReceptionAndErrorsGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdDashboarddataDispatchReceptionAndErrorsGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdDashboarddataKsefixVersionGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdDashboarddataKsefixVersionGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdDashboarddataSalesVsPurchaseGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdDashboarddataSalesVsPurchaseGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdDashboarddataVatDueVsIncludedGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdDashboarddataVatDueVsIncludedGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardDataApi - factory interface
 * @export
 */
export const DashboardDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardDataApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdDashboarddataDispatchReceptionAndErrorsGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdDashboarddataDispatchReceptionAndErrorsGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdDashboarddataKsefixVersionGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdDashboarddataKsefixVersionGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdDashboarddataSalesVsPurchaseGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdDashboarddataSalesVsPurchaseGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdDashboarddataVatDueVsIncludedGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdDashboarddataVatDueVsIncludedGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardDataApi - object-oriented interface
 * @export
 * @class DashboardDataApi
 * @extends {BaseAPI}
 */
export class DashboardDataApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardDataApi
     */
    public apiTenantIdOrganizationIdDashboarddataDispatchReceptionAndErrorsGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DashboardDataApiFp(this.configuration).apiTenantIdOrganizationIdDashboarddataDispatchReceptionAndErrorsGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardDataApi
     */
    public apiTenantIdOrganizationIdDashboarddataKsefixVersionGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DashboardDataApiFp(this.configuration).apiTenantIdOrganizationIdDashboarddataKsefixVersionGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardDataApi
     */
    public apiTenantIdOrganizationIdDashboarddataSalesVsPurchaseGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DashboardDataApiFp(this.configuration).apiTenantIdOrganizationIdDashboarddataSalesVsPurchaseGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardDataApi
     */
    public apiTenantIdOrganizationIdDashboarddataVatDueVsIncludedGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DashboardDataApiFp(this.configuration).apiTenantIdOrganizationIdDashboarddataVatDueVsIncludedGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardMenuApi - axios parameter creator
 * @export
 */
export const DashboardMenuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdDashboardmenuQuantityGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdDashboardmenuQuantityGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdDashboardmenuQuantityGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/dashboardmenu/quantity`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardMenuApi - functional programming interface
 * @export
 */
export const DashboardMenuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardMenuApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdDashboardmenuQuantityGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdDashboardmenuQuantityGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardMenuApi - factory interface
 * @export
 */
export const DashboardMenuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardMenuApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdDashboardmenuQuantityGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdDashboardmenuQuantityGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardMenuApi - object-oriented interface
 * @export
 * @class DashboardMenuApi
 * @extends {BaseAPI}
 */
export class DashboardMenuApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMenuApi
     */
    public apiTenantIdOrganizationIdDashboardmenuQuantityGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DashboardMenuApiFp(this.configuration).apiTenantIdOrganizationIdDashboardmenuQuantityGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictionaryApi - axios parameter creator
 * @export
 */
export const DictionaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary GetANSICodes
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryAnsiCodesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryAnsiCodesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/ansi-codes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryBatchprocessingstatusesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryBatchprocessingstatusesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/batchprocessingstatuses`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryCompressiontypesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryCompressiontypesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/compressiontypes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryCurrencycodesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryCurrencycodesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/currencycodes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryExportparametersdatasourcesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryExportparametersdatasourcesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/exportparametersdatasources`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryExportparametersoutputfiletypeGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryExportparametersoutputfiletypeGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/exportparametersoutputfiletype`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryInvoiceKindsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryInvoiceKindsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/invoice-kinds`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetInvoiceNotificationsEvents
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryInvoiceNotificationEventsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryInvoiceNotificationEventsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/invoice-notification-events`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryJobprocessingstatusesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryJobprocessingstatusesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/jobprocessingstatuses`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryProcessingstatusesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryProcessingstatusesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/processingstatuses`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetReportPeriods
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryReportPeriodsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryReportPeriodsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/report-periods`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetReportTypes
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryReportTypesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryReportTypesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/report-types`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryResetIdPeriodGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryResetIdPeriodGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/reset-id-period`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionarySaleDateTagsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionarySaleDateTagsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/sale-date-tags`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionarySubject3RolesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionarySubject3RolesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dictionary/subject3-roles`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictionaryApi - functional programming interface
 * @export
 */
export const DictionaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictionaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary GetANSICodes
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryAnsiCodesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32ANSICodeKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryAnsiCodesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryBatchprocessingstatusesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryBatchprocessingstatusesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryCompressiontypesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32CompressionTypeKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryCompressiontypesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryCurrencycodesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryCurrencycodesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryExportparametersdatasourcesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32DataSourceKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryExportparametersdatasourcesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryExportparametersoutputfiletypeGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryExportparametersoutputfiletypeGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryInvoiceKindsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryInvoiceKindsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetInvoiceNotificationsEvents
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryInvoiceNotificationEventsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryInvoiceNotificationEventsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryJobprocessingstatusesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryJobprocessingstatusesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryProcessingstatusesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryProcessingstatusesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetReportPeriods
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryReportPeriodsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryReportPeriodsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetReportTypes
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryReportTypesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryReportTypesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryResetIdPeriodGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryResetIdPeriodGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionarySaleDateTagsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionarySaleDateTagsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionarySubject3RolesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionarySubject3RolesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictionaryApi - factory interface
 * @export
 */
export const DictionaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictionaryApiFp(configuration)
    return {
        /**
         * 
         * @summary GetANSICodes
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryAnsiCodesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32ANSICodeKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryAnsiCodesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryBatchprocessingstatusesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryBatchprocessingstatusesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryCompressiontypesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32CompressionTypeKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryCompressiontypesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryCurrencycodesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryCurrencycodesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryExportparametersdatasourcesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32DataSourceKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryExportparametersdatasourcesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryExportparametersoutputfiletypeGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryExportparametersoutputfiletypeGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryInvoiceKindsGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryInvoiceKindsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetInvoiceNotificationsEvents
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryInvoiceNotificationEventsGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryInvoiceNotificationEventsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryJobprocessingstatusesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryJobprocessingstatusesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryProcessingstatusesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryProcessingstatusesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetReportPeriods
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryReportPeriodsGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryReportPeriodsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetReportTypes
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryReportTypesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryReportTypesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryResetIdPeriodGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryResetIdPeriodGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionarySaleDateTagsGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionarySaleDateTagsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionarySubject3RolesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionarySubject3RolesGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictionaryApi - object-oriented interface
 * @export
 * @class DictionaryApi
 * @extends {BaseAPI}
 */
export class DictionaryApi extends BaseAPI {
    /**
     * 
     * @summary GetANSICodes
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryAnsiCodesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryAnsiCodesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryBatchprocessingstatusesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryBatchprocessingstatusesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryCompressiontypesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryCompressiontypesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryCurrencycodesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryCurrencycodesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryExportparametersdatasourcesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryExportparametersdatasourcesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryExportparametersoutputfiletypeGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryExportparametersoutputfiletypeGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryInvoiceKindsGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryInvoiceKindsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetInvoiceNotificationsEvents
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryInvoiceNotificationEventsGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryInvoiceNotificationEventsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryJobprocessingstatusesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryJobprocessingstatusesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryProcessingstatusesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryProcessingstatusesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetReportPeriods
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryReportPeriodsGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryReportPeriodsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetReportTypes
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryReportTypesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryReportTypesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryResetIdPeriodGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryResetIdPeriodGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionarySaleDateTagsGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionarySaleDateTagsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionarySubject3RolesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionarySubject3RolesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchOtherAcceptanceApi - axios parameter creator
 * @export
 */
export const DispatchOtherAcceptanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchotheracceptanceGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchotheracceptanceGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchotheracceptance`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherAcceptance: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherAcceptance', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherAcceptance', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchotheracceptance`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherAcceptanceCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherAcceptanceCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherAcceptanceCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchotheracceptance/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchOtherAcceptanceApi - functional programming interface
 * @export
 */
export const DispatchOtherAcceptanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchOtherAcceptanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchotheracceptanceGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchotheracceptanceGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchOtherAcceptance(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchOtherAcceptance(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchOtherAcceptanceCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchOtherAcceptanceCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchOtherAcceptanceApi - factory interface
 * @export
 */
export const DispatchOtherAcceptanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchOtherAcceptanceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchotheracceptanceGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchotheracceptanceGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherAcceptance(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchOtherAcceptance(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherAcceptanceCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchOtherAcceptanceCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchOtherAcceptanceApi - object-oriented interface
 * @export
 * @class DispatchOtherAcceptanceApi
 * @extends {BaseAPI}
 */
export class DispatchOtherAcceptanceApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherAcceptanceApi
     */
    public apiTenantIdDispatchotheracceptanceGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchOtherAcceptanceApiFp(this.configuration).apiTenantIdDispatchotheracceptanceGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherAcceptanceApi
     */
    public apiTenantIdOrganizationIdOdataDispatchOtherAcceptance(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchOtherAcceptanceApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchOtherAcceptance(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherAcceptanceApi
     */
    public apiTenantIdOrganizationIdOdataDispatchOtherAcceptanceCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchOtherAcceptanceApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchOtherAcceptanceCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchOtherBatchSendingApi - axios parameter creator
 * @export
 */
export const DispatchOtherBatchSendingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchotherbatchsendingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchotherbatchsendingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchotherbatchsending`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherBatchSending: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherBatchSending', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherBatchSending', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchotherbatchsending`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherBatchSendingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherBatchSendingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherBatchSendingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchotherbatchsending/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchOtherBatchSendingApi - functional programming interface
 * @export
 */
export const DispatchOtherBatchSendingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchOtherBatchSendingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchotherbatchsendingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchDispatchRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchotherbatchsendingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchOtherBatchSending(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchDispatchRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchOtherBatchSending(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchOtherBatchSendingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchDispatchRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchOtherBatchSendingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchOtherBatchSendingApi - factory interface
 * @export
 */
export const DispatchOtherBatchSendingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchOtherBatchSendingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchotherbatchsendingGet(tenantId: string, options?: any): AxiosPromise<Array<BatchDispatchRM>> {
            return localVarFp.apiTenantIdDispatchotherbatchsendingGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherBatchSending(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<BatchDispatchRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchOtherBatchSending(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherBatchSendingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<BatchDispatchRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchOtherBatchSendingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchOtherBatchSendingApi - object-oriented interface
 * @export
 * @class DispatchOtherBatchSendingApi
 * @extends {BaseAPI}
 */
export class DispatchOtherBatchSendingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherBatchSendingApi
     */
    public apiTenantIdDispatchotherbatchsendingGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchOtherBatchSendingApiFp(this.configuration).apiTenantIdDispatchotherbatchsendingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherBatchSendingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchOtherBatchSending(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchOtherBatchSendingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchOtherBatchSending(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherBatchSendingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchOtherBatchSendingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchOtherBatchSendingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchOtherBatchSendingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchOtherBatchToSendApi - axios parameter creator
 * @export
 */
export const DispatchOtherBatchToSendApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchotherbatchtosendGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchotherbatchtosendGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchotherbatchtosend`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherBatchToSend: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherBatchToSend', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherBatchToSend', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchotherbatchtosend`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherBatchToSendCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherBatchToSendCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherBatchToSendCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchotherbatchtosend/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchOtherBatchToSendApi - functional programming interface
 * @export
 */
export const DispatchOtherBatchToSendApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchOtherBatchToSendApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchotherbatchtosendGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchotherbatchtosendGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchOtherBatchToSend(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchOtherBatchToSend(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchOtherBatchToSendCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchOtherBatchToSendCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchOtherBatchToSendApi - factory interface
 * @export
 */
export const DispatchOtherBatchToSendApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchOtherBatchToSendApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchotherbatchtosendGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchotherbatchtosendGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherBatchToSend(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchOtherBatchToSend(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherBatchToSendCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchOtherBatchToSendCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchOtherBatchToSendApi - object-oriented interface
 * @export
 * @class DispatchOtherBatchToSendApi
 * @extends {BaseAPI}
 */
export class DispatchOtherBatchToSendApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherBatchToSendApi
     */
    public apiTenantIdDispatchotherbatchtosendGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchOtherBatchToSendApiFp(this.configuration).apiTenantIdDispatchotherbatchtosendGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherBatchToSendApi
     */
    public apiTenantIdOrganizationIdOdataDispatchOtherBatchToSend(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchOtherBatchToSendApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchOtherBatchToSend(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherBatchToSendApi
     */
    public apiTenantIdOrganizationIdOdataDispatchOtherBatchToSendCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchOtherBatchToSendApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchOtherBatchToSendCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchOtherFailedApi - axios parameter creator
 * @export
 */
export const DispatchOtherFailedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchotherfailedGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchotherfailedGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchotherfailed`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherFailed: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherFailed', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherFailed', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchotherfailed`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherFailedCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherFailedCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherFailedCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchotherfailed/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchOtherFailedApi - functional programming interface
 * @export
 */
export const DispatchOtherFailedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchOtherFailedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchotherfailedGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchotherfailedGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchOtherFailed(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchOtherFailed(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchOtherFailedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchOtherFailedCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchOtherFailedApi - factory interface
 * @export
 */
export const DispatchOtherFailedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchOtherFailedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchotherfailedGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchotherfailedGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherFailed(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchOtherFailed(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherFailedCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchOtherFailedCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchOtherFailedApi - object-oriented interface
 * @export
 * @class DispatchOtherFailedApi
 * @extends {BaseAPI}
 */
export class DispatchOtherFailedApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherFailedApi
     */
    public apiTenantIdDispatchotherfailedGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchOtherFailedApiFp(this.configuration).apiTenantIdDispatchotherfailedGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherFailedApi
     */
    public apiTenantIdOrganizationIdOdataDispatchOtherFailed(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchOtherFailedApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchOtherFailed(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherFailedApi
     */
    public apiTenantIdOrganizationIdOdataDispatchOtherFailedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchOtherFailedApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchOtherFailedCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchOtherProcessingApi - axios parameter creator
 * @export
 */
export const DispatchOtherProcessingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchotherprocessingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchotherprocessingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchotherprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherProcessing: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherProcessing', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherProcessing', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchotherprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherProcessingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherProcessingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchOtherProcessingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchotherprocessing/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchOtherProcessingApi - functional programming interface
 * @export
 */
export const DispatchOtherProcessingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchOtherProcessingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchotherprocessingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchotherprocessingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchOtherProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchOtherProcessing(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchOtherProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchOtherProcessingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchOtherProcessingApi - factory interface
 * @export
 */
export const DispatchOtherProcessingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchOtherProcessingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchotherprocessingGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchotherprocessingGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherProcessing(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchOtherProcessing(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchOtherProcessingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchOtherProcessingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchOtherProcessingApi - object-oriented interface
 * @export
 * @class DispatchOtherProcessingApi
 * @extends {BaseAPI}
 */
export class DispatchOtherProcessingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherProcessingApi
     */
    public apiTenantIdDispatchotherprocessingGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchOtherProcessingApiFp(this.configuration).apiTenantIdDispatchotherprocessingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherProcessingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchOtherProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchOtherProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchOtherProcessing(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchOtherProcessingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchOtherProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchOtherProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchOtherProcessingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchSaleAcceptanceApi - axios parameter creator
 * @export
 */
export const DispatchSaleAcceptanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchsaleacceptanceGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchsaleacceptanceGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchsaleacceptance`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleAcceptance: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleAcceptance', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleAcceptance', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchsaleacceptance`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleAcceptanceCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleAcceptanceCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleAcceptanceCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchsaleacceptance/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchSaleAcceptanceApi - functional programming interface
 * @export
 */
export const DispatchSaleAcceptanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchSaleAcceptanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchsaleacceptanceGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchsaleacceptanceGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSaleAcceptance(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSaleAcceptance(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSaleAcceptanceCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSaleAcceptanceCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchSaleAcceptanceApi - factory interface
 * @export
 */
export const DispatchSaleAcceptanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchSaleAcceptanceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchsaleacceptanceGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchsaleacceptanceGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleAcceptance(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSaleAcceptance(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleAcceptanceCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSaleAcceptanceCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchSaleAcceptanceApi - object-oriented interface
 * @export
 * @class DispatchSaleAcceptanceApi
 * @extends {BaseAPI}
 */
export class DispatchSaleAcceptanceApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleAcceptanceApi
     */
    public apiTenantIdDispatchsaleacceptanceGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchSaleAcceptanceApiFp(this.configuration).apiTenantIdDispatchsaleacceptanceGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleAcceptanceApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSaleAcceptance(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSaleAcceptanceApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSaleAcceptance(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleAcceptanceApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSaleAcceptanceCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSaleAcceptanceApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSaleAcceptanceCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchSaleBatchSendingApi - axios parameter creator
 * @export
 */
export const DispatchSaleBatchSendingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchsalebatchsendingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchsalebatchsendingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchsalebatchsending`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleBatchSending: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleBatchSending', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleBatchSending', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchsalebatchsending`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleBatchSendingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleBatchSendingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleBatchSendingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchsalebatchsending/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchSaleBatchSendingApi - functional programming interface
 * @export
 */
export const DispatchSaleBatchSendingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchSaleBatchSendingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchsalebatchsendingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchDispatchRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchsalebatchsendingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSaleBatchSending(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchDispatchRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSaleBatchSending(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSaleBatchSendingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchDispatchRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSaleBatchSendingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchSaleBatchSendingApi - factory interface
 * @export
 */
export const DispatchSaleBatchSendingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchSaleBatchSendingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchsalebatchsendingGet(tenantId: string, options?: any): AxiosPromise<Array<BatchDispatchRM>> {
            return localVarFp.apiTenantIdDispatchsalebatchsendingGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleBatchSending(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<BatchDispatchRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSaleBatchSending(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleBatchSendingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<BatchDispatchRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSaleBatchSendingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchSaleBatchSendingApi - object-oriented interface
 * @export
 * @class DispatchSaleBatchSendingApi
 * @extends {BaseAPI}
 */
export class DispatchSaleBatchSendingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleBatchSendingApi
     */
    public apiTenantIdDispatchsalebatchsendingGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchSaleBatchSendingApiFp(this.configuration).apiTenantIdDispatchsalebatchsendingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleBatchSendingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSaleBatchSending(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSaleBatchSendingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSaleBatchSending(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleBatchSendingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSaleBatchSendingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSaleBatchSendingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSaleBatchSendingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchSaleBatchToSendApi - axios parameter creator
 * @export
 */
export const DispatchSaleBatchToSendApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchsalebatchtosendGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchsalebatchtosendGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchsalebatchtosend`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleBatchToSend: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleBatchToSend', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleBatchToSend', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchsalebatchtosend`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleBatchToSendCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleBatchToSendCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleBatchToSendCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchsalebatchtosend/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchSaleBatchToSendApi - functional programming interface
 * @export
 */
export const DispatchSaleBatchToSendApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchSaleBatchToSendApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchsalebatchtosendGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchsalebatchtosendGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSaleBatchToSend(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSaleBatchToSend(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSaleBatchToSendCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSaleBatchToSendCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchSaleBatchToSendApi - factory interface
 * @export
 */
export const DispatchSaleBatchToSendApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchSaleBatchToSendApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchsalebatchtosendGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchsalebatchtosendGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleBatchToSend(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSaleBatchToSend(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleBatchToSendCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSaleBatchToSendCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchSaleBatchToSendApi - object-oriented interface
 * @export
 * @class DispatchSaleBatchToSendApi
 * @extends {BaseAPI}
 */
export class DispatchSaleBatchToSendApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleBatchToSendApi
     */
    public apiTenantIdDispatchsalebatchtosendGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchSaleBatchToSendApiFp(this.configuration).apiTenantIdDispatchsalebatchtosendGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleBatchToSendApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSaleBatchToSend(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSaleBatchToSendApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSaleBatchToSend(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleBatchToSendApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSaleBatchToSendCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSaleBatchToSendApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSaleBatchToSendCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchSaleFailedApi - axios parameter creator
 * @export
 */
export const DispatchSaleFailedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchsalefailedGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchsalefailedGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchsalefailed`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleFailed: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleFailed', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleFailed', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchsalefailed`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleFailedCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleFailedCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleFailedCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchsalefailed/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchSaleFailedApi - functional programming interface
 * @export
 */
export const DispatchSaleFailedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchSaleFailedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchsalefailedGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchsalefailedGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSaleFailed(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSaleFailed(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSaleFailedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSaleFailedCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchSaleFailedApi - factory interface
 * @export
 */
export const DispatchSaleFailedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchSaleFailedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchsalefailedGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchsalefailedGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleFailed(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSaleFailed(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleFailedCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSaleFailedCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchSaleFailedApi - object-oriented interface
 * @export
 * @class DispatchSaleFailedApi
 * @extends {BaseAPI}
 */
export class DispatchSaleFailedApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleFailedApi
     */
    public apiTenantIdDispatchsalefailedGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchSaleFailedApiFp(this.configuration).apiTenantIdDispatchsalefailedGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleFailedApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSaleFailed(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSaleFailedApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSaleFailed(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleFailedApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSaleFailedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSaleFailedApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSaleFailedCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchSaleProcessingApi - axios parameter creator
 * @export
 */
export const DispatchSaleProcessingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchsaleprocessingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchsaleprocessingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchsaleprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleProcessing: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleProcessing', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleProcessing', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchsaleprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleProcessingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleProcessingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSaleProcessingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchsaleprocessing/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchSaleProcessingApi - functional programming interface
 * @export
 */
export const DispatchSaleProcessingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchSaleProcessingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchsaleprocessingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchsaleprocessingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSaleProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSaleProcessing(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSaleProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSaleProcessingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchSaleProcessingApi - factory interface
 * @export
 */
export const DispatchSaleProcessingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchSaleProcessingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchsaleprocessingGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchsaleprocessingGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleProcessing(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSaleProcessing(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSaleProcessingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSaleProcessingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchSaleProcessingApi - object-oriented interface
 * @export
 * @class DispatchSaleProcessingApi
 * @extends {BaseAPI}
 */
export class DispatchSaleProcessingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleProcessingApi
     */
    public apiTenantIdDispatchsaleprocessingGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchSaleProcessingApiFp(this.configuration).apiTenantIdDispatchsaleprocessingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleProcessingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSaleProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSaleProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSaleProcessing(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSaleProcessingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSaleProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSaleProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSaleProcessingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchSelfInvoicingAcceptanceApi - axios parameter creator
 * @export
 */
export const DispatchSelfInvoicingAcceptanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchselfinvoicingacceptanceGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchselfinvoicingacceptanceGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchselfinvoicingacceptance`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptance: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptance', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptance', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchselfinvoicingacceptance`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptanceCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptanceCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptanceCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchselfinvoicingacceptance/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchSelfInvoicingAcceptanceApi - functional programming interface
 * @export
 */
export const DispatchSelfInvoicingAcceptanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchSelfInvoicingAcceptanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchselfinvoicingacceptanceGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchselfinvoicingacceptanceGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptance(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptance(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptanceCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptanceCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchSelfInvoicingAcceptanceApi - factory interface
 * @export
 */
export const DispatchSelfInvoicingAcceptanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchSelfInvoicingAcceptanceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchselfinvoicingacceptanceGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchselfinvoicingacceptanceGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptance(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptance(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptanceCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptanceCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchSelfInvoicingAcceptanceApi - object-oriented interface
 * @export
 * @class DispatchSelfInvoicingAcceptanceApi
 * @extends {BaseAPI}
 */
export class DispatchSelfInvoicingAcceptanceApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingAcceptanceApi
     */
    public apiTenantIdDispatchselfinvoicingacceptanceGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingAcceptanceApiFp(this.configuration).apiTenantIdDispatchselfinvoicingacceptanceGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingAcceptanceApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptance(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingAcceptanceApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptance(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingAcceptanceApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptanceCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingAcceptanceApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSelfInvoicingAcceptanceCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchSelfInvoicingBatchSendingApi - axios parameter creator
 * @export
 */
export const DispatchSelfInvoicingBatchSendingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchselfinvoicingbatchsendingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchselfinvoicingbatchsendingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchselfinvoicingbatchsending`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSending: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSending', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSending', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchselfinvoicingbatchsending`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSendingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSendingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSendingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchselfinvoicingbatchsending/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchSelfInvoicingBatchSendingApi - functional programming interface
 * @export
 */
export const DispatchSelfInvoicingBatchSendingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchSelfInvoicingBatchSendingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchselfinvoicingbatchsendingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchDispatchRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchselfinvoicingbatchsendingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSending(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchDispatchRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSending(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSendingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BatchDispatchRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSendingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchSelfInvoicingBatchSendingApi - factory interface
 * @export
 */
export const DispatchSelfInvoicingBatchSendingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchSelfInvoicingBatchSendingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchselfinvoicingbatchsendingGet(tenantId: string, options?: any): AxiosPromise<Array<BatchDispatchRM>> {
            return localVarFp.apiTenantIdDispatchselfinvoicingbatchsendingGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSending(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<BatchDispatchRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSending(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSendingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<BatchDispatchRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSendingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchSelfInvoicingBatchSendingApi - object-oriented interface
 * @export
 * @class DispatchSelfInvoicingBatchSendingApi
 * @extends {BaseAPI}
 */
export class DispatchSelfInvoicingBatchSendingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingBatchSendingApi
     */
    public apiTenantIdDispatchselfinvoicingbatchsendingGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingBatchSendingApiFp(this.configuration).apiTenantIdDispatchselfinvoicingbatchsendingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingBatchSendingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSending(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingBatchSendingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSending(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingBatchSendingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSendingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingBatchSendingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchSendingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchSelfInvoicingBatchToSendApi - axios parameter creator
 * @export
 */
export const DispatchSelfInvoicingBatchToSendApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchselfinvoicingbatchtosendGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchselfinvoicingbatchtosendGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchselfinvoicingbatchtosend`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSend: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSend', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSend', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchselfinvoicingbatchtosend`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSendCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSendCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSendCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchselfinvoicingbatchtosend/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchSelfInvoicingBatchToSendApi - functional programming interface
 * @export
 */
export const DispatchSelfInvoicingBatchToSendApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchSelfInvoicingBatchToSendApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchselfinvoicingbatchtosendGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchselfinvoicingbatchtosendGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSend(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSend(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSendCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSendCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchSelfInvoicingBatchToSendApi - factory interface
 * @export
 */
export const DispatchSelfInvoicingBatchToSendApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchSelfInvoicingBatchToSendApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchselfinvoicingbatchtosendGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchselfinvoicingbatchtosendGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSend(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSend(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSendCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSendCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchSelfInvoicingBatchToSendApi - object-oriented interface
 * @export
 * @class DispatchSelfInvoicingBatchToSendApi
 * @extends {BaseAPI}
 */
export class DispatchSelfInvoicingBatchToSendApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingBatchToSendApi
     */
    public apiTenantIdDispatchselfinvoicingbatchtosendGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingBatchToSendApiFp(this.configuration).apiTenantIdDispatchselfinvoicingbatchtosendGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingBatchToSendApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSend(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingBatchToSendApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSend(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingBatchToSendApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSendCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingBatchToSendApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSelfInvoicingBatchToSendCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchSelfInvoicingFailedApi - axios parameter creator
 * @export
 */
export const DispatchSelfInvoicingFailedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchselfinvoicingfailedGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchselfinvoicingfailedGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchselfinvoicingfailed`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailed: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailed', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailed', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchselfinvoicingfailed`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailedCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailedCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailedCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchselfinvoicingfailed/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchSelfInvoicingFailedApi - functional programming interface
 * @export
 */
export const DispatchSelfInvoicingFailedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchSelfInvoicingFailedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchselfinvoicingfailedGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchselfinvoicingfailedGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailed(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailed(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailedCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchSelfInvoicingFailedApi - factory interface
 * @export
 */
export const DispatchSelfInvoicingFailedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchSelfInvoicingFailedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchselfinvoicingfailedGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchselfinvoicingfailedGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailed(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailed(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailedCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailedCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchSelfInvoicingFailedApi - object-oriented interface
 * @export
 * @class DispatchSelfInvoicingFailedApi
 * @extends {BaseAPI}
 */
export class DispatchSelfInvoicingFailedApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingFailedApi
     */
    public apiTenantIdDispatchselfinvoicingfailedGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingFailedApiFp(this.configuration).apiTenantIdDispatchselfinvoicingfailedGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingFailedApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailed(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingFailedApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailed(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingFailedApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingFailedApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSelfInvoicingFailedCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DispatchSelfInvoicingProcessingApi - axios parameter creator
 * @export
 */
export const DispatchSelfInvoicingProcessingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchselfinvoicingprocessingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDispatchselfinvoicingprocessingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/dispatchselfinvoicingprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessing: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessing', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessing', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchselfinvoicingprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/dispatchselfinvoicingprocessing/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DispatchSelfInvoicingProcessingApi - functional programming interface
 * @export
 */
export const DispatchSelfInvoicingProcessingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DispatchSelfInvoicingProcessingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDispatchselfinvoicingprocessingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDispatchselfinvoicingprocessingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessing(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DispatchSelfInvoicingProcessingApi - factory interface
 * @export
 */
export const DispatchSelfInvoicingProcessingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DispatchSelfInvoicingProcessingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDispatchselfinvoicingprocessingGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdDispatchselfinvoicingprocessingGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessing(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessing(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DispatchSelfInvoicingProcessingApi - object-oriented interface
 * @export
 * @class DispatchSelfInvoicingProcessingApi
 * @extends {BaseAPI}
 */
export class DispatchSelfInvoicingProcessingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingProcessingApi
     */
    public apiTenantIdDispatchselfinvoicingprocessingGet(tenantId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingProcessingApiFp(this.configuration).apiTenantIdDispatchselfinvoicingprocessingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingProcessingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessing(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DispatchSelfInvoicingProcessingApi
     */
    public apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return DispatchSelfInvoicingProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataDispatchSelfInvoicingProcessingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EditorApi - axios parameter creator
 * @export
 */
export const EditorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the tokens for a specific invoice by its ID.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] The ID of the invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdEditorGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdEditorGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdEditorGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/editor`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetHtmlFromXml
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {GetPrintFromXmlDto} [getPrintFromXmlDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdEditorHtmlFromXmlPost: async (tenantId: string, organizationId: string, getPrintFromXmlDto?: GetPrintFromXmlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdEditorHtmlFromXmlPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdEditorHtmlFromXmlPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/editor/html-from-xml`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPrintFromXmlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets editor model.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [schema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdEditorModelGet: async (tenantId: string, organizationId: string, schema?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdEditorModelGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdEditorModelGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/editor/model`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (schema !== undefined) {
                localVarQueryParameter['schema'] = schema;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets tokens for a new invoice.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdEditorNewGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdEditorNewGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdEditorNewGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/editor/new`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetPdfFromXml
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {GetPrintFromXmlDto} [getPrintFromXmlDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdEditorPdfFromXmlPost: async (tenantId: string, organizationId: string, getPrintFromXmlDto?: GetPrintFromXmlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdEditorPdfFromXmlPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdEditorPdfFromXmlPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/editor/pdf-from-xml`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPrintFromXmlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EditorApi - functional programming interface
 * @export
 */
export const EditorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EditorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the tokens for a specific invoice by its ID.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] The ID of the invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdEditorGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceEditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdEditorGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetHtmlFromXml
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {GetPrintFromXmlDto} [getPrintFromXmlDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdEditorHtmlFromXmlPost(tenantId: string, organizationId: string, getPrintFromXmlDto?: GetPrintFromXmlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdEditorHtmlFromXmlPost(tenantId, organizationId, getPrintFromXmlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets editor model.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [schema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdEditorModelGet(tenantId: string, organizationId: string, schema?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdEditorModelGet(tenantId, organizationId, schema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets tokens for a new invoice.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdEditorNewGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceEditDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdEditorNewGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetPdfFromXml
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {GetPrintFromXmlDto} [getPrintFromXmlDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdEditorPdfFromXmlPost(tenantId: string, organizationId: string, getPrintFromXmlDto?: GetPrintFromXmlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdEditorPdfFromXmlPost(tenantId, organizationId, getPrintFromXmlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EditorApi - factory interface
 * @export
 */
export const EditorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EditorApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the tokens for a specific invoice by its ID.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] The ID of the invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdEditorGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<InvoiceEditDto> {
            return localVarFp.apiTenantIdOrganizationIdEditorGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetHtmlFromXml
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {GetPrintFromXmlDto} [getPrintFromXmlDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdEditorHtmlFromXmlPost(tenantId: string, organizationId: string, getPrintFromXmlDto?: GetPrintFromXmlDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdEditorHtmlFromXmlPost(tenantId, organizationId, getPrintFromXmlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets editor model.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [schema] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdEditorModelGet(tenantId: string, organizationId: string, schema?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdEditorModelGet(tenantId, organizationId, schema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets tokens for a new invoice.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdEditorNewGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<InvoiceEditDto> {
            return localVarFp.apiTenantIdOrganizationIdEditorNewGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetPdfFromXml
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {GetPrintFromXmlDto} [getPrintFromXmlDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdEditorPdfFromXmlPost(tenantId: string, organizationId: string, getPrintFromXmlDto?: GetPrintFromXmlDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdEditorPdfFromXmlPost(tenantId, organizationId, getPrintFromXmlDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EditorApi - object-oriented interface
 * @export
 * @class EditorApi
 * @extends {BaseAPI}
 */
export class EditorApi extends BaseAPI {
    /**
     * 
     * @summary Gets the tokens for a specific invoice by its ID.
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] The ID of the invoice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditorApi
     */
    public apiTenantIdOrganizationIdEditorGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return EditorApiFp(this.configuration).apiTenantIdOrganizationIdEditorGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetHtmlFromXml
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {GetPrintFromXmlDto} [getPrintFromXmlDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditorApi
     */
    public apiTenantIdOrganizationIdEditorHtmlFromXmlPost(tenantId: string, organizationId: string, getPrintFromXmlDto?: GetPrintFromXmlDto, options?: AxiosRequestConfig) {
        return EditorApiFp(this.configuration).apiTenantIdOrganizationIdEditorHtmlFromXmlPost(tenantId, organizationId, getPrintFromXmlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets editor model.
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [schema] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditorApi
     */
    public apiTenantIdOrganizationIdEditorModelGet(tenantId: string, organizationId: string, schema?: string, options?: AxiosRequestConfig) {
        return EditorApiFp(this.configuration).apiTenantIdOrganizationIdEditorModelGet(tenantId, organizationId, schema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets tokens for a new invoice.
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditorApi
     */
    public apiTenantIdOrganizationIdEditorNewGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return EditorApiFp(this.configuration).apiTenantIdOrganizationIdEditorNewGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetPdfFromXml
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {GetPrintFromXmlDto} [getPrintFromXmlDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditorApi
     */
    public apiTenantIdOrganizationIdEditorPdfFromXmlPost(tenantId: string, organizationId: string, getPrintFromXmlDto?: GetPrintFromXmlDto, options?: AxiosRequestConfig) {
        return EditorApiFp(this.configuration).apiTenantIdOrganizationIdEditorPdfFromXmlPost(tenantId, organizationId, getPrintFromXmlDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get exported data
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {ExportParameters} [exportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdExportPost: async (tenantId: string, organizationId: string, exportParameters?: ExportParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdExportPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdExportPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/export`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get exported data
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {ExportParameters} [exportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdExportPost(tenantId: string, organizationId: string, exportParameters?: ExportParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdExportPost(tenantId, organizationId, exportParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiFp(configuration)
    return {
        /**
         * 
         * @summary Get exported data
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {ExportParameters} [exportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdExportPost(tenantId: string, organizationId: string, exportParameters?: ExportParameters, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdExportPost(tenantId, organizationId, exportParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * 
     * @summary Get exported data
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {ExportParameters} [exportParameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public apiTenantIdOrganizationIdExportPost(tenantId: string, organizationId: string, exportParameters?: ExportParameters, options?: AxiosRequestConfig) {
        return ExportApiFp(this.configuration).apiTenantIdOrganizationIdExportPost(tenantId, organizationId, exportParameters, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceDraftApi - axios parameter creator
 * @export
 */
export const InvoiceDraftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Import draft invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [fileURI] 
         * @param {boolean} [compressed] 
         * @param {string} [externalTrackingId] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [manualUpload] 
         * @param {SubjectType} [dispatchType] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {number} [timeout] 
         * @param {string} [vatDate] 
         * @param {boolean} [base64] 
         * @param {CompressionType} [compressionType] 
         * @param {boolean} [zipped] 
         * @param {boolean} [offline] 
         * @param {boolean} [validatePostImportAccess] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftDraftPost: async (tenantId: string, organizationId: string, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftDraftPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftDraftPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoicedraft/draft`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fileURI !== undefined) {
                localVarQueryParameter['FileURI'] = fileURI;
            }

            if (compressed !== undefined) {
                localVarQueryParameter['Compressed'] = compressed;
            }

            if (externalTrackingId !== undefined) {
                localVarQueryParameter['ExternalTrackingId'] = externalTrackingId;
            }

            if (skipExternalTrackingIdValidation !== undefined) {
                localVarQueryParameter['SkipExternalTrackingIdValidation'] = skipExternalTrackingIdValidation;
            }

            if (requiresBatchDispatch !== undefined) {
                localVarQueryParameter['RequiresBatchDispatch'] = requiresBatchDispatch;
            }

            if (manualUpload !== undefined) {
                localVarQueryParameter['ManualUpload'] = manualUpload;
            }

            if (dispatchType !== undefined) {
                localVarQueryParameter['DispatchType'] = dispatchType;
            }

            if (tag1 !== undefined) {
                localVarQueryParameter['Tag1'] = tag1;
            }

            if (tag2 !== undefined) {
                localVarQueryParameter['Tag2'] = tag2;
            }

            if (tag3 !== undefined) {
                localVarQueryParameter['Tag3'] = tag3;
            }

            if (tag4 !== undefined) {
                localVarQueryParameter['Tag4'] = tag4;
            }

            if (tag5 !== undefined) {
                localVarQueryParameter['Tag5'] = tag5;
            }

            if (tag6 !== undefined) {
                localVarQueryParameter['Tag6'] = tag6;
            }

            if (tag7 !== undefined) {
                localVarQueryParameter['Tag7'] = tag7;
            }

            if (tag8 !== undefined) {
                localVarQueryParameter['Tag8'] = tag8;
            }

            if (tag9 !== undefined) {
                localVarQueryParameter['Tag9'] = tag9;
            }

            if (tag10 !== undefined) {
                localVarQueryParameter['Tag10'] = tag10;
            }

            if (forceXsdValidation !== undefined) {
                localVarQueryParameter['ForceXsdValidation'] = forceXsdValidation;
            }

            if (anonymize !== undefined) {
                localVarQueryParameter['Anonymize'] = anonymize;
            }

            if (timeout !== undefined) {
                localVarQueryParameter['Timeout'] = timeout;
            }

            if (vatDate !== undefined) {
                localVarQueryParameter['VatDate'] = (vatDate as any instanceof Date) ?
                    (vatDate as any).toISOString().substr(0,10) :
                    vatDate;
            }

            if (base64 !== undefined) {
                localVarQueryParameter['Base64'] = base64;
            }

            if (compressionType !== undefined) {
                localVarQueryParameter['CompressionType'] = compressionType;
            }

            if (zipped !== undefined) {
                localVarQueryParameter['Zipped'] = zipped;
            }

            if (offline !== undefined) {
                localVarQueryParameter['Offline'] = offline;
            }

            if (validatePostImportAccess !== undefined) {
                localVarQueryParameter['ValidatePostImportAccess'] = validatePostImportAccess;
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftIdDelete: async (id: string, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftIdDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftIdDelete', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftIdDelete', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoicedraft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftIdGet: async (id: string, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftIdGet', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftIdGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftIdGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoicedraft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {InvoiceDraftUpdateRequest} [invoiceDraftUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftIdPut: async (id: string, tenantId: string, organizationId: string, invoiceDraftUpdateRequest?: InvoiceDraftUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftIdPut', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftIdPut', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftIdPut', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoicedraft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceDraftUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {InvoiceDraftUpdateRequest} [invoiceDraftUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftPost: async (tenantId: string, organizationId: string, invoiceDraftUpdateRequest?: InvoiceDraftUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoicedraft`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceDraftUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftSendIdPut: async (id: string, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftSendIdPut', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftSendIdPut', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftSendIdPut', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoicedraft/send/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {InvoiceDraftDto} [invoiceDraftDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftXmlFromTokensPost: async (tenantId: string, organizationId: string, invoiceDraftDto?: InvoiceDraftDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftXmlFromTokensPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftXmlFromTokensPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoicedraft/xml-from-tokens`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceDraftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftXmlIdGet: async (id: string, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftXmlIdGet', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftXmlIdGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftXmlIdGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoicedraft/xml/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceDraftApi - functional programming interface
 * @export
 */
export const InvoiceDraftApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceDraftApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Import draft invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [fileURI] 
         * @param {boolean} [compressed] 
         * @param {string} [externalTrackingId] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [manualUpload] 
         * @param {SubjectType} [dispatchType] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {number} [timeout] 
         * @param {string} [vatDate] 
         * @param {boolean} [base64] 
         * @param {CompressionType} [compressionType] 
         * @param {boolean} [zipped] 
         * @param {boolean} [offline] 
         * @param {boolean} [validatePostImportAccess] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicedraftDraftPost(tenantId: string, organizationId: string, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProcessCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicedraftDraftPost(tenantId, organizationId, fileURI, compressed, externalTrackingId, skipExternalTrackingIdValidation, requiresBatchDispatch, manualUpload, dispatchType, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, timeout, vatDate, base64, compressionType, zipped, offline, validatePostImportAccess, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicedraftIdDelete(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicedraftIdDelete(id, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicedraftIdGet(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDraftAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicedraftIdGet(id, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {InvoiceDraftUpdateRequest} [invoiceDraftUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicedraftIdPut(id: string, tenantId: string, organizationId: string, invoiceDraftUpdateRequest?: InvoiceDraftUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDraftAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicedraftIdPut(id, tenantId, organizationId, invoiceDraftUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {InvoiceDraftUpdateRequest} [invoiceDraftUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicedraftPost(tenantId: string, organizationId: string, invoiceDraftUpdateRequest?: InvoiceDraftUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDraftAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicedraftPost(tenantId, organizationId, invoiceDraftUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicedraftSendIdPut(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicedraftSendIdPut(id, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {InvoiceDraftDto} [invoiceDraftDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicedraftXmlFromTokensPost(tenantId: string, organizationId: string, invoiceDraftDto?: InvoiceDraftDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicedraftXmlFromTokensPost(tenantId, organizationId, invoiceDraftDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicedraftXmlIdGet(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDraftAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicedraftXmlIdGet(id, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceDraftApi - factory interface
 * @export
 */
export const InvoiceDraftApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceDraftApiFp(configuration)
    return {
        /**
         * 
         * @summary Import draft invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [fileURI] 
         * @param {boolean} [compressed] 
         * @param {string} [externalTrackingId] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [manualUpload] 
         * @param {SubjectType} [dispatchType] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {number} [timeout] 
         * @param {string} [vatDate] 
         * @param {boolean} [base64] 
         * @param {CompressionType} [compressionType] 
         * @param {boolean} [zipped] 
         * @param {boolean} [offline] 
         * @param {boolean} [validatePostImportAccess] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftDraftPost(tenantId: string, organizationId: string, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, file?: any, options?: any): AxiosPromise<CreateProcessCommandResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoicedraftDraftPost(tenantId, organizationId, fileURI, compressed, externalTrackingId, skipExternalTrackingIdValidation, requiresBatchDispatch, manualUpload, dispatchType, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, timeout, vatDate, base64, compressionType, zipped, offline, validatePostImportAccess, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftIdDelete(id: string, tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicedraftIdDelete(id, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftIdGet(id: string, tenantId: string, organizationId: string, options?: any): AxiosPromise<InvoiceDraftAggregate> {
            return localVarFp.apiTenantIdOrganizationIdInvoicedraftIdGet(id, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {InvoiceDraftUpdateRequest} [invoiceDraftUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftIdPut(id: string, tenantId: string, organizationId: string, invoiceDraftUpdateRequest?: InvoiceDraftUpdateRequest, options?: any): AxiosPromise<InvoiceDraftAggregate> {
            return localVarFp.apiTenantIdOrganizationIdInvoicedraftIdPut(id, tenantId, organizationId, invoiceDraftUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {InvoiceDraftUpdateRequest} [invoiceDraftUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftPost(tenantId: string, organizationId: string, invoiceDraftUpdateRequest?: InvoiceDraftUpdateRequest, options?: any): AxiosPromise<InvoiceDraftAggregate> {
            return localVarFp.apiTenantIdOrganizationIdInvoicedraftPost(tenantId, organizationId, invoiceDraftUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftSendIdPut(id: string, tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicedraftSendIdPut(id, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {InvoiceDraftDto} [invoiceDraftDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftXmlFromTokensPost(tenantId: string, organizationId: string, invoiceDraftDto?: InvoiceDraftDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicedraftXmlFromTokensPost(tenantId, organizationId, invoiceDraftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftXmlIdGet(id: string, tenantId: string, organizationId: string, options?: any): AxiosPromise<InvoiceDraftAggregate> {
            return localVarFp.apiTenantIdOrganizationIdInvoicedraftXmlIdGet(id, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceDraftApi - object-oriented interface
 * @export
 * @class InvoiceDraftApi
 * @extends {BaseAPI}
 */
export class InvoiceDraftApi extends BaseAPI {
    /**
     * 
     * @summary Import draft invoice
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [fileURI] 
     * @param {boolean} [compressed] 
     * @param {string} [externalTrackingId] 
     * @param {boolean} [skipExternalTrackingIdValidation] 
     * @param {boolean} [requiresBatchDispatch] 
     * @param {boolean} [manualUpload] 
     * @param {SubjectType} [dispatchType] 
     * @param {string} [tag1] 
     * @param {string} [tag2] 
     * @param {string} [tag3] 
     * @param {string} [tag4] 
     * @param {string} [tag5] 
     * @param {string} [tag6] 
     * @param {string} [tag7] 
     * @param {string} [tag8] 
     * @param {string} [tag9] 
     * @param {string} [tag10] 
     * @param {boolean} [forceXsdValidation] 
     * @param {boolean} [anonymize] 
     * @param {number} [timeout] 
     * @param {string} [vatDate] 
     * @param {boolean} [base64] 
     * @param {CompressionType} [compressionType] 
     * @param {boolean} [zipped] 
     * @param {boolean} [offline] 
     * @param {boolean} [validatePostImportAccess] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftApi
     */
    public apiTenantIdOrganizationIdInvoicedraftDraftPost(tenantId: string, organizationId: string, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, file?: any, options?: AxiosRequestConfig) {
        return InvoiceDraftApiFp(this.configuration).apiTenantIdOrganizationIdInvoicedraftDraftPost(tenantId, organizationId, fileURI, compressed, externalTrackingId, skipExternalTrackingIdValidation, requiresBatchDispatch, manualUpload, dispatchType, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, timeout, vatDate, base64, compressionType, zipped, offline, validatePostImportAccess, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftApi
     */
    public apiTenantIdOrganizationIdInvoicedraftIdDelete(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoiceDraftApiFp(this.configuration).apiTenantIdOrganizationIdInvoicedraftIdDelete(id, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftApi
     */
    public apiTenantIdOrganizationIdInvoicedraftIdGet(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoiceDraftApiFp(this.configuration).apiTenantIdOrganizationIdInvoicedraftIdGet(id, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {InvoiceDraftUpdateRequest} [invoiceDraftUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftApi
     */
    public apiTenantIdOrganizationIdInvoicedraftIdPut(id: string, tenantId: string, organizationId: string, invoiceDraftUpdateRequest?: InvoiceDraftUpdateRequest, options?: AxiosRequestConfig) {
        return InvoiceDraftApiFp(this.configuration).apiTenantIdOrganizationIdInvoicedraftIdPut(id, tenantId, organizationId, invoiceDraftUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {InvoiceDraftUpdateRequest} [invoiceDraftUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftApi
     */
    public apiTenantIdOrganizationIdInvoicedraftPost(tenantId: string, organizationId: string, invoiceDraftUpdateRequest?: InvoiceDraftUpdateRequest, options?: AxiosRequestConfig) {
        return InvoiceDraftApiFp(this.configuration).apiTenantIdOrganizationIdInvoicedraftPost(tenantId, organizationId, invoiceDraftUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftApi
     */
    public apiTenantIdOrganizationIdInvoicedraftSendIdPut(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoiceDraftApiFp(this.configuration).apiTenantIdOrganizationIdInvoicedraftSendIdPut(id, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {InvoiceDraftDto} [invoiceDraftDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftApi
     */
    public apiTenantIdOrganizationIdInvoicedraftXmlFromTokensPost(tenantId: string, organizationId: string, invoiceDraftDto?: InvoiceDraftDto, options?: AxiosRequestConfig) {
        return InvoiceDraftApiFp(this.configuration).apiTenantIdOrganizationIdInvoicedraftXmlFromTokensPost(tenantId, organizationId, invoiceDraftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftApi
     */
    public apiTenantIdOrganizationIdInvoicedraftXmlIdGet(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoiceDraftApiFp(this.configuration).apiTenantIdOrganizationIdInvoicedraftXmlIdGet(id, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceDraftsApi - axios parameter creator
 * @export
 */
export const InvoiceDraftsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftsGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftsGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicedraftsGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoicedrafts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataInvoiceDrafts: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataInvoiceDrafts', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataInvoiceDrafts', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/invoicedrafts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataInvoiceDraftsCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataInvoiceDraftsCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataInvoiceDraftsCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/invoicedrafts/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceDraftsApi - functional programming interface
 * @export
 */
export const InvoiceDraftsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceDraftsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicedraftsGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceDraftAggregate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicedraftsGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataInvoiceDrafts(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceDraftAggregate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataInvoiceDrafts(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataInvoiceDraftsCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceDraftAggregate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataInvoiceDraftsCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceDraftsApi - factory interface
 * @export
 */
export const InvoiceDraftsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceDraftsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicedraftsGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<InvoiceDraftAggregate>> {
            return localVarFp.apiTenantIdOrganizationIdInvoicedraftsGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataInvoiceDrafts(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<InvoiceDraftAggregate>> {
            return localVarFp.apiTenantIdOrganizationIdOdataInvoiceDrafts(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataInvoiceDraftsCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<InvoiceDraftAggregate>> {
            return localVarFp.apiTenantIdOrganizationIdOdataInvoiceDraftsCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceDraftsApi - object-oriented interface
 * @export
 * @class InvoiceDraftsApi
 * @extends {BaseAPI}
 */
export class InvoiceDraftsApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftsApi
     */
    public apiTenantIdOrganizationIdInvoicedraftsGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoiceDraftsApiFp(this.configuration).apiTenantIdOrganizationIdInvoicedraftsGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftsApi
     */
    public apiTenantIdOrganizationIdOdataInvoiceDrafts(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoiceDraftsApiFp(this.configuration).apiTenantIdOrganizationIdOdataInvoiceDrafts(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceDraftsApi
     */
    public apiTenantIdOrganizationIdOdataInvoiceDraftsCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoiceDraftsApiFp(this.configuration).apiTenantIdOrganizationIdOdataInvoiceDraftsCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceIntegrationApi - axios parameter creator
 * @export
 */
export const InvoiceIntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [anonymize] 
         * @param {string} [vatDate] 
         * @param {boolean} [offline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationBulkPost: async (tenantId: string, organizationId: string, file?: any, forceXsdValidation?: boolean, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, anonymize?: boolean, vatDate?: string, offline?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationBulkPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationBulkPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/bulk`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (forceXsdValidation !== undefined) { 
                localVarFormParams.append('ForceXsdValidation', forceXsdValidation as any);
            }
    
            if (skipExternalTrackingIdValidation !== undefined) { 
                localVarFormParams.append('SkipExternalTrackingIdValidation', skipExternalTrackingIdValidation as any);
            }
    
            if (requiresBatchDispatch !== undefined) { 
                localVarFormParams.append('RequiresBatchDispatch', requiresBatchDispatch as any);
            }
    
            if (anonymize !== undefined) { 
                localVarFormParams.append('Anonymize', anonymize as any);
            }
    
            if (vatDate !== undefined) { 
                localVarFormParams.append('VatDate', vatDate as any);
            }
    
            if (offline !== undefined) { 
                localVarFormParams.append('Offline', offline as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [adjustTokens] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {string} [vatDate] 
         * @param {ANSICode} [aNSICode] 
         * @param {SubjectType} [dispatchType] 
         * @param {boolean} [manualUpload] 
         * @param {boolean} [importOnlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationBulkfromtokensPost: async (tenantId: string, organizationId: string, file?: any, adjustTokens?: boolean, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, requiresBatchDispatch?: boolean, vatDate?: string, aNSICode?: ANSICode, dispatchType?: SubjectType, manualUpload?: boolean, importOnlyValid?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationBulkfromtokensPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationBulkfromtokensPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/bulkfromtokens`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (adjustTokens !== undefined) { 
                localVarFormParams.append('AdjustTokens', adjustTokens as any);
            }
    
            if (tag1 !== undefined) { 
                localVarFormParams.append('Tag1', tag1 as any);
            }
    
            if (tag2 !== undefined) { 
                localVarFormParams.append('Tag2', tag2 as any);
            }
    
            if (tag3 !== undefined) { 
                localVarFormParams.append('Tag3', tag3 as any);
            }
    
            if (tag4 !== undefined) { 
                localVarFormParams.append('Tag4', tag4 as any);
            }
    
            if (tag5 !== undefined) { 
                localVarFormParams.append('Tag5', tag5 as any);
            }
    
            if (tag6 !== undefined) { 
                localVarFormParams.append('Tag6', tag6 as any);
            }
    
            if (tag7 !== undefined) { 
                localVarFormParams.append('Tag7', tag7 as any);
            }
    
            if (tag8 !== undefined) { 
                localVarFormParams.append('Tag8', tag8 as any);
            }
    
            if (tag9 !== undefined) { 
                localVarFormParams.append('Tag9', tag9 as any);
            }
    
            if (tag10 !== undefined) { 
                localVarFormParams.append('Tag10', tag10 as any);
            }
    
            if (forceXsdValidation !== undefined) { 
                localVarFormParams.append('ForceXsdValidation', forceXsdValidation as any);
            }
    
            if (anonymize !== undefined) { 
                localVarFormParams.append('Anonymize', anonymize as any);
            }
    
            if (requiresBatchDispatch !== undefined) { 
                localVarFormParams.append('RequiresBatchDispatch', requiresBatchDispatch as any);
            }
    
            if (vatDate !== undefined) { 
                localVarFormParams.append('VatDate', vatDate as any);
            }
    
            if (aNSICode !== undefined) { 
                localVarFormParams.append('ANSICode', new Blob([JSON.stringify(aNSICode)], { type: "application/json", }));
            }
    
            if (dispatchType !== undefined) { 
                localVarFormParams.append('DispatchType', new Blob([JSON.stringify(dispatchType)], { type: "application/json", }));
            }
    
            if (manualUpload !== undefined) { 
                localVarFormParams.append('ManualUpload', manualUpload as any);
            }
    
            if (importOnlyValid !== undefined) { 
                localVarFormParams.append('ImportOnlyValid', importOnlyValid as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchHtmlGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchHtmlGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchHtmlGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/dispatch/html`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchPdfGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchPdfGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchPdfGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/dispatch/pdf`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchDispatchProcessQuery} [bulkSearchDispatchProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkPost: async (tenantId: string, organizationId: string, bulkSearchDispatchProcessQuery?: BulkSearchDispatchProcessQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/dispatch/search/bulk`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkSearchDispatchProcessQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchDispatchProcessQuery} [bulkSearchDispatchProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkSimplePost: async (tenantId: string, organizationId: string, bulkSearchDispatchProcessQuery?: BulkSearchDispatchProcessQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkSimplePost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkSimplePost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/dispatch/search/bulk/simple`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkSearchDispatchProcessQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [externalTrackingId] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchGet: async (tenantId: string, organizationId: string, id?: string, externalTrackingId?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/dispatch/search`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (externalTrackingId !== undefined) {
                localVarQueryParameter['ExternalTrackingId'] = externalTrackingId;
            }

            if (ksefNumber !== undefined) {
                localVarQueryParameter['KsefNumber'] = ksefNumber;
            }

            if (invoiceNumber !== undefined) {
                localVarQueryParameter['InvoiceNumber'] = invoiceNumber;
            }

            if (fKSystem !== undefined) {
                localVarQueryParameter['FKSystem'] = fKSystem;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns dispatch invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchXmlGet: async (tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchXmlGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationDispatchXmlGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/dispatch/xml`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (asFile !== undefined) {
                localVarQueryParameter['asFile'] = asFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedules an invoice in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {string} [fileURI] 
         * @param {boolean} [compressed] 
         * @param {string} [externalTrackingId] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [manualUpload] 
         * @param {SubjectType} [dispatchType] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {number} [timeout] 
         * @param {string} [vatDate] 
         * @param {boolean} [base64] 
         * @param {CompressionType} [compressionType] 
         * @param {boolean} [zipped] 
         * @param {boolean} [offline] 
         * @param {boolean} [validatePostImportAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationPost: async (tenantId: string, organizationId: string, file?: any, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (fileURI !== undefined) { 
                localVarFormParams.append('FileURI', fileURI as any);
            }
    
            if (compressed !== undefined) { 
                localVarFormParams.append('Compressed', compressed as any);
            }
    
            if (externalTrackingId !== undefined) { 
                localVarFormParams.append('ExternalTrackingId', externalTrackingId as any);
            }
    
            if (skipExternalTrackingIdValidation !== undefined) { 
                localVarFormParams.append('SkipExternalTrackingIdValidation', skipExternalTrackingIdValidation as any);
            }
    
            if (requiresBatchDispatch !== undefined) { 
                localVarFormParams.append('RequiresBatchDispatch', requiresBatchDispatch as any);
            }
    
            if (manualUpload !== undefined) { 
                localVarFormParams.append('ManualUpload', manualUpload as any);
            }
    
            if (dispatchType !== undefined) { 
                localVarFormParams.append('DispatchType', new Blob([JSON.stringify(dispatchType)], { type: "application/json", }));
            }
    
            if (tag1 !== undefined) { 
                localVarFormParams.append('Tag1', tag1 as any);
            }
    
            if (tag2 !== undefined) { 
                localVarFormParams.append('Tag2', tag2 as any);
            }
    
            if (tag3 !== undefined) { 
                localVarFormParams.append('Tag3', tag3 as any);
            }
    
            if (tag4 !== undefined) { 
                localVarFormParams.append('Tag4', tag4 as any);
            }
    
            if (tag5 !== undefined) { 
                localVarFormParams.append('Tag5', tag5 as any);
            }
    
            if (tag6 !== undefined) { 
                localVarFormParams.append('Tag6', tag6 as any);
            }
    
            if (tag7 !== undefined) { 
                localVarFormParams.append('Tag7', tag7 as any);
            }
    
            if (tag8 !== undefined) { 
                localVarFormParams.append('Tag8', tag8 as any);
            }
    
            if (tag9 !== undefined) { 
                localVarFormParams.append('Tag9', tag9 as any);
            }
    
            if (tag10 !== undefined) { 
                localVarFormParams.append('Tag10', tag10 as any);
            }
    
            if (forceXsdValidation !== undefined) { 
                localVarFormParams.append('ForceXsdValidation', forceXsdValidation as any);
            }
    
            if (anonymize !== undefined) { 
                localVarFormParams.append('Anonymize', anonymize as any);
            }
    
            if (timeout !== undefined) { 
                localVarFormParams.append('Timeout', timeout as any);
            }
    
            if (vatDate !== undefined) { 
                localVarFormParams.append('VatDate', vatDate as any);
            }
    
            if (base64 !== undefined) { 
                localVarFormParams.append('Base64', base64 as any);
            }
    
            if (compressionType !== undefined) { 
                localVarFormParams.append('CompressionType', new Blob([JSON.stringify(compressionType)], { type: "application/json", }));
            }
    
            if (zipped !== undefined) { 
                localVarFormParams.append('Zipped', zipped as any);
            }
    
            if (offline !== undefined) { 
                localVarFormParams.append('Offline', offline as any);
            }
    
            if (validatePostImportAccess !== undefined) { 
                localVarFormParams.append('ValidatePostImportAccess', validatePostImportAccess as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns received purchase invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionGet: async (tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/reception`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (receivedByExternalSystem !== undefined) {
                localVarQueryParameter['receivedByExternalSystem'] = receivedByExternalSystem;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionHtmlGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionHtmlGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionHtmlGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/reception/html`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns received other invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionOtherGet: async (tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionOtherGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionOtherGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/reception/other`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (receivedByExternalSystem !== undefined) {
                localVarQueryParameter['receivedByExternalSystem'] = receivedByExternalSystem;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionPdfGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionPdfGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionPdfGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/reception/pdf`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns received sales invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionSalesGet: async (tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionSalesGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionSalesGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/reception/sales`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (receivedByExternalSystem !== undefined) {
                localVarQueryParameter['receivedByExternalSystem'] = receivedByExternalSystem;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchReceptionProcessQuery} [bulkSearchReceptionProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkPost: async (tenantId: string, organizationId: string, bulkSearchReceptionProcessQuery?: BulkSearchReceptionProcessQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/reception/search/bulk`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkSearchReceptionProcessQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchReceptionProcessQuery} [bulkSearchReceptionProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkSimplePost: async (tenantId: string, organizationId: string, bulkSearchReceptionProcessQuery?: BulkSearchReceptionProcessQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkSimplePost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkSimplePost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/reception/search/bulk/simple`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkSearchReceptionProcessQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchGet: async (tenantId: string, organizationId: string, id?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/reception/search`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (ksefNumber !== undefined) {
                localVarQueryParameter['KsefNumber'] = ksefNumber;
            }

            if (invoiceNumber !== undefined) {
                localVarQueryParameter['InvoiceNumber'] = invoiceNumber;
            }

            if (fKSystem !== undefined) {
                localVarQueryParameter['FKSystem'] = fKSystem;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns received invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionXmlGet: async (tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionXmlGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationReceptionXmlGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/reception/xml`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (asFile !== undefined) {
                localVarQueryParameter['asFile'] = asFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set received flag for invoices
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {Array<SetReceivedInvoiceDto>} [setReceivedInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationSetReceivedPut: async (tenantId: string, organizationId: string, setReceivedInvoiceDto?: Array<SetReceivedInvoiceDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationSetReceivedPut', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationSetReceivedPut', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/set-received`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setReceivedInvoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns UPO for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationUpoGet: async (tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationUpoGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationUpoGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/upo`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (asFile !== undefined) {
                localVarQueryParameter['asFile'] = asFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PDF UPO representation for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationUpoPdfGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationUpoPdfGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationUpoPdfGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/upo/pdf`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns an image of verification qr code in png format.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] Id of verified invoice.
         * @param {number} [size] Size in centimeters for 300 DPI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationValidationQrcodeGet: async (tenantId: string, organizationId: string, invoiceId?: string, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationValidationQrcodeGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationValidationQrcodeGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/validation/qrcode`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns verification url for specified invoice.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] Id of verified invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationValidationUrlGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationValidationUrlGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoiceintegrationValidationUrlGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoiceintegration/validation/url`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceIntegrationApi - functional programming interface
 * @export
 */
export const InvoiceIntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceIntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [anonymize] 
         * @param {string} [vatDate] 
         * @param {boolean} [offline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationBulkPost(tenantId: string, organizationId: string, file?: any, forceXsdValidation?: boolean, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, anonymize?: boolean, vatDate?: string, offline?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProcessesFromZipCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationBulkPost(tenantId, organizationId, file, forceXsdValidation, skipExternalTrackingIdValidation, requiresBatchDispatch, anonymize, vatDate, offline, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [adjustTokens] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {string} [vatDate] 
         * @param {ANSICode} [aNSICode] 
         * @param {SubjectType} [dispatchType] 
         * @param {boolean} [manualUpload] 
         * @param {boolean} [importOnlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationBulkfromtokensPost(tenantId: string, organizationId: string, file?: any, adjustTokens?: boolean, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, requiresBatchDispatch?: boolean, vatDate?: string, aNSICode?: ANSICode, dispatchType?: SubjectType, manualUpload?: boolean, importOnlyValid?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProcessesFromTokensCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationBulkfromtokensPost(tenantId, organizationId, file, adjustTokens, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, requiresBatchDispatch, vatDate, aNSICode, dispatchType, manualUpload, importOnlyValid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationDispatchHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationDispatchHtmlGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationDispatchPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationDispatchPdfGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchDispatchProcessQuery} [bulkSearchDispatchProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkPost(tenantId: string, organizationId: string, bulkSearchDispatchProcessQuery?: BulkSearchDispatchProcessQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatchBulkSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkPost(tenantId, organizationId, bulkSearchDispatchProcessQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchDispatchProcessQuery} [bulkSearchDispatchProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkSimplePost(tenantId: string, organizationId: string, bulkSearchDispatchProcessQuery?: BulkSearchDispatchProcessQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DispatchSimpleBulkSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkSimplePost(tenantId, organizationId, bulkSearchDispatchProcessQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [externalTrackingId] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchGet(tenantId: string, organizationId: string, id?: string, externalTrackingId?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchDispatchProcessResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchGet(tenantId, organizationId, id, externalTrackingId, ksefNumber, invoiceNumber, fKSystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns dispatch invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationDispatchXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationDispatchXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedules an invoice in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {string} [fileURI] 
         * @param {boolean} [compressed] 
         * @param {string} [externalTrackingId] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [manualUpload] 
         * @param {SubjectType} [dispatchType] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {number} [timeout] 
         * @param {string} [vatDate] 
         * @param {boolean} [base64] 
         * @param {CompressionType} [compressionType] 
         * @param {boolean} [zipped] 
         * @param {boolean} [offline] 
         * @param {boolean} [validatePostImportAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationPost(tenantId: string, organizationId: string, file?: any, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProcessCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationPost(tenantId, organizationId, file, fileURI, compressed, externalTrackingId, skipExternalTrackingIdValidation, requiresBatchDispatch, manualUpload, dispatchType, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, timeout, vatDate, base64, compressionType, zipped, offline, validatePostImportAccess, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns received purchase invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationReceptionGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchReceptionProcessResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationReceptionGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationReceptionHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationReceptionHtmlGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns received other invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationReceptionOtherGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchReceptionProcessResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationReceptionOtherGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationReceptionPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationReceptionPdfGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns received sales invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationReceptionSalesGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchReceptionProcessResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationReceptionSalesGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchReceptionProcessQuery} [bulkSearchReceptionProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkPost(tenantId: string, organizationId: string, bulkSearchReceptionProcessQuery?: BulkSearchReceptionProcessQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceptionBulkSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkPost(tenantId, organizationId, bulkSearchReceptionProcessQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchReceptionProcessQuery} [bulkSearchReceptionProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkSimplePost(tenantId: string, organizationId: string, bulkSearchReceptionProcessQuery?: BulkSearchReceptionProcessQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceptionSimpleBulkSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkSimplePost(tenantId, organizationId, bulkSearchReceptionProcessQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchGet(tenantId: string, organizationId: string, id?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchReceptionProcessResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchGet(tenantId, organizationId, id, ksefNumber, invoiceNumber, fKSystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns received invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationReceptionXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationReceptionXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set received flag for invoices
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {Array<SetReceivedInvoiceDto>} [setReceivedInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationSetReceivedPut(tenantId: string, organizationId: string, setReceivedInvoiceDto?: Array<SetReceivedInvoiceDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationSetReceivedPut(tenantId, organizationId, setReceivedInvoiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns UPO for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationUpoGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationUpoGet(tenantId, organizationId, invoiceId, pretty, asFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PDF UPO representation for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationUpoPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationUpoPdfGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns an image of verification qr code in png format.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] Id of verified invoice.
         * @param {number} [size] Size in centimeters for 300 DPI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationValidationQrcodeGet(tenantId: string, organizationId: string, invoiceId?: string, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationValidationQrcodeGet(tenantId, organizationId, invoiceId, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns verification url for specified invoice.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] Id of verified invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoiceintegrationValidationUrlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoiceintegrationValidationUrlGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceIntegrationApi - factory interface
 * @export
 */
export const InvoiceIntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceIntegrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [anonymize] 
         * @param {string} [vatDate] 
         * @param {boolean} [offline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationBulkPost(tenantId: string, organizationId: string, file?: any, forceXsdValidation?: boolean, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, anonymize?: boolean, vatDate?: string, offline?: boolean, options?: any): AxiosPromise<CreateProcessesFromZipCommandResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationBulkPost(tenantId, organizationId, file, forceXsdValidation, skipExternalTrackingIdValidation, requiresBatchDispatch, anonymize, vatDate, offline, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [adjustTokens] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {string} [vatDate] 
         * @param {ANSICode} [aNSICode] 
         * @param {SubjectType} [dispatchType] 
         * @param {boolean} [manualUpload] 
         * @param {boolean} [importOnlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationBulkfromtokensPost(tenantId: string, organizationId: string, file?: any, adjustTokens?: boolean, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, requiresBatchDispatch?: boolean, vatDate?: string, aNSICode?: ANSICode, dispatchType?: SubjectType, manualUpload?: boolean, importOnlyValid?: boolean, options?: any): AxiosPromise<CreateProcessesFromTokensCommandResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationBulkfromtokensPost(tenantId, organizationId, file, adjustTokens, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, requiresBatchDispatch, vatDate, aNSICode, dispatchType, manualUpload, importOnlyValid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationDispatchHtmlGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationDispatchPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchDispatchProcessQuery} [bulkSearchDispatchProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkPost(tenantId: string, organizationId: string, bulkSearchDispatchProcessQuery?: BulkSearchDispatchProcessQuery, options?: any): AxiosPromise<DispatchBulkSearchResponse> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkPost(tenantId, organizationId, bulkSearchDispatchProcessQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchDispatchProcessQuery} [bulkSearchDispatchProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkSimplePost(tenantId: string, organizationId: string, bulkSearchDispatchProcessQuery?: BulkSearchDispatchProcessQuery, options?: any): AxiosPromise<DispatchSimpleBulkSearchResponse> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkSimplePost(tenantId, organizationId, bulkSearchDispatchProcessQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [externalTrackingId] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchGet(tenantId: string, organizationId: string, id?: string, externalTrackingId?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: any): AxiosPromise<SearchDispatchProcessResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchGet(tenantId, organizationId, id, externalTrackingId, ksefNumber, invoiceNumber, fKSystem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns dispatch invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationDispatchXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationDispatchXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedules an invoice in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {string} [fileURI] 
         * @param {boolean} [compressed] 
         * @param {string} [externalTrackingId] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [manualUpload] 
         * @param {SubjectType} [dispatchType] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {number} [timeout] 
         * @param {string} [vatDate] 
         * @param {boolean} [base64] 
         * @param {CompressionType} [compressionType] 
         * @param {boolean} [zipped] 
         * @param {boolean} [offline] 
         * @param {boolean} [validatePostImportAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationPost(tenantId: string, organizationId: string, file?: any, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, options?: any): AxiosPromise<CreateProcessCommandResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationPost(tenantId, organizationId, file, fileURI, compressed, externalTrackingId, skipExternalTrackingIdValidation, requiresBatchDispatch, manualUpload, dispatchType, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, timeout, vatDate, base64, compressionType, zipped, offline, validatePostImportAccess, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns received purchase invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: any): AxiosPromise<Array<SearchReceptionProcessResult>> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationReceptionGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationReceptionHtmlGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns received other invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionOtherGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: any): AxiosPromise<Array<SearchReceptionProcessResult>> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationReceptionOtherGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationReceptionPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns received sales invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionSalesGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: any): AxiosPromise<Array<SearchReceptionProcessResult>> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationReceptionSalesGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchReceptionProcessQuery} [bulkSearchReceptionProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkPost(tenantId: string, organizationId: string, bulkSearchReceptionProcessQuery?: BulkSearchReceptionProcessQuery, options?: any): AxiosPromise<ReceptionBulkSearchResponse> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkPost(tenantId, organizationId, bulkSearchReceptionProcessQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {BulkSearchReceptionProcessQuery} [bulkSearchReceptionProcessQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkSimplePost(tenantId: string, organizationId: string, bulkSearchReceptionProcessQuery?: BulkSearchReceptionProcessQuery, options?: any): AxiosPromise<ReceptionSimpleBulkSearchResponse> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkSimplePost(tenantId, organizationId, bulkSearchReceptionProcessQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchGet(tenantId: string, organizationId: string, id?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: any): AxiosPromise<SearchReceptionProcessResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchGet(tenantId, organizationId, id, ksefNumber, invoiceNumber, fKSystem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns received invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationReceptionXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationReceptionXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set received flag for invoices
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {Array<SetReceivedInvoiceDto>} [setReceivedInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationSetReceivedPut(tenantId: string, organizationId: string, setReceivedInvoiceDto?: Array<SetReceivedInvoiceDto>, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationSetReceivedPut(tenantId, organizationId, setReceivedInvoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns UPO for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationUpoGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationUpoGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PDF UPO representation for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationUpoPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationUpoPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns an image of verification qr code in png format.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] Id of verified invoice.
         * @param {number} [size] Size in centimeters for 300 DPI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationValidationQrcodeGet(tenantId: string, organizationId: string, invoiceId?: string, size?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationValidationQrcodeGet(tenantId, organizationId, invoiceId, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns verification url for specified invoice.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] Id of verified invoice.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoiceintegrationValidationUrlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoiceintegrationValidationUrlGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceIntegrationApi - object-oriented interface
 * @export
 * @class InvoiceIntegrationApi
 * @extends {BaseAPI}
 */
export class InvoiceIntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Schedules a collection of invoices in the dispatch pipeline
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {any} [file] 
     * @param {boolean} [forceXsdValidation] 
     * @param {boolean} [skipExternalTrackingIdValidation] 
     * @param {boolean} [requiresBatchDispatch] 
     * @param {boolean} [anonymize] 
     * @param {string} [vatDate] 
     * @param {boolean} [offline] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationBulkPost(tenantId: string, organizationId: string, file?: any, forceXsdValidation?: boolean, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, anonymize?: boolean, vatDate?: string, offline?: boolean, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationBulkPost(tenantId, organizationId, file, forceXsdValidation, skipExternalTrackingIdValidation, requiresBatchDispatch, anonymize, vatDate, offline, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedules a collection of invoices in the dispatch pipeline
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {any} [file] 
     * @param {boolean} [adjustTokens] 
     * @param {string} [tag1] 
     * @param {string} [tag2] 
     * @param {string} [tag3] 
     * @param {string} [tag4] 
     * @param {string} [tag5] 
     * @param {string} [tag6] 
     * @param {string} [tag7] 
     * @param {string} [tag8] 
     * @param {string} [tag9] 
     * @param {string} [tag10] 
     * @param {boolean} [forceXsdValidation] 
     * @param {boolean} [anonymize] 
     * @param {boolean} [requiresBatchDispatch] 
     * @param {string} [vatDate] 
     * @param {ANSICode} [aNSICode] 
     * @param {SubjectType} [dispatchType] 
     * @param {boolean} [manualUpload] 
     * @param {boolean} [importOnlyValid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationBulkfromtokensPost(tenantId: string, organizationId: string, file?: any, adjustTokens?: boolean, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, requiresBatchDispatch?: boolean, vatDate?: string, aNSICode?: ANSICode, dispatchType?: SubjectType, manualUpload?: boolean, importOnlyValid?: boolean, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationBulkfromtokensPost(tenantId, organizationId, file, adjustTokens, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, requiresBatchDispatch, vatDate, aNSICode, dispatchType, manualUpload, importOnlyValid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a HTML invoice representation
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationDispatchHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationDispatchHtmlGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PDF invoice representation
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationDispatchPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationDispatchPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for a dispatch invoice metadata
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {BulkSearchDispatchProcessQuery} [bulkSearchDispatchProcessQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkPost(tenantId: string, organizationId: string, bulkSearchDispatchProcessQuery?: BulkSearchDispatchProcessQuery, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkPost(tenantId, organizationId, bulkSearchDispatchProcessQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for a dispatch invoice metadata
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {BulkSearchDispatchProcessQuery} [bulkSearchDispatchProcessQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkSimplePost(tenantId: string, organizationId: string, bulkSearchDispatchProcessQuery?: BulkSearchDispatchProcessQuery, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchBulkSimplePost(tenantId, organizationId, bulkSearchDispatchProcessQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for a dispatch invoice metadata
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [id] 
     * @param {string} [externalTrackingId] 
     * @param {string} [ksefNumber] 
     * @param {string} [invoiceNumber] 
     * @param {string} [fKSystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchGet(tenantId: string, organizationId: string, id?: string, externalTrackingId?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationDispatchSearchGet(tenantId, organizationId, id, externalTrackingId, ksefNumber, invoiceNumber, fKSystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns dispatch invoice XML
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {boolean} [pretty] 
     * @param {boolean} [asFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationDispatchXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationDispatchXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedules an invoice in the dispatch pipeline
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {any} [file] 
     * @param {string} [fileURI] 
     * @param {boolean} [compressed] 
     * @param {string} [externalTrackingId] 
     * @param {boolean} [skipExternalTrackingIdValidation] 
     * @param {boolean} [requiresBatchDispatch] 
     * @param {boolean} [manualUpload] 
     * @param {SubjectType} [dispatchType] 
     * @param {string} [tag1] 
     * @param {string} [tag2] 
     * @param {string} [tag3] 
     * @param {string} [tag4] 
     * @param {string} [tag5] 
     * @param {string} [tag6] 
     * @param {string} [tag7] 
     * @param {string} [tag8] 
     * @param {string} [tag9] 
     * @param {string} [tag10] 
     * @param {boolean} [forceXsdValidation] 
     * @param {boolean} [anonymize] 
     * @param {number} [timeout] 
     * @param {string} [vatDate] 
     * @param {boolean} [base64] 
     * @param {CompressionType} [compressionType] 
     * @param {boolean} [zipped] 
     * @param {boolean} [offline] 
     * @param {boolean} [validatePostImportAccess] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationPost(tenantId: string, organizationId: string, file?: any, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationPost(tenantId, organizationId, file, fileURI, compressed, externalTrackingId, skipExternalTrackingIdValidation, requiresBatchDispatch, manualUpload, dispatchType, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, timeout, vatDate, base64, compressionType, zipped, offline, validatePostImportAccess, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns received purchase invoices metadata for given date range
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {boolean} [receivedByExternalSystem] 
     * @param {number} [start] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationReceptionGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationReceptionGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a HTML invoice representation
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationReceptionHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationReceptionHtmlGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns received other invoices metadata for given date range
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {boolean} [receivedByExternalSystem] 
     * @param {number} [start] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationReceptionOtherGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationReceptionOtherGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PDF invoice representation
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationReceptionPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationReceptionPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns received sales invoices metadata for given date range
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {boolean} [receivedByExternalSystem] 
     * @param {number} [start] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationReceptionSalesGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationReceptionSalesGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for a received invoice metadata
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {BulkSearchReceptionProcessQuery} [bulkSearchReceptionProcessQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkPost(tenantId: string, organizationId: string, bulkSearchReceptionProcessQuery?: BulkSearchReceptionProcessQuery, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkPost(tenantId, organizationId, bulkSearchReceptionProcessQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for a received invoice metadata
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {BulkSearchReceptionProcessQuery} [bulkSearchReceptionProcessQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkSimplePost(tenantId: string, organizationId: string, bulkSearchReceptionProcessQuery?: BulkSearchReceptionProcessQuery, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchBulkSimplePost(tenantId, organizationId, bulkSearchReceptionProcessQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for a received invoice metadata
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [id] 
     * @param {string} [ksefNumber] 
     * @param {string} [invoiceNumber] 
     * @param {string} [fKSystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchGet(tenantId: string, organizationId: string, id?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationReceptionSearchGet(tenantId, organizationId, id, ksefNumber, invoiceNumber, fKSystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns received invoice XML
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {boolean} [pretty] 
     * @param {boolean} [asFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationReceptionXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationReceptionXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set received flag for invoices
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {Array<SetReceivedInvoiceDto>} [setReceivedInvoiceDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationSetReceivedPut(tenantId: string, organizationId: string, setReceivedInvoiceDto?: Array<SetReceivedInvoiceDto>, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationSetReceivedPut(tenantId, organizationId, setReceivedInvoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns UPO for given invoice
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {boolean} [pretty] 
     * @param {boolean} [asFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationUpoGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationUpoGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PDF UPO representation for given invoice
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationUpoPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationUpoPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns an image of verification qr code in png format.
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] Id of verified invoice.
     * @param {number} [size] Size in centimeters for 300 DPI.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationValidationQrcodeGet(tenantId: string, organizationId: string, invoiceId?: string, size?: number, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationValidationQrcodeGet(tenantId, organizationId, invoiceId, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns verification url for specified invoice.
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] Id of verified invoice.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceIntegrationApi
     */
    public apiTenantIdOrganizationIdInvoiceintegrationValidationUrlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoiceIntegrationApiFp(this.configuration).apiTenantIdOrganizationIdInvoiceintegrationValidationUrlGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns invoices that require manual accept
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesAwaitingmanualacceptGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesAwaitingmanualacceptGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesAwaitingmanualacceptGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/awaitingmanualaccept`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Insert processes from billing to db and create batch
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {string} [billingId] 
         * @param {SubjectType} [batchDispatchType] 
         * @param {string} [externalTrackingId] 
         * @param {string} [scheduledDateUtc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesBillingBulkPost: async (tenantId: string, organizationId: string, file?: any, billingId?: string, batchDispatchType?: SubjectType, externalTrackingId?: string, scheduledDateUtc?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesBillingBulkPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesBillingBulkPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/billing/bulk`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (billingId !== undefined) { 
                localVarFormParams.append('BillingId', billingId as any);
            }
    
            if (batchDispatchType !== undefined) { 
                localVarFormParams.append('BatchDispatchType', new Blob([JSON.stringify(batchDispatchType)], { type: "application/json", }));
            }
    
            if (externalTrackingId !== undefined) { 
                localVarFormParams.append('ExternalTrackingId', externalTrackingId as any);
            }
    
            if (scheduledDateUtc !== undefined) { 
                localVarFormParams.append('ScheduledDateUtc', scheduledDateUtc as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns exportmetadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {boolean} [errors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesBillingExportmetadataGet: async (tenantId: string, organizationId: string, id?: string, errors?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesBillingExportmetadataGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesBillingExportmetadataGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/billing/exportmetadata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (errors !== undefined) {
                localVarQueryParameter['errors'] = errors;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [anonymize] 
         * @param {string} [vatDate] 
         * @param {boolean} [offline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesBulkPost: async (tenantId: string, organizationId: string, file?: any, forceXsdValidation?: boolean, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, anonymize?: boolean, vatDate?: string, offline?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesBulkPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesBulkPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/bulk`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (forceXsdValidation !== undefined) { 
                localVarFormParams.append('ForceXsdValidation', forceXsdValidation as any);
            }
    
            if (skipExternalTrackingIdValidation !== undefined) { 
                localVarFormParams.append('SkipExternalTrackingIdValidation', skipExternalTrackingIdValidation as any);
            }
    
            if (requiresBatchDispatch !== undefined) { 
                localVarFormParams.append('RequiresBatchDispatch', requiresBatchDispatch as any);
            }
    
            if (anonymize !== undefined) { 
                localVarFormParams.append('Anonymize', anonymize as any);
            }
    
            if (vatDate !== undefined) { 
                localVarFormParams.append('VatDate', vatDate as any);
            }
    
            if (offline !== undefined) { 
                localVarFormParams.append('Offline', offline as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [adjustTokens] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {string} [vatDate] 
         * @param {ANSICode} [aNSICode] 
         * @param {SubjectType} [dispatchType] 
         * @param {boolean} [manualUpload] 
         * @param {boolean} [importOnlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesBulkfromtokensPost: async (tenantId: string, organizationId: string, file?: any, adjustTokens?: boolean, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, requiresBatchDispatch?: boolean, vatDate?: string, aNSICode?: ANSICode, dispatchType?: SubjectType, manualUpload?: boolean, importOnlyValid?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesBulkfromtokensPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesBulkfromtokensPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/bulkfromtokens`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (adjustTokens !== undefined) { 
                localVarFormParams.append('AdjustTokens', adjustTokens as any);
            }
    
            if (tag1 !== undefined) { 
                localVarFormParams.append('Tag1', tag1 as any);
            }
    
            if (tag2 !== undefined) { 
                localVarFormParams.append('Tag2', tag2 as any);
            }
    
            if (tag3 !== undefined) { 
                localVarFormParams.append('Tag3', tag3 as any);
            }
    
            if (tag4 !== undefined) { 
                localVarFormParams.append('Tag4', tag4 as any);
            }
    
            if (tag5 !== undefined) { 
                localVarFormParams.append('Tag5', tag5 as any);
            }
    
            if (tag6 !== undefined) { 
                localVarFormParams.append('Tag6', tag6 as any);
            }
    
            if (tag7 !== undefined) { 
                localVarFormParams.append('Tag7', tag7 as any);
            }
    
            if (tag8 !== undefined) { 
                localVarFormParams.append('Tag8', tag8 as any);
            }
    
            if (tag9 !== undefined) { 
                localVarFormParams.append('Tag9', tag9 as any);
            }
    
            if (tag10 !== undefined) { 
                localVarFormParams.append('Tag10', tag10 as any);
            }
    
            if (forceXsdValidation !== undefined) { 
                localVarFormParams.append('ForceXsdValidation', forceXsdValidation as any);
            }
    
            if (anonymize !== undefined) { 
                localVarFormParams.append('Anonymize', anonymize as any);
            }
    
            if (requiresBatchDispatch !== undefined) { 
                localVarFormParams.append('RequiresBatchDispatch', requiresBatchDispatch as any);
            }
    
            if (vatDate !== undefined) { 
                localVarFormParams.append('VatDate', vatDate as any);
            }
    
            if (aNSICode !== undefined) { 
                localVarFormParams.append('ANSICode', new Blob([JSON.stringify(aNSICode)], { type: "application/json", }));
            }
    
            if (dispatchType !== undefined) { 
                localVarFormParams.append('DispatchType', new Blob([JSON.stringify(dispatchType)], { type: "application/json", }));
            }
    
            if (manualUpload !== undefined) { 
                localVarFormParams.append('ManualUpload', manualUpload as any);
            }
    
            if (importOnlyValid !== undefined) { 
                localVarFormParams.append('ImportOnlyValid', importOnlyValid as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedules a job to create archive
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [sourceId] 
         * @param {PostJobCreateArchiveDto} [postJobCreateArchiveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesCreatearchivePost: async (tenantId: string, organizationId: string, sourceId?: string, postJobCreateArchiveDto?: PostJobCreateArchiveDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesCreatearchivePost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesCreatearchivePost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/createarchive`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (sourceId !== undefined) {
                localVarQueryParameter['sourceId'] = sourceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postJobCreateArchiveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given process id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchBusinessLogGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchBusinessLogGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchBusinessLogGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/dispatch/business-log`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchHtmlGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchHtmlGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchHtmlGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/dispatch/html`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manual accept for given dispatch process
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [processId] 
         * @param {boolean} [reject] Pass true to reject an invoice (it will end up as an erroneous invoice with appropriate msg)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchManualAcceptPost: async (tenantId: string, organizationId: string, processId?: string, reject?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchManualAcceptPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchManualAcceptPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/dispatch/manual-accept`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (processId !== undefined) {
                localVarQueryParameter['processId'] = processId;
            }

            if (reject !== undefined) {
                localVarQueryParameter['reject'] = reject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchPdfGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchPdfGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchPdfGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/dispatch/pdf`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [externalTrackingId] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchSearchGet: async (tenantId: string, organizationId: string, id?: string, externalTrackingId?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchSearchGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchSearchGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/dispatch/search`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (externalTrackingId !== undefined) {
                localVarQueryParameter['ExternalTrackingId'] = externalTrackingId;
            }

            if (ksefNumber !== undefined) {
                localVarQueryParameter['KsefNumber'] = ksefNumber;
            }

            if (invoiceNumber !== undefined) {
                localVarQueryParameter['InvoiceNumber'] = invoiceNumber;
            }

            if (fKSystem !== undefined) {
                localVarQueryParameter['FKSystem'] = fKSystem;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns dispatch invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchXmlGet: async (tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchXmlGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesDispatchXmlGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/dispatch/xml`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (asFile !== undefined) {
                localVarQueryParameter['asFile'] = asFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get UPO on demand.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesGetUpoOnDemandGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesGetUpoOnDemandGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesGetUpoOnDemandGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/get-upo-on-demand`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedules an invoice in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {string} [fileURI] 
         * @param {boolean} [compressed] 
         * @param {string} [externalTrackingId] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [manualUpload] 
         * @param {SubjectType} [dispatchType] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {number} [timeout] 
         * @param {string} [vatDate] 
         * @param {boolean} [base64] 
         * @param {CompressionType} [compressionType] 
         * @param {boolean} [zipped] 
         * @param {boolean} [offline] 
         * @param {boolean} [validatePostImportAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesPost: async (tenantId: string, organizationId: string, file?: any, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (fileURI !== undefined) { 
                localVarFormParams.append('FileURI', fileURI as any);
            }
    
            if (compressed !== undefined) { 
                localVarFormParams.append('Compressed', compressed as any);
            }
    
            if (externalTrackingId !== undefined) { 
                localVarFormParams.append('ExternalTrackingId', externalTrackingId as any);
            }
    
            if (skipExternalTrackingIdValidation !== undefined) { 
                localVarFormParams.append('SkipExternalTrackingIdValidation', skipExternalTrackingIdValidation as any);
            }
    
            if (requiresBatchDispatch !== undefined) { 
                localVarFormParams.append('RequiresBatchDispatch', requiresBatchDispatch as any);
            }
    
            if (manualUpload !== undefined) { 
                localVarFormParams.append('ManualUpload', manualUpload as any);
            }
    
            if (dispatchType !== undefined) { 
                localVarFormParams.append('DispatchType', new Blob([JSON.stringify(dispatchType)], { type: "application/json", }));
            }
    
            if (tag1 !== undefined) { 
                localVarFormParams.append('Tag1', tag1 as any);
            }
    
            if (tag2 !== undefined) { 
                localVarFormParams.append('Tag2', tag2 as any);
            }
    
            if (tag3 !== undefined) { 
                localVarFormParams.append('Tag3', tag3 as any);
            }
    
            if (tag4 !== undefined) { 
                localVarFormParams.append('Tag4', tag4 as any);
            }
    
            if (tag5 !== undefined) { 
                localVarFormParams.append('Tag5', tag5 as any);
            }
    
            if (tag6 !== undefined) { 
                localVarFormParams.append('Tag6', tag6 as any);
            }
    
            if (tag7 !== undefined) { 
                localVarFormParams.append('Tag7', tag7 as any);
            }
    
            if (tag8 !== undefined) { 
                localVarFormParams.append('Tag8', tag8 as any);
            }
    
            if (tag9 !== undefined) { 
                localVarFormParams.append('Tag9', tag9 as any);
            }
    
            if (tag10 !== undefined) { 
                localVarFormParams.append('Tag10', tag10 as any);
            }
    
            if (forceXsdValidation !== undefined) { 
                localVarFormParams.append('ForceXsdValidation', forceXsdValidation as any);
            }
    
            if (anonymize !== undefined) { 
                localVarFormParams.append('Anonymize', anonymize as any);
            }
    
            if (timeout !== undefined) { 
                localVarFormParams.append('Timeout', timeout as any);
            }
    
            if (vatDate !== undefined) { 
                localVarFormParams.append('VatDate', vatDate as any);
            }
    
            if (base64 !== undefined) { 
                localVarFormParams.append('Base64', base64 as any);
            }
    
            if (compressionType !== undefined) { 
                localVarFormParams.append('CompressionType', new Blob([JSON.stringify(compressionType)], { type: "application/json", }));
            }
    
            if (zipped !== undefined) { 
                localVarFormParams.append('Zipped', zipped as any);
            }
    
            if (offline !== undefined) { 
                localVarFormParams.append('Offline', offline as any);
            }
    
            if (validatePostImportAccess !== undefined) { 
                localVarFormParams.append('ValidatePostImportAccess', validatePostImportAccess as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary look for a duplicate by given pipelineType
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {PipelineType} [pipelineType] 
         * @param {InvoiceForModules} [invoiceForModules] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesProcessduplicatebypipelinetypePost: async (tenantId: string, organizationId: string, pipelineType?: PipelineType, invoiceForModules?: InvoiceForModules, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesProcessduplicatebypipelinetypePost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesProcessduplicatebypipelinetypePost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/processduplicatebypipelinetype`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (pipelineType !== undefined) {
                localVarQueryParameter['pipelineType'] = pipelineType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceForModules, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns received invoices metadata for given time range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionAllfromtimeperiodGet: async (tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionAllfromtimeperiodGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionAllfromtimeperiodGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/reception/allfromtimeperiod`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given process id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionBusinessLogGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionBusinessLogGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionBusinessLogGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/reception/business-log`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns received purchase invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionGet: async (tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/reception`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (receivedByExternalSystem !== undefined) {
                localVarQueryParameter['receivedByExternalSystem'] = receivedByExternalSystem;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionHtmlGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionHtmlGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionHtmlGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/reception/html`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manual accept for given reception process
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [processId] 
         * @param {boolean} [reject] Pass true to reject an invoice (it will end up as an erroneous invoice with appropriate msg)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionManualAcceptPost: async (tenantId: string, organizationId: string, processId?: string, reject?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionManualAcceptPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionManualAcceptPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/reception/manual-accept`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (processId !== undefined) {
                localVarQueryParameter['processId'] = processId;
            }

            if (reject !== undefined) {
                localVarQueryParameter['reject'] = reject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns received other invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionOtherGet: async (tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionOtherGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionOtherGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/reception/other`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (receivedByExternalSystem !== undefined) {
                localVarQueryParameter['receivedByExternalSystem'] = receivedByExternalSystem;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionPdfGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionPdfGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionPdfGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/reception/pdf`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns received sales invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionSalesGet: async (tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionSalesGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionSalesGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/reception/sales`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }

            if (receivedByExternalSystem !== undefined) {
                localVarQueryParameter['receivedByExternalSystem'] = receivedByExternalSystem;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionSearchGet: async (tenantId: string, organizationId: string, id?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionSearchGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionSearchGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/reception/search`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (ksefNumber !== undefined) {
                localVarQueryParameter['KsefNumber'] = ksefNumber;
            }

            if (invoiceNumber !== undefined) {
                localVarQueryParameter['InvoiceNumber'] = invoiceNumber;
            }

            if (fKSystem !== undefined) {
                localVarQueryParameter['FKSystem'] = fKSystem;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns received invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionXmlGet: async (tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionXmlGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptionXmlGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/reception/xml`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (asFile !== undefined) {
                localVarQueryParameter['asFile'] = asFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given process id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [checkupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptioncheckupsBusinessLogGet: async (tenantId: string, organizationId: string, checkupId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptioncheckupsBusinessLogGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesReceptioncheckupsBusinessLogGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/receptioncheckups/business-log`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (checkupId !== undefined) {
                localVarQueryParameter['checkupId'] = checkupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesResendPost: async (tenantId: string, organizationId: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesResendPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesResendPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/resend`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checks if repository contains any invoice (dispatch and reception) by given vatNumber
         * @param {string} vatNumber 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesSearchGet: async (vatNumber: string, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vatNumber' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesSearchGet', 'vatNumber', vatNumber)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesSearchGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesSearchGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/search`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (vatNumber !== undefined) {
                localVarQueryParameter['vatNumber'] = vatNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Schedules session for termmination.
         * @param {string} sessionId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost: async (sessionId: string, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost', 'sessionId', sessionId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/session/{sessionId}/mark-to-close`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given session id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesSessionsBusinessLogGet: async (tenantId: string, organizationId: string, sessionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesSessionsBusinessLogGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesSessionsBusinessLogGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/sessions/business-log`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {Array<SetReceivedInvoiceDto>} [setReceivedInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesSetReceivedPut: async (tenantId: string, organizationId: string, setReceivedInvoiceDto?: Array<SetReceivedInvoiceDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesSetReceivedPut', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesSetReceivedPut', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/set-received`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setReceivedInvoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns UPO for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesUpoGet: async (tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesUpoGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesUpoGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/upo`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (pretty !== undefined) {
                localVarQueryParameter['pretty'] = pretty;
            }

            if (asFile !== undefined) {
                localVarQueryParameter['asFile'] = asFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a PDF UPO representation for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesUpoPdfGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesUpoPdfGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesUpoPdfGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/upo/pdf`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns an image of verification qr code in png format.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] Id of verified invoice.
         * @param {number} [size] Size in centimeters for 300 DPI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesValidationQrcodeGet: async (tenantId: string, organizationId: string, invoiceId?: string, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesValidationQrcodeGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesValidationQrcodeGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/validation/qrcode`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesValidationUrlGet: async (tenantId: string, organizationId: string, invoiceId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesValidationUrlGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdInvoicesValidationUrlGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/invoices/validation/url`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns invoices that require manual accept
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesAwaitingmanualacceptGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesAwaitingmanualacceptGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Insert processes from billing to db and create batch
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {string} [billingId] 
         * @param {SubjectType} [batchDispatchType] 
         * @param {string} [externalTrackingId] 
         * @param {string} [scheduledDateUtc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesBillingBulkPost(tenantId: string, organizationId: string, file?: any, billingId?: string, batchDispatchType?: SubjectType, externalTrackingId?: string, scheduledDateUtc?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesBillingBulkPost(tenantId, organizationId, file, billingId, batchDispatchType, externalTrackingId, scheduledDateUtc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns exportmetadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {boolean} [errors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesBillingExportmetadataGet(tenantId: string, organizationId: string, id?: string, errors?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingExportMetadata>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesBillingExportmetadataGet(tenantId, organizationId, id, errors, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [anonymize] 
         * @param {string} [vatDate] 
         * @param {boolean} [offline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesBulkPost(tenantId: string, organizationId: string, file?: any, forceXsdValidation?: boolean, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, anonymize?: boolean, vatDate?: string, offline?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProcessesFromZipCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesBulkPost(tenantId, organizationId, file, forceXsdValidation, skipExternalTrackingIdValidation, requiresBatchDispatch, anonymize, vatDate, offline, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [adjustTokens] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {string} [vatDate] 
         * @param {ANSICode} [aNSICode] 
         * @param {SubjectType} [dispatchType] 
         * @param {boolean} [manualUpload] 
         * @param {boolean} [importOnlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesBulkfromtokensPost(tenantId: string, organizationId: string, file?: any, adjustTokens?: boolean, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, requiresBatchDispatch?: boolean, vatDate?: string, aNSICode?: ANSICode, dispatchType?: SubjectType, manualUpload?: boolean, importOnlyValid?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProcessesFromTokensCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesBulkfromtokensPost(tenantId, organizationId, file, adjustTokens, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, requiresBatchDispatch, vatDate, aNSICode, dispatchType, manualUpload, importOnlyValid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedules a job to create archive
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [sourceId] 
         * @param {PostJobCreateArchiveDto} [postJobCreateArchiveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesCreatearchivePost(tenantId: string, organizationId: string, sourceId?: string, postJobCreateArchiveDto?: PostJobCreateArchiveDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesCreatearchivePost(tenantId, organizationId, sourceId, postJobCreateArchiveDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given process id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesDispatchBusinessLogGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesDispatchBusinessLogGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesDispatchHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesDispatchHtmlGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manual accept for given dispatch process
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [processId] 
         * @param {boolean} [reject] Pass true to reject an invoice (it will end up as an erroneous invoice with appropriate msg)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesDispatchManualAcceptPost(tenantId: string, organizationId: string, processId?: string, reject?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesDispatchManualAcceptPost(tenantId, organizationId, processId, reject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesDispatchPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesDispatchPdfGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [externalTrackingId] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesDispatchSearchGet(tenantId: string, organizationId: string, id?: string, externalTrackingId?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchDispatchProcessResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesDispatchSearchGet(tenantId, organizationId, id, externalTrackingId, ksefNumber, invoiceNumber, fKSystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns dispatch invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesDispatchXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesDispatchXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get UPO on demand.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesGetUpoOnDemandGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesGetUpoOnDemandGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedules an invoice in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {string} [fileURI] 
         * @param {boolean} [compressed] 
         * @param {string} [externalTrackingId] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [manualUpload] 
         * @param {SubjectType} [dispatchType] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {number} [timeout] 
         * @param {string} [vatDate] 
         * @param {boolean} [base64] 
         * @param {CompressionType} [compressionType] 
         * @param {boolean} [zipped] 
         * @param {boolean} [offline] 
         * @param {boolean} [validatePostImportAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesPost(tenantId: string, organizationId: string, file?: any, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProcessCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesPost(tenantId, organizationId, file, fileURI, compressed, externalTrackingId, skipExternalTrackingIdValidation, requiresBatchDispatch, manualUpload, dispatchType, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, timeout, vatDate, base64, compressionType, zipped, offline, validatePostImportAccess, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary look for a duplicate by given pipelineType
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {PipelineType} [pipelineType] 
         * @param {InvoiceForModules} [invoiceForModules] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesProcessduplicatebypipelinetypePost(tenantId: string, organizationId: string, pipelineType?: PipelineType, invoiceForModules?: InvoiceForModules, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesProcessduplicatebypipelinetypePost(tenantId, organizationId, pipelineType, invoiceForModules, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns received invoices metadata for given time range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptionAllfromtimeperiodGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchReceptionProcessResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptionAllfromtimeperiodGet(tenantId, organizationId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given process id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptionBusinessLogGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptionBusinessLogGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns received purchase invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptionGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchReceptionProcessResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptionGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptionHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptionHtmlGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Manual accept for given reception process
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [processId] 
         * @param {boolean} [reject] Pass true to reject an invoice (it will end up as an erroneous invoice with appropriate msg)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptionManualAcceptPost(tenantId: string, organizationId: string, processId?: string, reject?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptionManualAcceptPost(tenantId, organizationId, processId, reject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns received other invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptionOtherGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchReceptionProcessResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptionOtherGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptionPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptionPdfGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns received sales invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptionSalesGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchReceptionProcessResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptionSalesGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptionSearchGet(tenantId: string, organizationId: string, id?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchReceptionProcessResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptionSearchGet(tenantId, organizationId, id, ksefNumber, invoiceNumber, fKSystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns received invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptionXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptionXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given process id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [checkupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesReceptioncheckupsBusinessLogGet(tenantId: string, organizationId: string, checkupId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesReceptioncheckupsBusinessLogGet(tenantId, organizationId, checkupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesResendPost(tenantId: string, organizationId: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesResendPost(tenantId, organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary checks if repository contains any invoice (dispatch and reception) by given vatNumber
         * @param {string} vatNumber 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesSearchGet(vatNumber: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesSearchGet(vatNumber, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Schedules session for termmination.
         * @param {string} sessionId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost(sessionId: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost(sessionId, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given session id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesSessionsBusinessLogGet(tenantId: string, organizationId: string, sessionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesSessionsBusinessLogGet(tenantId, organizationId, sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {Array<SetReceivedInvoiceDto>} [setReceivedInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesSetReceivedPut(tenantId: string, organizationId: string, setReceivedInvoiceDto?: Array<SetReceivedInvoiceDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesSetReceivedPut(tenantId, organizationId, setReceivedInvoiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns UPO for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesUpoGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesUpoGet(tenantId, organizationId, invoiceId, pretty, asFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a PDF UPO representation for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesUpoPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesUpoPdfGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns an image of verification qr code in png format.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] Id of verified invoice.
         * @param {number} [size] Size in centimeters for 300 DPI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesValidationQrcodeGet(tenantId: string, organizationId: string, invoiceId?: string, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesValidationQrcodeGet(tenantId, organizationId, invoiceId, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdInvoicesValidationUrlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdInvoicesValidationUrlGet(tenantId, organizationId, invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns invoices that require manual accept
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesAwaitingmanualacceptGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesAwaitingmanualacceptGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Insert processes from billing to db and create batch
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {string} [billingId] 
         * @param {SubjectType} [batchDispatchType] 
         * @param {string} [externalTrackingId] 
         * @param {string} [scheduledDateUtc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesBillingBulkPost(tenantId: string, organizationId: string, file?: any, billingId?: string, batchDispatchType?: SubjectType, externalTrackingId?: string, scheduledDateUtc?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesBillingBulkPost(tenantId, organizationId, file, billingId, batchDispatchType, externalTrackingId, scheduledDateUtc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns exportmetadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {boolean} [errors] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesBillingExportmetadataGet(tenantId: string, organizationId: string, id?: string, errors?: boolean, options?: any): AxiosPromise<Array<BillingExportMetadata>> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesBillingExportmetadataGet(tenantId, organizationId, id, errors, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [anonymize] 
         * @param {string} [vatDate] 
         * @param {boolean} [offline] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesBulkPost(tenantId: string, organizationId: string, file?: any, forceXsdValidation?: boolean, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, anonymize?: boolean, vatDate?: string, offline?: boolean, options?: any): AxiosPromise<CreateProcessesFromZipCommandResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesBulkPost(tenantId, organizationId, file, forceXsdValidation, skipExternalTrackingIdValidation, requiresBatchDispatch, anonymize, vatDate, offline, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedules a collection of invoices in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {boolean} [adjustTokens] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {string} [vatDate] 
         * @param {ANSICode} [aNSICode] 
         * @param {SubjectType} [dispatchType] 
         * @param {boolean} [manualUpload] 
         * @param {boolean} [importOnlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesBulkfromtokensPost(tenantId: string, organizationId: string, file?: any, adjustTokens?: boolean, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, requiresBatchDispatch?: boolean, vatDate?: string, aNSICode?: ANSICode, dispatchType?: SubjectType, manualUpload?: boolean, importOnlyValid?: boolean, options?: any): AxiosPromise<CreateProcessesFromTokensCommandResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesBulkfromtokensPost(tenantId, organizationId, file, adjustTokens, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, requiresBatchDispatch, vatDate, aNSICode, dispatchType, manualUpload, importOnlyValid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedules a job to create archive
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [sourceId] 
         * @param {PostJobCreateArchiveDto} [postJobCreateArchiveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesCreatearchivePost(tenantId: string, organizationId: string, sourceId?: string, postJobCreateArchiveDto?: PostJobCreateArchiveDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesCreatearchivePost(tenantId, organizationId, sourceId, postJobCreateArchiveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given process id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchBusinessLogGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesDispatchBusinessLogGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesDispatchHtmlGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manual accept for given dispatch process
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [processId] 
         * @param {boolean} [reject] Pass true to reject an invoice (it will end up as an erroneous invoice with appropriate msg)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchManualAcceptPost(tenantId: string, organizationId: string, processId?: string, reject?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesDispatchManualAcceptPost(tenantId, organizationId, processId, reject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesDispatchPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for a dispatch invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [externalTrackingId] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchSearchGet(tenantId: string, organizationId: string, id?: string, externalTrackingId?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: any): AxiosPromise<SearchDispatchProcessResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesDispatchSearchGet(tenantId, organizationId, id, externalTrackingId, ksefNumber, invoiceNumber, fKSystem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns dispatch invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesDispatchXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesDispatchXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get UPO on demand.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesGetUpoOnDemandGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesGetUpoOnDemandGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedules an invoice in the dispatch pipeline
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} [file] 
         * @param {string} [fileURI] 
         * @param {boolean} [compressed] 
         * @param {string} [externalTrackingId] 
         * @param {boolean} [skipExternalTrackingIdValidation] 
         * @param {boolean} [requiresBatchDispatch] 
         * @param {boolean} [manualUpload] 
         * @param {SubjectType} [dispatchType] 
         * @param {string} [tag1] 
         * @param {string} [tag2] 
         * @param {string} [tag3] 
         * @param {string} [tag4] 
         * @param {string} [tag5] 
         * @param {string} [tag6] 
         * @param {string} [tag7] 
         * @param {string} [tag8] 
         * @param {string} [tag9] 
         * @param {string} [tag10] 
         * @param {boolean} [forceXsdValidation] 
         * @param {boolean} [anonymize] 
         * @param {number} [timeout] 
         * @param {string} [vatDate] 
         * @param {boolean} [base64] 
         * @param {CompressionType} [compressionType] 
         * @param {boolean} [zipped] 
         * @param {boolean} [offline] 
         * @param {boolean} [validatePostImportAccess] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesPost(tenantId: string, organizationId: string, file?: any, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, options?: any): AxiosPromise<CreateProcessCommandResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesPost(tenantId, organizationId, file, fileURI, compressed, externalTrackingId, skipExternalTrackingIdValidation, requiresBatchDispatch, manualUpload, dispatchType, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, timeout, vatDate, base64, compressionType, zipped, offline, validatePostImportAccess, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary look for a duplicate by given pipelineType
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {PipelineType} [pipelineType] 
         * @param {InvoiceForModules} [invoiceForModules] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesProcessduplicatebypipelinetypePost(tenantId: string, organizationId: string, pipelineType?: PipelineType, invoiceForModules?: InvoiceForModules, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesProcessduplicatebypipelinetypePost(tenantId, organizationId, pipelineType, invoiceForModules, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns received invoices metadata for given time range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionAllfromtimeperiodGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, options?: any): AxiosPromise<Array<SearchReceptionProcessResult>> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptionAllfromtimeperiodGet(tenantId, organizationId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given process id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionBusinessLogGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptionBusinessLogGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns received purchase invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: any): AxiosPromise<Array<SearchReceptionProcessResult>> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptionGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a HTML invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptionHtmlGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manual accept for given reception process
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [processId] 
         * @param {boolean} [reject] Pass true to reject an invoice (it will end up as an erroneous invoice with appropriate msg)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionManualAcceptPost(tenantId: string, organizationId: string, processId?: string, reject?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptionManualAcceptPost(tenantId, organizationId, processId, reject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns received other invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionOtherGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: any): AxiosPromise<Array<SearchReceptionProcessResult>> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptionOtherGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PDF invoice representation
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptionPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns received sales invoices metadata for given date range
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {boolean} [receivedByExternalSystem] 
         * @param {number} [start] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionSalesGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: any): AxiosPromise<Array<SearchReceptionProcessResult>> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptionSalesGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for a received invoice metadata
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [id] 
         * @param {string} [ksefNumber] 
         * @param {string} [invoiceNumber] 
         * @param {string} [fKSystem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionSearchGet(tenantId: string, organizationId: string, id?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: any): AxiosPromise<SearchReceptionProcessResult> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptionSearchGet(tenantId, organizationId, id, ksefNumber, invoiceNumber, fKSystem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns received invoice XML
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptionXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptionXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given process id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [checkupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesReceptioncheckupsBusinessLogGet(tenantId: string, organizationId: string, checkupId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesReceptioncheckupsBusinessLogGet(tenantId, organizationId, checkupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesResendPost(tenantId: string, organizationId: string, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesResendPost(tenantId, organizationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary checks if repository contains any invoice (dispatch and reception) by given vatNumber
         * @param {string} vatNumber 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesSearchGet(vatNumber: string, tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesSearchGet(vatNumber, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Schedules session for termmination.
         * @param {string} sessionId 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost(sessionId: string, tenantId: string, organizationId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost(sessionId, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a collection of business events recorded for given session id
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesSessionsBusinessLogGet(tenantId: string, organizationId: string, sessionId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesSessionsBusinessLogGet(tenantId, organizationId, sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {Array<SetReceivedInvoiceDto>} [setReceivedInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesSetReceivedPut(tenantId: string, organizationId: string, setReceivedInvoiceDto?: Array<SetReceivedInvoiceDto>, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesSetReceivedPut(tenantId, organizationId, setReceivedInvoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns UPO for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {boolean} [pretty] 
         * @param {boolean} [asFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesUpoGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesUpoGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a PDF UPO representation for given invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesUpoPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesUpoPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns an image of verification qr code in png format.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] Id of verified invoice.
         * @param {number} [size] Size in centimeters for 300 DPI.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesValidationQrcodeGet(tenantId: string, organizationId: string, invoiceId?: string, size?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesValidationQrcodeGet(tenantId, organizationId, invoiceId, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [invoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdInvoicesValidationUrlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdInvoicesValidationUrlGet(tenantId, organizationId, invoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Returns invoices that require manual accept
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesAwaitingmanualacceptGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesAwaitingmanualacceptGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Insert processes from billing to db and create batch
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {any} [file] 
     * @param {string} [billingId] 
     * @param {SubjectType} [batchDispatchType] 
     * @param {string} [externalTrackingId] 
     * @param {string} [scheduledDateUtc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesBillingBulkPost(tenantId: string, organizationId: string, file?: any, billingId?: string, batchDispatchType?: SubjectType, externalTrackingId?: string, scheduledDateUtc?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesBillingBulkPost(tenantId, organizationId, file, billingId, batchDispatchType, externalTrackingId, scheduledDateUtc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns exportmetadata
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [id] 
     * @param {boolean} [errors] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesBillingExportmetadataGet(tenantId: string, organizationId: string, id?: string, errors?: boolean, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesBillingExportmetadataGet(tenantId, organizationId, id, errors, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedules a collection of invoices in the dispatch pipeline
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {any} [file] 
     * @param {boolean} [forceXsdValidation] 
     * @param {boolean} [skipExternalTrackingIdValidation] 
     * @param {boolean} [requiresBatchDispatch] 
     * @param {boolean} [anonymize] 
     * @param {string} [vatDate] 
     * @param {boolean} [offline] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesBulkPost(tenantId: string, organizationId: string, file?: any, forceXsdValidation?: boolean, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, anonymize?: boolean, vatDate?: string, offline?: boolean, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesBulkPost(tenantId, organizationId, file, forceXsdValidation, skipExternalTrackingIdValidation, requiresBatchDispatch, anonymize, vatDate, offline, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedules a collection of invoices in the dispatch pipeline
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {any} [file] 
     * @param {boolean} [adjustTokens] 
     * @param {string} [tag1] 
     * @param {string} [tag2] 
     * @param {string} [tag3] 
     * @param {string} [tag4] 
     * @param {string} [tag5] 
     * @param {string} [tag6] 
     * @param {string} [tag7] 
     * @param {string} [tag8] 
     * @param {string} [tag9] 
     * @param {string} [tag10] 
     * @param {boolean} [forceXsdValidation] 
     * @param {boolean} [anonymize] 
     * @param {boolean} [requiresBatchDispatch] 
     * @param {string} [vatDate] 
     * @param {ANSICode} [aNSICode] 
     * @param {SubjectType} [dispatchType] 
     * @param {boolean} [manualUpload] 
     * @param {boolean} [importOnlyValid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesBulkfromtokensPost(tenantId: string, organizationId: string, file?: any, adjustTokens?: boolean, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, requiresBatchDispatch?: boolean, vatDate?: string, aNSICode?: ANSICode, dispatchType?: SubjectType, manualUpload?: boolean, importOnlyValid?: boolean, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesBulkfromtokensPost(tenantId, organizationId, file, adjustTokens, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, requiresBatchDispatch, vatDate, aNSICode, dispatchType, manualUpload, importOnlyValid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedules a job to create archive
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [sourceId] 
     * @param {PostJobCreateArchiveDto} [postJobCreateArchiveDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesCreatearchivePost(tenantId: string, organizationId: string, sourceId?: string, postJobCreateArchiveDto?: PostJobCreateArchiveDto, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesCreatearchivePost(tenantId, organizationId, sourceId, postJobCreateArchiveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a collection of business events recorded for given process id
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesDispatchBusinessLogGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesDispatchBusinessLogGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a HTML invoice representation
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesDispatchHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesDispatchHtmlGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manual accept for given dispatch process
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [processId] 
     * @param {boolean} [reject] Pass true to reject an invoice (it will end up as an erroneous invoice with appropriate msg)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesDispatchManualAcceptPost(tenantId: string, organizationId: string, processId?: string, reject?: boolean, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesDispatchManualAcceptPost(tenantId, organizationId, processId, reject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PDF invoice representation
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesDispatchPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesDispatchPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for a dispatch invoice metadata
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [id] 
     * @param {string} [externalTrackingId] 
     * @param {string} [ksefNumber] 
     * @param {string} [invoiceNumber] 
     * @param {string} [fKSystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesDispatchSearchGet(tenantId: string, organizationId: string, id?: string, externalTrackingId?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesDispatchSearchGet(tenantId, organizationId, id, externalTrackingId, ksefNumber, invoiceNumber, fKSystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns dispatch invoice XML
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {boolean} [pretty] 
     * @param {boolean} [asFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesDispatchXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesDispatchXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get UPO on demand.
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesGetUpoOnDemandGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesGetUpoOnDemandGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedules an invoice in the dispatch pipeline
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {any} [file] 
     * @param {string} [fileURI] 
     * @param {boolean} [compressed] 
     * @param {string} [externalTrackingId] 
     * @param {boolean} [skipExternalTrackingIdValidation] 
     * @param {boolean} [requiresBatchDispatch] 
     * @param {boolean} [manualUpload] 
     * @param {SubjectType} [dispatchType] 
     * @param {string} [tag1] 
     * @param {string} [tag2] 
     * @param {string} [tag3] 
     * @param {string} [tag4] 
     * @param {string} [tag5] 
     * @param {string} [tag6] 
     * @param {string} [tag7] 
     * @param {string} [tag8] 
     * @param {string} [tag9] 
     * @param {string} [tag10] 
     * @param {boolean} [forceXsdValidation] 
     * @param {boolean} [anonymize] 
     * @param {number} [timeout] 
     * @param {string} [vatDate] 
     * @param {boolean} [base64] 
     * @param {CompressionType} [compressionType] 
     * @param {boolean} [zipped] 
     * @param {boolean} [offline] 
     * @param {boolean} [validatePostImportAccess] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesPost(tenantId: string, organizationId: string, file?: any, fileURI?: string, compressed?: boolean, externalTrackingId?: string, skipExternalTrackingIdValidation?: boolean, requiresBatchDispatch?: boolean, manualUpload?: boolean, dispatchType?: SubjectType, tag1?: string, tag2?: string, tag3?: string, tag4?: string, tag5?: string, tag6?: string, tag7?: string, tag8?: string, tag9?: string, tag10?: string, forceXsdValidation?: boolean, anonymize?: boolean, timeout?: number, vatDate?: string, base64?: boolean, compressionType?: CompressionType, zipped?: boolean, offline?: boolean, validatePostImportAccess?: boolean, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesPost(tenantId, organizationId, file, fileURI, compressed, externalTrackingId, skipExternalTrackingIdValidation, requiresBatchDispatch, manualUpload, dispatchType, tag1, tag2, tag3, tag4, tag5, tag6, tag7, tag8, tag9, tag10, forceXsdValidation, anonymize, timeout, vatDate, base64, compressionType, zipped, offline, validatePostImportAccess, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary look for a duplicate by given pipelineType
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {PipelineType} [pipelineType] 
     * @param {InvoiceForModules} [invoiceForModules] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesProcessduplicatebypipelinetypePost(tenantId: string, organizationId: string, pipelineType?: PipelineType, invoiceForModules?: InvoiceForModules, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesProcessduplicatebypipelinetypePost(tenantId, organizationId, pipelineType, invoiceForModules, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns received invoices metadata for given time range
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptionAllfromtimeperiodGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptionAllfromtimeperiodGet(tenantId, organizationId, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a collection of business events recorded for given process id
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptionBusinessLogGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptionBusinessLogGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns received purchase invoices metadata for given date range
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {boolean} [receivedByExternalSystem] 
     * @param {number} [start] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptionGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptionGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a HTML invoice representation
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptionHtmlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptionHtmlGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manual accept for given reception process
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [processId] 
     * @param {boolean} [reject] Pass true to reject an invoice (it will end up as an erroneous invoice with appropriate msg)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptionManualAcceptPost(tenantId: string, organizationId: string, processId?: string, reject?: boolean, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptionManualAcceptPost(tenantId, organizationId, processId, reject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns received other invoices metadata for given date range
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {boolean} [receivedByExternalSystem] 
     * @param {number} [start] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptionOtherGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptionOtherGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PDF invoice representation
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptionPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptionPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns received sales invoices metadata for given date range
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {boolean} [receivedByExternalSystem] 
     * @param {number} [start] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptionSalesGet(tenantId: string, organizationId: string, dateFrom?: string, dateTo?: string, receivedByExternalSystem?: boolean, start?: number, limit?: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptionSalesGet(tenantId, organizationId, dateFrom, dateTo, receivedByExternalSystem, start, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for a received invoice metadata
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [id] 
     * @param {string} [ksefNumber] 
     * @param {string} [invoiceNumber] 
     * @param {string} [fKSystem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptionSearchGet(tenantId: string, organizationId: string, id?: string, ksefNumber?: string, invoiceNumber?: string, fKSystem?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptionSearchGet(tenantId, organizationId, id, ksefNumber, invoiceNumber, fKSystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns received invoice XML
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {boolean} [pretty] 
     * @param {boolean} [asFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptionXmlGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptionXmlGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a collection of business events recorded for given process id
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [checkupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesReceptioncheckupsBusinessLogGet(tenantId: string, organizationId: string, checkupId?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesReceptioncheckupsBusinessLogGet(tenantId, organizationId, checkupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesResendPost(tenantId: string, organizationId: string, body?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesResendPost(tenantId, organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary checks if repository contains any invoice (dispatch and reception) by given vatNumber
     * @param {string} vatNumber 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesSearchGet(vatNumber: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesSearchGet(vatNumber, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Schedules session for termmination.
     * @param {string} sessionId 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost(sessionId: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesSessionSessionIdMarkToClosePost(sessionId, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a collection of business events recorded for given session id
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [sessionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesSessionsBusinessLogGet(tenantId: string, organizationId: string, sessionId?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesSessionsBusinessLogGet(tenantId, organizationId, sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {Array<SetReceivedInvoiceDto>} [setReceivedInvoiceDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesSetReceivedPut(tenantId: string, organizationId: string, setReceivedInvoiceDto?: Array<SetReceivedInvoiceDto>, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesSetReceivedPut(tenantId, organizationId, setReceivedInvoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns UPO for given invoice
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {boolean} [pretty] 
     * @param {boolean} [asFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesUpoGet(tenantId: string, organizationId: string, invoiceId?: string, pretty?: boolean, asFile?: boolean, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesUpoGet(tenantId, organizationId, invoiceId, pretty, asFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a PDF UPO representation for given invoice
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesUpoPdfGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesUpoPdfGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns an image of verification qr code in png format.
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] Id of verified invoice.
     * @param {number} [size] Size in centimeters for 300 DPI.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesValidationQrcodeGet(tenantId: string, organizationId: string, invoiceId?: string, size?: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesValidationQrcodeGet(tenantId, organizationId, invoiceId, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [invoiceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public apiTenantIdOrganizationIdInvoicesValidationUrlGet(tenantId: string, organizationId: string, invoiceId?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).apiTenantIdOrganizationIdInvoicesValidationUrlGet(tenantId, organizationId, invoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get job business event logs
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdJobsBusinessLogGet: async (tenantId: string, organizationId: string, jobId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdJobsBusinessLogGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdJobsBusinessLogGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/jobs/business-log`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdJobsCreateFromResultPost: async (organizationId: string, tenantId: string, file?: any, jsonData?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdJobsCreateFromResultPost', 'organizationId', organizationId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdJobsCreateFromResultPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/{organizationId}/jobs/create-from-result`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (jsonData !== undefined) { 
                localVarFormParams.append('jsonData', jsonData as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {string} [jobGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdJobsDelete: async (organizationId: string, tenantId: string, jobGuid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdJobsDelete', 'organizationId', organizationId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdJobsDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/{organizationId}/jobs`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (jobGuid !== undefined) {
                localVarQueryParameter['jobGuid'] = jobGuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdJobsGet: async (organizationId: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdJobsGet', 'organizationId', organizationId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdJobsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/{organizationId}/jobs`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get job result
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdJobsResultGet: async (tenantId: string, organizationId: string, jobId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdJobsResultGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdJobsResultGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/jobs/result`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataJobs: async (organizationId: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataJobs', 'organizationId', organizationId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataJobs', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/jobs`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataJobsCount: async (organizationId: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataJobsCount', 'organizationId', organizationId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataJobsCount', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/jobs/$count`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get job business event logs
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdJobsBusinessLogGet(tenantId: string, organizationId: string, jobId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdJobsBusinessLogGet(tenantId, organizationId, jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdJobsCreateFromResultPost(organizationId: string, tenantId: string, file?: any, jsonData?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdJobsCreateFromResultPost(organizationId, tenantId, file, jsonData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {string} [jobGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdJobsDelete(organizationId: string, tenantId: string, jobGuid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdJobsDelete(organizationId, tenantId, jobGuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdJobsGet(organizationId: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdJobsGet(organizationId, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get job result
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdJobsResultGet(tenantId: string, organizationId: string, jobId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdJobsResultGet(tenantId, organizationId, jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataJobs(organizationId: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataJobs(organizationId, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataJobsCount(organizationId: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataJobsCount(organizationId, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get job business event logs
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdJobsBusinessLogGet(tenantId: string, organizationId: string, jobId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdJobsBusinessLogGet(tenantId, organizationId, jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdJobsCreateFromResultPost(organizationId: string, tenantId: string, file?: any, jsonData?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdJobsCreateFromResultPost(organizationId, tenantId, file, jsonData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {string} [jobGuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdJobsDelete(organizationId: string, tenantId: string, jobGuid?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdJobsDelete(organizationId, tenantId, jobGuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdJobsGet(organizationId: string, tenantId: string, options?: any): AxiosPromise<Array<JobRM>> {
            return localVarFp.apiTenantIdOrganizationIdJobsGet(organizationId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get job result
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdJobsResultGet(tenantId: string, organizationId: string, jobId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdJobsResultGet(tenantId, organizationId, jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataJobs(organizationId: string, tenantId: string, options?: any): AxiosPromise<Array<JobRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataJobs(organizationId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataJobsCount(organizationId: string, tenantId: string, options?: any): AxiosPromise<Array<JobRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataJobsCount(organizationId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
    /**
     * 
     * @summary Get job business event logs
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [jobId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public apiTenantIdOrganizationIdJobsBusinessLogGet(tenantId: string, organizationId: string, jobId?: string, options?: AxiosRequestConfig) {
        return JobsApiFp(this.configuration).apiTenantIdOrganizationIdJobsBusinessLogGet(tenantId, organizationId, jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} tenantId 
     * @param {any} [file] 
     * @param {string} [jsonData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public apiTenantIdOrganizationIdJobsCreateFromResultPost(organizationId: string, tenantId: string, file?: any, jsonData?: string, options?: AxiosRequestConfig) {
        return JobsApiFp(this.configuration).apiTenantIdOrganizationIdJobsCreateFromResultPost(organizationId, tenantId, file, jsonData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} tenantId 
     * @param {string} [jobGuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public apiTenantIdOrganizationIdJobsDelete(organizationId: string, tenantId: string, jobGuid?: string, options?: AxiosRequestConfig) {
        return JobsApiFp(this.configuration).apiTenantIdOrganizationIdJobsDelete(organizationId, tenantId, jobGuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public apiTenantIdOrganizationIdJobsGet(organizationId: string, tenantId: string, options?: AxiosRequestConfig) {
        return JobsApiFp(this.configuration).apiTenantIdOrganizationIdJobsGet(organizationId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get job result
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [jobId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public apiTenantIdOrganizationIdJobsResultGet(tenantId: string, organizationId: string, jobId?: string, options?: AxiosRequestConfig) {
        return JobsApiFp(this.configuration).apiTenantIdOrganizationIdJobsResultGet(tenantId, organizationId, jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public apiTenantIdOrganizationIdOdataJobs(organizationId: string, tenantId: string, options?: AxiosRequestConfig) {
        return JobsApiFp(this.configuration).apiTenantIdOrganizationIdOdataJobs(organizationId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public apiTenantIdOrganizationIdOdataJobsCount(organizationId: string, tenantId: string, options?: AxiosRequestConfig) {
        return JobsApiFp(this.configuration).apiTenantIdOrganizationIdOdataJobsCount(organizationId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetadataApi - axios parameter creator
 * @export
 */
export const MetadataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdata: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdata', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdata', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataMetadata: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataMetadata', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataMetadata', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/$metadata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetadataApi - functional programming interface
 * @export
 */
export const MetadataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetadataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdata(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ODataServiceDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdata(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataMetadata(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEdmModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataMetadata(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetadataApi - factory interface
 * @export
 */
export const MetadataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetadataApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdata(tenantId: string, organizationId: string, options?: any): AxiosPromise<ODataServiceDocument> {
            return localVarFp.apiTenantIdOrganizationIdOdata(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataMetadata(tenantId: string, organizationId: string, options?: any): AxiosPromise<IEdmModel> {
            return localVarFp.apiTenantIdOrganizationIdOdataMetadata(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetadataApi - object-oriented interface
 * @export
 * @class MetadataApi
 * @extends {BaseAPI}
 */
export class MetadataApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public apiTenantIdOrganizationIdOdata(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return MetadataApiFp(this.configuration).apiTenantIdOrganizationIdOdata(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public apiTenantIdOrganizationIdOdataMetadata(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return MetadataApiFp(this.configuration).apiTenantIdOrganizationIdOdataMetadata(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MigrationApi - axios parameter creator
 * @export
 */
export const MigrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Migrate invoices by organization
         * @param {string} organizationId 
         * @param {string} migrateTo 
         * @param {string} tenantId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost: async (organizationId: string, migrateTo: string, tenantId: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost', 'organizationId', organizationId)
            // verify required parameter 'migrateTo' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost', 'migrateTo', migrateTo)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/{organizationId}/migration/purchase/{migrateTo}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"migrateTo"}}`, encodeURIComponent(String(migrateTo)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Migrate invoices by organization
         * @param {string} organizationId 
         * @param {string} migrateTo 
         * @param {string} tenantId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdMigrationSalesMigrateToPost: async (organizationId: string, migrateTo: string, tenantId: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdMigrationSalesMigrateToPost', 'organizationId', organizationId)
            // verify required parameter 'migrateTo' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdMigrationSalesMigrateToPost', 'migrateTo', migrateTo)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdMigrationSalesMigrateToPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/{organizationId}/migration/sales/{migrateTo}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"migrateTo"}}`, encodeURIComponent(String(migrateTo)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MigrationApi - functional programming interface
 * @export
 */
export const MigrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MigrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Migrate invoices by organization
         * @param {string} organizationId 
         * @param {string} migrateTo 
         * @param {string} tenantId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost(organizationId: string, migrateTo: string, tenantId: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost(organizationId, migrateTo, tenantId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Migrate invoices by organization
         * @param {string} organizationId 
         * @param {string} migrateTo 
         * @param {string} tenantId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdMigrationSalesMigrateToPost(organizationId: string, migrateTo: string, tenantId: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdMigrationSalesMigrateToPost(organizationId, migrateTo, tenantId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MigrationApi - factory interface
 * @export
 */
export const MigrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MigrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Migrate invoices by organization
         * @param {string} organizationId 
         * @param {string} migrateTo 
         * @param {string} tenantId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost(organizationId: string, migrateTo: string, tenantId: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost(organizationId, migrateTo, tenantId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Migrate invoices by organization
         * @param {string} organizationId 
         * @param {string} migrateTo 
         * @param {string} tenantId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdMigrationSalesMigrateToPost(organizationId: string, migrateTo: string, tenantId: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdMigrationSalesMigrateToPost(organizationId, migrateTo, tenantId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MigrationApi - object-oriented interface
 * @export
 * @class MigrationApi
 * @extends {BaseAPI}
 */
export class MigrationApi extends BaseAPI {
    /**
     * 
     * @summary Migrate invoices by organization
     * @param {string} organizationId 
     * @param {string} migrateTo 
     * @param {string} tenantId 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MigrationApi
     */
    public apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost(organizationId: string, migrateTo: string, tenantId: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return MigrationApiFp(this.configuration).apiTenantIdOrganizationIdMigrationPurchaseMigrateToPost(organizationId, migrateTo, tenantId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Migrate invoices by organization
     * @param {string} organizationId 
     * @param {string} migrateTo 
     * @param {string} tenantId 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MigrationApi
     */
    public apiTenantIdOrganizationIdMigrationSalesMigrateToPost(organizationId: string, migrateTo: string, tenantId: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return MigrationApiFp(this.configuration).apiTenantIdOrganizationIdMigrationSalesMigrateToPost(organizationId, migrateTo, tenantId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NbpExchangeRatesApi - axios parameter creator
 * @export
 */
export const NbpExchangeRatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Exchange rates from table A for specified dates
         * @param {string} [id] 
         * @param {string} [currency] 
         * @param {string} [dates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNbpexchangeratesGet: async (id?: string, currency?: string, dates?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/nbpexchangerates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (dates !== undefined) {
                localVarQueryParameter['dates'] = dates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NbpExchangeRatesApi - functional programming interface
 * @export
 */
export const NbpExchangeRatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NbpExchangeRatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Exchange rates from table A for specified dates
         * @param {string} [id] 
         * @param {string} [currency] 
         * @param {string} [dates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNbpexchangeratesGet(id?: string, currency?: string, dates?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNbpexchangeratesGet(id, currency, dates, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NbpExchangeRatesApi - factory interface
 * @export
 */
export const NbpExchangeRatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NbpExchangeRatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Exchange rates from table A for specified dates
         * @param {string} [id] 
         * @param {string} [currency] 
         * @param {string} [dates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNbpexchangeratesGet(id?: string, currency?: string, dates?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiNbpexchangeratesGet(id, currency, dates, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NbpExchangeRatesApi - object-oriented interface
 * @export
 * @class NbpExchangeRatesApi
 * @extends {BaseAPI}
 */
export class NbpExchangeRatesApi extends BaseAPI {
    /**
     * 
     * @summary Get Exchange rates from table A for specified dates
     * @param {string} [id] 
     * @param {string} [currency] 
     * @param {string} [dates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NbpExchangeRatesApi
     */
    public apiNbpexchangeratesGet(id?: string, currency?: string, dates?: string, options?: AxiosRequestConfig) {
        return NbpExchangeRatesApiFp(this.configuration).apiNbpexchangeratesGet(id, currency, dates, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OtherRepositoryApi - axios parameter creator
 * @export
 */
export const OtherRepositoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataOtherRepository: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataOtherRepository', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataOtherRepository', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/otherrepository`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataOtherRepositoryCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataOtherRepositoryCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataOtherRepositoryCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/otherrepository/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOtherrepositoryGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOtherrepositoryGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/otherrepository`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OtherRepositoryApi - functional programming interface
 * @export
 */
export const OtherRepositoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OtherRepositoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataOtherRepository(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchArchiveProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataOtherRepository(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataOtherRepositoryCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchArchiveProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataOtherRepositoryCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOtherrepositoryGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchArchiveProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOtherrepositoryGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OtherRepositoryApi - factory interface
 * @export
 */
export const OtherRepositoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OtherRepositoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataOtherRepository(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchArchiveProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataOtherRepository(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataOtherRepositoryCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchArchiveProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataOtherRepositoryCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOtherrepositoryGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchArchiveProcessRM>> {
            return localVarFp.apiTenantIdOtherrepositoryGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OtherRepositoryApi - object-oriented interface
 * @export
 * @class OtherRepositoryApi
 * @extends {BaseAPI}
 */
export class OtherRepositoryApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherRepositoryApi
     */
    public apiTenantIdOrganizationIdOdataOtherRepository(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return OtherRepositoryApiFp(this.configuration).apiTenantIdOrganizationIdOdataOtherRepository(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherRepositoryApi
     */
    public apiTenantIdOrganizationIdOdataOtherRepositoryCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return OtherRepositoryApiFp(this.configuration).apiTenantIdOrganizationIdOdataOtherRepositoryCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherRepositoryApi
     */
    public apiTenantIdOtherrepositoryGet(tenantId: string, options?: AxiosRequestConfig) {
        return OtherRepositoryApiFp(this.configuration).apiTenantIdOtherrepositoryGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseRepositoryApi - axios parameter creator
 * @export
 */
export const PurchaseRepositoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataPurchaseRepository: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataPurchaseRepository', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataPurchaseRepository', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/purchaserepository`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataPurchaseRepositoryCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataPurchaseRepositoryCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataPurchaseRepositoryCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/purchaserepository/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPurchaserepositoryGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPurchaserepositoryGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/purchaserepository`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseRepositoryApi - functional programming interface
 * @export
 */
export const PurchaseRepositoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseRepositoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataPurchaseRepository(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionRepositoryProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataPurchaseRepository(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataPurchaseRepositoryCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionRepositoryProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataPurchaseRepositoryCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPurchaserepositoryGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionRepositoryProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPurchaserepositoryGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseRepositoryApi - factory interface
 * @export
 */
export const PurchaseRepositoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseRepositoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataPurchaseRepository(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionRepositoryProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataPurchaseRepository(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataPurchaseRepositoryCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionRepositoryProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataPurchaseRepositoryCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPurchaserepositoryGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionRepositoryProcessRM>> {
            return localVarFp.apiTenantIdPurchaserepositoryGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseRepositoryApi - object-oriented interface
 * @export
 * @class PurchaseRepositoryApi
 * @extends {BaseAPI}
 */
export class PurchaseRepositoryApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRepositoryApi
     */
    public apiTenantIdOrganizationIdOdataPurchaseRepository(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return PurchaseRepositoryApiFp(this.configuration).apiTenantIdOrganizationIdOdataPurchaseRepository(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRepositoryApi
     */
    public apiTenantIdOrganizationIdOdataPurchaseRepositoryCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return PurchaseRepositoryApiFp(this.configuration).apiTenantIdOrganizationIdOdataPurchaseRepositoryCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseRepositoryApi
     */
    public apiTenantIdPurchaserepositoryGet(tenantId: string, options?: AxiosRequestConfig) {
        return PurchaseRepositoryApiFp(this.configuration).apiTenantIdPurchaserepositoryGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReadyApi - axios parameter creator
 * @export
 */
export const ReadyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReadyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ready`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReadyApi - functional programming interface
 * @export
 */
export const ReadyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReadyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReadyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReadyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReadyApi - factory interface
 * @export
 */
export const ReadyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReadyApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReadyGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiReadyGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReadyApi - object-oriented interface
 * @export
 * @class ReadyApi
 * @extends {BaseAPI}
 */
export class ReadyApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadyApi
     */
    public apiReadyGet(options?: AxiosRequestConfig) {
        return ReadyApiFp(this.configuration).apiReadyGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionCheckupsApi - axios parameter creator
 * @export
 */
export const ReceptionCheckupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionCheckups: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionCheckups', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionCheckups', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptioncheckups`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionCheckupsCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionCheckupsCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionCheckupsCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptioncheckups/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptioncheckupsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptioncheckupsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptioncheckups`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionCheckupsApi - functional programming interface
 * @export
 */
export const ReceptionCheckupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionCheckupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionCheckups(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionCheckupRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionCheckups(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionCheckupsCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionCheckupRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionCheckupsCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptioncheckupsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionCheckupRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptioncheckupsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionCheckupsApi - factory interface
 * @export
 */
export const ReceptionCheckupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionCheckupsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionCheckups(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionCheckupRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionCheckups(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionCheckupsCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionCheckupRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionCheckupsCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptioncheckupsGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionCheckupRM>> {
            return localVarFp.apiTenantIdReceptioncheckupsGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionCheckupsApi - object-oriented interface
 * @export
 * @class ReceptionCheckupsApi
 * @extends {BaseAPI}
 */
export class ReceptionCheckupsApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionCheckupsApi
     */
    public apiTenantIdOrganizationIdOdataReceptionCheckups(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionCheckupsApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionCheckups(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionCheckupsApi
     */
    public apiTenantIdOrganizationIdOdataReceptionCheckupsCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionCheckupsApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionCheckupsCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionCheckupsApi
     */
    public apiTenantIdReceptioncheckupsGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionCheckupsApiFp(this.configuration).apiTenantIdReceptioncheckupsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionOtherAlertApi - axios parameter creator
 * @export
 */
export const ReceptionOtherAlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherAlert: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherAlert', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherAlert', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionotheralert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherAlertCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherAlertCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherAlertCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionotheralert/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionotheralertGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionotheralertGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionotheralert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionOtherAlertApi - functional programming interface
 * @export
 */
export const ReceptionOtherAlertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionOtherAlertApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionOtherAlert(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionOtherAlert(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionOtherAlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionOtherAlertCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionotheralertGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionotheralertGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionOtherAlertApi - factory interface
 * @export
 */
export const ReceptionOtherAlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionOtherAlertApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherAlert(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionOtherAlert(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherAlertCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionOtherAlertCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionotheralertGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionotheralertGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionOtherAlertApi - object-oriented interface
 * @export
 * @class ReceptionOtherAlertApi
 * @extends {BaseAPI}
 */
export class ReceptionOtherAlertApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionOtherAlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionOtherAlert(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionOtherAlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionOtherAlert(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionOtherAlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionOtherAlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionOtherAlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionOtherAlertCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionOtherAlertApi
     */
    public apiTenantIdReceptionotheralertGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionOtherAlertApiFp(this.configuration).apiTenantIdReceptionotheralertGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionOtherProcessingApi - axios parameter creator
 * @export
 */
export const ReceptionOtherProcessingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherProcessing: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherProcessing', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherProcessing', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionotherprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherProcessingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherProcessingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherProcessingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionotherprocessing/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionotherprocessingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionotherprocessingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionotherprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionOtherProcessingApi - functional programming interface
 * @export
 */
export const ReceptionOtherProcessingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionOtherProcessingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionOtherProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionOtherProcessing(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionOtherProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionOtherProcessingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionotherprocessingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionotherprocessingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionOtherProcessingApi - factory interface
 * @export
 */
export const ReceptionOtherProcessingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionOtherProcessingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherProcessing(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionOtherProcessing(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherProcessingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionOtherProcessingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionotherprocessingGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionotherprocessingGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionOtherProcessingApi - object-oriented interface
 * @export
 * @class ReceptionOtherProcessingApi
 * @extends {BaseAPI}
 */
export class ReceptionOtherProcessingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionOtherProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionOtherProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionOtherProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionOtherProcessing(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionOtherProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionOtherProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionOtherProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionOtherProcessingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionOtherProcessingApi
     */
    public apiTenantIdReceptionotherprocessingGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionOtherProcessingApiFp(this.configuration).apiTenantIdReceptionotherprocessingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionOtherRevokedApi - axios parameter creator
 * @export
 */
export const ReceptionOtherRevokedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherRevoked: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherRevoked', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherRevoked', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionotherrevoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherRevokedCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherRevokedCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionOtherRevokedCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionotherrevoked/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionotherrevokedGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionotherrevokedGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionotherrevoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionOtherRevokedApi - functional programming interface
 * @export
 */
export const ReceptionOtherRevokedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionOtherRevokedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionOtherRevoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionOtherRevoked(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionOtherRevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionOtherRevokedCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionotherrevokedGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionotherrevokedGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionOtherRevokedApi - factory interface
 * @export
 */
export const ReceptionOtherRevokedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionOtherRevokedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherRevoked(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionOtherRevoked(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionOtherRevokedCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionOtherRevokedCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionotherrevokedGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionotherrevokedGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionOtherRevokedApi - object-oriented interface
 * @export
 * @class ReceptionOtherRevokedApi
 * @extends {BaseAPI}
 */
export class ReceptionOtherRevokedApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionOtherRevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionOtherRevoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionOtherRevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionOtherRevoked(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionOtherRevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionOtherRevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionOtherRevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionOtherRevokedCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionOtherRevokedApi
     */
    public apiTenantIdReceptionotherrevokedGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionOtherRevokedApiFp(this.configuration).apiTenantIdReceptionotherrevokedGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionPurchaseAlertApi - axios parameter creator
 * @export
 */
export const ReceptionPurchaseAlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseAlert: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseAlert', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseAlert', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchasealert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseAlertCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseAlertCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseAlertCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchasealert/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchasealertGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionpurchasealertGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionpurchasealert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionPurchaseAlertApi - functional programming interface
 * @export
 */
export const ReceptionPurchaseAlertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionPurchaseAlertApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseAlert(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseAlert(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseAlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseAlertCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionpurchasealertGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionpurchasealertGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionPurchaseAlertApi - factory interface
 * @export
 */
export const ReceptionPurchaseAlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionPurchaseAlertApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseAlert(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseAlert(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseAlertCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseAlertCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchasealertGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionpurchasealertGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionPurchaseAlertApi - object-oriented interface
 * @export
 * @class ReceptionPurchaseAlertApi
 * @extends {BaseAPI}
 */
export class ReceptionPurchaseAlertApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseAlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseAlert(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseAlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseAlert(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseAlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseAlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseAlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseAlertCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseAlertApi
     */
    public apiTenantIdReceptionpurchasealertGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseAlertApiFp(this.configuration).apiTenantIdReceptionpurchasealertGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionPurchaseP3AlertApi - axios parameter creator
 * @export
 */
export const ReceptionPurchaseP3AlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3Alert: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3Alert', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3Alert', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchasep3alert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3AlertCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3AlertCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3AlertCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchasep3alert/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchasep3alertGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionpurchasep3alertGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionpurchasep3alert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionPurchaseP3AlertApi - functional programming interface
 * @export
 */
export const ReceptionPurchaseP3AlertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionPurchaseP3AlertApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseP3Alert(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseP3Alert(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseP3AlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseP3AlertCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionpurchasep3alertGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionpurchasep3alertGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionPurchaseP3AlertApi - factory interface
 * @export
 */
export const ReceptionPurchaseP3AlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionPurchaseP3AlertApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3Alert(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseP3Alert(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3AlertCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseP3AlertCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchasep3alertGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionpurchasep3alertGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionPurchaseP3AlertApi - object-oriented interface
 * @export
 * @class ReceptionPurchaseP3AlertApi
 * @extends {BaseAPI}
 */
export class ReceptionPurchaseP3AlertApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseP3AlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseP3Alert(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseP3AlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseP3Alert(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseP3AlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseP3AlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseP3AlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseP3AlertCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseP3AlertApi
     */
    public apiTenantIdReceptionpurchasep3alertGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseP3AlertApiFp(this.configuration).apiTenantIdReceptionpurchasep3alertGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionPurchaseP3ProcessingApi - axios parameter creator
 * @export
 */
export const ReceptionPurchaseP3ProcessingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3Processing: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3Processing', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3Processing', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchasep3processing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3ProcessingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3ProcessingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3ProcessingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchasep3processing/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchasep3processingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionpurchasep3processingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionpurchasep3processing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionPurchaseP3ProcessingApi - functional programming interface
 * @export
 */
export const ReceptionPurchaseP3ProcessingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionPurchaseP3ProcessingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseP3Processing(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseP3Processing(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseP3ProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseP3ProcessingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionpurchasep3processingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionpurchasep3processingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionPurchaseP3ProcessingApi - factory interface
 * @export
 */
export const ReceptionPurchaseP3ProcessingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionPurchaseP3ProcessingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3Processing(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseP3Processing(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3ProcessingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseP3ProcessingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchasep3processingGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionpurchasep3processingGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionPurchaseP3ProcessingApi - object-oriented interface
 * @export
 * @class ReceptionPurchaseP3ProcessingApi
 * @extends {BaseAPI}
 */
export class ReceptionPurchaseP3ProcessingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseP3ProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseP3Processing(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseP3ProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseP3Processing(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseP3ProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseP3ProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseP3ProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseP3ProcessingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseP3ProcessingApi
     */
    public apiTenantIdReceptionpurchasep3processingGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseP3ProcessingApiFp(this.configuration).apiTenantIdReceptionpurchasep3processingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionPurchaseP3RevokedApi - axios parameter creator
 * @export
 */
export const ReceptionPurchaseP3RevokedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3Revoked: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3Revoked', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3Revoked', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchasep3revoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3RevokedCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3RevokedCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseP3RevokedCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchasep3revoked/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchasep3revokedGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionpurchasep3revokedGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionpurchasep3revoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionPurchaseP3RevokedApi - functional programming interface
 * @export
 */
export const ReceptionPurchaseP3RevokedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionPurchaseP3RevokedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseP3Revoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseP3Revoked(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseP3RevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseP3RevokedCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionpurchasep3revokedGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionpurchasep3revokedGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionPurchaseP3RevokedApi - factory interface
 * @export
 */
export const ReceptionPurchaseP3RevokedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionPurchaseP3RevokedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3Revoked(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseP3Revoked(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseP3RevokedCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseP3RevokedCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchasep3revokedGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionpurchasep3revokedGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionPurchaseP3RevokedApi - object-oriented interface
 * @export
 * @class ReceptionPurchaseP3RevokedApi
 * @extends {BaseAPI}
 */
export class ReceptionPurchaseP3RevokedApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseP3RevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseP3Revoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseP3RevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseP3Revoked(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseP3RevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseP3RevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseP3RevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseP3RevokedCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseP3RevokedApi
     */
    public apiTenantIdReceptionpurchasep3revokedGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseP3RevokedApiFp(this.configuration).apiTenantIdReceptionpurchasep3revokedGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionPurchaseProcessingApi - axios parameter creator
 * @export
 */
export const ReceptionPurchaseProcessingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseProcessing: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseProcessing', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseProcessing', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchaseprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseProcessingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseProcessingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseProcessingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchaseprocessing/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchaseprocessingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionpurchaseprocessingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionpurchaseprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionPurchaseProcessingApi - functional programming interface
 * @export
 */
export const ReceptionPurchaseProcessingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionPurchaseProcessingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseProcessing(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseProcessingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionpurchaseprocessingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionpurchaseprocessingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionPurchaseProcessingApi - factory interface
 * @export
 */
export const ReceptionPurchaseProcessingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionPurchaseProcessingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseProcessing(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseProcessing(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseProcessingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseProcessingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchaseprocessingGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionpurchaseprocessingGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionPurchaseProcessingApi - object-oriented interface
 * @export
 * @class ReceptionPurchaseProcessingApi
 * @extends {BaseAPI}
 */
export class ReceptionPurchaseProcessingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseProcessing(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseProcessingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseProcessingApi
     */
    public apiTenantIdReceptionpurchaseprocessingGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseProcessingApiFp(this.configuration).apiTenantIdReceptionpurchaseprocessingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionPurchaseRevokedApi - axios parameter creator
 * @export
 */
export const ReceptionPurchaseRevokedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseRevoked: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseRevoked', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseRevoked', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchaserevoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseRevokedCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseRevokedCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionPurchaseRevokedCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionpurchaserevoked/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchaserevokedGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionpurchaserevokedGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionpurchaserevoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionPurchaseRevokedApi - functional programming interface
 * @export
 */
export const ReceptionPurchaseRevokedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionPurchaseRevokedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseRevoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseRevoked(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionPurchaseRevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionPurchaseRevokedCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionpurchaserevokedGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionpurchaserevokedGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionPurchaseRevokedApi - factory interface
 * @export
 */
export const ReceptionPurchaseRevokedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionPurchaseRevokedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseRevoked(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseRevoked(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionPurchaseRevokedCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionPurchaseRevokedCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionpurchaserevokedGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionpurchaserevokedGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionPurchaseRevokedApi - object-oriented interface
 * @export
 * @class ReceptionPurchaseRevokedApi
 * @extends {BaseAPI}
 */
export class ReceptionPurchaseRevokedApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseRevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseRevoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseRevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseRevoked(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseRevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionPurchaseRevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseRevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionPurchaseRevokedCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionPurchaseRevokedApi
     */
    public apiTenantIdReceptionpurchaserevokedGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionPurchaseRevokedApiFp(this.configuration).apiTenantIdReceptionpurchaserevokedGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionRepresentationAlertApi - axios parameter creator
 * @export
 */
export const ReceptionRepresentationAlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationAlert: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationAlert', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationAlert', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionrepresentationalert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationAlertCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationAlertCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationAlertCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionrepresentationalert/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionrepresentationalertGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionrepresentationalertGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionrepresentationalert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionRepresentationAlertApi - functional programming interface
 * @export
 */
export const ReceptionRepresentationAlertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionRepresentationAlertApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionRepresentationAlert(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionRepresentationAlert(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionRepresentationAlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionRepresentationAlertCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionrepresentationalertGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionrepresentationalertGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionRepresentationAlertApi - factory interface
 * @export
 */
export const ReceptionRepresentationAlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionRepresentationAlertApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationAlert(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionRepresentationAlert(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationAlertCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionRepresentationAlertCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionrepresentationalertGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionrepresentationalertGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionRepresentationAlertApi - object-oriented interface
 * @export
 * @class ReceptionRepresentationAlertApi
 * @extends {BaseAPI}
 */
export class ReceptionRepresentationAlertApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionRepresentationAlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionRepresentationAlert(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionRepresentationAlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionRepresentationAlert(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionRepresentationAlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionRepresentationAlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionRepresentationAlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionRepresentationAlertCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionRepresentationAlertApi
     */
    public apiTenantIdReceptionrepresentationalertGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionRepresentationAlertApiFp(this.configuration).apiTenantIdReceptionrepresentationalertGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionRepresentationProcessingApi - axios parameter creator
 * @export
 */
export const ReceptionRepresentationProcessingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationProcessing: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationProcessing', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationProcessing', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionrepresentationprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationProcessingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationProcessingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationProcessingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionrepresentationprocessing/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionrepresentationprocessingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionrepresentationprocessingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionrepresentationprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionRepresentationProcessingApi - functional programming interface
 * @export
 */
export const ReceptionRepresentationProcessingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionRepresentationProcessingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionRepresentationProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionRepresentationProcessing(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionRepresentationProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionRepresentationProcessingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionrepresentationprocessingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionrepresentationprocessingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionRepresentationProcessingApi - factory interface
 * @export
 */
export const ReceptionRepresentationProcessingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionRepresentationProcessingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationProcessing(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionRepresentationProcessing(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationProcessingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionRepresentationProcessingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionrepresentationprocessingGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionrepresentationprocessingGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionRepresentationProcessingApi - object-oriented interface
 * @export
 * @class ReceptionRepresentationProcessingApi
 * @extends {BaseAPI}
 */
export class ReceptionRepresentationProcessingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionRepresentationProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionRepresentationProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionRepresentationProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionRepresentationProcessing(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionRepresentationProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionRepresentationProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionRepresentationProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionRepresentationProcessingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionRepresentationProcessingApi
     */
    public apiTenantIdReceptionrepresentationprocessingGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionRepresentationProcessingApiFp(this.configuration).apiTenantIdReceptionrepresentationprocessingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionRepresentationRevokedApi - axios parameter creator
 * @export
 */
export const ReceptionRepresentationRevokedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationRevoked: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationRevoked', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationRevoked', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionrepresentationrevoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationRevokedCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationRevokedCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionRepresentationRevokedCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionrepresentationrevoked/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionrepresentationrevokedGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionrepresentationrevokedGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionrepresentationrevoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionRepresentationRevokedApi - functional programming interface
 * @export
 */
export const ReceptionRepresentationRevokedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionRepresentationRevokedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionRepresentationRevoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionRepresentationRevoked(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionRepresentationRevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionRepresentationRevokedCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionrepresentationrevokedGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionrepresentationrevokedGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionRepresentationRevokedApi - factory interface
 * @export
 */
export const ReceptionRepresentationRevokedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionRepresentationRevokedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationRevoked(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionRepresentationRevoked(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionRepresentationRevokedCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionRepresentationRevokedCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionrepresentationrevokedGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionrepresentationrevokedGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionRepresentationRevokedApi - object-oriented interface
 * @export
 * @class ReceptionRepresentationRevokedApi
 * @extends {BaseAPI}
 */
export class ReceptionRepresentationRevokedApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionRepresentationRevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionRepresentationRevoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionRepresentationRevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionRepresentationRevoked(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionRepresentationRevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionRepresentationRevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionRepresentationRevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionRepresentationRevokedCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionRepresentationRevokedApi
     */
    public apiTenantIdReceptionrepresentationrevokedGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionRepresentationRevokedApiFp(this.configuration).apiTenantIdReceptionrepresentationrevokedGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionSalesAlertApi - axios parameter creator
 * @export
 */
export const ReceptionSalesAlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesAlert: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesAlert', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesAlert', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionsalesalert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesAlertCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesAlertCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesAlertCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionsalesalert/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionsalesalertGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionsalesalertGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionsalesalert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionSalesAlertApi - functional programming interface
 * @export
 */
export const ReceptionSalesAlertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionSalesAlertApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSalesAlert(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSalesAlert(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSalesAlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSalesAlertCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionsalesalertGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionsalesalertGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionSalesAlertApi - factory interface
 * @export
 */
export const ReceptionSalesAlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionSalesAlertApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesAlert(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSalesAlert(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesAlertCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSalesAlertCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionsalesalertGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionsalesalertGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionSalesAlertApi - object-oriented interface
 * @export
 * @class ReceptionSalesAlertApi
 * @extends {BaseAPI}
 */
export class ReceptionSalesAlertApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSalesAlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSalesAlert(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSalesAlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSalesAlert(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSalesAlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSalesAlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSalesAlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSalesAlertCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSalesAlertApi
     */
    public apiTenantIdReceptionsalesalertGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionSalesAlertApiFp(this.configuration).apiTenantIdReceptionsalesalertGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionSalesProcessingApi - axios parameter creator
 * @export
 */
export const ReceptionSalesProcessingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesProcessing: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesProcessing', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesProcessing', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionsalesprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesProcessingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesProcessingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesProcessingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionsalesprocessing/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionsalesprocessingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionsalesprocessingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionsalesprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionSalesProcessingApi - functional programming interface
 * @export
 */
export const ReceptionSalesProcessingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionSalesProcessingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSalesProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSalesProcessing(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSalesProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSalesProcessingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionsalesprocessingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionsalesprocessingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionSalesProcessingApi - factory interface
 * @export
 */
export const ReceptionSalesProcessingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionSalesProcessingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesProcessing(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSalesProcessing(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesProcessingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSalesProcessingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionsalesprocessingGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionsalesprocessingGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionSalesProcessingApi - object-oriented interface
 * @export
 * @class ReceptionSalesProcessingApi
 * @extends {BaseAPI}
 */
export class ReceptionSalesProcessingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSalesProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSalesProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSalesProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSalesProcessing(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSalesProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSalesProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSalesProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSalesProcessingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSalesProcessingApi
     */
    public apiTenantIdReceptionsalesprocessingGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionSalesProcessingApiFp(this.configuration).apiTenantIdReceptionsalesprocessingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionSalesRevokedApi - axios parameter creator
 * @export
 */
export const ReceptionSalesRevokedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesRevoked: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesRevoked', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesRevoked', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionsalesrevoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesRevokedCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesRevokedCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSalesRevokedCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionsalesrevoked/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionsalesrevokedGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionsalesrevokedGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionsalesrevoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionSalesRevokedApi - functional programming interface
 * @export
 */
export const ReceptionSalesRevokedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionSalesRevokedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSalesRevoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSalesRevoked(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSalesRevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSalesRevokedCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionsalesrevokedGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionsalesrevokedGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionSalesRevokedApi - factory interface
 * @export
 */
export const ReceptionSalesRevokedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionSalesRevokedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesRevoked(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSalesRevoked(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSalesRevokedCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSalesRevokedCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionsalesrevokedGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionsalesrevokedGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionSalesRevokedApi - object-oriented interface
 * @export
 * @class ReceptionSalesRevokedApi
 * @extends {BaseAPI}
 */
export class ReceptionSalesRevokedApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSalesRevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSalesRevoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSalesRevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSalesRevoked(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSalesRevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSalesRevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSalesRevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSalesRevokedCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSalesRevokedApi
     */
    public apiTenantIdReceptionsalesrevokedGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionSalesRevokedApiFp(this.configuration).apiTenantIdReceptionsalesrevokedGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionSelfInvoicingAlertApi - axios parameter creator
 * @export
 */
export const ReceptionSelfInvoicingAlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlert: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlert', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlert', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionselfinvoicingalert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlertCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlertCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlertCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionselfinvoicingalert/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionselfinvoicingalertGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionselfinvoicingalertGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionselfinvoicingalert`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionSelfInvoicingAlertApi - functional programming interface
 * @export
 */
export const ReceptionSelfInvoicingAlertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionSelfInvoicingAlertApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlert(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlert(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlertCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionselfinvoicingalertGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionselfinvoicingalertGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionSelfInvoicingAlertApi - factory interface
 * @export
 */
export const ReceptionSelfInvoicingAlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionSelfInvoicingAlertApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlert(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlert(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlertCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlertCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionselfinvoicingalertGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionselfinvoicingalertGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionSelfInvoicingAlertApi - object-oriented interface
 * @export
 * @class ReceptionSelfInvoicingAlertApi
 * @extends {BaseAPI}
 */
export class ReceptionSelfInvoicingAlertApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSelfInvoicingAlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlert(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSelfInvoicingAlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlert(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSelfInvoicingAlertApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlertCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSelfInvoicingAlertApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSelfInvoicingAlertCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSelfInvoicingAlertApi
     */
    public apiTenantIdReceptionselfinvoicingalertGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionSelfInvoicingAlertApiFp(this.configuration).apiTenantIdReceptionselfinvoicingalertGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionSelfInvoicingProcessingApi - axios parameter creator
 * @export
 */
export const ReceptionSelfInvoicingProcessingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessing: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessing', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessing', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionselfinvoicingprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessingCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessingCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessingCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionselfinvoicingprocessing/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionselfinvoicingprocessingGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionselfinvoicingprocessingGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionselfinvoicingprocessing`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionSelfInvoicingProcessingApi - functional programming interface
 * @export
 */
export const ReceptionSelfInvoicingProcessingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionSelfInvoicingProcessingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessing(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessingCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionselfinvoicingprocessingGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionselfinvoicingprocessingGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionSelfInvoicingProcessingApi - factory interface
 * @export
 */
export const ReceptionSelfInvoicingProcessingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionSelfInvoicingProcessingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessing(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessing(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessingCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessingCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionselfinvoicingprocessingGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionselfinvoicingprocessingGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionSelfInvoicingProcessingApi - object-oriented interface
 * @export
 * @class ReceptionSelfInvoicingProcessingApi
 * @extends {BaseAPI}
 */
export class ReceptionSelfInvoicingProcessingApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSelfInvoicingProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessing(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSelfInvoicingProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessing(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSelfInvoicingProcessingApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessingCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSelfInvoicingProcessingApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSelfInvoicingProcessingCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSelfInvoicingProcessingApi
     */
    public apiTenantIdReceptionselfinvoicingprocessingGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionSelfInvoicingProcessingApiFp(this.configuration).apiTenantIdReceptionselfinvoicingprocessingGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReceptionSelfInvoicingRevokedApi - axios parameter creator
 * @export
 */
export const ReceptionSelfInvoicingRevokedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevoked: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevoked', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevoked', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionselfinvoicingrevoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevokedCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevokedCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevokedCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/receptionselfinvoicingrevoked/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionselfinvoicingrevokedGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReceptionselfinvoicingrevokedGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/receptionselfinvoicingrevoked`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceptionSelfInvoicingRevokedApi - functional programming interface
 * @export
 */
export const ReceptionSelfInvoicingRevokedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceptionSelfInvoicingRevokedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevoked(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevokedCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReceptionselfinvoicingrevokedGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceptionProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReceptionselfinvoicingrevokedGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceptionSelfInvoicingRevokedApi - factory interface
 * @export
 */
export const ReceptionSelfInvoicingRevokedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceptionSelfInvoicingRevokedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevoked(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevoked(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevokedCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevokedCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReceptionselfinvoicingrevokedGet(tenantId: string, options?: any): AxiosPromise<Array<ReceptionProcessRM>> {
            return localVarFp.apiTenantIdReceptionselfinvoicingrevokedGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceptionSelfInvoicingRevokedApi - object-oriented interface
 * @export
 * @class ReceptionSelfInvoicingRevokedApi
 * @extends {BaseAPI}
 */
export class ReceptionSelfInvoicingRevokedApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSelfInvoicingRevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevoked(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSelfInvoicingRevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevoked(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSelfInvoicingRevokedApi
     */
    public apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevokedCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return ReceptionSelfInvoicingRevokedApiFp(this.configuration).apiTenantIdOrganizationIdOdataReceptionSelfInvoicingRevokedCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceptionSelfInvoicingRevokedApi
     */
    public apiTenantIdReceptionselfinvoicingrevokedGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReceptionSelfInvoicingRevokedApiFp(this.configuration).apiTenantIdReceptionselfinvoicingrevokedGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RepairDraftDataApi - axios parameter creator
 * @export
 */
export const RepairDraftDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Do jednorazowego odpalenia. Ma na celu wpisanie brakujących typów faktury w draftach
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRepairdraftdataRepairGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdRepairdraftdataRepairGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/repairdraftdata/repair`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RepairDraftDataApi - functional programming interface
 * @export
 */
export const RepairDraftDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RepairDraftDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Do jednorazowego odpalenia. Ma na celu wpisanie brakujących typów faktury w draftach
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdRepairdraftdataRepairGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateProcessesFromZipCommandResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdRepairdraftdataRepairGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RepairDraftDataApi - factory interface
 * @export
 */
export const RepairDraftDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RepairDraftDataApiFp(configuration)
    return {
        /**
         * 
         * @summary Do jednorazowego odpalenia. Ma na celu wpisanie brakujących typów faktury w draftach
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRepairdraftdataRepairGet(tenantId: string, options?: any): AxiosPromise<CreateProcessesFromZipCommandResult> {
            return localVarFp.apiTenantIdRepairdraftdataRepairGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RepairDraftDataApi - object-oriented interface
 * @export
 * @class RepairDraftDataApi
 * @extends {BaseAPI}
 */
export class RepairDraftDataApi extends BaseAPI {
    /**
     * 
     * @summary Do jednorazowego odpalenia. Ma na celu wpisanie brakujących typów faktury w draftach
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RepairDraftDataApi
     */
    public apiTenantIdRepairdraftdataRepairGet(tenantId: string, options?: AxiosRequestConfig) {
        return RepairDraftDataApiFp(this.configuration).apiTenantIdRepairdraftdataRepairGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SalesRepositoryApi - axios parameter creator
 * @export
 */
export const SalesRepositoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSalesRepository: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSalesRepository', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSalesRepository', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/salesrepository`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSalesRepositoryCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSalesRepositoryCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSalesRepositoryCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/salesrepository/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSalesrepositoryGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdSalesrepositoryGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/salesrepository`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesRepositoryApi - functional programming interface
 * @export
 */
export const SalesRepositoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesRepositoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataSalesRepository(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchArchiveProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataSalesRepository(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataSalesRepositoryCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchArchiveProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataSalesRepositoryCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdSalesrepositoryGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DispatchArchiveProcessRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdSalesrepositoryGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SalesRepositoryApi - factory interface
 * @export
 */
export const SalesRepositoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesRepositoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSalesRepository(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchArchiveProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataSalesRepository(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSalesRepositoryCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<DispatchArchiveProcessRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataSalesRepositoryCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSalesrepositoryGet(tenantId: string, options?: any): AxiosPromise<Array<DispatchArchiveProcessRM>> {
            return localVarFp.apiTenantIdSalesrepositoryGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalesRepositoryApi - object-oriented interface
 * @export
 * @class SalesRepositoryApi
 * @extends {BaseAPI}
 */
export class SalesRepositoryApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesRepositoryApi
     */
    public apiTenantIdOrganizationIdOdataSalesRepository(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return SalesRepositoryApiFp(this.configuration).apiTenantIdOrganizationIdOdataSalesRepository(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesRepositoryApi
     */
    public apiTenantIdOrganizationIdOdataSalesRepositoryCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return SalesRepositoryApiFp(this.configuration).apiTenantIdOrganizationIdOdataSalesRepositoryCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesRepositoryApi
     */
    public apiTenantIdSalesrepositoryGet(tenantId: string, options?: AxiosRequestConfig) {
        return SalesRepositoryApiFp(this.configuration).apiTenantIdSalesrepositoryGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionsApi - axios parameter creator
 * @export
 */
export const SessionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSessions: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSessions', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSessions', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/sessions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSessionsCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSessionsCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSessionsCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/sessions/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSessionsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdSessionsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/sessions`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionsApi - functional programming interface
 * @export
 */
export const SessionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataSessions(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataSessions(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataSessionsCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataSessionsCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdSessionsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdSessionsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionsApi - factory interface
 * @export
 */
export const SessionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSessions(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<SessionRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataSessions(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSessionsCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<SessionRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataSessionsCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSessionsGet(tenantId: string, options?: any): AxiosPromise<Array<SessionRM>> {
            return localVarFp.apiTenantIdSessionsGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionsApi - object-oriented interface
 * @export
 * @class SessionsApi
 * @extends {BaseAPI}
 */
export class SessionsApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public apiTenantIdOrganizationIdOdataSessions(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return SessionsApiFp(this.configuration).apiTenantIdOrganizationIdOdataSessions(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public apiTenantIdOrganizationIdOdataSessionsCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return SessionsApiFp(this.configuration).apiTenantIdOrganizationIdOdataSessionsCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionsApi
     */
    public apiTenantIdSessionsGet(tenantId: string, options?: AxiosRequestConfig) {
        return SessionsApiFp(this.configuration).apiTenantIdSessionsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Edit tags for invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {PostTagsRequest} [postTagsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdTagsPost: async (tenantId: string, organizationId: string, postTagsRequest?: PostTagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdTagsPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdTagsPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/tags`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Edit tags for invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {PostTagsRequest} [postTagsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdTagsPost(tenantId: string, organizationId: string, postTagsRequest?: PostTagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdTagsPost(tenantId, organizationId, postTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagsApiFp(configuration)
    return {
        /**
         * 
         * @summary Edit tags for invoice
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {PostTagsRequest} [postTagsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdTagsPost(tenantId: string, organizationId: string, postTagsRequest?: PostTagsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdTagsPost(tenantId, organizationId, postTagsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
    /**
     * 
     * @summary Edit tags for invoice
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {PostTagsRequest} [postTagsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public apiTenantIdOrganizationIdTagsPost(tenantId: string, organizationId: string, postTagsRequest?: PostTagsRequest, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).apiTenantIdOrganizationIdTagsPost(tenantId, organizationId, postTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VatWhitelistModuleApi - axios parameter creator
 * @export
 */
export const VatWhitelistModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if bank account is on vat whitelist
         * @param {string} nip 
         * @param {string} bankAccounts 
         * @param {string} [checkDate] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVatwhitelistmoduleNipBankaccountsBankAccountsPost: async (nip: string, bankAccounts: string, checkDate?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nip' is not null or undefined
            assertParamExists('apiVatwhitelistmoduleNipBankaccountsBankAccountsPost', 'nip', nip)
            // verify required parameter 'bankAccounts' is not null or undefined
            assertParamExists('apiVatwhitelistmoduleNipBankaccountsBankAccountsPost', 'bankAccounts', bankAccounts)
            const localVarPath = `/api/vatwhitelistmodule/{nip}/bankaccounts/{bankAccounts}`
                .replace(`{${"nip"}}`, encodeURIComponent(String(nip)))
                .replace(`{${"bankAccounts"}}`, encodeURIComponent(String(bankAccounts)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (checkDate !== undefined) {
                localVarQueryParameter['checkDate'] = (checkDate as any instanceof Date) ?
                    (checkDate as any).toISOString().substr(0,10) :
                    checkDate;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if nip is on vat whitelist
         * @param {string} nips 
         * @param {string} [checkDate] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVatwhitelistmoduleNipsPost: async (nips: string, checkDate?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nips' is not null or undefined
            assertParamExists('apiVatwhitelistmoduleNipsPost', 'nips', nips)
            const localVarPath = `/api/vatwhitelistmodule/{nips}`
                .replace(`{${"nips"}}`, encodeURIComponent(String(nips)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (checkDate !== undefined) {
                localVarQueryParameter['checkDate'] = (checkDate as any instanceof Date) ?
                    (checkDate as any).toISOString().substr(0,10) :
                    checkDate;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VatWhitelistModuleApi - functional programming interface
 * @export
 */
export const VatWhitelistModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VatWhitelistModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if bank account is on vat whitelist
         * @param {string} nip 
         * @param {string} bankAccounts 
         * @param {string} [checkDate] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVatwhitelistmoduleNipBankaccountsBankAccountsPost(nip: string, bankAccounts: string, checkDate?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVatwhitelistmoduleNipBankaccountsBankAccountsPost(nip, bankAccounts, checkDate, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if nip is on vat whitelist
         * @param {string} nips 
         * @param {string} [checkDate] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVatwhitelistmoduleNipsPost(nips: string, checkDate?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVatwhitelistmoduleNipsPost(nips, checkDate, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VatWhitelistModuleApi - factory interface
 * @export
 */
export const VatWhitelistModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VatWhitelistModuleApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if bank account is on vat whitelist
         * @param {string} nip 
         * @param {string} bankAccounts 
         * @param {string} [checkDate] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVatwhitelistmoduleNipBankaccountsBankAccountsPost(nip: string, bankAccounts: string, checkDate?: string, id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVatwhitelistmoduleNipBankaccountsBankAccountsPost(nip, bankAccounts, checkDate, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if nip is on vat whitelist
         * @param {string} nips 
         * @param {string} [checkDate] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVatwhitelistmoduleNipsPost(nips: string, checkDate?: string, id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVatwhitelistmoduleNipsPost(nips, checkDate, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VatWhitelistModuleApi - object-oriented interface
 * @export
 * @class VatWhitelistModuleApi
 * @extends {BaseAPI}
 */
export class VatWhitelistModuleApi extends BaseAPI {
    /**
     * 
     * @summary Check if bank account is on vat whitelist
     * @param {string} nip 
     * @param {string} bankAccounts 
     * @param {string} [checkDate] 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VatWhitelistModuleApi
     */
    public apiVatwhitelistmoduleNipBankaccountsBankAccountsPost(nip: string, bankAccounts: string, checkDate?: string, id?: string, options?: AxiosRequestConfig) {
        return VatWhitelistModuleApiFp(this.configuration).apiVatwhitelistmoduleNipBankaccountsBankAccountsPost(nip, bankAccounts, checkDate, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if nip is on vat whitelist
     * @param {string} nips 
     * @param {string} [checkDate] 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VatWhitelistModuleApi
     */
    public apiVatwhitelistmoduleNipsPost(nips: string, checkDate?: string, id?: string, options?: AxiosRequestConfig) {
        return VatWhitelistModuleApiFp(this.configuration).apiVatwhitelistmoduleNipsPost(nips, checkDate, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ViesListApi - axios parameter creator
 * @export
 */
export const ViesListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {string} nipsToCheck 
         * @param {string} [nip] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVieslistCheckNipsStatusesNipsToCheckGet: async (nipsToCheck: string, nip?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nipsToCheck' is not null or undefined
            assertParamExists('apiVieslistCheckNipsStatusesNipsToCheckGet', 'nipsToCheck', nipsToCheck)
            const localVarPath = `/api/vieslist/check-nips-statuses/{nipsToCheck}`
                .replace(`{${"nipsToCheck"}}`, encodeURIComponent(String(nipsToCheck)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (nip !== undefined) {
                localVarQueryParameter['nip'] = nip;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViesListApi - functional programming interface
 * @export
 */
export const ViesListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViesListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} nipsToCheck 
         * @param {string} [nip] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVieslistCheckNipsStatusesNipsToCheckGet(nipsToCheck: string, nip?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVieslistCheckNipsStatusesNipsToCheckGet(nipsToCheck, nip, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ViesListApi - factory interface
 * @export
 */
export const ViesListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViesListApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {string} nipsToCheck 
         * @param {string} [nip] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVieslistCheckNipsStatusesNipsToCheckGet(nipsToCheck: string, nip?: string, id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVieslistCheckNipsStatusesNipsToCheckGet(nipsToCheck, nip, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ViesListApi - object-oriented interface
 * @export
 * @class ViesListApi
 * @extends {BaseAPI}
 */
export class ViesListApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {string} nipsToCheck 
     * @param {string} [nip] 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViesListApi
     */
    public apiVieslistCheckNipsStatusesNipsToCheckGet(nipsToCheck: string, nip?: string, id?: string, options?: AxiosRequestConfig) {
        return ViesListApiFp(this.configuration).apiVieslistCheckNipsStatusesNipsToCheckGet(nipsToCheck, nip, id, options).then((request) => request(this.axios, this.basePath));
    }
}


