/* tslint:disable */
/* eslint-disable */
/**
 * KSeF Api
 * KSeF application
 *
 * The version of the OpenAPI document: v1.0
 * Contact: info@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'id'?: string;
    /**
     * 
     * @type {User}
     * @memberof AccessToken
     */
    'user'?: User;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'expirationDateTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccessToken
     */
    'revoked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof AccessToken
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'modificationDateUtc': string;
}
/**
 * 
 * @export
 * @interface AccountingSystem
 */
export interface AccountingSystem {
    /**
     * 
     * @type {string}
     * @memberof AccountingSystem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingSystem
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<OrganizationAccountingSystem>}
     * @memberof AccountingSystem
     */
    'organizationAccountingSystems'?: Array<OrganizationAccountingSystem> | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingSystem
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof AccountingSystem
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof AccountingSystem
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof AccountingSystem
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof AccountingSystem
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof AccountingSystem
     */
    'modificationDateUtc': string;
}
/**
 * 
 * @export
 * @interface AccountingSystemRM
 */
export interface AccountingSystemRM {
    /**
     * 
     * @type {string}
     * @memberof AccountingSystemRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountingSystemRM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingSystemRM
     */
    'organizations'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountingSystemRM
     */
    'organizationIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AccountingSystemRequest
 */
export interface AccountingSystemRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountingSystemRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountingSystemRequest
     */
    'organizationIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AddExitPathRequest
 */
export interface AddExitPathRequest {
    /**
     * 
     * @type {string}
     * @memberof AddExitPathRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddExitPathRequest
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddExitPathRequest
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddExitPathRequest
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {{ [key: string]: Authentication; }}
     * @memberof AddExitPathRequest
     */
    'endpoints'?: { [key: string]: Authentication; } | null;
    /**
     * 
     * @type {number}
     * @memberof AddExitPathRequest
     */
    'timeout'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddExitPathRequest
     */
    'retryCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddExitPathRequest
     */
    'detailLogsEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddExitPathRequest
     */
    'logAuthData'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AddExitPathRequest
     */
    'retryInterval'?: number;
    /**
     * 
     * @type {Array<StringStringKeyValuePair>}
     * @memberof AddExitPathRequest
     */
    'headers'?: Array<StringStringKeyValuePair> | null;
    /**
     * 
     * @type {Array<XfxNotificationEvent>}
     * @memberof AddExitPathRequest
     */
    'events'?: Array<XfxNotificationEvent> | null;
}
/**
 * 
 * @export
 * @interface AddOrganizationSyncSettingDto
 */
export interface AddOrganizationSyncSettingDto {
    /**
     * 
     * @type {boolean}
     * @memberof AddOrganizationSyncSettingDto
     */
    'useAdditionalEncryption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrganizationSyncSettingDto
     */
    'asyncModeEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationSyncSettingDto
     */
    'organizationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationSyncSettingDto
     */
    'lastSyncDateAsSubject1Utc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationSyncSettingDto
     */
    'lastSyncDateAsSubject2Utc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationSyncSettingDto
     */
    'lastSyncDateAsSubject3Utc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrganizationSyncSettingDto
     */
    'syncRequested'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrganizationSyncSettingDto
     */
    'receptionSyncOnAsSubject1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrganizationSyncSettingDto
     */
    'receptionSyncOnAsSubject2'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrganizationSyncSettingDto
     */
    'receptionSyncOnAsSubject3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrganizationSyncSettingDto
     */
    'useWriteSession'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddOrganizationSyncSettingDto
     */
    'initialReceptionCheckupDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrganizationSyncSettingDto
     */
    'largeWalkingWindowEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrganizationSyncSettingDto
     */
    'shippingSynchronizationEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface AddReportDto
 */
export interface AddReportDto {
    /**
     * 
     * @type {string}
     * @memberof AddReportDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {ReportType}
     * @memberof AddReportDto
     */
    'type'?: ReportType;
    /**
     * 
     * @type {DataSource}
     * @memberof AddReportDto
     */
    'dataSource'?: DataSource;
    /**
     * 
     * @type {OutputFileType}
     * @memberof AddReportDto
     */
    'outputFileType'?: OutputFileType;
    /**
     * 
     * @type {ReportPeriod}
     * @memberof AddReportDto
     */
    'period'?: ReportPeriod;
    /**
     * 
     * @type {string}
     * @memberof AddReportDto
     */
    'initDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddReportDto
     */
    'filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddReportDto
     */
    'odataFilter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddReportDto
     */
    'columns'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddReportDto
     */
    'organizationsIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddReportDto
     */
    'usersIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddReportDto
     */
    'additionalEmails'?: Array<string> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AddResult = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type AddResult = typeof AddResult[keyof typeof AddResult];


/**
 * 
 * @export
 * @interface AddUserRequest
 */
export interface AddUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'secret'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddUserRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof AddUserRequest
     */
    'type': UserType;
    /**
     * 
     * @type {boolean}
     * @memberof AddUserRequest
     */
    'admin'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddUserRequest
     */
    'roleIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AddressFieldOnInvoice = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type AddressFieldOnInvoice = typeof AddressFieldOnInvoice[keyof typeof AddressFieldOnInvoice];


/**
 * 
 * @export
 * @enum {string}
 */

export const AuthType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type AuthType = typeof AuthType[keyof typeof AuthType];


/**
 * 
 * @export
 * @interface Authentication
 */
export interface Authentication {
    /**
     * 
     * @type {AuthType}
     * @memberof Authentication
     */
    'type'?: AuthType;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    'value'?: string | null;
}


/**
 * 
 * @export
 * @interface AutoRefresh
 */
export interface AutoRefresh {
    /**
     * 
     * @type {number}
     * @memberof AutoRefresh
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AutoRefresh
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AutoRefresh
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AutoRefresh
     */
    'reloadIntervalMs'?: number | null;
}
/**
 * 
 * @export
 * @interface BasicContractorsInfoResponse
 */
export interface BasicContractorsInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof BasicContractorsInfoResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicContractorsInfoResponse
     */
    'countryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicContractorsInfoResponse
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicContractorsInfoResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicContractorsInfoResponse
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicContractorsInfoResponse
     */
    'modificationDateUtc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasicContractorsInfoResponse
     */
    'selfInvoicingContractSigned'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasicContractorsInfoResponse
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicContractorsInfoResponse
     */
    'organizationId'?: string;
}
/**
 * 
 * @export
 * @interface BasicOrganizationInfoResponse
 */
export interface BasicOrganizationInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'nip'?: string | null;
    /**
     * 
     * @type {OrganizationType}
     * @memberof BasicOrganizationInfoResponse
     */
    'type'?: OrganizationType;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'streetNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'flatNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'krs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'regon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'createdById'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'modificationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'modificatedById'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicOrganizationInfoResponse
     */
    'mapping'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BasicOrganizationInfoResponse
     */
    'isActive'?: boolean;
}


/**
 * 
 * @export
 * @interface BasicPrivilegeInfoResponse
 */
export interface BasicPrivilegeInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof BasicPrivilegeInfoResponse
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof BasicPrivilegeInfoResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicPrivilegeInfoResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicPrivilegeInfoResponse
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface BasicPrivilegeInfoResponseIEnumerableState
 */
export interface BasicPrivilegeInfoResponseIEnumerableState {
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicPrivilegeInfoResponseIEnumerableState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof BasicPrivilegeInfoResponseIEnumerableState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicPrivilegeInfoResponseIEnumerableState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof BasicPrivilegeInfoResponseIEnumerableState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasicPrivilegeInfoResponseIEnumerableState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {Array<BasicPrivilegeInfoResponse>}
     * @memberof BasicPrivilegeInfoResponseIEnumerableState
     */
    'stateObject'?: Array<BasicPrivilegeInfoResponse> | null;
}
/**
 * 
 * @export
 * @interface BasicRoleInfoResponse
 */
export interface BasicRoleInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof BasicRoleInfoResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicRoleInfoResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicRoleInfoResponse
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface BasicRoleInfoResponseIEnumerableState
 */
export interface BasicRoleInfoResponseIEnumerableState {
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicRoleInfoResponseIEnumerableState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof BasicRoleInfoResponseIEnumerableState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicRoleInfoResponseIEnumerableState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof BasicRoleInfoResponseIEnumerableState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasicRoleInfoResponseIEnumerableState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {Array<BasicRoleInfoResponse>}
     * @memberof BasicRoleInfoResponseIEnumerableState
     */
    'stateObject'?: Array<BasicRoleInfoResponse> | null;
}
/**
 * 
 * @export
 * @interface BasicRoleInfoResponseState
 */
export interface BasicRoleInfoResponseState {
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicRoleInfoResponseState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof BasicRoleInfoResponseState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicRoleInfoResponseState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof BasicRoleInfoResponseState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BasicRoleInfoResponseState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {BasicRoleInfoResponse}
     * @memberof BasicRoleInfoResponseState
     */
    'stateObject'?: BasicRoleInfoResponse;
}
/**
 * 
 * @export
 * @interface ContextIdentifier
 */
export interface ContextIdentifier {
    /**
     * 
     * @type {string}
     * @memberof ContextIdentifier
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContextIdentifier
     */
    'identifier'?: string | null;
}
/**
 * 
 * @export
 * @interface ContextName
 */
export interface ContextName {
    /**
     * 
     * @type {string}
     * @memberof ContextName
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContextName
     */
    'tradeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContextName
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface Contractor
 */
export interface Contractor {
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'clientNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'eoriNo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'selfInvoicingContractSigned'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'vatueNo'?: string | null;
    /**
     * 
     * @type {EuCountryCode}
     * @memberof Contractor
     */
    'countryPrefix'?: EuCountryCode;
    /**
     * 
     * @type {CountryCode}
     * @memberof Contractor
     */
    'countryCode'?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'idNo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'withoutTaxId'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'isSupplier'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    'isRecipient'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'organizationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof Contractor
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {Array<ContractorAddress>}
     * @memberof Contractor
     */
    'contractorAddresses'?: Array<ContractorAddress> | null;
    /**
     * 
     * @type {Organization}
     * @memberof Contractor
     */
    'organization'?: Organization;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface ContractorAddress
 */
export interface ContractorAddress {
    /**
     * 
     * @type {string}
     * @memberof ContractorAddress
     */
    'contractorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddress
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof ContractorAddress
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {Contractor}
     * @memberof ContractorAddress
     */
    'contractor'?: Contractor;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddress
     */
    'id'?: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof ContractorAddress
     */
    'countryCode'?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddress
     */
    'gln'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddress
     */
    'adresL1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddress
     */
    'adresL2'?: string | null;
    /**
     * 
     * @type {AddressFieldOnInvoice}
     * @memberof ContractorAddress
     */
    'fieldOnInvoice'?: AddressFieldOnInvoice;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddress
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddress
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddress
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddress
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface ContractorAddressModel
 */
export interface ContractorAddressModel {
    /**
     * 
     * @type {string}
     * @memberof ContractorAddressModel
     */
    'id'?: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof ContractorAddressModel
     */
    'countryCode'?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddressModel
     */
    'gln'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddressModel
     */
    'adresL1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorAddressModel
     */
    'adresL2'?: string | null;
}


/**
 * 
 * @export
 * @interface ContractorRequest
 */
export interface ContractorRequest {
    /**
     * 
     * @type {string}
     * @memberof ContractorRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorRequest
     */
    'clientNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorRequest
     */
    'eoriNo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorRequest
     */
    'selfInvoicingContractSigned'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorRequest
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorRequest
     */
    'vatueNo'?: string | null;
    /**
     * 
     * @type {EuCountryCode}
     * @memberof ContractorRequest
     */
    'countryPrefix'?: EuCountryCode;
    /**
     * 
     * @type {CountryCode}
     * @memberof ContractorRequest
     */
    'countryCode'?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof ContractorRequest
     */
    'idNo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorRequest
     */
    'withoutTaxId'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorRequest
     */
    'isSupplier'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorRequest
     */
    'isRecipient'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorRequest
     */
    'organizationId'?: string | null;
    /**
     * 
     * @type {ContractorAddressModel}
     * @memberof ContractorRequest
     */
    'address'?: ContractorAddressModel;
    /**
     * 
     * @type {ContractorAddressModel}
     * @memberof ContractorRequest
     */
    'correspondenceAddress'?: ContractorAddressModel;
}


/**
 * 
 * @export
 * @interface ContractorRequestState
 */
export interface ContractorRequestState {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractorRequestState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ContractorRequestState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractorRequestState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorRequestState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorRequestState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {ContractorRequest}
     * @memberof ContractorRequestState
     */
    'stateObject'?: ContractorRequest;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CountryCode = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28,
    NUMBER_29: 29,
    NUMBER_30: 30,
    NUMBER_31: 31,
    NUMBER_32: 32,
    NUMBER_33: 33,
    NUMBER_34: 34,
    NUMBER_35: 35,
    NUMBER_36: 36,
    NUMBER_37: 37,
    NUMBER_38: 38,
    NUMBER_39: 39,
    NUMBER_40: 40,
    NUMBER_41: 41,
    NUMBER_42: 42,
    NUMBER_43: 43,
    NUMBER_44: 44,
    NUMBER_45: 45,
    NUMBER_46: 46,
    NUMBER_47: 47,
    NUMBER_48: 48,
    NUMBER_49: 49,
    NUMBER_50: 50,
    NUMBER_51: 51,
    NUMBER_52: 52,
    NUMBER_53: 53,
    NUMBER_54: 54,
    NUMBER_55: 55,
    NUMBER_56: 56,
    NUMBER_57: 57,
    NUMBER_58: 58,
    NUMBER_59: 59,
    NUMBER_60: 60,
    NUMBER_61: 61,
    NUMBER_62: 62,
    NUMBER_63: 63,
    NUMBER_64: 64,
    NUMBER_65: 65,
    NUMBER_66: 66,
    NUMBER_67: 67,
    NUMBER_68: 68,
    NUMBER_69: 69,
    NUMBER_70: 70,
    NUMBER_71: 71,
    NUMBER_72: 72,
    NUMBER_73: 73,
    NUMBER_74: 74,
    NUMBER_75: 75,
    NUMBER_76: 76,
    NUMBER_77: 77,
    NUMBER_78: 78,
    NUMBER_79: 79,
    NUMBER_80: 80,
    NUMBER_81: 81,
    NUMBER_82: 82,
    NUMBER_83: 83,
    NUMBER_84: 84,
    NUMBER_85: 85,
    NUMBER_86: 86,
    NUMBER_87: 87,
    NUMBER_88: 88,
    NUMBER_89: 89,
    NUMBER_90: 90,
    NUMBER_91: 91,
    NUMBER_92: 92,
    NUMBER_93: 93,
    NUMBER_94: 94,
    NUMBER_95: 95,
    NUMBER_96: 96,
    NUMBER_97: 97,
    NUMBER_98: 98,
    NUMBER_99: 99,
    NUMBER_100: 100,
    NUMBER_101: 101,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_104: 104,
    NUMBER_105: 105,
    NUMBER_106: 106,
    NUMBER_107: 107,
    NUMBER_108: 108,
    NUMBER_109: 109,
    NUMBER_110: 110,
    NUMBER_111: 111,
    NUMBER_112: 112,
    NUMBER_113: 113,
    NUMBER_114: 114,
    NUMBER_115: 115,
    NUMBER_116: 116,
    NUMBER_117: 117,
    NUMBER_118: 118,
    NUMBER_119: 119,
    NUMBER_120: 120,
    NUMBER_121: 121,
    NUMBER_122: 122,
    NUMBER_123: 123,
    NUMBER_124: 124,
    NUMBER_125: 125,
    NUMBER_126: 126,
    NUMBER_127: 127,
    NUMBER_128: 128,
    NUMBER_129: 129,
    NUMBER_130: 130,
    NUMBER_131: 131,
    NUMBER_132: 132,
    NUMBER_133: 133,
    NUMBER_134: 134,
    NUMBER_135: 135,
    NUMBER_136: 136,
    NUMBER_137: 137,
    NUMBER_138: 138,
    NUMBER_139: 139,
    NUMBER_140: 140,
    NUMBER_141: 141,
    NUMBER_142: 142,
    NUMBER_143: 143,
    NUMBER_144: 144,
    NUMBER_145: 145,
    NUMBER_146: 146,
    NUMBER_147: 147,
    NUMBER_148: 148,
    NUMBER_149: 149,
    NUMBER_150: 150,
    NUMBER_151: 151,
    NUMBER_152: 152,
    NUMBER_153: 153,
    NUMBER_154: 154,
    NUMBER_155: 155,
    NUMBER_156: 156,
    NUMBER_157: 157,
    NUMBER_158: 158,
    NUMBER_159: 159,
    NUMBER_160: 160,
    NUMBER_161: 161,
    NUMBER_162: 162,
    NUMBER_163: 163,
    NUMBER_164: 164,
    NUMBER_165: 165,
    NUMBER_166: 166,
    NUMBER_167: 167,
    NUMBER_168: 168,
    NUMBER_169: 169,
    NUMBER_170: 170,
    NUMBER_171: 171,
    NUMBER_172: 172,
    NUMBER_173: 173,
    NUMBER_174: 174,
    NUMBER_175: 175,
    NUMBER_176: 176,
    NUMBER_177: 177,
    NUMBER_178: 178,
    NUMBER_179: 179,
    NUMBER_180: 180,
    NUMBER_181: 181,
    NUMBER_182: 182,
    NUMBER_183: 183,
    NUMBER_184: 184,
    NUMBER_185: 185,
    NUMBER_186: 186,
    NUMBER_187: 187,
    NUMBER_188: 188,
    NUMBER_189: 189,
    NUMBER_190: 190,
    NUMBER_191: 191,
    NUMBER_192: 192,
    NUMBER_193: 193,
    NUMBER_194: 194,
    NUMBER_195: 195,
    NUMBER_196: 196,
    NUMBER_197: 197,
    NUMBER_198: 198,
    NUMBER_199: 199,
    NUMBER_200: 200,
    NUMBER_201: 201,
    NUMBER_202: 202,
    NUMBER_203: 203,
    NUMBER_204: 204,
    NUMBER_205: 205,
    NUMBER_206: 206,
    NUMBER_207: 207,
    NUMBER_208: 208,
    NUMBER_209: 209,
    NUMBER_210: 210,
    NUMBER_211: 211,
    NUMBER_212: 212,
    NUMBER_213: 213,
    NUMBER_214: 214,
    NUMBER_215: 215,
    NUMBER_216: 216,
    NUMBER_217: 217,
    NUMBER_218: 218,
    NUMBER_219: 219,
    NUMBER_220: 220,
    NUMBER_221: 221,
    NUMBER_222: 222,
    NUMBER_223: 223,
    NUMBER_224: 224,
    NUMBER_225: 225,
    NUMBER_226: 226,
    NUMBER_227: 227,
    NUMBER_228: 228,
    NUMBER_229: 229,
    NUMBER_230: 230,
    NUMBER_231: 231,
    NUMBER_232: 232,
    NUMBER_233: 233,
    NUMBER_234: 234,
    NUMBER_235: 235,
    NUMBER_236: 236,
    NUMBER_237: 237,
    NUMBER_238: 238,
    NUMBER_239: 239,
    NUMBER_240: 240,
    NUMBER_241: 241,
    NUMBER_242: 242,
    NUMBER_243: 243,
    NUMBER_244: 244,
    NUMBER_245: 245,
    NUMBER_246: 246,
    NUMBER_247: 247,
    NUMBER_248: 248,
    NUMBER_249: 249,
    NUMBER_250: 250,
    NUMBER_251: 251,
    NUMBER_252: 252,
    NUMBER_253: 253
} as const;

export type CountryCode = typeof CountryCode[keyof typeof CountryCode];


/**
 * 
 * @export
 * @interface CreateDashboardDto
 */
export interface CreateDashboardDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDashboardDto
     */
    'xmlDefinition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDashboardDto
     */
    'dashboardName'?: string | null;
}
/**
 * 
 * @export
 * @interface CredentialIdentifier
 */
export interface CredentialIdentifier {
    /**
     * 
     * @type {string}
     * @memberof CredentialIdentifier
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialIdentifier
     */
    'identifier'?: string | null;
}
/**
 * 
 * @export
 * @interface CredentialsBaseTypeObject
 */
export interface CredentialsBaseTypeObject {
    /**
     * 
     * @type {CredentialIdentifier}
     * @memberof CredentialsBaseTypeObject
     */
    'identifier'?: CredentialIdentifier;
    /**
     * 
     * @type {string}
     * @memberof CredentialsBaseTypeObject
     */
    'type'?: string | null;
    /**
     * 
     * @type {Array<CredentialsExtendedType>}
     * @memberof CredentialsBaseTypeObject
     */
    'credentialsRoleList'?: Array<CredentialsExtendedType> | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsBaseTypeObject
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsBaseTypeObject
     */
    'lastUseTimestamp'?: string | null;
    /**
     * 
     * @type {CredentialsPlainType}
     * @memberof CredentialsBaseTypeObject
     */
    'parent'?: CredentialsPlainType;
    /**
     * 
     * @type {string}
     * @memberof CredentialsBaseTypeObject
     */
    'registrationTimestamp'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CredentialsBaseTypeObject
     */
    'status'?: number | null;
}
/**
 * 
 * @export
 * @interface CredentialsExtendedType
 */
export interface CredentialsExtendedType {
    /**
     * 
     * @type {string}
     * @memberof CredentialsExtendedType
     */
    'roleDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsExtendedType
     */
    'roleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsExtendedType
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsExtendedType
     */
    'credentialsAssignmentType'?: string | null;
    /**
     * 
     * @type {CredentialIdentifier}
     * @memberof CredentialsExtendedType
     */
    'roleAssigningAuthorIdentifier'?: CredentialIdentifier;
}
/**
 * 
 * @export
 * @interface CredentialsPlainType
 */
export interface CredentialsPlainType {
    /**
     * 
     * @type {Array<CredentialsRoleResponseStandardDetailsType>}
     * @memberof CredentialsPlainType
     */
    'credentialsRoleList'?: Array<CredentialsRoleResponseStandardDetailsType> | null;
    /**
     * 
     * @type {CredentialIdentifier}
     * @memberof CredentialsPlainType
     */
    'identifier'?: CredentialIdentifier;
    /**
     * 
     * @type {string}
     * @memberof CredentialsPlainType
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface CredentialsRoleResponseStandardDetailsType
 */
export interface CredentialsRoleResponseStandardDetailsType {
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseStandardDetailsType
     */
    'roleDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseStandardDetailsType
     */
    'roleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseStandardDetailsType
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseStandardDetailsType
     */
    'credentialsAssignmentType'?: string | null;
    /**
     * 
     * @type {CredentialIdentifier}
     * @memberof CredentialsRoleResponseStandardDetailsType
     */
    'roleAssigningAuthorIdentifier'?: CredentialIdentifier;
}
/**
 * 
 * @export
 * @interface CredentialsRoleResponseTokenType
 */
export interface CredentialsRoleResponseTokenType {
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseTokenType
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseTokenType
     */
    'roleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseTokenType
     */
    'roleDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CredentialsRoleResponseTokenType
     */
    'startTimestamp'?: string | null;
    /**
     * 
     * @type {RoleGrantorIdentifier}
     * @memberof CredentialsRoleResponseTokenType
     */
    'roleGrantorIdentifier'?: RoleGrantorIdentifier;
}
/**
 * 
 * @export
 * @interface CustomSyncRequest
 */
export interface CustomSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomSyncRequest
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomSyncRequest
     */
    'dateTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomSyncRequest
     */
    'dateFrom'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DataSource = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23
} as const;

export type DataSource = typeof DataSource[keyof typeof DataSource];


/**
 * 
 * @export
 * @interface DocumentTemplate
 */
export interface DocumentTemplate {
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplate
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof DocumentTemplate
     */
    'invoiceKind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    'templateFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof DocumentTemplate
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    'templatePayloadZip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    'templatePayload'?: string | null;
    /**
     * 
     * @type {Array<OrganizationDocumentTemplate>}
     * @memberof DocumentTemplate
     */
    'organizationDocumentTemplates'?: Array<OrganizationDocumentTemplate> | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplate
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface DocumentTemplateRM
 */
export interface DocumentTemplateRM {
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateRM
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateRM
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateRM
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentTemplateRM
     */
    'modificationDateUtc'?: string | null;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof DocumentTemplateRM
     */
    'invoiceKind'?: InvoiceKind;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentTemplateRM
     */
    'organizations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentTemplateRM
     */
    'organizationIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface DocumentTemplateRMIEnumerableState
 */
export interface DocumentTemplateRMIEnumerableState {
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentTemplateRMIEnumerableState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof DocumentTemplateRMIEnumerableState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentTemplateRMIEnumerableState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateRMIEnumerableState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentTemplateRMIEnumerableState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {Array<DocumentTemplateRM>}
     * @memberof DocumentTemplateRMIEnumerableState
     */
    'stateObject'?: Array<DocumentTemplateRM> | null;
}
/**
 * 
 * @export
 * @interface EditExitPathRequest
 */
export interface EditExitPathRequest {
    /**
     * 
     * @type {string}
     * @memberof EditExitPathRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditExitPathRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditExitPathRequest
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditExitPathRequest
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditExitPathRequest
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {{ [key: string]: Authentication; }}
     * @memberof EditExitPathRequest
     */
    'endpoints'?: { [key: string]: Authentication; } | null;
    /**
     * 
     * @type {number}
     * @memberof EditExitPathRequest
     */
    'timeout'?: number;
    /**
     * 
     * @type {number}
     * @memberof EditExitPathRequest
     */
    'retryCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditExitPathRequest
     */
    'detailLogsEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditExitPathRequest
     */
    'logAuthData'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EditExitPathRequest
     */
    'retryInterval'?: number;
    /**
     * 
     * @type {Array<StringStringKeyValuePair>}
     * @memberof EditExitPathRequest
     */
    'headers'?: Array<StringStringKeyValuePair> | null;
    /**
     * 
     * @type {Array<XfxNotificationEvent>}
     * @memberof EditExitPathRequest
     */
    'events'?: Array<XfxNotificationEvent> | null;
}
/**
 * 
 * @export
 * @interface EditOrganizationSyncSettingDto
 */
export interface EditOrganizationSyncSettingDto {
    /**
     * 
     * @type {string}
     * @memberof EditOrganizationSyncSettingDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationSyncSettingDto
     */
    'useAdditionalEncryption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationSyncSettingDto
     */
    'asyncModeEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditOrganizationSyncSettingDto
     */
    'organizationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditOrganizationSyncSettingDto
     */
    'lastSyncDateAsSubject1Utc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditOrganizationSyncSettingDto
     */
    'lastSyncDateAsSubject2Utc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditOrganizationSyncSettingDto
     */
    'lastSyncDateAsSubject3Utc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationSyncSettingDto
     */
    'syncRequested'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationSyncSettingDto
     */
    'receptionSyncOnAsSubject1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationSyncSettingDto
     */
    'receptionSyncOnAsSubject2'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationSyncSettingDto
     */
    'receptionSyncOnAsSubject3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationSyncSettingDto
     */
    'useWriteSession'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditOrganizationSyncSettingDto
     */
    'initialReceptionCheckupDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationSyncSettingDto
     */
    'largeWalkingWindowEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditOrganizationSyncSettingDto
     */
    'shippingSynchronizationEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface EditReportDto
 */
export interface EditReportDto {
    /**
     * 
     * @type {string}
     * @memberof EditReportDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditReportDto
     */
    'lastCheckupDateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditReportDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {ReportType}
     * @memberof EditReportDto
     */
    'type'?: ReportType;
    /**
     * 
     * @type {DataSource}
     * @memberof EditReportDto
     */
    'dataSource'?: DataSource;
    /**
     * 
     * @type {OutputFileType}
     * @memberof EditReportDto
     */
    'outputFileType'?: OutputFileType;
    /**
     * 
     * @type {ReportPeriod}
     * @memberof EditReportDto
     */
    'period'?: ReportPeriod;
    /**
     * 
     * @type {string}
     * @memberof EditReportDto
     */
    'initDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditReportDto
     */
    'filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditReportDto
     */
    'odataFilter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditReportDto
     */
    'columns'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditReportDto
     */
    'organizationsIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditReportDto
     */
    'usersIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditReportDto
     */
    'additionalEmails'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface EditUserRequest
 */
export interface EditUserRequest {
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserRequest
     */
    'disabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    'secret'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditUserRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof EditUserRequest
     */
    'type': UserType;
    /**
     * 
     * @type {boolean}
     * @memberof EditUserRequest
     */
    'admin'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditUserRequest
     */
    'roleIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const EdmContainerElementKind = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type EdmContainerElementKind = typeof EdmContainerElementKind[keyof typeof EdmContainerElementKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const EdmExpressionKind = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25
} as const;

export type EdmExpressionKind = typeof EdmExpressionKind[keyof typeof EdmExpressionKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const EdmSchemaElementKind = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type EdmSchemaElementKind = typeof EdmSchemaElementKind[keyof typeof EdmSchemaElementKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const EdmTypeKind = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type EdmTypeKind = typeof EdmTypeKind[keyof typeof EdmTypeKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const EuCountryCode = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27
} as const;

export type EuCountryCode = typeof EuCountryCode[keyof typeof EuCountryCode];


/**
 * 
 * @export
 * @interface ExitPath
 */
export interface ExitPath {
    /**
     * 
     * @type {string}
     * @memberof ExitPath
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExitPath
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExitPath
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExitPath
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExitPath
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {{ [key: string]: Authentication; }}
     * @memberof ExitPath
     */
    'endpoints'?: { [key: string]: Authentication; } | null;
    /**
     * 
     * @type {number}
     * @memberof ExitPath
     */
    'retryCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExitPath
     */
    'retryInterval'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExitPath
     */
    'detailLogsEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExitPath
     */
    'logAuthData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExitPath
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof ExitPath
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {Array<StringStringKeyValuePair>}
     * @memberof ExitPath
     */
    'headers'?: Array<StringStringKeyValuePair> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ExitPath
     */
    'events'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof ExitPath
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof ExitPath
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof ExitPath
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof ExitPath
     */
    'modificationDateUtc': string;
}
/**
 * 
 * @export
 * @interface ExitPathRM
 */
export interface ExitPathRM {
    /**
     * 
     * @type {string}
     * @memberof ExitPathRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExitPathRM
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExitPathRM
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExitPathRM
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExitPathRM
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {{ [key: string]: Authentication; }}
     * @memberof ExitPathRM
     */
    'endpoints'?: { [key: string]: Authentication; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExitPathRM
     */
    'detailLogsEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExitPathRM
     */
    'logAuthData'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExitPathRM
     */
    'retryCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExitPathRM
     */
    'retryInterval'?: number;
    /**
     * 
     * @type {Array<StringStringKeyValuePair>}
     * @memberof ExitPathRM
     */
    'headers'?: Array<StringStringKeyValuePair> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ExitPathRM
     */
    'events'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof ExitPathRM
     */
    'organizationName'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FileSource = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type FileSource = typeof FileSource[keyof typeof FileSource];


/**
 * 
 * @export
 * @interface FrontColumnExtended
 */
export interface FrontColumnExtended {
    /**
     * 
     * @type {string}
     * @memberof FrontColumnExtended
     */
    'dataType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontColumnExtended
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontColumnExtended
     */
    'caption'?: string | null;
}
/**
 * 
 * @export
 * @interface GenerateToken
 */
export interface GenerateToken {
    /**
     * 
     * @type {string}
     * @memberof GenerateToken
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<KsefRole>}
     * @memberof GenerateToken
     */
    'credentialsRoleList'?: Array<KsefRole> | null;
}
/**
 * 
 * @export
 * @interface GenerateTokenRequest
 */
export interface GenerateTokenRequest {
    /**
     * 
     * @type {GenerateToken}
     * @memberof GenerateTokenRequest
     */
    'generateToken'?: GenerateToken;
}
/**
 * 
 * @export
 * @interface IEdmEntityContainer
 */
export interface IEdmEntityContainer {
    /**
     * 
     * @type {Array<IEdmEntityContainerElement>}
     * @memberof IEdmEntityContainer
     */
    'elements'?: Array<IEdmEntityContainerElement> | null;
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmEntityContainer
     */
    'schemaElementKind'?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainer
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainer
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmEntityContainerElement
 */
export interface IEdmEntityContainerElement {
    /**
     * 
     * @type {EdmContainerElementKind}
     * @memberof IEdmEntityContainerElement
     */
    'containerElementKind'?: EdmContainerElementKind;
    /**
     * 
     * @type {IEdmEntityContainer}
     * @memberof IEdmEntityContainerElement
     */
    'container'?: IEdmEntityContainer;
    /**
     * 
     * @type {string}
     * @memberof IEdmEntityContainerElement
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmExpression
 */
export interface IEdmExpression {
    /**
     * 
     * @type {EdmExpressionKind}
     * @memberof IEdmExpression
     */
    'expressionKind'?: EdmExpressionKind;
}


/**
 * 
 * @export
 * @interface IEdmModel
 */
export interface IEdmModel {
    /**
     * 
     * @type {Array<IEdmSchemaElement>}
     * @memberof IEdmModel
     */
    'schemaElements'?: Array<IEdmSchemaElement> | null;
    /**
     * 
     * @type {Array<IEdmVocabularyAnnotation>}
     * @memberof IEdmModel
     */
    'vocabularyAnnotations'?: Array<IEdmVocabularyAnnotation> | null;
    /**
     * 
     * @type {Array<IEdmModel>}
     * @memberof IEdmModel
     */
    'referencedModels'?: Array<IEdmModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IEdmModel
     */
    'declaredNamespaces'?: Array<string> | null;
    /**
     * 
     * @type {object}
     * @memberof IEdmModel
     */
    'directValueAnnotationsManager'?: object;
    /**
     * 
     * @type {IEdmEntityContainer}
     * @memberof IEdmModel
     */
    'entityContainer'?: IEdmEntityContainer;
}
/**
 * 
 * @export
 * @interface IEdmSchemaElement
 */
export interface IEdmSchemaElement {
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmSchemaElement
     */
    'schemaElementKind'?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmSchemaElement
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmSchemaElement
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmTerm
 */
export interface IEdmTerm {
    /**
     * 
     * @type {IEdmTypeReference}
     * @memberof IEdmTerm
     */
    'type'?: IEdmTypeReference;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'appliesTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'defaultValue'?: string | null;
    /**
     * 
     * @type {EdmSchemaElementKind}
     * @memberof IEdmTerm
     */
    'schemaElementKind'?: EdmSchemaElementKind;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IEdmTerm
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface IEdmType
 */
export interface IEdmType {
    /**
     * 
     * @type {EdmTypeKind}
     * @memberof IEdmType
     */
    'typeKind'?: EdmTypeKind;
}


/**
 * 
 * @export
 * @interface IEdmTypeReference
 */
export interface IEdmTypeReference {
    /**
     * 
     * @type {boolean}
     * @memberof IEdmTypeReference
     */
    'isNullable'?: boolean;
    /**
     * 
     * @type {IEdmType}
     * @memberof IEdmTypeReference
     */
    'definition'?: IEdmType;
}
/**
 * 
 * @export
 * @interface IEdmVocabularyAnnotation
 */
export interface IEdmVocabularyAnnotation {
    /**
     * 
     * @type {string}
     * @memberof IEdmVocabularyAnnotation
     */
    'qualifier'?: string | null;
    /**
     * 
     * @type {IEdmTerm}
     * @memberof IEdmVocabularyAnnotation
     */
    'term'?: IEdmTerm;
    /**
     * 
     * @type {object}
     * @memberof IEdmVocabularyAnnotation
     */
    'target'?: object;
    /**
     * 
     * @type {IEdmExpression}
     * @memberof IEdmVocabularyAnnotation
     */
    'value'?: IEdmExpression;
}
/**
 * 
 * @export
 * @interface IModuleProperty
 */
export interface IModuleProperty {
    /**
     * 
     * @type {string}
     * @memberof IModuleProperty
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IModuleProperty
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IModuleProperty
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IModuleProperty
     */
    'dependentPropertyKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IModuleProperty
     */
    'dependentPropertyValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IModuleProperty
     */
    'manualAcceptEnablingValue'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IModuleProperty
     */
    'isManualAcceptProperty'?: boolean;
    /**
     * 
     * @type {Array<IModulePropertyValue>}
     * @memberof IModuleProperty
     */
    'values'?: Array<IModulePropertyValue> | null;
    /**
     * 
     * @type {number}
     * @memberof IModuleProperty
     */
    'defaultValueIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof IModuleProperty
     */
    'configuration'?: string | null;
}
/**
 * 
 * @export
 * @interface IModulePropertyValue
 */
export interface IModulePropertyValue {
    /**
     * 
     * @type {string}
     * @memberof IModulePropertyValue
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IModulePropertyValue
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface ImportedConfigurationRM
 */
export interface ImportedConfigurationRM {
    /**
     * 
     * @type {string}
     * @memberof ImportedConfigurationRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedConfigurationRM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportedConfigurationRM
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {ImportedConfigurationStatus}
     * @memberof ImportedConfigurationRM
     */
    'status'?: ImportedConfigurationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ImportedConfigurationRM
     */
    'allDataImported'?: boolean;
}


/**
 * 
 * @export
 * @interface ImportedConfigurationRMIEnumerableState
 */
export interface ImportedConfigurationRMIEnumerableState {
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportedConfigurationRMIEnumerableState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ImportedConfigurationRMIEnumerableState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportedConfigurationRMIEnumerableState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImportedConfigurationRMIEnumerableState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImportedConfigurationRMIEnumerableState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {Array<ImportedConfigurationRM>}
     * @memberof ImportedConfigurationRMIEnumerableState
     */
    'stateObject'?: Array<ImportedConfigurationRM> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImportedConfigurationStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ImportedConfigurationStatus = typeof ImportedConfigurationStatus[keyof typeof ImportedConfigurationStatus];


/**
 * 
 * @export
 * @interface InitSignedResponse
 */
export interface InitSignedResponse {
    /**
     * 
     * @type {string}
     * @memberof InitSignedResponse
     */
    'timestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InitSignedResponse
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {SessionToken}
     * @memberof InitSignedResponse
     */
    'sessionToken'?: SessionToken;
}
/**
 * 
 * @export
 * @interface InitSignedResponseState
 */
export interface InitSignedResponseState {
    /**
     * 
     * @type {Array<string>}
     * @memberof InitSignedResponseState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof InitSignedResponseState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InitSignedResponseState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InitSignedResponseState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InitSignedResponseState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {InitSignedResponse}
     * @memberof InitSignedResponseState
     */
    'stateObject'?: InitSignedResponse;
}
/**
 * 
 * @export
 * @interface InitTokenResponse
 */
export interface InitTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof InitTokenResponse
     */
    'timestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InitTokenResponse
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {SessionToken}
     * @memberof InitTokenResponse
     */
    'sessionToken'?: SessionToken;
}
/**
 * 
 * @export
 * @interface InitTokenResponseState
 */
export interface InitTokenResponseState {
    /**
     * 
     * @type {Array<string>}
     * @memberof InitTokenResponseState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof InitTokenResponseState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InitTokenResponseState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InitTokenResponseState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InitTokenResponseState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {InitTokenResponse}
     * @memberof InitTokenResponseState
     */
    'stateObject'?: InitTokenResponse;
}
/**
 * 
 * @export
 * @interface Int32StringKeyValuePair
 */
export interface Int32StringKeyValuePair {
    /**
     * 
     * @type {number}
     * @memberof Int32StringKeyValuePair
     */
    'key'?: number;
    /**
     * 
     * @type {string}
     * @memberof Int32StringKeyValuePair
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceKind = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type InvoiceKind = typeof InvoiceKind[keyof typeof InvoiceKind];


/**
 * 
 * @export
 * @enum {string}
 */

export const KsefCredentialsIdentifierType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type KsefCredentialsIdentifierType = typeof KsefCredentialsIdentifierType[keyof typeof KsefCredentialsIdentifierType];


/**
 * 
 * @export
 * @enum {string}
 */

export const KsefCredentialsRoleStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type KsefCredentialsRoleStatus = typeof KsefCredentialsRoleStatus[keyof typeof KsefCredentialsRoleStatus];


/**
 * 
 * @export
 * @interface KsefCredentialsRoleSyncStatus
 */
export interface KsefCredentialsRoleSyncStatus {
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleSyncStatus
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleSyncStatus
     */
    'organizationNip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleSyncStatus
     */
    'lastSuccessfulSync'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleSyncStatus
     */
    'lastFailedSync'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleSyncStatus
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof KsefCredentialsRoleSyncStatus
     */
    'tenant'?: Tenant;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const KsefCredentialsRoleType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11
} as const;

export type KsefCredentialsRoleType = typeof KsefCredentialsRoleType[keyof typeof KsefCredentialsRoleType];


/**
 * 
 * @export
 * @interface KsefCredentialsRoleWithStatus
 */
export interface KsefCredentialsRoleWithStatus {
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'creationDateTimeOffset'?: string;
    /**
     * 
     * @type {KsefCredentialsRoleStatus}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'status'?: KsefCredentialsRoleStatus;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'lastSynchronizationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'organizationNip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'credentialsIdentifier'?: string | null;
    /**
     * 
     * @type {KsefCredentialsIdentifierType}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'credentialsIdentifierType'?: KsefCredentialsIdentifierType;
    /**
     * 
     * @type {boolean}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'isIndividualRole'?: boolean;
    /**
     * 
     * @type {Set<KsefCredentialsRoleType>}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'credentialRoles'?: Set<KsefCredentialsRoleType> | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsRoleWithStatus
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface KsefCredentialsToken
 */
export interface KsefCredentialsToken {
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'organizationNip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'credentialsIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'authorisationToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'timestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KsefCredentialsToken
     */
    'processingCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'processingDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'elementReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'payload'?: string | null;
    /**
     * 
     * @type {KsefCredentialsRoleStatus}
     * @memberof KsefCredentialsToken
     */
    'status'?: KsefCredentialsRoleStatus;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof KsefCredentialsToken
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof KsefCredentialsToken
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface KsefEditRoleRequest
 */
export interface KsefEditRoleRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof KsefEditRoleRequest
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface KsefInfo
 */
export interface KsefInfo {
    /**
     * 
     * @type {boolean}
     * @memberof KsefInfo
     */
    'maintenanceMode'?: boolean;
}
/**
 * 
 * @export
 * @interface KsefNewRoleRequest
 */
export interface KsefNewRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof KsefNewRoleRequest
     */
    'credentialsIdentifier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KsefNewRoleRequest
     */
    'isIndividualRole'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof KsefNewRoleRequest
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface KsefRole
 */
export interface KsefRole {
    /**
     * 
     * @type {string}
     * @memberof KsefRole
     */
    'roleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefRole
     */
    'roleDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefRole
     */
    'startTimestamp'?: string | null;
    /**
     * 
     * @type {RoleGrantorIdentifier}
     * @memberof KsefRole
     */
    'roleGrantorIdentifier'?: RoleGrantorIdentifier;
}
/**
 * 
 * @export
 * @interface KsefRoleRM
 */
export interface KsefRoleRM {
    /**
     * 
     * @type {string}
     * @memberof KsefRoleRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KsefRoleRM
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefRoleRM
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefRoleRM
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof KsefRoleRM
     */
    'lastSynchronizationDateUtc'?: string;
    /**
     * 
     * @type {KsefCredentialsRoleStatus}
     * @memberof KsefRoleRM
     */
    'status'?: KsefCredentialsRoleStatus;
    /**
     * 
     * @type {boolean}
     * @memberof KsefRoleRM
     */
    'isIndividualRole'?: boolean;
    /**
     * 
     * @type {Array<KsefCredentialsRoleType>}
     * @memberof KsefRoleRM
     */
    'credentials'?: Array<KsefCredentialsRoleType> | null;
    /**
     * 
     * @type {Array<KsefCredentialsRoleType>}
     * @memberof KsefRoleRM
     */
    'roles'?: Array<KsefCredentialsRoleType> | null;
}


/**
 * 
 * @export
 * @interface KsefSelfInvoicingGranted
 */
export interface KsefSelfInvoicingGranted {
    /**
     * 
     * @type {string}
     * @memberof KsefSelfInvoicingGranted
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KsefSelfInvoicingGranted
     */
    'organizationNip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefSelfInvoicingGranted
     */
    'grantedByNip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefSelfInvoicingGranted
     */
    'lastCheckUpDateUtc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KsefSelfInvoicingGranted
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KsefSelfInvoicingGranted
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof KsefSelfInvoicingGranted
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof KsefSelfInvoicingGranted
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof KsefSelfInvoicingGranted
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof KsefSelfInvoicingGranted
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof KsefSelfInvoicingGranted
     */
    'modificationDateUtc': string;
}
/**
 * 
 * @export
 * @interface KsefTokenRM
 */
export interface KsefTokenRM {
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'rolesDescriptions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'roles'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'organizationNip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'credentialsIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'authorisationToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'timestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KsefTokenRM
     */
    'processingCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'processingDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'elementReferenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'payload'?: string | null;
    /**
     * 
     * @type {KsefCredentialsRoleStatus}
     * @memberof KsefTokenRM
     */
    'status'?: KsefCredentialsRoleStatus;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof KsefTokenRM
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface KsefixUserContext
 */
export interface KsefixUserContext {
    /**
     * 
     * @type {string}
     * @memberof KsefixUserContext
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KsefixUserContext
     */
    'userDbId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KsefixUserContext
     */
    'userEmail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KsefixUserContext
     */
    'isAdmin'?: boolean;
}
/**
 * 
 * @export
 * @interface LicenseRM
 */
export interface LicenseRM {
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseRM
     */
    'features'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseRM
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseRM
     */
    'licenseId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LicenseRM
     */
    'nipsNumber'?: number;
}
/**
 * 
 * @export
 * @interface LicenseRMState
 */
export interface LicenseRMState {
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseRMState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof LicenseRMState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseRMState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseRMState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseRMState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {LicenseRM}
     * @memberof LicenseRMState
     */
    'stateObject'?: LicenseRM;
}
/**
 * 
 * @export
 * @interface LicenseRecord
 */
export interface LicenseRecord {
    /**
     * 
     * @type {string}
     * @memberof LicenseRecord
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseRecord
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseRecord
     */
    'licenseId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseRecord
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof LicenseRecord
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof LicenseRecord
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseRecord
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseRecord
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseRecord
     */
    'modificationDateUtc': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ListType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ListType = typeof ListType[keyof typeof ListType];


/**
 * 
 * @export
 * @enum {string}
 */

export const MetadataKey = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type MetadataKey = typeof MetadataKey[keyof typeof MetadataKey];


/**
 * 
 * @export
 * @interface ModuleRM
 */
export interface ModuleRM {
    /**
     * 
     * @type {string}
     * @memberof ModuleRM
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModuleRM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModuleRM
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModuleRM
     */
    'detailedDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleRM
     */
    'isAvailable'?: boolean;
    /**
     * 
     * @type {Array<IModuleProperty>}
     * @memberof ModuleRM
     */
    'properties'?: Array<IModuleProperty> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleRM
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleRM
     */
    'isReception'?: boolean;
}
/**
 * 
 * @export
 * @interface ModuleUsage
 */
export interface ModuleUsage {
    /**
     * 
     * @type {string}
     * @memberof ModuleUsage
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsage
     */
    'moduleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsage
     */
    'moduleFullyQualifiedClassName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsage
     */
    'moduleEntryPoint'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleUsage
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsage
     */
    'settings'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleUsage
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleUsage
     */
    'isLicenseActive'?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ModuleUsage
     */
    'settingsDeserialized'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {number}
     * @memberof ModuleUsage
     */
    'orderIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsage
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof ModuleUsage
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsage
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsage
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsage
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsage
     */
    'modificationDateUtc': string;
}
/**
 * 
 * @export
 * @interface ModuleUsageDto
 */
export interface ModuleUsageDto {
    /**
     * 
     * @type {string}
     * @memberof ModuleUsageDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsageDto
     */
    'moduleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModuleUsageDto
     */
    'settings'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleUsageDto
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface NewInvoiceNumberDto
 */
export interface NewInvoiceNumberDto {
    /**
     * 
     * @type {boolean}
     * @memberof NewInvoiceNumberDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewInvoiceNumberDto
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewInvoiceNumberDto
     */
    'error'?: string | null;
}
/**
 * 
 * @export
 * @interface NipList
 */
export interface NipList {
    /**
     * 
     * @type {string}
     * @memberof NipList
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NipList
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<NipListElement>}
     * @memberof NipList
     */
    'nipListElements'?: Array<NipListElement> | null;
    /**
     * 
     * @type {string}
     * @memberof NipList
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof NipList
     */
    'tenant'?: Tenant;
}
/**
 * 
 * @export
 * @interface NipListElement
 */
export interface NipListElement {
    /**
     * 
     * @type {string}
     * @memberof NipListElement
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NipListElement
     */
    'nip'?: string | null;
    /**
     * 
     * @type {NipList}
     * @memberof NipListElement
     */
    'nipList'?: NipList;
    /**
     * 
     * @type {string}
     * @memberof NipListElement
     */
    'nipListId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NipListElement
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof NipListElement
     */
    'tenant'?: Tenant;
}
/**
 * 
 * @export
 * @interface NotificationConfigurationDto
 */
export interface NotificationConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationConfigurationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationConfigurationDto
     */
    'id'?: string;
    /**
     * 
     * @type {NotificationType}
     * @memberof NotificationConfigurationDto
     */
    'notificationType'?: NotificationType;
    /**
     * 
     * @type {NotificationMode}
     * @memberof NotificationConfigurationDto
     */
    'notificationMode'?: NotificationMode;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationConfigurationDto
     */
    'attachPdf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationConfigurationDto
     */
    'attachXml'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationConfigurationDto
     */
    'sendToContractor'?: boolean;
    /**
     * 
     * @type {NotificationPeriod}
     * @memberof NotificationConfigurationDto
     */
    'notificationPeriod'?: NotificationPeriod;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationConfigurationDto
     */
    'organizations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationConfigurationDto
     */
    'users'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationConfigurationDto
     */
    'additionalEmails'?: Array<string> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationMode = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type NotificationMode = typeof NotificationMode[keyof typeof NotificationMode];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationPeriod = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11
} as const;

export type NotificationPeriod = typeof NotificationPeriod[keyof typeof NotificationPeriod];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type NotificationType = typeof NotificationType[keyof typeof NotificationType];


/**
 * 
 * @export
 * @interface NumberingSeries
 */
export interface NumberingSeries {
    /**
     * 
     * @type {string}
     * @memberof NumberingSeries
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeries
     */
    'name'?: string | null;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof NumberingSeries
     */
    'invoiceKind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeries
     */
    'mask'?: string | null;
    /**
     * 
     * @type {ResetIdPeriod}
     * @memberof NumberingSeries
     */
    'resetIdPeriod'?: ResetIdPeriod;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeries
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<OrganizationNumberingSeries>}
     * @memberof NumberingSeries
     */
    'organizationNumberingSeries'?: Array<OrganizationNumberingSeries> | null;
    /**
     * 
     * @type {Array<NumberingSeriesCounter>}
     * @memberof NumberingSeries
     */
    'counters'?: Array<NumberingSeriesCounter> | null;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeries
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeries
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeries
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeries
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface NumberingSeriesCounter
 */
export interface NumberingSeriesCounter {
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesCounter
     */
    'id'?: string;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof NumberingSeriesCounter
     */
    'invoiceKind'?: InvoiceKind;
    /**
     * 
     * @type {number}
     * @memberof NumberingSeriesCounter
     */
    'lastNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesCounter
     */
    'mask'?: string | null;
    /**
     * 
     * @type {ResetIdPeriod}
     * @memberof NumberingSeriesCounter
     */
    'resetIdPeriod'?: ResetIdPeriod;
    /**
     * 
     * @type {number}
     * @memberof NumberingSeriesCounter
     */
    'currentResetIdPeriodNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesCounter
     */
    'timeStampUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesCounter
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesCounter
     */
    'organizationId'?: string;
    /**
     * 
     * @type {Organization}
     * @memberof NumberingSeriesCounter
     */
    'organization'?: Organization;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesCounter
     */
    'numberingSeriesId'?: string | null;
    /**
     * 
     * @type {NumberingSeries}
     * @memberof NumberingSeriesCounter
     */
    'numberingSeries'?: NumberingSeries;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesCounter
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesCounter
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesCounter
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesCounter
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface NumberingSeriesDto
 */
export interface NumberingSeriesDto {
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NumberingSeriesDto
     */
    'organizationIds'?: Array<string> | null;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof NumberingSeriesDto
     */
    'invoiceKind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesDto
     */
    'mask'?: string | null;
    /**
     * 
     * @type {ResetIdPeriod}
     * @memberof NumberingSeriesDto
     */
    'resetIdPeriod'?: ResetIdPeriod;
}


/**
 * 
 * @export
 * @interface NumberingSeriesRM
 */
export interface NumberingSeriesRM {
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesRM
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesRM
     */
    'name'?: string | null;
    /**
     * 
     * @type {InvoiceKind}
     * @memberof NumberingSeriesRM
     */
    'invoiceKind'?: InvoiceKind;
    /**
     * 
     * @type {string}
     * @memberof NumberingSeriesRM
     */
    'mask'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NumberingSeriesRM
     */
    'resetIdPeriod'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof NumberingSeriesRM
     */
    'organizations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NumberingSeriesRM
     */
    'organizationIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface ODataEntitySetInfo
 */
export interface ODataEntitySetInfo {
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataEntitySetInfo
     */
    'title'?: string | null;
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataEntitySetInfo
     */
    'typeAnnotation'?: ODataTypeAnnotation;
}
/**
 * 
 * @export
 * @interface ODataFunctionImportInfo
 */
export interface ODataFunctionImportInfo {
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataFunctionImportInfo
     */
    'title'?: string | null;
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataFunctionImportInfo
     */
    'typeAnnotation'?: ODataTypeAnnotation;
}
/**
 * 
 * @export
 * @interface ODataServiceDocument
 */
export interface ODataServiceDocument {
    /**
     * 
     * @type {Array<ODataEntitySetInfo>}
     * @memberof ODataServiceDocument
     */
    'entitySets'?: Array<ODataEntitySetInfo> | null;
    /**
     * 
     * @type {Array<ODataSingletonInfo>}
     * @memberof ODataServiceDocument
     */
    'singletons'?: Array<ODataSingletonInfo> | null;
    /**
     * 
     * @type {Array<ODataFunctionImportInfo>}
     * @memberof ODataServiceDocument
     */
    'functionImports'?: Array<ODataFunctionImportInfo> | null;
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataServiceDocument
     */
    'typeAnnotation'?: ODataTypeAnnotation;
}
/**
 * 
 * @export
 * @interface ODataSingletonInfo
 */
export interface ODataSingletonInfo {
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ODataSingletonInfo
     */
    'title'?: string | null;
    /**
     * 
     * @type {ODataTypeAnnotation}
     * @memberof ODataSingletonInfo
     */
    'typeAnnotation'?: ODataTypeAnnotation;
}
/**
 * 
 * @export
 * @interface ODataTypeAnnotation
 */
export interface ODataTypeAnnotation {
    /**
     * 
     * @type {string}
     * @memberof ODataTypeAnnotation
     */
    'typeName'?: string | null;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'nip'?: string | null;
    /**
     * 
     * @type {OrganizationType}
     * @memberof Organization
     */
    'type'?: OrganizationType;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'krs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'regon'?: string | null;
    /**
     * 
     * @type {EuCountryCode}
     * @memberof Organization
     */
    'countryPrefix'?: EuCountryCode;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'eoriNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'invoiceFooter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'bdo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'mapping'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof Organization
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {Array<OrganizationBankAccount>}
     * @memberof Organization
     */
    'organizationBankAccounts'?: Array<OrganizationBankAccount> | null;
    /**
     * 
     * @type {Array<OrganizationAddress>}
     * @memberof Organization
     */
    'organizationAddresses'?: Array<OrganizationAddress> | null;
    /**
     * 
     * @type {Array<OrganizationAccountingSystem>}
     * @memberof Organization
     */
    'organizationAccountingSystems'?: Array<OrganizationAccountingSystem> | null;
    /**
     * 
     * @type {Array<OrganizationDocumentTemplate>}
     * @memberof Organization
     */
    'organizationDocumentTemplates'?: Array<OrganizationDocumentTemplate> | null;
    /**
     * 
     * @type {Array<OrganizationNumberingSeries>}
     * @memberof Organization
     */
    'organizationNumberingSeries'?: Array<OrganizationNumberingSeries> | null;
    /**
     * 
     * @type {Array<Contractor>}
     * @memberof Organization
     */
    'contractors'?: Array<Contractor> | null;
    /**
     * 
     * @type {OrganizationSyncSetting}
     * @memberof Organization
     */
    'organizationSyncSetting'?: OrganizationSyncSetting;
    /**
     * 
     * @type {Array<NumberingSeriesCounter>}
     * @memberof Organization
     */
    'numberingSeriesCounters'?: Array<NumberingSeriesCounter> | null;
    /**
     * 
     * @type {Array<ReportAggregate>}
     * @memberof Organization
     */
    'reports'?: Array<ReportAggregate> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface OrganizationAccountingSystem
 */
export interface OrganizationAccountingSystem {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAccountingSystem
     */
    'accountingSystemId'?: string;
    /**
     * 
     * @type {AccountingSystem}
     * @memberof OrganizationAccountingSystem
     */
    'accountingSystem'?: AccountingSystem;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAccountingSystem
     */
    'organizationId'?: string;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationAccountingSystem
     */
    'organization'?: Organization;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAccountingSystem
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof OrganizationAccountingSystem
     */
    'tenant'?: Tenant;
}
/**
 * 
 * @export
 * @interface OrganizationAddress
 */
export interface OrganizationAddress {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddress
     */
    'organizationId'?: string;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationAddress
     */
    'organization'?: Organization;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddress
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof OrganizationAddress
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddress
     */
    'id'?: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof OrganizationAddress
     */
    'countryCode'?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddress
     */
    'gln'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddress
     */
    'adresL1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddress
     */
    'adresL2'?: string | null;
    /**
     * 
     * @type {AddressFieldOnInvoice}
     * @memberof OrganizationAddress
     */
    'fieldOnInvoice'?: AddressFieldOnInvoice;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddress
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddress
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddress
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddress
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface OrganizationAddressModel
 */
export interface OrganizationAddressModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddressModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {CountryCode}
     * @memberof OrganizationAddressModel
     */
    'countryCode'?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddressModel
     */
    'countryCodeStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddressModel
     */
    'gln'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddressModel
     */
    'adresL1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationAddressModel
     */
    'adresL2'?: string | null;
}


/**
 * 
 * @export
 * @interface OrganizationBankAccount
 */
export interface OrganizationBankAccount {
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccount
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccount
     */
    'rbplNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccount
     */
    'bankName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccount
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccount
     */
    'organizationId'?: string;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationBankAccount
     */
    'organization'?: Organization;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccount
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof OrganizationBankAccount
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccount
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccount
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccount
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccount
     */
    'modificationDateUtc': string;
}
/**
 * 
 * @export
 * @interface OrganizationBankAccountModel
 */
export interface OrganizationBankAccountModel {
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccountModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccountModel
     */
    'rbplNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccountModel
     */
    'bankName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationBankAccountModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationContext
 */
export interface OrganizationContext {
    /**
     * 
     * @type {string}
     * @memberof OrganizationContext
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationContext
     */
    'nip'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationDocumentTemplate
 */
export interface OrganizationDocumentTemplate {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDocumentTemplate
     */
    'documentTemplateId'?: string;
    /**
     * 
     * @type {DocumentTemplate}
     * @memberof OrganizationDocumentTemplate
     */
    'documentTemplate'?: DocumentTemplate;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDocumentTemplate
     */
    'organizationId'?: string;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationDocumentTemplate
     */
    'organization'?: Organization;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDocumentTemplate
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof OrganizationDocumentTemplate
     */
    'tenant'?: Tenant;
}
/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {OrganizationType}
     * @memberof OrganizationDto
     */
    'type'?: OrganizationType;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'krs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'regon'?: string | null;
    /**
     * 
     * @type {EuCountryCode}
     * @memberof OrganizationDto
     */
    'countryPrefix'?: EuCountryCode;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'countryPrefixStr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'eoriNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'tradeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'invoiceFooter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'bdo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    'mapping'?: string | null;
    /**
     * 
     * @type {OrganizationAddressModel}
     * @memberof OrganizationDto
     */
    'address'?: OrganizationAddressModel;
    /**
     * 
     * @type {OrganizationAddressModel}
     * @memberof OrganizationDto
     */
    'correspondenceAddress'?: OrganizationAddressModel;
    /**
     * 
     * @type {Array<OrganizationBankAccountModel>}
     * @memberof OrganizationDto
     */
    'organizationBankAccounts'?: Array<OrganizationBankAccountModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDto
     */
    'isActive'?: boolean;
}


/**
 * 
 * @export
 * @interface OrganizationGuidsNIPResponse
 */
export interface OrganizationGuidsNIPResponse {
    /**
     * 
     * @type {string}
     * @memberof OrganizationGuidsNIPResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationGuidsNIPResponse
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationGuidsNIPResponse
     */
    'nip'?: string | null;
}
/**
 * 
 * @export
 * @interface OrganizationNumberingSeries
 */
export interface OrganizationNumberingSeries {
    /**
     * 
     * @type {string}
     * @memberof OrganizationNumberingSeries
     */
    'numberingSeriesId'?: string;
    /**
     * 
     * @type {NumberingSeries}
     * @memberof OrganizationNumberingSeries
     */
    'numberingSeries'?: NumberingSeries;
    /**
     * 
     * @type {string}
     * @memberof OrganizationNumberingSeries
     */
    'organizationId'?: string;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationNumberingSeries
     */
    'organization'?: Organization;
    /**
     * 
     * @type {string}
     * @memberof OrganizationNumberingSeries
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof OrganizationNumberingSeries
     */
    'tenant'?: Tenant;
}
/**
 * 
 * @export
 * @interface OrganizationRM
 */
export interface OrganizationRM {
    /**
     * 
     * @type {string}
     * @memberof OrganizationRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRM
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRM
     */
    'nip'?: string | null;
    /**
     * 
     * @type {OrganizationType}
     * @memberof OrganizationRM
     */
    'type'?: OrganizationType;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRM
     */
    'krs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRM
     */
    'regon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRM
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRM
     */
    'createdById'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRM
     */
    'modificationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRM
     */
    'modificatedById'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationRM
     */
    'mapping'?: string | null;
}


/**
 * 
 * @export
 * @interface OrganizationState
 */
export interface OrganizationState {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof OrganizationState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizationState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationState
     */
    'stateObject'?: Organization;
}
/**
 * 
 * @export
 * @interface OrganizationSyncSetting
 */
export interface OrganizationSyncSetting {
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSetting
     */
    'useAdditionalEncryption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSetting
     */
    'asyncModeEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'organizationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'lastSyncDateAsSubject1Utc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'lastSyncDateAsSubject2Utc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'lastSyncDateAsSubject3Utc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSetting
     */
    'syncRequested'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSetting
     */
    'receptionSyncOnAsSubject1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSetting
     */
    'receptionSyncOnAsSubject2'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSetting
     */
    'receptionSyncOnAsSubject3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSetting
     */
    'useWriteSession'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'initialReceptionCheckupDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSetting
     */
    'largeWalkingWindowEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSetting
     */
    'shippingSynchronizationEnabled'?: boolean;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationSyncSetting
     */
    'organization'?: Organization;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof OrganizationSyncSetting
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSetting
     */
    'modificationDateUtc': string;
}
/**
 * 
 * @export
 * @interface OrganizationSyncSettingRM
 */
export interface OrganizationSyncSettingRM {
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSettingRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSettingRM
     */
    'organizationNIP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSettingRM
     */
    'organizationName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSettingRM
     */
    'useAdditionalEncryption'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSettingRM
     */
    'asyncModeEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSettingRM
     */
    'organizationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSettingRM
     */
    'lastSyncDateAsSubject1Utc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSettingRM
     */
    'lastSyncDateAsSubject2Utc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSettingRM
     */
    'lastSyncDateAsSubject3Utc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSettingRM
     */
    'syncRequested'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSettingRM
     */
    'receptionSyncOnAsSubject1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSettingRM
     */
    'receptionSyncOnAsSubject2'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSettingRM
     */
    'receptionSyncOnAsSubject3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSettingRM
     */
    'useWriteSession'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSyncSettingRM
     */
    'initialReceptionCheckupDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSettingRM
     */
    'largeWalkingWindowEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationSyncSettingRM
     */
    'shippingSynchronizationEnabled'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrganizationType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type OrganizationType = typeof OrganizationType[keyof typeof OrganizationType];


/**
 * 
 * @export
 * @interface OrganizationTypesInfoResponse
 */
export interface OrganizationTypesInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationTypesInfoResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypesInfoResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypesInfoResponse
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OutputFileType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type OutputFileType = typeof OutputFileType[keyof typeof OutputFileType];


/**
 * 
 * @export
 * @interface Pipeline
 */
export interface Pipeline {
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Pipeline
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {PipelineType}
     * @memberof Pipeline
     */
    'type'?: PipelineType;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof Pipeline
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {Array<ModuleUsage>}
     * @memberof Pipeline
     */
    'steps'?: Array<ModuleUsage> | null;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof Pipeline
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface PipelineDto
 */
export interface PipelineDto {
    /**
     * 
     * @type {string}
     * @memberof PipelineDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PipelineDto
     */
    'isDispatch'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PipelineDto
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PipelineDto
     */
    'fkSystem'?: string | null;
    /**
     * 
     * @type {PipelineType}
     * @memberof PipelineDto
     */
    'type'?: PipelineType;
    /**
     * 
     * @type {Array<ModuleUsageDto>}
     * @memberof PipelineDto
     */
    'steps'?: Array<ModuleUsageDto> | null;
    /**
     * 
     * @type {string}
     * @memberof PipelineDto
     */
    'organizationName'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PipelineType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type PipelineType = typeof PipelineType[keyof typeof PipelineType];


/**
 * 
 * @export
 * @interface PipelineTypeRM
 */
export interface PipelineTypeRM {
    /**
     * 
     * @type {number}
     * @memberof PipelineTypeRM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PipelineTypeRM
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Privileges = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28,
    NUMBER_29: 29,
    NUMBER_30: 30,
    NUMBER_31: 31,
    NUMBER_32: 32,
    NUMBER_33: 33,
    NUMBER_34: 34,
    NUMBER_35: 35,
    NUMBER_36: 36,
    NUMBER_37: 37,
    NUMBER_38: 38,
    NUMBER_39: 39,
    NUMBER_40: 40,
    NUMBER_41: 41,
    NUMBER_42: 42,
    NUMBER_43: 43,
    NUMBER_44: 44,
    NUMBER_45: 45,
    NUMBER_46: 46,
    NUMBER_47: 47,
    NUMBER_48: 48,
    NUMBER_49: 49,
    NUMBER_50: 50,
    NUMBER_51: 51,
    NUMBER_52: 52,
    NUMBER_53: 53
} as const;

export type Privileges = typeof Privileges[keyof typeof Privileges];


/**
 * 
 * @export
 * @interface PrivilegesByToken
 */
export interface PrivilegesByToken {
    /**
     * 
     * @type {boolean}
     * @memberof PrivilegesByToken
     */
    'credentialsCheckAvalailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PrivilegesByToken
     */
    'credentialsManageAvalailable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PrivilegesByToken
     */
    'tokenActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PrivilegesByToken
     */
    'contextIdentifier'?: string | null;
    /**
     * 
     * @type {SessionKind}
     * @memberof PrivilegesByToken
     */
    'sessionType'?: SessionKind;
    /**
     * 
     * @type {string}
     * @memberof PrivilegesByToken
     */
    'contextType'?: string | null;
}


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface QuerySyncCredentialsResponse
 */
export interface QuerySyncCredentialsResponse {
    /**
     * 
     * @type {string}
     * @memberof QuerySyncCredentialsResponse
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuerySyncCredentialsResponse
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Array<CredentialsBaseTypeObject>}
     * @memberof QuerySyncCredentialsResponse
     */
    'credentialsList'?: Array<CredentialsBaseTypeObject> | null;
}
/**
 * 
 * @export
 * @interface QuerySyncCredentialsResponseState
 */
export interface QuerySyncCredentialsResponseState {
    /**
     * 
     * @type {Array<string>}
     * @memberof QuerySyncCredentialsResponseState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof QuerySyncCredentialsResponseState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuerySyncCredentialsResponseState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuerySyncCredentialsResponseState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuerySyncCredentialsResponseState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {QuerySyncCredentialsResponse}
     * @memberof QuerySyncCredentialsResponseState
     */
    'stateObject'?: QuerySyncCredentialsResponse;
}
/**
 * 
 * @export
 * @interface ReportAggregate
 */
export interface ReportAggregate {
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'name'?: string | null;
    /**
     * 
     * @type {ReportType}
     * @memberof ReportAggregate
     */
    'type'?: ReportType;
    /**
     * 
     * @type {DataSource}
     * @memberof ReportAggregate
     */
    'dataSource'?: DataSource;
    /**
     * 
     * @type {OutputFileType}
     * @memberof ReportAggregate
     */
    'outputFileType'?: OutputFileType;
    /**
     * 
     * @type {ReportPeriod}
     * @memberof ReportAggregate
     */
    'period'?: ReportPeriod;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'initDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'odataFilter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'columns'?: string | null;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof ReportAggregate
     */
    'organizations'?: Array<Organization> | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof ReportAggregate
     */
    'users'?: Array<User> | null;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'additionalEmails'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'lastCheckupDateUtc'?: string | null;
    /**
     * 
     * @type {Array<FrontColumnExtended>}
     * @memberof ReportAggregate
     */
    'frontColumns'?: Array<FrontColumnExtended> | null;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof ReportAggregate
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ReportPeriod = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ReportPeriod = typeof ReportPeriod[keyof typeof ReportPeriod];


/**
 * 
 * @export
 * @interface ReportRM
 */
export interface ReportRM {
    /**
     * 
     * @type {string}
     * @memberof ReportRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportRM
     */
    'name'?: string | null;
    /**
     * 
     * @type {DataSource}
     * @memberof ReportRM
     */
    'dataSource'?: DataSource;
    /**
     * 
     * @type {ReportType}
     * @memberof ReportRM
     */
    'reportType'?: ReportType;
    /**
     * 
     * @type {string}
     * @memberof ReportRM
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportRM
     */
    'modificationDateUtc'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportRM
     */
    'organizations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportRM
     */
    'organizationIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ReportType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ReportType = typeof ReportType[keyof typeof ReportType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ResetIdPeriod = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ResetIdPeriod = typeof ResetIdPeriod[keyof typeof ResetIdPeriod];


/**
 * 
 * @export
 * @enum {string}
 */

export const RestrictedColumns = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type RestrictedColumns = typeof RestrictedColumns[keyof typeof RestrictedColumns];


/**
 * 
 * @export
 * @interface RestrictedColumnsRestrictionsList
 */
export interface RestrictedColumnsRestrictionsList {
    /**
     * 
     * @type {Array<RestrictedColumns>}
     * @memberof RestrictedColumnsRestrictionsList
     */
    'allowed'?: Array<RestrictedColumns> | null;
    /**
     * 
     * @type {Array<RestrictedColumns>}
     * @memberof RestrictedColumnsRestrictionsList
     */
    'forbidden'?: Array<RestrictedColumns> | null;
    /**
     * 
     * @type {ListType}
     * @memberof RestrictedColumnsRestrictionsList
     */
    'listType'?: ListType;
}


/**
 * 
 * @export
 * @interface Role2
 */
export interface Role2 {
    /**
     * 
     * @type {string}
     * @memberof Role2
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role2
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Role2
     */
    'usersIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Role2
     */
    'id'?: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Role2
     */
    'users'?: Array<User> | null;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof Role2
     */
    'organizations'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof Role2
     */
    'salesNips'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof Role2
     */
    'purchaseNips'?: StringRestrictionsList;
    /**
     * 
     * @type {RestrictedColumnsRestrictionsList}
     * @memberof Role2
     */
    'saleColumns'?: RestrictedColumnsRestrictionsList;
    /**
     * 
     * @type {RestrictedColumnsRestrictionsList}
     * @memberof Role2
     */
    'purchaseColumns'?: RestrictedColumnsRestrictionsList;
    /**
     * 
     * @type {RestrictedColumnsRestrictionsList}
     * @memberof Role2
     */
    'otherColumns'?: RestrictedColumnsRestrictionsList;
    /**
     * 
     * @type {TagsRestrictionsSet}
     * @memberof Role2
     */
    'saleTags'?: TagsRestrictionsSet;
    /**
     * 
     * @type {TagsRestrictionsSet}
     * @memberof Role2
     */
    'purchaseTags'?: TagsRestrictionsSet;
    /**
     * 
     * @type {Array<Privileges>}
     * @memberof Role2
     */
    'privileges'?: Array<Privileges> | null;
    /**
     * 
     * @type {SelfinvoicingRestriction}
     * @memberof Role2
     */
    'salesSelfInvoicingVisibility'?: SelfinvoicingRestriction;
    /**
     * 
     * @type {SelfinvoicingRestriction}
     * @memberof Role2
     */
    'purchaseSelfInvoicingVisibility'?: SelfinvoicingRestriction;
    /**
     * 
     * @type {string}
     * @memberof Role2
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof Role2
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof Role2
     */
    'salesAccountingSystems'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof Role2
     */
    'purchaseAccountingSystems'?: StringRestrictionsList;
    /**
     * 
     * @type {string}
     * @memberof Role2
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof Role2
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof Role2
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof Role2
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof RoleDto
     */
    'salesNips'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof RoleDto
     */
    'purchaseNips'?: StringRestrictionsList;
    /**
     * 
     * @type {RestrictedColumnsRestrictionsList}
     * @memberof RoleDto
     */
    'saleColumns'?: RestrictedColumnsRestrictionsList;
    /**
     * 
     * @type {RestrictedColumnsRestrictionsList}
     * @memberof RoleDto
     */
    'purchaseColumns'?: RestrictedColumnsRestrictionsList;
    /**
     * 
     * @type {RestrictedColumnsRestrictionsList}
     * @memberof RoleDto
     */
    'otherColumns'?: RestrictedColumnsRestrictionsList;
    /**
     * 
     * @type {TagsRestrictionsSet}
     * @memberof RoleDto
     */
    'saleTags'?: TagsRestrictionsSet;
    /**
     * 
     * @type {TagsRestrictionsSet}
     * @memberof RoleDto
     */
    'purchaseTags'?: TagsRestrictionsSet;
    /**
     * 
     * @type {Array<Privileges>}
     * @memberof RoleDto
     */
    'privileges'?: Array<Privileges> | null;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof RoleDto
     */
    'organizations'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof RoleDto
     */
    'organizationsView'?: StringRestrictionsList;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleDto
     */
    'privilegeIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleDto
     */
    'organizationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'users'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleDto
     */
    'usersIds'?: Array<string> | null;
    /**
     * 
     * @type {SelfinvoicingRestriction}
     * @memberof RoleDto
     */
    'salesSelfInvoicingVisibility'?: SelfinvoicingRestriction;
    /**
     * 
     * @type {SelfinvoicingRestriction}
     * @memberof RoleDto
     */
    'purchaseSelfInvoicingVisibility'?: SelfinvoicingRestriction;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof RoleDto
     */
    'salesAccountingSystems'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof RoleDto
     */
    'purchaseAccountingSystems'?: StringRestrictionsList;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface RoleGrantorIdentifier
 */
export interface RoleGrantorIdentifier {
    /**
     * 
     * @type {string}
     * @memberof RoleGrantorIdentifier
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleGrantorIdentifier
     */
    'identifier'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RoleType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11
} as const;

export type RoleType = typeof RoleType[keyof typeof RoleType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SecurityEvent = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_100: 100,
    NUMBER_101: 101,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_104: 104,
    NUMBER_105: 105,
    NUMBER_106: 106,
    NUMBER_107: 107,
    NUMBER_108: 108,
    NUMBER_109: 109,
    NUMBER_110: 110,
    NUMBER_111: 111,
    NUMBER_112: 112,
    NUMBER_113: 113,
    NUMBER_114: 114,
    NUMBER_115: 115,
    NUMBER_116: 116,
    NUMBER_117: 117,
    NUMBER_118: 118,
    NUMBER_119: 119,
    NUMBER_120: 120,
    NUMBER_121: 121,
    NUMBER_122: 122,
    NUMBER_123: 123,
    NUMBER_124: 124,
    NUMBER_125: 125,
    NUMBER_126: 126,
    NUMBER_127: 127,
    NUMBER_128: 128,
    NUMBER_129: 129,
    NUMBER_130: 130,
    NUMBER_131: 131,
    NUMBER_132: 132,
    NUMBER_133: 133,
    NUMBER_134: 134,
    NUMBER_135: 135,
    NUMBER_136: 136,
    NUMBER_137: 137,
    NUMBER_138: 138,
    NUMBER_139: 139,
    NUMBER_140: 140,
    NUMBER_141: 141,
    NUMBER_142: 142,
    NUMBER_143: 143,
    NUMBER_144: 144,
    NUMBER_145: 145,
    NUMBER_146: 146,
    NUMBER_147: 147,
    NUMBER_148: 148,
    NUMBER_149: 149,
    NUMBER_150: 150,
    NUMBER_151: 151,
    NUMBER_152: 152,
    NUMBER_153: 153,
    NUMBER_154: 154,
    NUMBER_155: 155,
    NUMBER_156: 156,
    NUMBER_157: 157,
    NUMBER_158: 158,
    NUMBER_159: 159,
    NUMBER_160: 160,
    NUMBER_161: 161,
    NUMBER_162: 162,
    NUMBER_163: 163,
    NUMBER_164: 164,
    NUMBER_165: 165,
    NUMBER_166: 166,
    NUMBER_167: 167,
    NUMBER_168: 168,
    NUMBER_169: 169,
    NUMBER_170: 170,
    NUMBER_171: 171,
    NUMBER_172: 172,
    NUMBER_173: 173,
    NUMBER_174: 174,
    NUMBER_175: 175,
    NUMBER_176: 176,
    NUMBER_177: 177,
    NUMBER_178: 178,
    NUMBER_179: 179,
    NUMBER_180: 180,
    NUMBER_181: 181,
    NUMBER_182: 182,
    NUMBER_183: 183,
    NUMBER_184: 184,
    NUMBER_185: 185,
    NUMBER_186: 186,
    NUMBER_187: 187,
    NUMBER_188: 188,
    NUMBER_189: 189,
    NUMBER_190: 190,
    NUMBER_191: 191,
    NUMBER_192: 192,
    NUMBER_193: 193,
    NUMBER_194: 194,
    NUMBER_195: 195,
    NUMBER_196: 196,
    NUMBER_197: 197,
    NUMBER_198: 198,
    NUMBER_199: 199,
    NUMBER_200: 200,
    NUMBER_201: 201,
    NUMBER_202: 202,
    NUMBER_203: 203,
    NUMBER_204: 204,
    NUMBER_205: 205,
    NUMBER_206: 206,
    NUMBER_207: 207,
    NUMBER_208: 208,
    NUMBER_209: 209,
    NUMBER_210: 210,
    NUMBER_211: 211,
    NUMBER_1000: 1000,
    NUMBER_1001: 1001,
    NUMBER_1002: 1002,
    NUMBER_1003: 1003,
    NUMBER_1004: 1004,
    NUMBER_1005: 1005,
    NUMBER_1006: 1006,
    NUMBER_1007: 1007,
    NUMBER_1008: 1008,
    NUMBER_1009: 1009,
    NUMBER_1010: 1010,
    NUMBER_1011: 1011,
    NUMBER_1012: 1012,
    NUMBER_1013: 1013,
    NUMBER_1014: 1014,
    NUMBER_1015: 1015,
    NUMBER_1016: 1016,
    NUMBER_1017: 1017,
    NUMBER_1018: 1018,
    NUMBER_1019: 1019,
    NUMBER_1020: 1020,
    NUMBER_1021: 1021,
    NUMBER_1022: 1022,
    NUMBER_1023: 1023,
    NUMBER_1024: 1024,
    NUMBER_1025: 1025,
    NUMBER_1026: 1026,
    NUMBER_1027: 1027,
    NUMBER_1028: 1028,
    NUMBER_1029: 1029,
    NUMBER_1030: 1030,
    NUMBER_1031: 1031,
    NUMBER_1032: 1032,
    NUMBER_1033: 1033,
    NUMBER_1034: 1034,
    NUMBER_1035: 1035,
    NUMBER_1036: 1036,
    NUMBER_1037: 1037,
    NUMBER_1038: 1038,
    NUMBER_1039: 1039,
    NUMBER_1040: 1040,
    NUMBER_1041: 1041,
    NUMBER_1042: 1042,
    NUMBER_1043: 1043,
    NUMBER_1044: 1044,
    NUMBER_1045: 1045,
    NUMBER_1046: 1046,
    NUMBER_1047: 1047,
    NUMBER_1048: 1048,
    NUMBER_1049: 1049,
    NUMBER_1050: 1050,
    NUMBER_1051: 1051,
    NUMBER_1052: 1052,
    NUMBER_1053: 1053,
    NUMBER_1054: 1054,
    NUMBER_1055: 1055,
    NUMBER_1056: 1056,
    NUMBER_1057: 1057,
    NUMBER_1058: 1058,
    NUMBER_1059: 1059,
    NUMBER_1060: 1060,
    NUMBER_1061: 1061,
    NUMBER_1062: 1062,
    NUMBER_1063: 1063,
    NUMBER_1064: 1064,
    NUMBER_1065: 1065,
    NUMBER_1066: 1066,
    NUMBER_1067: 1067,
    NUMBER_1068: 1068,
    NUMBER_1069: 1069,
    NUMBER_1070: 1070,
    NUMBER_1071: 1071,
    NUMBER_1072: 1072,
    NUMBER_1073: 1073,
    NUMBER_1074: 1074,
    NUMBER_1075: 1075,
    NUMBER_1076: 1076,
    NUMBER_1077: 1077,
    NUMBER_1078: 1078,
    NUMBER_1079: 1079,
    NUMBER_1080: 1080,
    NUMBER_1081: 1081,
    NUMBER_1082: 1082,
    NUMBER_1083: 1083,
    NUMBER_1084: 1084,
    NUMBER_1085: 1085,
    NUMBER_1086: 1086,
    NUMBER_1087: 1087,
    NUMBER_1088: 1088,
    NUMBER_1089: 1089,
    NUMBER_1090: 1090,
    NUMBER_1091: 1091,
    NUMBER_1092: 1092,
    NUMBER_1093: 1093,
    NUMBER_1094: 1094,
    NUMBER_1095: 1095,
    NUMBER_1096: 1096,
    NUMBER_1097: 1097
} as const;

export type SecurityEvent = typeof SecurityEvent[keyof typeof SecurityEvent];


/**
 * 
 * @export
 * @interface SecurityEventLog
 */
export interface SecurityEventLog {
    /**
     * 
     * @type {number}
     * @memberof SecurityEventLog
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLog
     */
    'publicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLog
     */
    'timestampUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLog
     */
    'endTimestampUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLog
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLog
     */
    'userName'?: string | null;
    /**
     * 
     * @type {SecurityEvent}
     * @memberof SecurityEventLog
     */
    'securityEvent'?: SecurityEvent;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLog
     */
    'organization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLog
     */
    'tenantId'?: string;
    /**
     * 
     * @type {SecurityEventLogDetails}
     * @memberof SecurityEventLog
     */
    'details'?: SecurityEventLogDetails;
    /**
     * 
     * @type {number}
     * @memberof SecurityEventLog
     */
    'httpStatus'?: number;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLog
     */
    'xfxSession'?: string | null;
}


/**
 * 
 * @export
 * @interface SecurityEventLogChange
 */
export interface SecurityEventLogChange {
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLogChange
     */
    'name'?: string | null;
    /**
     * 
     * @type {SecurityEventLogChangeType}
     * @memberof SecurityEventLogChange
     */
    'type'?: SecurityEventLogChangeType;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLogChange
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLogChange
     */
    'newValue'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SecurityEventLogChangeType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type SecurityEventLogChangeType = typeof SecurityEventLogChangeType[keyof typeof SecurityEventLogChangeType];


/**
 * 
 * @export
 * @interface SecurityEventLogDetails
 */
export interface SecurityEventLogDetails {
    /**
     * 
     * @type {Array<SecurityEventLogChange>}
     * @memberof SecurityEventLogDetails
     */
    'changes'?: Array<SecurityEventLogChange> | null;
    /**
     * 
     * @type {Array<SecurityEventLogParameter>}
     * @memberof SecurityEventLogDetails
     */
    'parameters'?: Array<SecurityEventLogParameter> | null;
}
/**
 * 
 * @export
 * @interface SecurityEventLogParameter
 */
export interface SecurityEventLogParameter {
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLogParameter
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SecurityEventLogParameter
     */
    'value'?: string | null;
    /**
     * 
     * @type {SecurityEventLogParameterType}
     * @memberof SecurityEventLogParameter
     */
    'type'?: SecurityEventLogParameterType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SecurityEventLogParameterType = {
    NUMBER_0: 0
} as const;

export type SecurityEventLogParameterType = typeof SecurityEventLogParameterType[keyof typeof SecurityEventLogParameterType];


/**
 * 
 * @export
 * @interface SecurityLogSettings
 */
export interface SecurityLogSettings {
    /**
     * 
     * @type {Array<SecurityEvent>}
     * @memberof SecurityLogSettings
     */
    'selectedEvents'?: Array<SecurityEvent> | null;
}
/**
 * 
 * @export
 * @interface SelfInvoicingGrantedRM
 */
export interface SelfInvoicingGrantedRM {
    /**
     * 
     * @type {string}
     * @memberof SelfInvoicingGrantedRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelfInvoicingGrantedRM
     */
    'creationDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelfInvoicingGrantedRM
     */
    'grantedByNip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SelfInvoicingGrantedRM
     */
    'lastCheckUpDateUtc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SelfInvoicingGrantedRM
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface SelfInvoicingGrantedRMIEnumerableState
 */
export interface SelfInvoicingGrantedRMIEnumerableState {
    /**
     * 
     * @type {Array<string>}
     * @memberof SelfInvoicingGrantedRMIEnumerableState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof SelfInvoicingGrantedRMIEnumerableState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SelfInvoicingGrantedRMIEnumerableState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SelfInvoicingGrantedRMIEnumerableState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SelfInvoicingGrantedRMIEnumerableState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {Array<SelfInvoicingGrantedRM>}
     * @memberof SelfInvoicingGrantedRMIEnumerableState
     */
    'stateObject'?: Array<SelfInvoicingGrantedRM> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SelfinvoicingRestriction = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type SelfinvoicingRestriction = typeof SelfinvoicingRestriction[keyof typeof SelfinvoicingRestriction];


/**
 * 
 * @export
 * @interface SessionContext
 */
export interface SessionContext {
    /**
     * 
     * @type {ContextIdentifier}
     * @memberof SessionContext
     */
    'contextIdentifier'?: ContextIdentifier;
    /**
     * 
     * @type {ContextName}
     * @memberof SessionContext
     */
    'contextName'?: ContextName;
    /**
     * 
     * @type {Array<CredentialsRoleResponseTokenType>}
     * @memberof SessionContext
     */
    'credentialsRoleList'?: Array<CredentialsRoleResponseTokenType> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SessionKind = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type SessionKind = typeof SessionKind[keyof typeof SessionKind];


/**
 * 
 * @export
 * @interface SessionToken
 */
export interface SessionToken {
    /**
     * 
     * @type {SessionContext}
     * @memberof SessionToken
     */
    'context'?: SessionContext;
    /**
     * 
     * @type {string}
     * @memberof SessionToken
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Setting
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof Setting
     */
    'tenant'?: Tenant;
}
/**
 * 
 * @export
 * @interface Settings
 */
export interface Settings {
    /**
     * 
     * @type {OrganizationContext}
     * @memberof Settings
     */
    'organizationContext'?: OrganizationContext;
    /**
     * 
     * @type {string}
     * @memberof Settings
     */
    'language'?: string | null;
    /**
     * 
     * @type {AutoRefresh}
     * @memberof Settings
     */
    'autoRefresh'?: AutoRefresh;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof Settings
     */
    'columnsToSort'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof Settings
     */
    'columnsVisible'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof Settings
     */
    'columnsFixed'?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof Settings
     */
    'emptySorting'?: { [key: string]: boolean; } | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof Settings
     */
    'columnsAvailableInChooser'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof Settings
     */
    'visibleIndex'?: { [key: string]: { [key: string]: string; }; } | null;
}
/**
 * 
 * @export
 * @interface SignedChallengeResponse
 */
export interface SignedChallengeResponse {
    /**
     * 
     * @type {string}
     * @memberof SignedChallengeResponse
     */
    'data'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignedChallengeResponse
     */
    'fileName'?: string | null;
}
/**
 * 
 * @export
 * @interface SignedChallengeResponseState
 */
export interface SignedChallengeResponseState {
    /**
     * 
     * @type {Array<string>}
     * @memberof SignedChallengeResponseState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof SignedChallengeResponseState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SignedChallengeResponseState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SignedChallengeResponseState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SignedChallengeResponseState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {SignedChallengeResponse}
     * @memberof SignedChallengeResponseState
     */
    'stateObject'?: SignedChallengeResponse;
}
/**
 * 
 * @export
 * @interface State
 */
export interface State {
    /**
     * 
     * @type {Array<string>}
     * @memberof State
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof State
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof State
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof State
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof State
     */
    'stateObject'?: any | null;
}
/**
 * 
 * @export
 * @interface StringRestrictionsList
 */
export interface StringRestrictionsList {
    /**
     * 
     * @type {Array<string>}
     * @memberof StringRestrictionsList
     */
    'allowed'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StringRestrictionsList
     */
    'forbidden'?: Array<string> | null;
    /**
     * 
     * @type {ListType}
     * @memberof StringRestrictionsList
     */
    'listType'?: ListType;
}


/**
 * 
 * @export
 * @interface StringState
 */
export interface StringState {
    /**
     * 
     * @type {Array<string>}
     * @memberof StringState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof StringState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StringState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StringState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringState
     */
    'stateObject'?: string | null;
}
/**
 * 
 * @export
 * @interface StringStringKeyValuePair
 */
export interface StringStringKeyValuePair {
    /**
     * 
     * @type {string}
     * @memberof StringStringKeyValuePair
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringStringKeyValuePair
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface TagsRestrictionsSet
 */
export interface TagsRestrictionsSet {
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof TagsRestrictionsSet
     */
    'tag1'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof TagsRestrictionsSet
     */
    'tag2'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof TagsRestrictionsSet
     */
    'tag3'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof TagsRestrictionsSet
     */
    'tag4'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof TagsRestrictionsSet
     */
    'tag5'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof TagsRestrictionsSet
     */
    'tag6'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof TagsRestrictionsSet
     */
    'tag7'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof TagsRestrictionsSet
     */
    'tag8'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof TagsRestrictionsSet
     */
    'tag9'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof TagsRestrictionsSet
     */
    'tag10'?: StringRestrictionsList;
}
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'legalForm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'nip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'regon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'krs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<AccessToken>}
     * @memberof Tenant
     */
    'accessTokens'?: Array<AccessToken> | null;
    /**
     * 
     * @type {Array<AccountingSystem>}
     * @memberof Tenant
     */
    'accountingSystems'?: Array<AccountingSystem> | null;
    /**
     * 
     * @type {Array<Contractor>}
     * @memberof Tenant
     */
    'contractors'?: Array<Contractor> | null;
    /**
     * 
     * @type {Array<ContractorAddress>}
     * @memberof Tenant
     */
    'contractorAddresses'?: Array<ContractorAddress> | null;
    /**
     * 
     * @type {Array<DocumentTemplate>}
     * @memberof Tenant
     */
    'documentTemplates'?: Array<DocumentTemplate> | null;
    /**
     * 
     * @type {Array<ExitPath>}
     * @memberof Tenant
     */
    'exitPaths'?: Array<ExitPath> | null;
    /**
     * 
     * @type {Array<KsefCredentialsRoleSyncStatus>}
     * @memberof Tenant
     */
    'ksefCredentialsRoleSyncStatuses'?: Array<KsefCredentialsRoleSyncStatus> | null;
    /**
     * 
     * @type {Array<KsefCredentialsRoleWithStatus>}
     * @memberof Tenant
     */
    'ksefCredentialsRoleWithStatuses'?: Array<KsefCredentialsRoleWithStatus> | null;
    /**
     * 
     * @type {Array<KsefCredentialsToken>}
     * @memberof Tenant
     */
    'ksefCredentialsTokens'?: Array<KsefCredentialsToken> | null;
    /**
     * 
     * @type {Array<KsefSelfInvoicingGranted>}
     * @memberof Tenant
     */
    'ksefSelfInvoicingGranted'?: Array<KsefSelfInvoicingGranted> | null;
    /**
     * 
     * @type {Array<LicenseRecord>}
     * @memberof Tenant
     */
    'licenseRecords'?: Array<LicenseRecord> | null;
    /**
     * 
     * @type {Array<ModuleUsage>}
     * @memberof Tenant
     */
    'moduleUsages'?: Array<ModuleUsage> | null;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof Tenant
     */
    'organizations'?: Array<Organization> | null;
    /**
     * 
     * @type {Array<OrganizationAccountingSystem>}
     * @memberof Tenant
     */
    'organizationAccountingSystems'?: Array<OrganizationAccountingSystem> | null;
    /**
     * 
     * @type {Array<OrganizationAddress>}
     * @memberof Tenant
     */
    'organizationAddresses'?: Array<OrganizationAddress> | null;
    /**
     * 
     * @type {Array<OrganizationBankAccount>}
     * @memberof Tenant
     */
    'organizationBankAccounts'?: Array<OrganizationBankAccount> | null;
    /**
     * 
     * @type {Array<OrganizationDocumentTemplate>}
     * @memberof Tenant
     */
    'organizationDocumentTemplates'?: Array<OrganizationDocumentTemplate> | null;
    /**
     * 
     * @type {Array<OrganizationSyncSetting>}
     * @memberof Tenant
     */
    'organizationSyncSettings'?: Array<OrganizationSyncSetting> | null;
    /**
     * 
     * @type {Array<Pipeline>}
     * @memberof Tenant
     */
    'pipelines'?: Array<Pipeline> | null;
    /**
     * 
     * @type {Array<Role2>}
     * @memberof Tenant
     */
    'role2s'?: Array<Role2> | null;
    /**
     * 
     * @type {Array<Setting>}
     * @memberof Tenant
     */
    'settings'?: Array<Setting> | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof Tenant
     */
    'users'?: Array<User> | null;
    /**
     * 
     * @type {Array<UserSettings>}
     * @memberof Tenant
     */
    'userSettingss'?: Array<UserSettings> | null;
    /**
     * 
     * @type {Array<NipList>}
     * @memberof Tenant
     */
    'nipLists'?: Array<NipList> | null;
    /**
     * 
     * @type {Array<NipListElement>}
     * @memberof Tenant
     */
    'nipListElements'?: Array<NipListElement> | null;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'modificationDateUtc': string;
}
/**
 * 
 * @export
 * @interface TenantInfoDto
 */
export interface TenantInfoDto {
    /**
     * 
     * @type {string}
     * @memberof TenantInfoDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantInfoDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface TokenByPrivsRequest
 */
export interface TokenByPrivsRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenByPrivsRequest
     */
    'nipScopeToAuthorizeFor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenByPrivsRequest
     */
    'nipOrPeselToAuthorizeAs'?: string | null;
    /**
     * 
     * @type {Array<RoleType>}
     * @memberof TokenByPrivsRequest
     */
    'requiredPrivileges'?: Array<RoleType> | null;
}
/**
 * 
 * @export
 * @interface UnsignedChallengeResponse
 */
export interface UnsignedChallengeResponse {
    /**
     * 
     * @type {string}
     * @memberof UnsignedChallengeResponse
     */
    'data'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnsignedChallengeResponse
     */
    'fileName'?: string | null;
}
/**
 * 
 * @export
 * @interface UnsignedChallengeResponseState
 */
export interface UnsignedChallengeResponseState {
    /**
     * 
     * @type {Array<string>}
     * @memberof UnsignedChallengeResponseState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UnsignedChallengeResponseState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnsignedChallengeResponseState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UnsignedChallengeResponseState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UnsignedChallengeResponseState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {UnsignedChallengeResponse}
     * @memberof UnsignedChallengeResponseState
     */
    'stateObject'?: UnsignedChallengeResponse;
}
/**
 * 
 * @export
 * @interface UpdateDashboardDto
 */
export interface UpdateDashboardDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDashboardDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDashboardDto
     */
    'xmlDefinition'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateMetadataDto
 */
export interface UpdateMetadataDto {
    /**
     * 
     * @type {MetadataKey}
     * @memberof UpdateMetadataDto
     */
    'key'?: MetadataKey;
    /**
     * 
     * @type {any}
     * @memberof UpdateMetadataDto
     */
    'value'?: any | null;
}


/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'oid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof User
     */
    'type'?: UserType;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'disabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'disableTimestampUtc'?: string | null;
    /**
     * 
     * @type {Array<Role2>}
     * @memberof User
     */
    'roles'?: Array<Role2> | null;
    /**
     * 
     * @type {Array<ReportAggregate>}
     * @memberof User
     */
    'reports'?: Array<ReportAggregate> | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof User
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'modificationDateUtc': string;
}


/**
 * 
 * @export
 * @interface UserPrivilegeWithRestrictionsDto
 */
export interface UserPrivilegeWithRestrictionsDto {
    /**
     * 
     * @type {TagsRestrictionsSet}
     * @memberof UserPrivilegeWithRestrictionsDto
     */
    'purchaseTags'?: TagsRestrictionsSet;
    /**
     * 
     * @type {TagsRestrictionsSet}
     * @memberof UserPrivilegeWithRestrictionsDto
     */
    'saleTags'?: TagsRestrictionsSet;
    /**
     * 
     * @type {number}
     * @memberof UserPrivilegeWithRestrictionsDto
     */
    'privilegeId'?: number;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof UserPrivilegeWithRestrictionsDto
     */
    'salesNips'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof UserPrivilegeWithRestrictionsDto
     */
    'purchaseNips'?: StringRestrictionsList;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPrivilegeWithRestrictionsDto
     */
    'roleNames'?: Array<string> | null;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof UserPrivilegeWithRestrictionsDto
     */
    'purchaseAccountingSystems'?: StringRestrictionsList;
    /**
     * 
     * @type {StringRestrictionsList}
     * @memberof UserPrivilegeWithRestrictionsDto
     */
    'salesAccountingSystems'?: StringRestrictionsList;
}
/**
 * 
 * @export
 * @interface UserRM
 */
export interface UserRM {
    /**
     * 
     * @type {string}
     * @memberof UserRM
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRM
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRM
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRM
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRM
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRM
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserRM
     */
    'typeValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRM
     */
    'token'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRM
     */
    'roleIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserRM
     */
    'roles'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRM
     */
    'admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRM
     */
    'disabled'?: boolean;
}
/**
 * 
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    'settings'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof UserSettings
     */
    'tenant'?: Tenant;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    'createdById': string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    'modificatedById': string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    'creationDateUtc': string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    'modificationDateUtc': string;
}
/**
 * 
 * @export
 * @interface UserSettingsDto
 */
export interface UserSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof UserSettingsDto
     */
    'userId'?: string;
    /**
     * 
     * @type {Settings}
     * @memberof UserSettingsDto
     */
    'settings'?: Settings;
}
/**
 * 
 * @export
 * @interface UserState
 */
export interface UserState {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UserState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {User}
     * @memberof UserState
     */
    'stateObject'?: User;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type UserType = typeof UserType[keyof typeof UserType];


/**
 * 
 * @export
 * @interface UserWithRolesResponse
 */
export interface UserWithRolesResponse {
    /**
     * 
     * @type {string}
     * @memberof UserWithRolesResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithRolesResponse
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRolesResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRolesResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRolesResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserWithRolesResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof UserWithRolesResponse
     */
    'type'?: UserType;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRolesResponse
     */
    'admin'?: boolean;
    /**
     * 
     * @type {Array<BasicRoleInfoResponse>}
     * @memberof UserWithRolesResponse
     */
    'userRoles'?: Array<BasicRoleInfoResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRolesResponse
     */
    'disabled'?: boolean;
}


/**
 * 
 * @export
 * @interface UserWithRolesResponseIEnumerableState
 */
export interface UserWithRolesResponseIEnumerableState {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserWithRolesResponseIEnumerableState
     */
    'errors'?: Array<string> | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UserWithRolesResponseIEnumerableState
     */
    'errorsDictionary'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserWithRolesResponseIEnumerableState
     */
    'messages'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRolesResponseIEnumerableState
     */
    'isNotValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithRolesResponseIEnumerableState
     */
    'isValid'?: boolean;
    /**
     * 
     * @type {Array<UserWithRolesResponse>}
     * @memberof UserWithRolesResponseIEnumerableState
     */
    'stateObject'?: Array<UserWithRolesResponse> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const XfxNotificationEvent = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8
} as const;

export type XfxNotificationEvent = typeof XfxNotificationEvent[keyof typeof XfxNotificationEvent];


/**
 * 
 * @export
 * @interface XfxProblemDetails
 */
export interface XfxProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {Array<string>}
     * @memberof XfxProblemDetails
     */
    'xfxDetails'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof XfxProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XfxProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof XfxProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XfxProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XfxProblemDetails
     */
    'instance'?: string | null;
}

/**
 * AccountingSystemApi - axios parameter creator
 * @export
 */
export const AccountingSystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Usuń system księgowy
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAccountingSystemDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdAccountingSystemDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdAccountingSystemDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/AccountingSystem`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edytuj system księgowy
         * @param {string} id 
         * @param {string} tenantId 
         * @param {AccountingSystemRequest} [accountingSystemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAccountingSystemIdPut: async (id: string, tenantId: string, accountingSystemRequest?: AccountingSystemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdAccountingSystemIdPut', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdAccountingSystemIdPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/AccountingSystem/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountingSystemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodaj system księgowy
         * @param {string} tenantId 
         * @param {AccountingSystemRequest} [accountingSystemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAccountingSystemPost: async (tenantId: string, accountingSystemRequest?: AccountingSystemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdAccountingSystemPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/AccountingSystem`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountingSystemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountingSystemApi - functional programming interface
 * @export
 */
export const AccountingSystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountingSystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Usuń system księgowy
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdAccountingSystemDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdAccountingSystemDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edytuj system księgowy
         * @param {string} id 
         * @param {string} tenantId 
         * @param {AccountingSystemRequest} [accountingSystemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdAccountingSystemIdPut(id: string, tenantId: string, accountingSystemRequest?: AccountingSystemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdAccountingSystemIdPut(id, tenantId, accountingSystemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dodaj system księgowy
         * @param {string} tenantId 
         * @param {AccountingSystemRequest} [accountingSystemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdAccountingSystemPost(tenantId: string, accountingSystemRequest?: AccountingSystemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdAccountingSystemPost(tenantId, accountingSystemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountingSystemApi - factory interface
 * @export
 */
export const AccountingSystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountingSystemApiFp(configuration)
    return {
        /**
         * 
         * @summary Usuń system księgowy
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAccountingSystemDelete(id: string, tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdAccountingSystemDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edytuj system księgowy
         * @param {string} id 
         * @param {string} tenantId 
         * @param {AccountingSystemRequest} [accountingSystemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAccountingSystemIdPut(id: string, tenantId: string, accountingSystemRequest?: AccountingSystemRequest, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdAccountingSystemIdPut(id, tenantId, accountingSystemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dodaj system księgowy
         * @param {string} tenantId 
         * @param {AccountingSystemRequest} [accountingSystemRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAccountingSystemPost(tenantId: string, accountingSystemRequest?: AccountingSystemRequest, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdAccountingSystemPost(tenantId, accountingSystemRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountingSystemApi - object-oriented interface
 * @export
 * @class AccountingSystemApi
 * @extends {BaseAPI}
 */
export class AccountingSystemApi extends BaseAPI {
    /**
     * 
     * @summary Usuń system księgowy
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingSystemApi
     */
    public apiTenantIdAccountingSystemDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return AccountingSystemApiFp(this.configuration).apiTenantIdAccountingSystemDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edytuj system księgowy
     * @param {string} id 
     * @param {string} tenantId 
     * @param {AccountingSystemRequest} [accountingSystemRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingSystemApi
     */
    public apiTenantIdAccountingSystemIdPut(id: string, tenantId: string, accountingSystemRequest?: AccountingSystemRequest, options?: AxiosRequestConfig) {
        return AccountingSystemApiFp(this.configuration).apiTenantIdAccountingSystemIdPut(id, tenantId, accountingSystemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dodaj system księgowy
     * @param {string} tenantId 
     * @param {AccountingSystemRequest} [accountingSystemRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingSystemApi
     */
    public apiTenantIdAccountingSystemPost(tenantId: string, accountingSystemRequest?: AccountingSystemRequest, options?: AxiosRequestConfig) {
        return AccountingSystemApiFp(this.configuration).apiTenantIdAccountingSystemPost(tenantId, accountingSystemRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountingSystemODataApi - axios parameter creator
 * @export
 */
export const AccountingSystemODataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAccountingSystemODataGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdAccountingSystemODataGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/AccountingSystemOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataAccountingSystemOData: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataAccountingSystemOData', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/AccountingSystemOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataAccountingSystemODataCount: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataAccountingSystemODataCount', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/AccountingSystemOData/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountingSystemODataApi - functional programming interface
 * @export
 */
export const AccountingSystemODataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountingSystemODataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdAccountingSystemODataGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountingSystemRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdAccountingSystemODataGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataAccountingSystemOData(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountingSystemRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataAccountingSystemOData(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataAccountingSystemODataCount(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountingSystemRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataAccountingSystemODataCount(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountingSystemODataApi - factory interface
 * @export
 */
export const AccountingSystemODataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountingSystemODataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAccountingSystemODataGet(tenantId: string, options?: any): AxiosPromise<Array<AccountingSystemRM>> {
            return localVarFp.apiTenantIdAccountingSystemODataGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataAccountingSystemOData(tenantId: string, options?: any): AxiosPromise<Array<AccountingSystemRM>> {
            return localVarFp.apiTenantIdOdataAccountingSystemOData(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataAccountingSystemODataCount(tenantId: string, options?: any): AxiosPromise<Array<AccountingSystemRM>> {
            return localVarFp.apiTenantIdOdataAccountingSystemODataCount(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountingSystemODataApi - object-oriented interface
 * @export
 * @class AccountingSystemODataApi
 * @extends {BaseAPI}
 */
export class AccountingSystemODataApi extends BaseAPI {
    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingSystemODataApi
     */
    public apiTenantIdAccountingSystemODataGet(tenantId: string, options?: AxiosRequestConfig) {
        return AccountingSystemODataApiFp(this.configuration).apiTenantIdAccountingSystemODataGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingSystemODataApi
     */
    public apiTenantIdOdataAccountingSystemOData(tenantId: string, options?: AxiosRequestConfig) {
        return AccountingSystemODataApiFp(this.configuration).apiTenantIdOdataAccountingSystemOData(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingSystemODataApi
     */
    public apiTenantIdOdataAccountingSystemODataCount(tenantId: string, options?: AxiosRequestConfig) {
        return AccountingSystemODataApiFp(this.configuration).apiTenantIdOdataAccountingSystemODataCount(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AliveApi - axios parameter creator
 * @export
 */
export const AliveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Endpoint providing simplified alive status.
         * @param {boolean} [checkElasticsearch] 
         * @param {boolean} [checkInvoiceApi] 
         * @param {boolean} [checkDb] 
         * @param {boolean} [checkCamunda] 
         * @param {number} [minBackgroundWorkersCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveGet: async (checkElasticsearch?: boolean, checkInvoiceApi?: boolean, checkDb?: boolean, checkCamunda?: boolean, minBackgroundWorkersCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Alive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (checkElasticsearch !== undefined) {
                localVarQueryParameter['CheckElasticsearch'] = checkElasticsearch;
            }

            if (checkInvoiceApi !== undefined) {
                localVarQueryParameter['CheckInvoiceApi'] = checkInvoiceApi;
            }

            if (checkDb !== undefined) {
                localVarQueryParameter['CheckDb'] = checkDb;
            }

            if (checkCamunda !== undefined) {
                localVarQueryParameter['CheckCamunda'] = checkCamunda;
            }

            if (minBackgroundWorkersCount !== undefined) {
                localVarQueryParameter['MinBackgroundWorkersCount'] = minBackgroundWorkersCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint providing full alive info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveReportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Alive/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Alive response endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveSimpleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Alive/simple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AliveApi - functional programming interface
 * @export
 */
export const AliveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AliveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Endpoint providing simplified alive status.
         * @param {boolean} [checkElasticsearch] 
         * @param {boolean} [checkInvoiceApi] 
         * @param {boolean} [checkDb] 
         * @param {boolean} [checkCamunda] 
         * @param {number} [minBackgroundWorkersCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAliveGet(checkElasticsearch?: boolean, checkInvoiceApi?: boolean, checkDb?: boolean, checkCamunda?: boolean, minBackgroundWorkersCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAliveGet(checkElasticsearch, checkInvoiceApi, checkDb, checkCamunda, minBackgroundWorkersCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Endpoint providing full alive info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAliveReportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAliveReportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Alive response endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAliveSimpleGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAliveSimpleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AliveApi - factory interface
 * @export
 */
export const AliveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AliveApiFp(configuration)
    return {
        /**
         * 
         * @summary Endpoint providing simplified alive status.
         * @param {boolean} [checkElasticsearch] 
         * @param {boolean} [checkInvoiceApi] 
         * @param {boolean} [checkDb] 
         * @param {boolean} [checkCamunda] 
         * @param {number} [minBackgroundWorkersCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveGet(checkElasticsearch?: boolean, checkInvoiceApi?: boolean, checkDb?: boolean, checkCamunda?: boolean, minBackgroundWorkersCount?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiAliveGet(checkElasticsearch, checkInvoiceApi, checkDb, checkCamunda, minBackgroundWorkersCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Endpoint providing full alive info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveReportGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiAliveReportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Alive response endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAliveSimpleGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiAliveSimpleGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AliveApi - object-oriented interface
 * @export
 * @class AliveApi
 * @extends {BaseAPI}
 */
export class AliveApi extends BaseAPI {
    /**
     * 
     * @summary Endpoint providing simplified alive status.
     * @param {boolean} [checkElasticsearch] 
     * @param {boolean} [checkInvoiceApi] 
     * @param {boolean} [checkDb] 
     * @param {boolean} [checkCamunda] 
     * @param {number} [minBackgroundWorkersCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AliveApi
     */
    public apiAliveGet(checkElasticsearch?: boolean, checkInvoiceApi?: boolean, checkDb?: boolean, checkCamunda?: boolean, minBackgroundWorkersCount?: number, options?: AxiosRequestConfig) {
        return AliveApiFp(this.configuration).apiAliveGet(checkElasticsearch, checkInvoiceApi, checkDb, checkCamunda, minBackgroundWorkersCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Endpoint providing full alive info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AliveApi
     */
    public apiAliveReportGet(options?: AxiosRequestConfig) {
        return AliveApiFp(this.configuration).apiAliveReportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Alive response endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AliveApi
     */
    public apiAliveSimpleGet(options?: AxiosRequestConfig) {
        return AliveApiFp(this.configuration).apiAliveSimpleGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [grantType] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAuthObtainTokenPost: async (tenantId: string, grantType?: string, clientId?: string, clientSecret?: string, refreshToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdAuthObtainTokenPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Auth/obtain-token`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (grantType !== undefined) { 
                localVarFormParams.append('grant_type', grantType as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.append('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.append('client_secret', clientSecret as any);
            }
    
            if (refreshToken !== undefined) { 
                localVarFormParams.append('refresh_token', refreshToken as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [grantType] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdAuthObtainTokenPost(tenantId: string, grantType?: string, clientId?: string, clientSecret?: string, refreshToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdAuthObtainTokenPost(tenantId, grantType, clientId, clientSecret, refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [grantType] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdAuthObtainTokenPost(tenantId: string, grantType?: string, clientId?: string, clientSecret?: string, refreshToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdAuthObtainTokenPost(tenantId, grantType, clientId, clientSecret, refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} [grantType] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiTenantIdAuthObtainTokenPost(tenantId: string, grantType?: string, clientId?: string, clientSecret?: string, refreshToken?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiTenantIdAuthObtainTokenPost(tenantId, grantType, clientId, clientSecret, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigImportApi - axios parameter creator
 * @export
 */
export const ConfigImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get imported configuration
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigImportFileGet: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdConfigImportFileGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/ConfigImport/file`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import configuration
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigImportPost: async (tenantId: string, file?: any, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdConfigImportPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/ConfigImport`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get imported configuration report
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigImportReportGet: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdConfigImportReportGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/ConfigImport/report`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigImportApi - functional programming interface
 * @export
 */
export const ConfigImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get imported configuration
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdConfigImportFileGet(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdConfigImportFileGet(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import configuration
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdConfigImportPost(tenantId: string, file?: any, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdConfigImportPost(tenantId, file, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get imported configuration report
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdConfigImportReportGet(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdConfigImportReportGet(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigImportApi - factory interface
 * @export
 */
export const ConfigImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigImportApiFp(configuration)
    return {
        /**
         * 
         * @summary Get imported configuration
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigImportFileGet(tenantId: string, id?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdConfigImportFileGet(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import configuration
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigImportPost(tenantId: string, file?: any, name?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdConfigImportPost(tenantId, file, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get imported configuration report
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigImportReportGet(tenantId: string, id?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdConfigImportReportGet(tenantId, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigImportApi - object-oriented interface
 * @export
 * @class ConfigImportApi
 * @extends {BaseAPI}
 */
export class ConfigImportApi extends BaseAPI {
    /**
     * 
     * @summary Get imported configuration
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigImportApi
     */
    public apiTenantIdConfigImportFileGet(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return ConfigImportApiFp(this.configuration).apiTenantIdConfigImportFileGet(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import configuration
     * @param {string} tenantId 
     * @param {any} [file] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigImportApi
     */
    public apiTenantIdConfigImportPost(tenantId: string, file?: any, name?: string, options?: AxiosRequestConfig) {
        return ConfigImportApiFp(this.configuration).apiTenantIdConfigImportPost(tenantId, file, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get imported configuration report
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigImportApi
     */
    public apiTenantIdConfigImportReportGet(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return ConfigImportApiFp(this.configuration).apiTenantIdConfigImportReportGet(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigImportODataApi - axios parameter creator
 * @export
 */
export const ConfigImportODataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigImportODataGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdConfigImportODataGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/ConfigImportOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataConfigImportOData: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataConfigImportOData', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/ConfigImportOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataConfigImportODataCount: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataConfigImportODataCount', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/ConfigImportOData/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigImportODataApi - functional programming interface
 * @export
 */
export const ConfigImportODataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigImportODataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdConfigImportODataGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportedConfigurationRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdConfigImportODataGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataConfigImportOData(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportedConfigurationRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataConfigImportOData(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataConfigImportODataCount(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImportedConfigurationRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataConfigImportODataCount(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigImportODataApi - factory interface
 * @export
 */
export const ConfigImportODataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigImportODataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdConfigImportODataGet(tenantId: string, options?: any): AxiosPromise<Array<ImportedConfigurationRM>> {
            return localVarFp.apiTenantIdConfigImportODataGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataConfigImportOData(tenantId: string, options?: any): AxiosPromise<Array<ImportedConfigurationRM>> {
            return localVarFp.apiTenantIdOdataConfigImportOData(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataConfigImportODataCount(tenantId: string, options?: any): AxiosPromise<Array<ImportedConfigurationRM>> {
            return localVarFp.apiTenantIdOdataConfigImportODataCount(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigImportODataApi - object-oriented interface
 * @export
 * @class ConfigImportODataApi
 * @extends {BaseAPI}
 */
export class ConfigImportODataApi extends BaseAPI {
    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigImportODataApi
     */
    public apiTenantIdConfigImportODataGet(tenantId: string, options?: AxiosRequestConfig) {
        return ConfigImportODataApiFp(this.configuration).apiTenantIdConfigImportODataGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigImportODataApi
     */
    public apiTenantIdOdataConfigImportOData(tenantId: string, options?: AxiosRequestConfig) {
        return ConfigImportODataApiFp(this.configuration).apiTenantIdOdataConfigImportOData(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigImportODataApi
     */
    public apiTenantIdOdataConfigImportODataCount(tenantId: string, options?: AxiosRequestConfig) {
        return ConfigImportODataApiFp(this.configuration).apiTenantIdOdataConfigImportODataCount(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractorApi - axios parameter creator
 * @export
 */
export const ContractorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Pobierz wszystkich kontrahentów
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorContractorsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdContractorContractorsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Contractor/Contractors`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Załaduj plik z kontrahentami
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorContractorsPost: async (organizationId: string, tenantId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdContractorContractorsPost', 'organizationId', organizationId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdContractorContractorsPost', 'tenantId', tenantId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiTenantIdContractorContractorsPost', 'file', file)
            const localVarPath = `/api/{tenantId}/Contractor/Contractors`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usuń Kontrahenta
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdContractorDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdContractorDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Contractor`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz informacje o kontrahencie
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorGet: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdContractorGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Contractor`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edytuj Kontrahenta
         * @param {string} id 
         * @param {string} tenantId 
         * @param {ContractorRequest} [contractorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorIdPut: async (id: string, tenantId: string, contractorRequest?: ContractorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdContractorIdPut', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdContractorIdPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Contractor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [organizationNip] 
         * @param {string} [contractorNip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorIsContractorGet: async (tenantId: string, organizationNip?: string, contractorNip?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdContractorIsContractorGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Contractor/is-contractor`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (organizationNip !== undefined) {
                localVarQueryParameter['organizationNip'] = organizationNip;
            }

            if (contractorNip !== undefined) {
                localVarQueryParameter['contractorNip'] = contractorNip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Utwórz Kontrahenta
         * @param {string} tenantId 
         * @param {ContractorRequest} [contractorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorPost: async (tenantId: string, contractorRequest?: ContractorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdContractorPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Contractor`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractorApi - functional programming interface
 * @export
 */
export const ContractorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Pobierz wszystkich kontrahentów
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdContractorContractorsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BasicContractorsInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdContractorContractorsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Załaduj plik z kontrahentami
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdContractorContractorsPost(organizationId: string, tenantId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdContractorContractorsPost(organizationId, tenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Usuń Kontrahenta
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdContractorDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdContractorDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz informacje o kontrahencie
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdContractorGet(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractorRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdContractorGet(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edytuj Kontrahenta
         * @param {string} id 
         * @param {string} tenantId 
         * @param {ContractorRequest} [contractorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdContractorIdPut(id: string, tenantId: string, contractorRequest?: ContractorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdContractorIdPut(id, tenantId, contractorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [organizationNip] 
         * @param {string} [contractorNip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdContractorIsContractorGet(tenantId: string, organizationNip?: string, contractorNip?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdContractorIsContractorGet(tenantId, organizationNip, contractorNip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Utwórz Kontrahenta
         * @param {string} tenantId 
         * @param {ContractorRequest} [contractorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdContractorPost(tenantId: string, contractorRequest?: ContractorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdContractorPost(tenantId, contractorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractorApi - factory interface
 * @export
 */
export const ContractorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractorApiFp(configuration)
    return {
        /**
         * 
         * @summary Pobierz wszystkich kontrahentów
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorContractorsGet(tenantId: string, options?: any): AxiosPromise<Array<BasicContractorsInfoResponse>> {
            return localVarFp.apiTenantIdContractorContractorsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Załaduj plik z kontrahentami
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorContractorsPost(organizationId: string, tenantId: string, file: any, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdContractorContractorsPost(organizationId, tenantId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Usuń Kontrahenta
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorDelete(id: string, tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdContractorDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz informacje o kontrahencie
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorGet(tenantId: string, id?: string, options?: any): AxiosPromise<ContractorRequest> {
            return localVarFp.apiTenantIdContractorGet(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edytuj Kontrahenta
         * @param {string} id 
         * @param {string} tenantId 
         * @param {ContractorRequest} [contractorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorIdPut(id: string, tenantId: string, contractorRequest?: ContractorRequest, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdContractorIdPut(id, tenantId, contractorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [organizationNip] 
         * @param {string} [contractorNip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorIsContractorGet(tenantId: string, organizationNip?: string, contractorNip?: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdContractorIsContractorGet(tenantId, organizationNip, contractorNip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Utwórz Kontrahenta
         * @param {string} tenantId 
         * @param {ContractorRequest} [contractorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdContractorPost(tenantId: string, contractorRequest?: ContractorRequest, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdContractorPost(tenantId, contractorRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractorApi - object-oriented interface
 * @export
 * @class ContractorApi
 * @extends {BaseAPI}
 */
export class ContractorApi extends BaseAPI {
    /**
     * 
     * @summary Pobierz wszystkich kontrahentów
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiTenantIdContractorContractorsGet(tenantId: string, options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).apiTenantIdContractorContractorsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Załaduj plik z kontrahentami
     * @param {string} organizationId 
     * @param {string} tenantId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiTenantIdContractorContractorsPost(organizationId: string, tenantId: string, file: any, options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).apiTenantIdContractorContractorsPost(organizationId, tenantId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Usuń Kontrahenta
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiTenantIdContractorDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).apiTenantIdContractorDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz informacje o kontrahencie
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiTenantIdContractorGet(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).apiTenantIdContractorGet(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edytuj Kontrahenta
     * @param {string} id 
     * @param {string} tenantId 
     * @param {ContractorRequest} [contractorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiTenantIdContractorIdPut(id: string, tenantId: string, contractorRequest?: ContractorRequest, options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).apiTenantIdContractorIdPut(id, tenantId, contractorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} [organizationNip] 
     * @param {string} [contractorNip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiTenantIdContractorIsContractorGet(tenantId: string, organizationNip?: string, contractorNip?: string, options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).apiTenantIdContractorIsContractorGet(tenantId, organizationNip, contractorNip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Utwórz Kontrahenta
     * @param {string} tenantId 
     * @param {ContractorRequest} [contractorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorApi
     */
    public apiTenantIdContractorPost(tenantId: string, contractorRequest?: ContractorRequest, options?: AxiosRequestConfig) {
        return ContractorApiFp(this.configuration).apiTenantIdContractorPost(tenantId, contractorRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardAvailableGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboard/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metadata value for given key
         * @param {string} [dashboardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardDefinitionGet: async (dashboardId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboard/definition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (dashboardId !== undefined) {
                localVarQueryParameter['dashboardId'] = dashboardId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set metadata value for given key
         * @param {CreateDashboardDto} [createDashboardDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardPost: async (createDashboardDto?: CreateDashboardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDashboardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {UpdateDashboardDto} [updateDashboardDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardPut: async (updateDashboardDto?: UpdateDashboardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDashboardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardAvailableGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardAvailableGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get metadata value for given key
         * @param {string} [dashboardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardDefinitionGet(dashboardId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardDefinitionGet(dashboardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set metadata value for given key
         * @param {CreateDashboardDto} [createDashboardDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardPost(createDashboardDto?: CreateDashboardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardPost(createDashboardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {UpdateDashboardDto} [updateDashboardDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardPut(updateDashboardDto?: UpdateDashboardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardPut(updateDashboardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardAvailableGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiDashboardAvailableGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metadata value for given key
         * @param {string} [dashboardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardDefinitionGet(dashboardId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDashboardDefinitionGet(dashboardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set metadata value for given key
         * @param {CreateDashboardDto} [createDashboardDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardPost(createDashboardDto?: CreateDashboardDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiDashboardPost(createDashboardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {UpdateDashboardDto} [updateDashboardDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardPut(updateDashboardDto?: UpdateDashboardDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiDashboardPut(updateDashboardDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardAvailableGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardAvailableGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metadata value for given key
     * @param {string} [dashboardId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardDefinitionGet(dashboardId?: string, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardDefinitionGet(dashboardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set metadata value for given key
     * @param {CreateDashboardDto} [createDashboardDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardPost(createDashboardDto?: CreateDashboardDto, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardPost(createDashboardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {UpdateDashboardDto} [updateDashboardDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardPut(updateDashboardDto?: UpdateDashboardDto, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardPut(updateDashboardDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictionaryApi - axios parameter creator
 * @export
 */
export const DictionaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of country codes beginning with Polish code
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryCountryCodesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryCountryCodesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Dictionary/country-codes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of eu countries prefixes beginning with polish prefix
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryEuCountryPrefixesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDictionaryEuCountryPrefixesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Dictionary/eu-country-prefixes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictionaryApi - functional programming interface
 * @export
 */
export const DictionaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictionaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of country codes beginning with Polish code
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryCountryCodesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryCountryCodesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of eu countries prefixes beginning with polish prefix
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDictionaryEuCountryPrefixesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDictionaryEuCountryPrefixesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictionaryApi - factory interface
 * @export
 */
export const DictionaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictionaryApiFp(configuration)
    return {
        /**
         * 
         * @summary List of country codes beginning with Polish code
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryCountryCodesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryCountryCodesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of eu countries prefixes beginning with polish prefix
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDictionaryEuCountryPrefixesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdDictionaryEuCountryPrefixesGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictionaryApi - object-oriented interface
 * @export
 * @class DictionaryApi
 * @extends {BaseAPI}
 */
export class DictionaryApi extends BaseAPI {
    /**
     * 
     * @summary List of country codes beginning with Polish code
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryCountryCodesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryCountryCodesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of eu countries prefixes beginning with polish prefix
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public apiTenantIdDictionaryEuCountryPrefixesGet(tenantId: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).apiTenantIdDictionaryEuCountryPrefixesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentTemplateApi - axios parameter creator
 * @export
 */
export const DocumentTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplateDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdDocumentTemplateDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDocumentTemplateDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/DocumentTemplate`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplateFileGet: async (tenantId: string, templateId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDocumentTemplateFileGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/DocumentTemplate/file`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} [name] 
         * @param {boolean} [isDispatch] 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {any} [file] 
         * @param {Array<string>} [organizationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplateIdPut: async (id: string, tenantId: string, name?: string, isDispatch?: boolean, invoiceKind?: InvoiceKind, file?: any, organizationIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdDocumentTemplateIdPut', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDocumentTemplateIdPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/DocumentTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (isDispatch !== undefined) { 
                localVarFormParams.append('IsDispatch', isDispatch as any);
            }
    
            if (invoiceKind !== undefined) { 
                localVarFormParams.append('InvoiceKind', new Blob([JSON.stringify(invoiceKind)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
                if (organizationIds) {
                organizationIds.forEach((element) => {
                    localVarFormParams.append('OrganizationIds', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [name] 
         * @param {boolean} [isDispatch] 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {any} [file] 
         * @param {Array<string>} [organizationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplatePost: async (tenantId: string, name?: string, isDispatch?: boolean, invoiceKind?: InvoiceKind, file?: any, organizationIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDocumentTemplatePost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/DocumentTemplate`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (isDispatch !== undefined) { 
                localVarFormParams.append('IsDispatch', isDispatch as any);
            }
    
            if (invoiceKind !== undefined) { 
                localVarFormParams.append('InvoiceKind', new Blob([JSON.stringify(invoiceKind)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
                if (organizationIds) {
                organizationIds.forEach((element) => {
                    localVarFormParams.append('OrganizationIds', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [vatNo] 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {boolean} [isDispatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplateTemplateGet: async (tenantId: string, vatNo?: string, invoiceKind?: InvoiceKind, isDispatch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDocumentTemplateTemplateGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/DocumentTemplate/template`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (vatNo !== undefined) {
                localVarQueryParameter['vatNo'] = vatNo;
            }

            if (invoiceKind !== undefined) {
                localVarQueryParameter['invoiceKind'] = invoiceKind;
            }

            if (isDispatch !== undefined) {
                localVarQueryParameter['isDispatch'] = isDispatch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplateApi - functional programming interface
 * @export
 */
export const DocumentTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDocumentTemplateDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDocumentTemplateDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDocumentTemplateFileGet(tenantId: string, templateId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDocumentTemplateFileGet(tenantId, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} [name] 
         * @param {boolean} [isDispatch] 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {any} [file] 
         * @param {Array<string>} [organizationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDocumentTemplateIdPut(id: string, tenantId: string, name?: string, isDispatch?: boolean, invoiceKind?: InvoiceKind, file?: any, organizationIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDocumentTemplateIdPut(id, tenantId, name, isDispatch, invoiceKind, file, organizationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [name] 
         * @param {boolean} [isDispatch] 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {any} [file] 
         * @param {Array<string>} [organizationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDocumentTemplatePost(tenantId: string, name?: string, isDispatch?: boolean, invoiceKind?: InvoiceKind, file?: any, organizationIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDocumentTemplatePost(tenantId, name, isDispatch, invoiceKind, file, organizationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [vatNo] 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {boolean} [isDispatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDocumentTemplateTemplateGet(tenantId: string, vatNo?: string, invoiceKind?: InvoiceKind, isDispatch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDocumentTemplateTemplateGet(tenantId, vatNo, invoiceKind, isDispatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentTemplateApi - factory interface
 * @export
 */
export const DocumentTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplateDelete(id: string, tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdDocumentTemplateDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplateFileGet(tenantId: string, templateId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdDocumentTemplateFileGet(tenantId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} [name] 
         * @param {boolean} [isDispatch] 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {any} [file] 
         * @param {Array<string>} [organizationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplateIdPut(id: string, tenantId: string, name?: string, isDispatch?: boolean, invoiceKind?: InvoiceKind, file?: any, organizationIds?: Array<string>, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdDocumentTemplateIdPut(id, tenantId, name, isDispatch, invoiceKind, file, organizationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [name] 
         * @param {boolean} [isDispatch] 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {any} [file] 
         * @param {Array<string>} [organizationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplatePost(tenantId: string, name?: string, isDispatch?: boolean, invoiceKind?: InvoiceKind, file?: any, organizationIds?: Array<string>, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdDocumentTemplatePost(tenantId, name, isDispatch, invoiceKind, file, organizationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [vatNo] 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {boolean} [isDispatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplateTemplateGet(tenantId: string, vatNo?: string, invoiceKind?: InvoiceKind, isDispatch?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdDocumentTemplateTemplateGet(tenantId, vatNo, invoiceKind, isDispatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTemplateApi - object-oriented interface
 * @export
 * @class DocumentTemplateApi
 * @extends {BaseAPI}
 */
export class DocumentTemplateApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateApi
     */
    public apiTenantIdDocumentTemplateDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return DocumentTemplateApiFp(this.configuration).apiTenantIdDocumentTemplateDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} [templateId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateApi
     */
    public apiTenantIdDocumentTemplateFileGet(tenantId: string, templateId?: string, options?: AxiosRequestConfig) {
        return DocumentTemplateApiFp(this.configuration).apiTenantIdDocumentTemplateFileGet(tenantId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {string} [name] 
     * @param {boolean} [isDispatch] 
     * @param {InvoiceKind} [invoiceKind] 
     * @param {any} [file] 
     * @param {Array<string>} [organizationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateApi
     */
    public apiTenantIdDocumentTemplateIdPut(id: string, tenantId: string, name?: string, isDispatch?: boolean, invoiceKind?: InvoiceKind, file?: any, organizationIds?: Array<string>, options?: AxiosRequestConfig) {
        return DocumentTemplateApiFp(this.configuration).apiTenantIdDocumentTemplateIdPut(id, tenantId, name, isDispatch, invoiceKind, file, organizationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} [name] 
     * @param {boolean} [isDispatch] 
     * @param {InvoiceKind} [invoiceKind] 
     * @param {any} [file] 
     * @param {Array<string>} [organizationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateApi
     */
    public apiTenantIdDocumentTemplatePost(tenantId: string, name?: string, isDispatch?: boolean, invoiceKind?: InvoiceKind, file?: any, organizationIds?: Array<string>, options?: AxiosRequestConfig) {
        return DocumentTemplateApiFp(this.configuration).apiTenantIdDocumentTemplatePost(tenantId, name, isDispatch, invoiceKind, file, organizationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} [vatNo] 
     * @param {InvoiceKind} [invoiceKind] 
     * @param {boolean} [isDispatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateApi
     */
    public apiTenantIdDocumentTemplateTemplateGet(tenantId: string, vatNo?: string, invoiceKind?: InvoiceKind, isDispatch?: boolean, options?: AxiosRequestConfig) {
        return DocumentTemplateApiFp(this.configuration).apiTenantIdDocumentTemplateTemplateGet(tenantId, vatNo, invoiceKind, isDispatch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentTemplateODataApi - axios parameter creator
 * @export
 */
export const DocumentTemplateODataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplateODataGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdDocumentTemplateODataGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/DocumentTemplateOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataDocumentTemplateOData: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataDocumentTemplateOData', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/DocumentTemplateOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataDocumentTemplateODataCount: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataDocumentTemplateODataCount', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/DocumentTemplateOData/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplateODataApi - functional programming interface
 * @export
 */
export const DocumentTemplateODataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentTemplateODataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdDocumentTemplateODataGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentTemplateRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdDocumentTemplateODataGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataDocumentTemplateOData(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentTemplateRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataDocumentTemplateOData(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataDocumentTemplateODataCount(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentTemplateRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataDocumentTemplateODataCount(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentTemplateODataApi - factory interface
 * @export
 */
export const DocumentTemplateODataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentTemplateODataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdDocumentTemplateODataGet(tenantId: string, options?: any): AxiosPromise<Array<DocumentTemplateRM>> {
            return localVarFp.apiTenantIdDocumentTemplateODataGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataDocumentTemplateOData(tenantId: string, options?: any): AxiosPromise<Array<DocumentTemplateRM>> {
            return localVarFp.apiTenantIdOdataDocumentTemplateOData(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataDocumentTemplateODataCount(tenantId: string, options?: any): AxiosPromise<Array<DocumentTemplateRM>> {
            return localVarFp.apiTenantIdOdataDocumentTemplateODataCount(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTemplateODataApi - object-oriented interface
 * @export
 * @class DocumentTemplateODataApi
 * @extends {BaseAPI}
 */
export class DocumentTemplateODataApi extends BaseAPI {
    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateODataApi
     */
    public apiTenantIdDocumentTemplateODataGet(tenantId: string, options?: AxiosRequestConfig) {
        return DocumentTemplateODataApiFp(this.configuration).apiTenantIdDocumentTemplateODataGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateODataApi
     */
    public apiTenantIdOdataDocumentTemplateOData(tenantId: string, options?: AxiosRequestConfig) {
        return DocumentTemplateODataApiFp(this.configuration).apiTenantIdOdataDocumentTemplateOData(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplateODataApi
     */
    public apiTenantIdOdataDocumentTemplateODataCount(tenantId: string, options?: AxiosRequestConfig) {
        return DocumentTemplateODataApiFp(this.configuration).apiTenantIdOdataDocumentTemplateODataCount(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExitPathApi - axios parameter creator
 * @export
 */
export const ExitPathApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary GetExitPathConfig
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {boolean} [isDispatch] 
         * @param {string} [fkSystem] 
         * @param {number} [eventType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdExitPathConfigGet: async (tenantId: string, nip?: string, isDispatch?: boolean, fkSystem?: string, eventType?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdExitPathConfigGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/ExitPath/config`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (nip !== undefined) {
                localVarQueryParameter['nip'] = nip;
            }

            if (isDispatch !== undefined) {
                localVarQueryParameter['isDispatch'] = isDispatch;
            }

            if (fkSystem !== undefined) {
                localVarQueryParameter['fkSystem'] = fkSystem;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdExitPathGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdExitPathGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/ExitPath`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdExitPathIdDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdExitPathIdDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdExitPathIdDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/ExitPath/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post
         * @param {string} tenantId 
         * @param {AddExitPathRequest} [addExitPathRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdExitPathPost: async (tenantId: string, addExitPathRequest?: AddExitPathRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdExitPathPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/ExitPath`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addExitPathRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put
         * @param {string} tenantId 
         * @param {EditExitPathRequest} [editExitPathRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdExitPathPut: async (tenantId: string, editExitPathRequest?: EditExitPathRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdExitPathPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/ExitPath`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editExitPathRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExitPathApi - functional programming interface
 * @export
 */
export const ExitPathApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExitPathApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary GetExitPathConfig
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {boolean} [isDispatch] 
         * @param {string} [fkSystem] 
         * @param {number} [eventType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdExitPathConfigGet(tenantId: string, nip?: string, isDispatch?: boolean, fkSystem?: string, eventType?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExitPath>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdExitPathConfigGet(tenantId, nip, isDispatch, fkSystem, eventType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdExitPathGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExitPathRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdExitPathGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdExitPathIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdExitPathIdDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post
         * @param {string} tenantId 
         * @param {AddExitPathRequest} [addExitPathRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdExitPathPost(tenantId: string, addExitPathRequest?: AddExitPathRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdExitPathPost(tenantId, addExitPathRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put
         * @param {string} tenantId 
         * @param {EditExitPathRequest} [editExitPathRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdExitPathPut(tenantId: string, editExitPathRequest?: EditExitPathRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdExitPathPut(tenantId, editExitPathRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExitPathApi - factory interface
 * @export
 */
export const ExitPathApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExitPathApiFp(configuration)
    return {
        /**
         * 
         * @summary GetExitPathConfig
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {boolean} [isDispatch] 
         * @param {string} [fkSystem] 
         * @param {number} [eventType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdExitPathConfigGet(tenantId: string, nip?: string, isDispatch?: boolean, fkSystem?: string, eventType?: number, options?: any): AxiosPromise<Array<ExitPath>> {
            return localVarFp.apiTenantIdExitPathConfigGet(tenantId, nip, isDispatch, fkSystem, eventType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdExitPathGet(tenantId: string, options?: any): AxiosPromise<Array<ExitPathRM>> {
            return localVarFp.apiTenantIdExitPathGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdExitPathIdDelete(id: string, tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdExitPathIdDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post
         * @param {string} tenantId 
         * @param {AddExitPathRequest} [addExitPathRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdExitPathPost(tenantId: string, addExitPathRequest?: AddExitPathRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdExitPathPost(tenantId, addExitPathRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put
         * @param {string} tenantId 
         * @param {EditExitPathRequest} [editExitPathRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdExitPathPut(tenantId: string, editExitPathRequest?: EditExitPathRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdExitPathPut(tenantId, editExitPathRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExitPathApi - object-oriented interface
 * @export
 * @class ExitPathApi
 * @extends {BaseAPI}
 */
export class ExitPathApi extends BaseAPI {
    /**
     * 
     * @summary GetExitPathConfig
     * @param {string} tenantId 
     * @param {string} [nip] 
     * @param {boolean} [isDispatch] 
     * @param {string} [fkSystem] 
     * @param {number} [eventType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExitPathApi
     */
    public apiTenantIdExitPathConfigGet(tenantId: string, nip?: string, isDispatch?: boolean, fkSystem?: string, eventType?: number, options?: AxiosRequestConfig) {
        return ExitPathApiFp(this.configuration).apiTenantIdExitPathConfigGet(tenantId, nip, isDispatch, fkSystem, eventType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExitPathApi
     */
    public apiTenantIdExitPathGet(tenantId: string, options?: AxiosRequestConfig) {
        return ExitPathApiFp(this.configuration).apiTenantIdExitPathGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExitPathApi
     */
    public apiTenantIdExitPathIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return ExitPathApiFp(this.configuration).apiTenantIdExitPathIdDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post
     * @param {string} tenantId 
     * @param {AddExitPathRequest} [addExitPathRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExitPathApi
     */
    public apiTenantIdExitPathPost(tenantId: string, addExitPathRequest?: AddExitPathRequest, options?: AxiosRequestConfig) {
        return ExitPathApiFp(this.configuration).apiTenantIdExitPathPost(tenantId, addExitPathRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put
     * @param {string} tenantId 
     * @param {EditExitPathRequest} [editExitPathRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExitPathApi
     */
    public apiTenantIdExitPathPut(tenantId: string, editExitPathRequest?: EditExitPathRequest, options?: AxiosRequestConfig) {
        return ExitPathApiFp(this.configuration).apiTenantIdExitPathPut(tenantId, editExitPathRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download file by id
         * @param {string} tenantId 
         * @param {string} [ksefReferenceNumber] 
         * @param {FileSource} [fileSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdFileGet: async (tenantId: string, ksefReferenceNumber?: string, fileSource?: FileSource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdFileGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/File`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (ksefReferenceNumber !== undefined) {
                localVarQueryParameter['ksefReferenceNumber'] = ksefReferenceNumber;
            }

            if (fileSource !== undefined) {
                localVarQueryParameter['fileSource'] = fileSource;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download file by id
         * @param {string} tenantId 
         * @param {string} [ksefReferenceNumber] 
         * @param {FileSource} [fileSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdFileGet(tenantId: string, ksefReferenceNumber?: string, fileSource?: FileSource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdFileGet(tenantId, ksefReferenceNumber, fileSource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Download file by id
         * @param {string} tenantId 
         * @param {string} [ksefReferenceNumber] 
         * @param {FileSource} [fileSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdFileGet(tenantId: string, ksefReferenceNumber?: string, fileSource?: FileSource, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdFileGet(tenantId, ksefReferenceNumber, fileSource, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Download file by id
     * @param {string} tenantId 
     * @param {string} [ksefReferenceNumber] 
     * @param {FileSource} [fileSource] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiTenantIdFileGet(tenantId: string, ksefReferenceNumber?: string, fileSource?: FileSource, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiTenantIdFileGet(tenantId, ksefReferenceNumber, fileSource, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns matching by name basic business entity info
         * @param {string} [name] 
         * @param {boolean} [useMapping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInfoBusinessEntityGet: async (name?: string, useMapping?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Info/BusinessEntity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (useMapping !== undefined) {
                localVarQueryParameter['useMapping'] = useMapping;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get authorization mode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns available tenants for authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInfoTenantsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Info/Tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns matching by name basic business entity info
         * @param {string} [name] 
         * @param {boolean} [useMapping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInfoBusinessEntityGet(name?: string, useMapping?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationGuidsNIPResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInfoBusinessEntityGet(name, useMapping, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get authorization mode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KsefInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns available tenants for authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInfoTenantsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInfoTenantsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns matching by name basic business entity info
         * @param {string} [name] 
         * @param {boolean} [useMapping] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInfoBusinessEntityGet(name?: string, useMapping?: boolean, options?: any): AxiosPromise<OrganizationGuidsNIPResponse> {
            return localVarFp.apiInfoBusinessEntityGet(name, useMapping, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get authorization mode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInfoGet(options?: any): AxiosPromise<KsefInfo> {
            return localVarFp.apiInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns available tenants for authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInfoTenantsGet(options?: any): AxiosPromise<Array<TenantInfoDto>> {
            return localVarFp.apiInfoTenantsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @summary Returns matching by name basic business entity info
     * @param {string} [name] 
     * @param {boolean} [useMapping] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public apiInfoBusinessEntityGet(name?: string, useMapping?: boolean, options?: AxiosRequestConfig) {
        return InfoApiFp(this.configuration).apiInfoBusinessEntityGet(name, useMapping, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get authorization mode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public apiInfoGet(options?: AxiosRequestConfig) {
        return InfoApiFp(this.configuration).apiInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns available tenants for authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public apiInfoTenantsGet(options?: AxiosRequestConfig) {
        return InfoApiFp(this.configuration).apiInfoTenantsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Info2Api - axios parameter creator
 * @export
 */
export const Info2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary UserContext
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdInfo2UserContextGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdInfo2UserContextGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Info2/UserContext`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Info2Api - functional programming interface
 * @export
 */
export const Info2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Info2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary UserContext
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdInfo2UserContextGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdInfo2UserContextGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Info2Api - factory interface
 * @export
 */
export const Info2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Info2ApiFp(configuration)
    return {
        /**
         * 
         * @summary UserContext
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdInfo2UserContextGet(tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdInfo2UserContextGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Info2Api - object-oriented interface
 * @export
 * @class Info2Api
 * @extends {BaseAPI}
 */
export class Info2Api extends BaseAPI {
    /**
     * 
     * @summary UserContext
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Info2Api
     */
    public apiTenantIdInfo2UserContextGet(tenantId: string, options?: AxiosRequestConfig) {
        return Info2ApiFp(this.configuration).apiTenantIdInfo2UserContextGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a list of self invoicing grants with information if grant is active and last checkup time in CET/CEST.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdIntegrationSelfInvoicingGrantsGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdIntegrationSelfInvoicingGrantsGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdIntegrationSelfInvoicingGrantsGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/Integration/self-invoicing-grants`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns a list of self invoicing grants with information if grant is active and last checkup time in CET/CEST.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdIntegrationSelfInvoicingGrantsGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdIntegrationSelfInvoicingGrantsGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns a list of self invoicing grants with information if grant is active and last checkup time in CET/CEST.
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdIntegrationSelfInvoicingGrantsGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdIntegrationSelfInvoicingGrantsGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI {
    /**
     * 
     * @summary Returns a list of self invoicing grants with information if grant is active and last checkup time in CET/CEST.
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public apiTenantIdOrganizationIdIntegrationSelfInvoicingGrantsGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).apiTenantIdOrganizationIdIntegrationSelfInvoicingGrantsGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KsefAuthApi - axios parameter creator
 * @export
 */
export const KsefAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Is autosign for authchallenge enabled (for testing convenience)
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefAuthAutosignGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdKsefAuthAutosignGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/KsefAuth/Autosign`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create unsigned Auth Challenge
         * @param {string} tenantId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefAuthGenerateChallengeToSignPost: async (tenantId: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdKsefAuthGenerateChallengeToSignPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/KsefAuth/GenerateChallengeToSign`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary InitSessionByTokenId
         * @param {string} tokenId 
         * @param {string} nipScopeToAuthorizeFor 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefAuthInitSessionByTokenIdPost: async (tokenId: string, nipScopeToAuthorizeFor: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('apiTenantIdKsefAuthInitSessionByTokenIdPost', 'tokenId', tokenId)
            // verify required parameter 'nipScopeToAuthorizeFor' is not null or undefined
            assertParamExists('apiTenantIdKsefAuthInitSessionByTokenIdPost', 'nipScopeToAuthorizeFor', nipScopeToAuthorizeFor)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdKsefAuthInitSessionByTokenIdPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/KsefAuth/InitSessionByTokenId`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (tokenId !== undefined) {
                localVarQueryParameter['tokenId'] = tokenId;
            }

            if (nipScopeToAuthorizeFor !== undefined) {
                localVarQueryParameter['nipScopeToAuthorizeFor'] = nipScopeToAuthorizeFor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary InitSessionByToken
         * @param {string} authToken 
         * @param {string} nipScopeToAuthorizeFor 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefAuthInitSessionByTokenPost: async (authToken: string, nipScopeToAuthorizeFor: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authToken' is not null or undefined
            assertParamExists('apiTenantIdKsefAuthInitSessionByTokenPost', 'authToken', authToken)
            // verify required parameter 'nipScopeToAuthorizeFor' is not null or undefined
            assertParamExists('apiTenantIdKsefAuthInitSessionByTokenPost', 'nipScopeToAuthorizeFor', nipScopeToAuthorizeFor)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdKsefAuthInitSessionByTokenPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/KsefAuth/InitSessionByToken`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (authToken !== undefined) {
                localVarQueryParameter['authToken'] = authToken;
            }

            if (nipScopeToAuthorizeFor !== undefined) {
                localVarQueryParameter['nipScopeToAuthorizeFor'] = nipScopeToAuthorizeFor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary InitSession
         * @param {string} tenantId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefAuthInitSessionPost: async (tenantId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdKsefAuthInitSessionPost', 'tenantId', tenantId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiTenantIdKsefAuthInitSessionPost', 'file', file)
            const localVarPath = `/api/{tenantId}/KsefAuth/InitSession`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KsefAuthApi - functional programming interface
 * @export
 */
export const KsefAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KsefAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Is autosign for authchallenge enabled (for testing convenience)
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdKsefAuthAutosignGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdKsefAuthAutosignGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create unsigned Auth Challenge
         * @param {string} tenantId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdKsefAuthGenerateChallengeToSignPost(tenantId: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdKsefAuthGenerateChallengeToSignPost(tenantId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary InitSessionByTokenId
         * @param {string} tokenId 
         * @param {string} nipScopeToAuthorizeFor 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdKsefAuthInitSessionByTokenIdPost(tokenId: string, nipScopeToAuthorizeFor: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdKsefAuthInitSessionByTokenIdPost(tokenId, nipScopeToAuthorizeFor, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary InitSessionByToken
         * @param {string} authToken 
         * @param {string} nipScopeToAuthorizeFor 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdKsefAuthInitSessionByTokenPost(authToken: string, nipScopeToAuthorizeFor: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdKsefAuthInitSessionByTokenPost(authToken, nipScopeToAuthorizeFor, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary InitSession
         * @param {string} tenantId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdKsefAuthInitSessionPost(tenantId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitSignedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdKsefAuthInitSessionPost(tenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KsefAuthApi - factory interface
 * @export
 */
export const KsefAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KsefAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Is autosign for authchallenge enabled (for testing convenience)
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefAuthAutosignGet(tenantId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiTenantIdKsefAuthAutosignGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create unsigned Auth Challenge
         * @param {string} tenantId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefAuthGenerateChallengeToSignPost(tenantId: string, body?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiTenantIdKsefAuthGenerateChallengeToSignPost(tenantId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary InitSessionByTokenId
         * @param {string} tokenId 
         * @param {string} nipScopeToAuthorizeFor 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefAuthInitSessionByTokenIdPost(tokenId: string, nipScopeToAuthorizeFor: string, tenantId: string, options?: any): AxiosPromise<InitTokenResponse> {
            return localVarFp.apiTenantIdKsefAuthInitSessionByTokenIdPost(tokenId, nipScopeToAuthorizeFor, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary InitSessionByToken
         * @param {string} authToken 
         * @param {string} nipScopeToAuthorizeFor 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefAuthInitSessionByTokenPost(authToken: string, nipScopeToAuthorizeFor: string, tenantId: string, options?: any): AxiosPromise<InitTokenResponse> {
            return localVarFp.apiTenantIdKsefAuthInitSessionByTokenPost(authToken, nipScopeToAuthorizeFor, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary InitSession
         * @param {string} tenantId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefAuthInitSessionPost(tenantId: string, file: any, options?: any): AxiosPromise<InitSignedResponse> {
            return localVarFp.apiTenantIdKsefAuthInitSessionPost(tenantId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KsefAuthApi - object-oriented interface
 * @export
 * @class KsefAuthApi
 * @extends {BaseAPI}
 */
export class KsefAuthApi extends BaseAPI {
    /**
     * 
     * @summary Is autosign for authchallenge enabled (for testing convenience)
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefAuthApi
     */
    public apiTenantIdKsefAuthAutosignGet(tenantId: string, options?: AxiosRequestConfig) {
        return KsefAuthApiFp(this.configuration).apiTenantIdKsefAuthAutosignGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create unsigned Auth Challenge
     * @param {string} tenantId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefAuthApi
     */
    public apiTenantIdKsefAuthGenerateChallengeToSignPost(tenantId: string, body?: string, options?: AxiosRequestConfig) {
        return KsefAuthApiFp(this.configuration).apiTenantIdKsefAuthGenerateChallengeToSignPost(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary InitSessionByTokenId
     * @param {string} tokenId 
     * @param {string} nipScopeToAuthorizeFor 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefAuthApi
     */
    public apiTenantIdKsefAuthInitSessionByTokenIdPost(tokenId: string, nipScopeToAuthorizeFor: string, tenantId: string, options?: AxiosRequestConfig) {
        return KsefAuthApiFp(this.configuration).apiTenantIdKsefAuthInitSessionByTokenIdPost(tokenId, nipScopeToAuthorizeFor, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary InitSessionByToken
     * @param {string} authToken 
     * @param {string} nipScopeToAuthorizeFor 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefAuthApi
     */
    public apiTenantIdKsefAuthInitSessionByTokenPost(authToken: string, nipScopeToAuthorizeFor: string, tenantId: string, options?: AxiosRequestConfig) {
        return KsefAuthApiFp(this.configuration).apiTenantIdKsefAuthInitSessionByTokenPost(authToken, nipScopeToAuthorizeFor, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary InitSession
     * @param {string} tenantId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefAuthApi
     */
    public apiTenantIdKsefAuthInitSessionPost(tenantId: string, file: any, options?: AxiosRequestConfig) {
        return KsefAuthApiFp(this.configuration).apiTenantIdKsefAuthInitSessionPost(tenantId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KsefAuthWithOrgApi - axios parameter creator
 * @export
 */
export const KsefAuthWithOrgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sign auth challenge, test purposes
         * @param {string} identifierToGenerateCertificateFor 
         * @param {string} identifierType 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost: async (identifierToGenerateCertificateFor: string, identifierType: string, tenantId: string, organizationId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifierToGenerateCertificateFor' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost', 'identifierToGenerateCertificateFor', identifierToGenerateCertificateFor)
            // verify required parameter 'identifierType' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost', 'identifierType', identifierType)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost', 'organizationId', organizationId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost', 'file', file)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefAuthWithOrg/SignChallenge`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (identifierToGenerateCertificateFor !== undefined) {
                localVarQueryParameter['identifierToGenerateCertificateFor'] = identifierToGenerateCertificateFor;
            }

            if (identifierType !== undefined) {
                localVarQueryParameter['identifierType'] = identifierType;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KsefAuthWithOrgApi - functional programming interface
 * @export
 */
export const KsefAuthWithOrgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KsefAuthWithOrgApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Sign auth challenge, test purposes
         * @param {string} identifierToGenerateCertificateFor 
         * @param {string} identifierType 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost(identifierToGenerateCertificateFor: string, identifierType: string, tenantId: string, organizationId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost(identifierToGenerateCertificateFor, identifierType, tenantId, organizationId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KsefAuthWithOrgApi - factory interface
 * @export
 */
export const KsefAuthWithOrgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KsefAuthWithOrgApiFp(configuration)
    return {
        /**
         * 
         * @summary Sign auth challenge, test purposes
         * @param {string} identifierToGenerateCertificateFor 
         * @param {string} identifierType 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost(identifierToGenerateCertificateFor: string, identifierType: string, tenantId: string, organizationId: string, file: any, options?: any): AxiosPromise<File> {
            return localVarFp.apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost(identifierToGenerateCertificateFor, identifierType, tenantId, organizationId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KsefAuthWithOrgApi - object-oriented interface
 * @export
 * @class KsefAuthWithOrgApi
 * @extends {BaseAPI}
 */
export class KsefAuthWithOrgApi extends BaseAPI {
    /**
     * 
     * @summary Sign auth challenge, test purposes
     * @param {string} identifierToGenerateCertificateFor 
     * @param {string} identifierType 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefAuthWithOrgApi
     */
    public apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost(identifierToGenerateCertificateFor: string, identifierType: string, tenantId: string, organizationId: string, file: any, options?: AxiosRequestConfig) {
        return KsefAuthWithOrgApiFp(this.configuration).apiTenantIdOrganizationIdKsefAuthWithOrgSignChallengePost(identifierToGenerateCertificateFor, identifierType, tenantId, organizationId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KsefCredentialsApi - axios parameter creator
 * @export
 */
export const KsefCredentialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get token by Id
         * @param {string} tenantId 
         * @param {string} [tokenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefCredentialsTokenByIdGet: async (tenantId: string, tokenId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdKsefCredentialsTokenByIdGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/KsefCredentials/TokenById`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (tokenId !== undefined) {
                localVarQueryParameter['tokenId'] = tokenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetTokenWithRequiredPrivs
         * @param {string} tenantId 
         * @param {TokenByPrivsRequest} [tokenByPrivsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefCredentialsTokenIdByRequiredPrivsPost: async (tenantId: string, tokenByPrivsRequest?: TokenByPrivsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdKsefCredentialsTokenIdByRequiredPrivsPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/KsefCredentials/TokenIdByRequiredPrivs`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenByPrivsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetTokenWithRequiredPrivs
         * @param {string} tenantId 
         * @param {TokenByPrivsRequest} [tokenByPrivsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefCredentialsTokenWithRequiredPrivsPost: async (tenantId: string, tokenByPrivsRequest?: TokenByPrivsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdKsefCredentialsTokenWithRequiredPrivsPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/KsefCredentials/TokenWithRequiredPrivs`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenByPrivsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get avalailable tokens to check
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefCredentials/PrivilegesByToken`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AddTokenToRepo this will probably won\'t stay in controller (only for tests now)
         * @param {string} contextIdentifier 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {GenerateTokenRequest} [generateTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost: async (contextIdentifier: string, tenantId: string, organizationId: string, generateTokenRequest?: GenerateTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contextIdentifier' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost', 'contextIdentifier', contextIdentifier)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefCredentials/Token/CreateLocally`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (contextIdentifier !== undefined) {
                localVarQueryParameter['contextIdentifier'] = contextIdentifier;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks specified token as a token to remove during synchronization
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost: async (tenantId: string, organizationId: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefCredentials/Token/MarkToRevoke`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Perform synchronization with ksef
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefCredentials/Token/SyncWithKsef`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KsefCredentialsApi - functional programming interface
 * @export
 */
export const KsefCredentialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KsefCredentialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get token by Id
         * @param {string} tenantId 
         * @param {string} [tokenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdKsefCredentialsTokenByIdGet(tenantId: string, tokenId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdKsefCredentialsTokenByIdGet(tenantId, tokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetTokenWithRequiredPrivs
         * @param {string} tenantId 
         * @param {TokenByPrivsRequest} [tokenByPrivsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdKsefCredentialsTokenIdByRequiredPrivsPost(tenantId: string, tokenByPrivsRequest?: TokenByPrivsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdKsefCredentialsTokenIdByRequiredPrivsPost(tenantId, tokenByPrivsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetTokenWithRequiredPrivs
         * @param {string} tenantId 
         * @param {TokenByPrivsRequest} [tokenByPrivsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdKsefCredentialsTokenWithRequiredPrivsPost(tenantId: string, tokenByPrivsRequest?: TokenByPrivsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdKsefCredentialsTokenWithRequiredPrivsPost(tenantId, tokenByPrivsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get avalailable tokens to check
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivilegesByToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary AddTokenToRepo this will probably won\'t stay in controller (only for tests now)
         * @param {string} contextIdentifier 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {GenerateTokenRequest} [generateTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost(contextIdentifier: string, tenantId: string, organizationId: string, generateTokenRequest?: GenerateTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost(contextIdentifier, tenantId, organizationId, generateTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marks specified token as a token to remove during synchronization
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost(tenantId: string, organizationId: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuerySyncCredentialsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost(tenantId, organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Perform synchronization with ksef
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuerySyncCredentialsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KsefCredentialsApi - factory interface
 * @export
 */
export const KsefCredentialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KsefCredentialsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get token by Id
         * @param {string} tenantId 
         * @param {string} [tokenId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefCredentialsTokenByIdGet(tenantId: string, tokenId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdKsefCredentialsTokenByIdGet(tenantId, tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetTokenWithRequiredPrivs
         * @param {string} tenantId 
         * @param {TokenByPrivsRequest} [tokenByPrivsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefCredentialsTokenIdByRequiredPrivsPost(tenantId: string, tokenByPrivsRequest?: TokenByPrivsRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdKsefCredentialsTokenIdByRequiredPrivsPost(tenantId, tokenByPrivsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetTokenWithRequiredPrivs
         * @param {string} tenantId 
         * @param {TokenByPrivsRequest} [tokenByPrivsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdKsefCredentialsTokenWithRequiredPrivsPost(tenantId: string, tokenByPrivsRequest?: TokenByPrivsRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdKsefCredentialsTokenWithRequiredPrivsPost(tenantId, tokenByPrivsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get avalailable tokens to check
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<PrivilegesByToken> {
            return localVarFp.apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AddTokenToRepo this will probably won\'t stay in controller (only for tests now)
         * @param {string} contextIdentifier 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {GenerateTokenRequest} [generateTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost(contextIdentifier: string, tenantId: string, organizationId: string, generateTokenRequest?: GenerateTokenRequest, options?: any): AxiosPromise<number> {
            return localVarFp.apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost(contextIdentifier, tenantId, organizationId, generateTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks specified token as a token to remove during synchronization
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost(tenantId: string, organizationId: string, body?: string, options?: any): AxiosPromise<QuerySyncCredentialsResponse> {
            return localVarFp.apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost(tenantId, organizationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Perform synchronization with ksef
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost(tenantId: string, organizationId: string, options?: any): AxiosPromise<QuerySyncCredentialsResponse> {
            return localVarFp.apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KsefCredentialsApi - object-oriented interface
 * @export
 * @class KsefCredentialsApi
 * @extends {BaseAPI}
 */
export class KsefCredentialsApi extends BaseAPI {
    /**
     * 
     * @summary Get token by Id
     * @param {string} tenantId 
     * @param {string} [tokenId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefCredentialsApi
     */
    public apiTenantIdKsefCredentialsTokenByIdGet(tenantId: string, tokenId?: string, options?: AxiosRequestConfig) {
        return KsefCredentialsApiFp(this.configuration).apiTenantIdKsefCredentialsTokenByIdGet(tenantId, tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetTokenWithRequiredPrivs
     * @param {string} tenantId 
     * @param {TokenByPrivsRequest} [tokenByPrivsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefCredentialsApi
     */
    public apiTenantIdKsefCredentialsTokenIdByRequiredPrivsPost(tenantId: string, tokenByPrivsRequest?: TokenByPrivsRequest, options?: AxiosRequestConfig) {
        return KsefCredentialsApiFp(this.configuration).apiTenantIdKsefCredentialsTokenIdByRequiredPrivsPost(tenantId, tokenByPrivsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetTokenWithRequiredPrivs
     * @param {string} tenantId 
     * @param {TokenByPrivsRequest} [tokenByPrivsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefCredentialsApi
     */
    public apiTenantIdKsefCredentialsTokenWithRequiredPrivsPost(tenantId: string, tokenByPrivsRequest?: TokenByPrivsRequest, options?: AxiosRequestConfig) {
        return KsefCredentialsApiFp(this.configuration).apiTenantIdKsefCredentialsTokenWithRequiredPrivsPost(tenantId, tokenByPrivsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get avalailable tokens to check
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefCredentialsApi
     */
    public apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return KsefCredentialsApiFp(this.configuration).apiTenantIdOrganizationIdKsefCredentialsPrivilegesByTokenGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary AddTokenToRepo this will probably won\'t stay in controller (only for tests now)
     * @param {string} contextIdentifier 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {GenerateTokenRequest} [generateTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefCredentialsApi
     */
    public apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost(contextIdentifier: string, tenantId: string, organizationId: string, generateTokenRequest?: GenerateTokenRequest, options?: AxiosRequestConfig) {
        return KsefCredentialsApiFp(this.configuration).apiTenantIdOrganizationIdKsefCredentialsTokenCreateLocallyPost(contextIdentifier, tenantId, organizationId, generateTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks specified token as a token to remove during synchronization
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefCredentialsApi
     */
    public apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost(tenantId: string, organizationId: string, body?: string, options?: AxiosRequestConfig) {
        return KsefCredentialsApiFp(this.configuration).apiTenantIdOrganizationIdKsefCredentialsTokenMarkToRevokePost(tenantId, organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Perform synchronization with ksef
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefCredentialsApi
     */
    public apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return KsefCredentialsApiFp(this.configuration).apiTenantIdOrganizationIdKsefCredentialsTokenSyncWithKsefPost(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KsefRoleApi - axios parameter creator
 * @export
 */
export const KsefRoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefRoleGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRoleGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRoleGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefRole`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefRoleIdDelete: async (id: string, tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRoleIdDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRoleIdDelete', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRoleIdDelete', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefRole/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {KsefEditRoleRequest} [ksefEditRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefRoleIdPut: async (id: string, tenantId: string, organizationId: string, ksefEditRoleRequest?: KsefEditRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRoleIdPut', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRoleIdPut', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRoleIdPut', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefRole/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ksefEditRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {KsefNewRoleRequest} [ksefNewRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefRolePost: async (tenantId: string, organizationId: string, ksefNewRoleRequest?: KsefNewRoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRolePost', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRolePost', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefRole`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ksefNewRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefRoleSyncGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRoleSyncGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefRoleSyncGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefRole/Sync`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataKsefRole: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataKsefRole', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataKsefRole', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/KsefRole`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataKsefRoleCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataKsefRoleCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataKsefRoleCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/KsefRole/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KsefRoleApi - functional programming interface
 * @export
 */
export const KsefRoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KsefRoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefRoleGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KsefRoleRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefRoleGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefRoleIdDelete(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefRoleIdDelete(id, tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {KsefEditRoleRequest} [ksefEditRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefRoleIdPut(id: string, tenantId: string, organizationId: string, ksefEditRoleRequest?: KsefEditRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefRoleIdPut(id, tenantId, organizationId, ksefEditRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {KsefNewRoleRequest} [ksefNewRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefRolePost(tenantId: string, organizationId: string, ksefNewRoleRequest?: KsefNewRoleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefRolePost(tenantId, organizationId, ksefNewRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefRoleSyncGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefRoleSyncGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataKsefRole(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KsefRoleRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataKsefRole(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataKsefRoleCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KsefRoleRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataKsefRoleCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KsefRoleApi - factory interface
 * @export
 */
export const KsefRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KsefRoleApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefRoleGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<KsefRoleRM>> {
            return localVarFp.apiTenantIdOrganizationIdKsefRoleGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefRoleIdDelete(id: string, tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdKsefRoleIdDelete(id, tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {KsefEditRoleRequest} [ksefEditRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefRoleIdPut(id: string, tenantId: string, organizationId: string, ksefEditRoleRequest?: KsefEditRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdKsefRoleIdPut(id, tenantId, organizationId, ksefEditRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {KsefNewRoleRequest} [ksefNewRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefRolePost(tenantId: string, organizationId: string, ksefNewRoleRequest?: KsefNewRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdKsefRolePost(tenantId, organizationId, ksefNewRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefRoleSyncGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdKsefRoleSyncGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataKsefRole(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<KsefRoleRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataKsefRole(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataKsefRoleCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<KsefRoleRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataKsefRoleCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KsefRoleApi - object-oriented interface
 * @export
 * @class KsefRoleApi
 * @extends {BaseAPI}
 */
export class KsefRoleApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefRoleApi
     */
    public apiTenantIdOrganizationIdKsefRoleGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return KsefRoleApiFp(this.configuration).apiTenantIdOrganizationIdKsefRoleGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefRoleApi
     */
    public apiTenantIdOrganizationIdKsefRoleIdDelete(id: string, tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return KsefRoleApiFp(this.configuration).apiTenantIdOrganizationIdKsefRoleIdDelete(id, tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {KsefEditRoleRequest} [ksefEditRoleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefRoleApi
     */
    public apiTenantIdOrganizationIdKsefRoleIdPut(id: string, tenantId: string, organizationId: string, ksefEditRoleRequest?: KsefEditRoleRequest, options?: AxiosRequestConfig) {
        return KsefRoleApiFp(this.configuration).apiTenantIdOrganizationIdKsefRoleIdPut(id, tenantId, organizationId, ksefEditRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {KsefNewRoleRequest} [ksefNewRoleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefRoleApi
     */
    public apiTenantIdOrganizationIdKsefRolePost(tenantId: string, organizationId: string, ksefNewRoleRequest?: KsefNewRoleRequest, options?: AxiosRequestConfig) {
        return KsefRoleApiFp(this.configuration).apiTenantIdOrganizationIdKsefRolePost(tenantId, organizationId, ksefNewRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefRoleApi
     */
    public apiTenantIdOrganizationIdKsefRoleSyncGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return KsefRoleApiFp(this.configuration).apiTenantIdOrganizationIdKsefRoleSyncGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefRoleApi
     */
    public apiTenantIdOrganizationIdOdataKsefRole(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return KsefRoleApiFp(this.configuration).apiTenantIdOrganizationIdOdataKsefRole(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefRoleApi
     */
    public apiTenantIdOrganizationIdOdataKsefRoleCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return KsefRoleApiFp(this.configuration).apiTenantIdOrganizationIdOdataKsefRoleCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KsefTokensApi - axios parameter creator
 * @export
 */
export const KsefTokensApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefTokensGet: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefTokensGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdKsefTokensGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/KsefTokens`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataKsefTokens: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataKsefTokens', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataKsefTokens', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/KsefTokens`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataKsefTokensCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataKsefTokensCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataKsefTokensCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/KsefTokens/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KsefTokensApi - functional programming interface
 * @export
 */
export const KsefTokensApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KsefTokensApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdKsefTokensGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KsefTokenRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdKsefTokensGet(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataKsefTokens(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KsefTokenRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataKsefTokens(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataKsefTokensCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KsefTokenRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataKsefTokensCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KsefTokensApi - factory interface
 * @export
 */
export const KsefTokensApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KsefTokensApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdKsefTokensGet(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<KsefTokenRM>> {
            return localVarFp.apiTenantIdOrganizationIdKsefTokensGet(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataKsefTokens(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<KsefTokenRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataKsefTokens(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataKsefTokensCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<KsefTokenRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataKsefTokensCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KsefTokensApi - object-oriented interface
 * @export
 * @class KsefTokensApi
 * @extends {BaseAPI}
 */
export class KsefTokensApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefTokensApi
     */
    public apiTenantIdOrganizationIdKsefTokensGet(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return KsefTokensApiFp(this.configuration).apiTenantIdOrganizationIdKsefTokensGet(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefTokensApi
     */
    public apiTenantIdOrganizationIdOdataKsefTokens(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return KsefTokensApiFp(this.configuration).apiTenantIdOrganizationIdOdataKsefTokens(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KsefTokensApi
     */
    public apiTenantIdOrganizationIdOdataKsefTokensCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return KsefTokensApiFp(this.configuration).apiTenantIdOrganizationIdOdataKsefTokensCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LicenseApi - axios parameter creator
 * @export
 */
export const LicenseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Remove license
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdLicenseDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdLicenseDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdLicenseDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/License`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all uploaded licenses infos
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdLicenseGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdLicenseGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/License`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all licenses merged into one
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdLicenseMergedGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdLicenseMergedGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/License/merged`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdLicensePost: async (tenantId: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdLicensePost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/License`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenseApi - functional programming interface
 * @export
 */
export const LicenseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Remove license
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdLicenseDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdLicenseDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all uploaded licenses infos
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdLicenseGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseRMState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdLicenseGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all licenses merged into one
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdLicenseMergedGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdLicenseMergedGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdLicensePost(tenantId: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdLicensePost(tenantId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LicenseApi - factory interface
 * @export
 */
export const LicenseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenseApiFp(configuration)
    return {
        /**
         * 
         * @summary Remove license
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdLicenseDelete(id: string, tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdLicenseDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all uploaded licenses infos
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdLicenseGet(tenantId: string, options?: any): AxiosPromise<LicenseRMState> {
            return localVarFp.apiTenantIdLicenseGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all licenses merged into one
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdLicenseMergedGet(tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdLicenseMergedGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {string} tenantId 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdLicensePost(tenantId: string, file?: any, options?: any): AxiosPromise<AddResult> {
            return localVarFp.apiTenantIdLicensePost(tenantId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenseApi - object-oriented interface
 * @export
 * @class LicenseApi
 * @extends {BaseAPI}
 */
export class LicenseApi extends BaseAPI {
    /**
     * 
     * @summary Remove license
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public apiTenantIdLicenseDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).apiTenantIdLicenseDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all uploaded licenses infos
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public apiTenantIdLicenseGet(tenantId: string, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).apiTenantIdLicenseGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all licenses merged into one
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public apiTenantIdLicenseMergedGet(tenantId: string, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).apiTenantIdLicenseMergedGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {string} tenantId 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public apiTenantIdLicensePost(tenantId: string, file?: any, options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).apiTenantIdLicensePost(tenantId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LicenseVerificationApi - axios parameter creator
 * @export
 */
export const LicenseVerificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary VerifyLicense
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdLicenseVerificationVerifyGet: async (tenantId: string, nip?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdLicenseVerificationVerifyGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/LicenseVerification/verify`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (nip !== undefined) {
                localVarQueryParameter['nip'] = nip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenseVerificationApi - functional programming interface
 * @export
 */
export const LicenseVerificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenseVerificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary VerifyLicense
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdLicenseVerificationVerifyGet(tenantId: string, nip?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdLicenseVerificationVerifyGet(tenantId, nip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LicenseVerificationApi - factory interface
 * @export
 */
export const LicenseVerificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenseVerificationApiFp(configuration)
    return {
        /**
         * 
         * @summary VerifyLicense
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdLicenseVerificationVerifyGet(tenantId: string, nip?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdLicenseVerificationVerifyGet(tenantId, nip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenseVerificationApi - object-oriented interface
 * @export
 * @class LicenseVerificationApi
 * @extends {BaseAPI}
 */
export class LicenseVerificationApi extends BaseAPI {
    /**
     * 
     * @summary VerifyLicense
     * @param {string} tenantId 
     * @param {string} [nip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseVerificationApi
     */
    public apiTenantIdLicenseVerificationVerifyGet(tenantId: string, nip?: string, options?: AxiosRequestConfig) {
        return LicenseVerificationApiFp(this.configuration).apiTenantIdLicenseVerificationVerifyGet(tenantId, nip, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetadataApi - axios parameter creator
 * @export
 */
export const MetadataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get metadata value for given key
         * @param {MetadataKey} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMetadataGet: async (key?: MetadataKey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set metadata value for given key
         * @param {UpdateMetadataDto} [updateMetadataDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMetadataPost: async (updateMetadataDto?: UpdateMetadataDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMetadataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdata: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdata', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataMetadata: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataMetadata', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/$metadata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdata: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdata', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdata', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataMetadata: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataMetadata', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataMetadata', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/$metadata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetadataApi - functional programming interface
 * @export
 */
export const MetadataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetadataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get metadata value for given key
         * @param {MetadataKey} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMetadataGet(key?: MetadataKey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMetadataGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set metadata value for given key
         * @param {UpdateMetadataDto} [updateMetadataDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMetadataPost(updateMetadataDto?: UpdateMetadataDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMetadataPost(updateMetadataDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdata(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ODataServiceDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdata(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataMetadata(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEdmModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataMetadata(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdata(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ODataServiceDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdata(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataMetadata(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IEdmModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataMetadata(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetadataApi - factory interface
 * @export
 */
export const MetadataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetadataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get metadata value for given key
         * @param {MetadataKey} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMetadataGet(key?: MetadataKey, options?: any): AxiosPromise<void> {
            return localVarFp.apiMetadataGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set metadata value for given key
         * @param {UpdateMetadataDto} [updateMetadataDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMetadataPost(updateMetadataDto?: UpdateMetadataDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiMetadataPost(updateMetadataDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdata(tenantId: string, options?: any): AxiosPromise<ODataServiceDocument> {
            return localVarFp.apiTenantIdOdata(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataMetadata(tenantId: string, options?: any): AxiosPromise<IEdmModel> {
            return localVarFp.apiTenantIdOdataMetadata(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdata(tenantId: string, organizationId: string, options?: any): AxiosPromise<ODataServiceDocument> {
            return localVarFp.apiTenantIdOrganizationIdOdata(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataMetadata(tenantId: string, organizationId: string, options?: any): AxiosPromise<IEdmModel> {
            return localVarFp.apiTenantIdOrganizationIdOdataMetadata(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetadataApi - object-oriented interface
 * @export
 * @class MetadataApi
 * @extends {BaseAPI}
 */
export class MetadataApi extends BaseAPI {
    /**
     * 
     * @summary Get metadata value for given key
     * @param {MetadataKey} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public apiMetadataGet(key?: MetadataKey, options?: AxiosRequestConfig) {
        return MetadataApiFp(this.configuration).apiMetadataGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set metadata value for given key
     * @param {UpdateMetadataDto} [updateMetadataDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public apiMetadataPost(updateMetadataDto?: UpdateMetadataDto, options?: AxiosRequestConfig) {
        return MetadataApiFp(this.configuration).apiMetadataPost(updateMetadataDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public apiTenantIdOdata(tenantId: string, options?: AxiosRequestConfig) {
        return MetadataApiFp(this.configuration).apiTenantIdOdata(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public apiTenantIdOdataMetadata(tenantId: string, options?: AxiosRequestConfig) {
        return MetadataApiFp(this.configuration).apiTenantIdOdataMetadata(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public apiTenantIdOrganizationIdOdata(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return MetadataApiFp(this.configuration).apiTenantIdOrganizationIdOdata(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    public apiTenantIdOrganizationIdOdataMetadata(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return MetadataApiFp(this.configuration).apiTenantIdOrganizationIdOdataMetadata(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModuleApi - axios parameter creator
 * @export
 */
export const ModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all licensed modules
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdModuleGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdModuleGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Module`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all licensed modules
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataModule: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataModule', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/Module`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all licensed modules
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataModuleCount: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataModuleCount', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/Module/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuleApi - functional programming interface
 * @export
 */
export const ModuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all licensed modules
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdModuleGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdModuleGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all licensed modules
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataModule(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataModule(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all licensed modules
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataModuleCount(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataModuleCount(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModuleApi - factory interface
 * @export
 */
export const ModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModuleApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all licensed modules
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdModuleGet(tenantId: string, options?: any): AxiosPromise<Array<ModuleRM>> {
            return localVarFp.apiTenantIdModuleGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all licensed modules
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataModule(tenantId: string, options?: any): AxiosPromise<Array<ModuleRM>> {
            return localVarFp.apiTenantIdOdataModule(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all licensed modules
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataModuleCount(tenantId: string, options?: any): AxiosPromise<Array<ModuleRM>> {
            return localVarFp.apiTenantIdOdataModuleCount(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModuleApi - object-oriented interface
 * @export
 * @class ModuleApi
 * @extends {BaseAPI}
 */
export class ModuleApi extends BaseAPI {
    /**
     * 
     * @summary Get all licensed modules
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiTenantIdModuleGet(tenantId: string, options?: AxiosRequestConfig) {
        return ModuleApiFp(this.configuration).apiTenantIdModuleGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all licensed modules
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiTenantIdOdataModule(tenantId: string, options?: AxiosRequestConfig) {
        return ModuleApiFp(this.configuration).apiTenantIdOdataModule(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all licensed modules
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public apiTenantIdOdataModuleCount(tenantId: string, options?: AxiosRequestConfig) {
        return ModuleApiFp(this.configuration).apiTenantIdOdataModuleCount(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NipListApi - axios parameter creator
 * @export
 */
export const NipListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNipListGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNipListGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NipList`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [listId] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNipListValueExistGet: async (tenantId: string, listId?: string, value?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNipListValueExistGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NipList/value-exist`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (listId !== undefined) {
                localVarQueryParameter['listId'] = listId;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NipListApi - functional programming interface
 * @export
 */
export const NipListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NipListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNipListGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNipListGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [listId] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNipListValueExistGet(tenantId: string, listId?: string, value?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNipListValueExistGet(tenantId, listId, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NipListApi - factory interface
 * @export
 */
export const NipListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NipListApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNipListGet(tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdNipListGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [listId] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNipListValueExistGet(tenantId: string, listId?: string, value?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdNipListValueExistGet(tenantId, listId, value, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NipListApi - object-oriented interface
 * @export
 * @class NipListApi
 * @extends {BaseAPI}
 */
export class NipListApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NipListApi
     */
    public apiTenantIdNipListGet(tenantId: string, options?: AxiosRequestConfig) {
        return NipListApiFp(this.configuration).apiTenantIdNipListGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} [listId] 
     * @param {string} [value] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NipListApi
     */
    public apiTenantIdNipListValueExistGet(tenantId: string, listId?: string, value?: string, options?: AxiosRequestConfig) {
        return NipListApiFp(this.configuration).apiTenantIdNipListValueExistGet(tenantId, listId, value, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send notification to user
         * @param {string} tenantId 
         * @param {string} [userDbId] Mail is used as user id when someone authorize via aad
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationPost: async (tenantId: string, userDbId?: string, message?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNotificationPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Notification`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (userDbId !== undefined) {
                localVarQueryParameter['userDbId'] = userDbId;
            }

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send notification to user
         * @param {string} tenantId 
         * @param {string} [userDbId] Mail is used as user id when someone authorize via aad
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNotificationPost(tenantId: string, userDbId?: string, message?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNotificationPost(tenantId, userDbId, message, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Send notification to user
         * @param {string} tenantId 
         * @param {string} [userDbId] Mail is used as user id when someone authorize via aad
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationPost(tenantId: string, userDbId?: string, message?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdNotificationPost(tenantId, userDbId, message, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Send notification to user
     * @param {string} tenantId 
     * @param {string} [userDbId] Mail is used as user id when someone authorize via aad
     * @param {string} [message] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiTenantIdNotificationPost(tenantId: string, userDbId?: string, message?: string, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiTenantIdNotificationPost(tenantId, userDbId, message, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationConfigurationApi - axios parameter creator
 * @export
 */
export const NotificationConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all notification configurations
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNotificationConfigurationGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NotificationConfiguration`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes notification configuration
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationIdDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdNotificationConfigurationIdDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNotificationConfigurationIdDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NotificationConfiguration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets defails of notification configuration
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationIdGet: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdNotificationConfigurationIdGet', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNotificationConfigurationIdGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NotificationConfiguration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets available notification modes
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationModesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNotificationConfigurationModesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NotificationConfiguration/modes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets available notification periods
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationPeriodsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNotificationConfigurationPeriodsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NotificationConfiguration/periods`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new notification configuration
         * @param {string} tenantId 
         * @param {NotificationConfigurationDto} [notificationConfigurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationPost: async (tenantId: string, notificationConfigurationDto?: NotificationConfigurationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNotificationConfigurationPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NotificationConfiguration`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationConfigurationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets properties of a notification type
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationPropertiesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNotificationConfigurationPropertiesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NotificationConfiguration/properties`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates existing notification configuration
         * @param {string} tenantId 
         * @param {NotificationConfigurationDto} [notificationConfigurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationPut: async (tenantId: string, notificationConfigurationDto?: NotificationConfigurationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNotificationConfigurationPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NotificationConfiguration`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationConfigurationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationConfigurationApi - functional programming interface
 * @export
 */
export const NotificationConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all notification configurations
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNotificationConfigurationGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNotificationConfigurationGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes notification configuration
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNotificationConfigurationIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNotificationConfigurationIdDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets defails of notification configuration
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNotificationConfigurationIdGet(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNotificationConfigurationIdGet(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets available notification modes
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNotificationConfigurationModesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNotificationConfigurationModesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets available notification periods
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNotificationConfigurationPeriodsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNotificationConfigurationPeriodsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates new notification configuration
         * @param {string} tenantId 
         * @param {NotificationConfigurationDto} [notificationConfigurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNotificationConfigurationPost(tenantId: string, notificationConfigurationDto?: NotificationConfigurationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNotificationConfigurationPost(tenantId, notificationConfigurationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets properties of a notification type
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNotificationConfigurationPropertiesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNotificationConfigurationPropertiesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates existing notification configuration
         * @param {string} tenantId 
         * @param {NotificationConfigurationDto} [notificationConfigurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNotificationConfigurationPut(tenantId: string, notificationConfigurationDto?: NotificationConfigurationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNotificationConfigurationPut(tenantId, notificationConfigurationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationConfigurationApi - factory interface
 * @export
 */
export const NotificationConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all notification configurations
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationGet(tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdNotificationConfigurationGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes notification configuration
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationIdDelete(id: string, tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdNotificationConfigurationIdDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets defails of notification configuration
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationIdGet(id: string, tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdNotificationConfigurationIdGet(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets available notification modes
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationModesGet(tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdNotificationConfigurationModesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets available notification periods
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationPeriodsGet(tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdNotificationConfigurationPeriodsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new notification configuration
         * @param {string} tenantId 
         * @param {NotificationConfigurationDto} [notificationConfigurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationPost(tenantId: string, notificationConfigurationDto?: NotificationConfigurationDto, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdNotificationConfigurationPost(tenantId, notificationConfigurationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets properties of a notification type
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationPropertiesGet(tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdNotificationConfigurationPropertiesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates existing notification configuration
         * @param {string} tenantId 
         * @param {NotificationConfigurationDto} [notificationConfigurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNotificationConfigurationPut(tenantId: string, notificationConfigurationDto?: NotificationConfigurationDto, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdNotificationConfigurationPut(tenantId, notificationConfigurationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationConfigurationApi - object-oriented interface
 * @export
 * @class NotificationConfigurationApi
 * @extends {BaseAPI}
 */
export class NotificationConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Gets all notification configurations
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiTenantIdNotificationConfigurationGet(tenantId: string, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiTenantIdNotificationConfigurationGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes notification configuration
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiTenantIdNotificationConfigurationIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiTenantIdNotificationConfigurationIdDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets defails of notification configuration
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiTenantIdNotificationConfigurationIdGet(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiTenantIdNotificationConfigurationIdGet(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets available notification modes
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiTenantIdNotificationConfigurationModesGet(tenantId: string, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiTenantIdNotificationConfigurationModesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets available notification periods
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiTenantIdNotificationConfigurationPeriodsGet(tenantId: string, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiTenantIdNotificationConfigurationPeriodsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new notification configuration
     * @param {string} tenantId 
     * @param {NotificationConfigurationDto} [notificationConfigurationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiTenantIdNotificationConfigurationPost(tenantId: string, notificationConfigurationDto?: NotificationConfigurationDto, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiTenantIdNotificationConfigurationPost(tenantId, notificationConfigurationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets properties of a notification type
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiTenantIdNotificationConfigurationPropertiesGet(tenantId: string, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiTenantIdNotificationConfigurationPropertiesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates existing notification configuration
     * @param {string} tenantId 
     * @param {NotificationConfigurationDto} [notificationConfigurationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiTenantIdNotificationConfigurationPut(tenantId: string, notificationConfigurationDto?: NotificationConfigurationDto, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiTenantIdNotificationConfigurationPut(tenantId, notificationConfigurationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NumberingSeriesApi - axios parameter creator
 * @export
 */
export const NumberingSeriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdNumberingSeriesDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNumberingSeriesDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NumberingSeries`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {string} [organizationVatNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesGetByInvKindGet: async (tenantId: string, invoiceKind?: InvoiceKind, organizationVatNo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNumberingSeriesGetByInvKindGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NumberingSeries/get-by-inv-kind`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (invoiceKind !== undefined) {
                localVarQueryParameter['invoiceKind'] = invoiceKind;
            }

            if (organizationVatNo !== undefined) {
                localVarQueryParameter['organizationVatNo'] = organizationVatNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {NumberingSeriesDto} [numberingSeriesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesIdPut: async (id: string, tenantId: string, numberingSeriesDto?: NumberingSeriesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdNumberingSeriesIdPut', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNumberingSeriesIdPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NumberingSeries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(numberingSeriesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} numberingSeriesId 
         * @param {string} tenantId 
         * @param {string} [organizationVatNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesNumberingSeriesIdGenerateGet: async (numberingSeriesId: string, tenantId: string, organizationVatNo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'numberingSeriesId' is not null or undefined
            assertParamExists('apiTenantIdNumberingSeriesNumberingSeriesIdGenerateGet', 'numberingSeriesId', numberingSeriesId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNumberingSeriesNumberingSeriesIdGenerateGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NumberingSeries/{numberingSeriesId}/generate`
                .replace(`{${"numberingSeriesId"}}`, encodeURIComponent(String(numberingSeriesId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (organizationVatNo !== undefined) {
                localVarQueryParameter['organizationVatNo'] = organizationVatNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {NumberingSeriesDto} [numberingSeriesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesPost: async (tenantId: string, numberingSeriesDto?: NumberingSeriesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNumberingSeriesPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NumberingSeries`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(numberingSeriesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesSingleGet: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNumberingSeriesSingleGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NumberingSeries/single`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NumberingSeriesApi - functional programming interface
 * @export
 */
export const NumberingSeriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NumberingSeriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNumberingSeriesDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNumberingSeriesDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {string} [organizationVatNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNumberingSeriesGetByInvKindGet(tenantId: string, invoiceKind?: InvoiceKind, organizationVatNo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNumberingSeriesGetByInvKindGet(tenantId, invoiceKind, organizationVatNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {NumberingSeriesDto} [numberingSeriesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNumberingSeriesIdPut(id: string, tenantId: string, numberingSeriesDto?: NumberingSeriesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNumberingSeriesIdPut(id, tenantId, numberingSeriesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} numberingSeriesId 
         * @param {string} tenantId 
         * @param {string} [organizationVatNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNumberingSeriesNumberingSeriesIdGenerateGet(numberingSeriesId: string, tenantId: string, organizationVatNo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewInvoiceNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNumberingSeriesNumberingSeriesIdGenerateGet(numberingSeriesId, tenantId, organizationVatNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {NumberingSeriesDto} [numberingSeriesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNumberingSeriesPost(tenantId: string, numberingSeriesDto?: NumberingSeriesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNumberingSeriesPost(tenantId, numberingSeriesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNumberingSeriesSingleGet(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNumberingSeriesSingleGet(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NumberingSeriesApi - factory interface
 * @export
 */
export const NumberingSeriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NumberingSeriesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesDelete(id: string, tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdNumberingSeriesDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {InvoiceKind} [invoiceKind] 
         * @param {string} [organizationVatNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesGetByInvKindGet(tenantId: string, invoiceKind?: InvoiceKind, organizationVatNo?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdNumberingSeriesGetByInvKindGet(tenantId, invoiceKind, organizationVatNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tenantId 
         * @param {NumberingSeriesDto} [numberingSeriesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesIdPut(id: string, tenantId: string, numberingSeriesDto?: NumberingSeriesDto, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdNumberingSeriesIdPut(id, tenantId, numberingSeriesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} numberingSeriesId 
         * @param {string} tenantId 
         * @param {string} [organizationVatNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesNumberingSeriesIdGenerateGet(numberingSeriesId: string, tenantId: string, organizationVatNo?: string, options?: any): AxiosPromise<NewInvoiceNumberDto> {
            return localVarFp.apiTenantIdNumberingSeriesNumberingSeriesIdGenerateGet(numberingSeriesId, tenantId, organizationVatNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {NumberingSeriesDto} [numberingSeriesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesPost(tenantId: string, numberingSeriesDto?: NumberingSeriesDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdNumberingSeriesPost(tenantId, numberingSeriesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesSingleGet(tenantId: string, id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdNumberingSeriesSingleGet(tenantId, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NumberingSeriesApi - object-oriented interface
 * @export
 * @class NumberingSeriesApi
 * @extends {BaseAPI}
 */
export class NumberingSeriesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NumberingSeriesApi
     */
    public apiTenantIdNumberingSeriesDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return NumberingSeriesApiFp(this.configuration).apiTenantIdNumberingSeriesDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {InvoiceKind} [invoiceKind] 
     * @param {string} [organizationVatNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NumberingSeriesApi
     */
    public apiTenantIdNumberingSeriesGetByInvKindGet(tenantId: string, invoiceKind?: InvoiceKind, organizationVatNo?: string, options?: AxiosRequestConfig) {
        return NumberingSeriesApiFp(this.configuration).apiTenantIdNumberingSeriesGetByInvKindGet(tenantId, invoiceKind, organizationVatNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tenantId 
     * @param {NumberingSeriesDto} [numberingSeriesDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NumberingSeriesApi
     */
    public apiTenantIdNumberingSeriesIdPut(id: string, tenantId: string, numberingSeriesDto?: NumberingSeriesDto, options?: AxiosRequestConfig) {
        return NumberingSeriesApiFp(this.configuration).apiTenantIdNumberingSeriesIdPut(id, tenantId, numberingSeriesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} numberingSeriesId 
     * @param {string} tenantId 
     * @param {string} [organizationVatNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NumberingSeriesApi
     */
    public apiTenantIdNumberingSeriesNumberingSeriesIdGenerateGet(numberingSeriesId: string, tenantId: string, organizationVatNo?: string, options?: AxiosRequestConfig) {
        return NumberingSeriesApiFp(this.configuration).apiTenantIdNumberingSeriesNumberingSeriesIdGenerateGet(numberingSeriesId, tenantId, organizationVatNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {NumberingSeriesDto} [numberingSeriesDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NumberingSeriesApi
     */
    public apiTenantIdNumberingSeriesPost(tenantId: string, numberingSeriesDto?: NumberingSeriesDto, options?: AxiosRequestConfig) {
        return NumberingSeriesApiFp(this.configuration).apiTenantIdNumberingSeriesPost(tenantId, numberingSeriesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NumberingSeriesApi
     */
    public apiTenantIdNumberingSeriesSingleGet(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return NumberingSeriesApiFp(this.configuration).apiTenantIdNumberingSeriesSingleGet(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NumberingSeriesODataApi - axios parameter creator
 * @export
 */
export const NumberingSeriesODataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesODataGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdNumberingSeriesODataGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/NumberingSeriesOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataNumberingSeriesOData: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataNumberingSeriesOData', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/NumberingSeriesOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataNumberingSeriesODataCount: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataNumberingSeriesODataCount', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/NumberingSeriesOData/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NumberingSeriesODataApi - functional programming interface
 * @export
 */
export const NumberingSeriesODataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NumberingSeriesODataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdNumberingSeriesODataGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NumberingSeriesRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdNumberingSeriesODataGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataNumberingSeriesOData(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NumberingSeriesRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataNumberingSeriesOData(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataNumberingSeriesODataCount(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NumberingSeriesRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataNumberingSeriesODataCount(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NumberingSeriesODataApi - factory interface
 * @export
 */
export const NumberingSeriesODataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NumberingSeriesODataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdNumberingSeriesODataGet(tenantId: string, options?: any): AxiosPromise<Array<NumberingSeriesRM>> {
            return localVarFp.apiTenantIdNumberingSeriesODataGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataNumberingSeriesOData(tenantId: string, options?: any): AxiosPromise<Array<NumberingSeriesRM>> {
            return localVarFp.apiTenantIdOdataNumberingSeriesOData(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataNumberingSeriesODataCount(tenantId: string, options?: any): AxiosPromise<Array<NumberingSeriesRM>> {
            return localVarFp.apiTenantIdOdataNumberingSeriesODataCount(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NumberingSeriesODataApi - object-oriented interface
 * @export
 * @class NumberingSeriesODataApi
 * @extends {BaseAPI}
 */
export class NumberingSeriesODataApi extends BaseAPI {
    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NumberingSeriesODataApi
     */
    public apiTenantIdNumberingSeriesODataGet(tenantId: string, options?: AxiosRequestConfig) {
        return NumberingSeriesODataApiFp(this.configuration).apiTenantIdNumberingSeriesODataGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NumberingSeriesODataApi
     */
    public apiTenantIdOdataNumberingSeriesOData(tenantId: string, options?: AxiosRequestConfig) {
        return NumberingSeriesODataApiFp(this.configuration).apiTenantIdOdataNumberingSeriesOData(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NumberingSeriesODataApi
     */
    public apiTenantIdOdataNumberingSeriesODataCount(tenantId: string, options?: AxiosRequestConfig) {
        return NumberingSeriesODataApiFp(this.configuration).apiTenantIdOdataNumberingSeriesODataCount(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Usuń organizację
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationDelete: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Organization`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz organizacje dla zalogowanego użytkownika
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationEditorGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationEditorGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Organization/Editor`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz informacje o organizacji
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationGet: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Organization`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz identyfikator organizacji w oparciu o NIP
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationGetOrganizationIdGet: async (tenantId: string, nip?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationGetOrganizationIdGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Organization/GetOrganizationId`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (nip !== undefined) {
                localVarQueryParameter['nip'] = nip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edytuj organizację
         * @param {string} id 
         * @param {string} tenantId 
         * @param {OrganizationDto} [organizationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdPut: async (id: string, tenantId: string, organizationDto?: OrganizationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdPut', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Organization/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz typy organizacji
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationOrganizationTypesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationOrganizationTypesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Organization/OrganizationTypes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz wszsytkie organizacje
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationOrganizationsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationOrganizationsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Organization/Organizations`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Utwórz organizację
         * @param {string} tenantId 
         * @param {OrganizationDto} [organizationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationPost: async (tenantId: string, organizationDto?: OrganizationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Organization`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Żądanie synchronizacji organizacji
         * @param {string} tenantId 
         * @param {CustomSyncRequest} [customSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncCustomPut: async (tenantId: string, customSyncRequest?: CustomSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationSyncCustomPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Organization/SyncCustom`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customSyncRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz organizacje dla zalogowanego użytkownika
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationUserOrganizationsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationUserOrganizationsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Organization/UserOrganizations`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Usuń organizację
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationDelete(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationDelete(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz organizacje dla zalogowanego użytkownika
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationEditorGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationEditorGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz informacje o organizacji
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationGet(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationGet(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz identyfikator organizacji w oparciu o NIP
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationGetOrganizationIdGet(tenantId: string, nip?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationGetOrganizationIdGet(tenantId, nip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edytuj organizację
         * @param {string} id 
         * @param {string} tenantId 
         * @param {OrganizationDto} [organizationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdPut(id: string, tenantId: string, organizationDto?: OrganizationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdPut(id, tenantId, organizationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz typy organizacji
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationOrganizationTypesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationTypesInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationOrganizationTypesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz wszsytkie organizacje
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationOrganizationsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BasicOrganizationInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationOrganizationsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Utwórz organizację
         * @param {string} tenantId 
         * @param {OrganizationDto} [organizationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationPost(tenantId: string, organizationDto?: OrganizationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationPost(tenantId, organizationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Żądanie synchronizacji organizacji
         * @param {string} tenantId 
         * @param {CustomSyncRequest} [customSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationSyncCustomPut(tenantId: string, customSyncRequest?: CustomSyncRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationSyncCustomPut(tenantId, customSyncRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz organizacje dla zalogowanego użytkownika
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationUserOrganizationsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BasicOrganizationInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationUserOrganizationsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @summary Usuń organizację
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationDelete(tenantId: string, id?: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdOrganizationDelete(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz organizacje dla zalogowanego użytkownika
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationEditorGet(tenantId: string, options?: any): AxiosPromise<Array<OrganizationDto>> {
            return localVarFp.apiTenantIdOrganizationEditorGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz informacje o organizacji
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationGet(tenantId: string, id?: string, options?: any): AxiosPromise<Organization> {
            return localVarFp.apiTenantIdOrganizationGet(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz identyfikator organizacji w oparciu o NIP
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationGetOrganizationIdGet(tenantId: string, nip?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationGetOrganizationIdGet(tenantId, nip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edytuj organizację
         * @param {string} id 
         * @param {string} tenantId 
         * @param {OrganizationDto} [organizationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdPut(id: string, tenantId: string, organizationDto?: OrganizationDto, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdOrganizationIdPut(id, tenantId, organizationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz typy organizacji
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationOrganizationTypesGet(tenantId: string, options?: any): AxiosPromise<Array<OrganizationTypesInfoResponse>> {
            return localVarFp.apiTenantIdOrganizationOrganizationTypesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz wszsytkie organizacje
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationOrganizationsGet(tenantId: string, options?: any): AxiosPromise<Array<BasicOrganizationInfoResponse>> {
            return localVarFp.apiTenantIdOrganizationOrganizationsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Utwórz organizację
         * @param {string} tenantId 
         * @param {OrganizationDto} [organizationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationPost(tenantId: string, organizationDto?: OrganizationDto, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdOrganizationPost(tenantId, organizationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Żądanie synchronizacji organizacji
         * @param {string} tenantId 
         * @param {CustomSyncRequest} [customSyncRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncCustomPut(tenantId: string, customSyncRequest?: CustomSyncRequest, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdOrganizationSyncCustomPut(tenantId, customSyncRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz organizacje dla zalogowanego użytkownika
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationUserOrganizationsGet(tenantId: string, options?: any): AxiosPromise<Array<BasicOrganizationInfoResponse>> {
            return localVarFp.apiTenantIdOrganizationUserOrganizationsGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @summary Usuń organizację
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiTenantIdOrganizationDelete(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiTenantIdOrganizationDelete(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz organizacje dla zalogowanego użytkownika
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiTenantIdOrganizationEditorGet(tenantId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiTenantIdOrganizationEditorGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz informacje o organizacji
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiTenantIdOrganizationGet(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiTenantIdOrganizationGet(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz identyfikator organizacji w oparciu o NIP
     * @param {string} tenantId 
     * @param {string} [nip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiTenantIdOrganizationGetOrganizationIdGet(tenantId: string, nip?: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiTenantIdOrganizationGetOrganizationIdGet(tenantId, nip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edytuj organizację
     * @param {string} id 
     * @param {string} tenantId 
     * @param {OrganizationDto} [organizationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiTenantIdOrganizationIdPut(id: string, tenantId: string, organizationDto?: OrganizationDto, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiTenantIdOrganizationIdPut(id, tenantId, organizationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz typy organizacji
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiTenantIdOrganizationOrganizationTypesGet(tenantId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiTenantIdOrganizationOrganizationTypesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz wszsytkie organizacje
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiTenantIdOrganizationOrganizationsGet(tenantId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiTenantIdOrganizationOrganizationsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Utwórz organizację
     * @param {string} tenantId 
     * @param {OrganizationDto} [organizationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiTenantIdOrganizationPost(tenantId: string, organizationDto?: OrganizationDto, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiTenantIdOrganizationPost(tenantId, organizationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Żądanie synchronizacji organizacji
     * @param {string} tenantId 
     * @param {CustomSyncRequest} [customSyncRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiTenantIdOrganizationSyncCustomPut(tenantId: string, customSyncRequest?: CustomSyncRequest, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiTenantIdOrganizationSyncCustomPut(tenantId, customSyncRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz organizacje dla zalogowanego użytkownika
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiTenantIdOrganizationUserOrganizationsGet(tenantId: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiTenantIdOrganizationUserOrganizationsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationNoTenantApi - axios parameter creator
 * @export
 */
export const OrganizationNoTenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary GetForSessionManager
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationNoTenantForSessionManagerGet: async (organizationId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrganizationNoTenant/for-session-manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationNoTenantApi - functional programming interface
 * @export
 */
export const OrganizationNoTenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationNoTenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary GetForSessionManager
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationNoTenantForSessionManagerGet(organizationId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationNoTenantForSessionManagerGet(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationNoTenantApi - factory interface
 * @export
 */
export const OrganizationNoTenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationNoTenantApiFp(configuration)
    return {
        /**
         * 
         * @summary GetForSessionManager
         * @param {string} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationNoTenantForSessionManagerGet(organizationId?: string, options?: any): AxiosPromise<Organization> {
            return localVarFp.apiOrganizationNoTenantForSessionManagerGet(organizationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationNoTenantApi - object-oriented interface
 * @export
 * @class OrganizationNoTenantApi
 * @extends {BaseAPI}
 */
export class OrganizationNoTenantApi extends BaseAPI {
    /**
     * 
     * @summary GetForSessionManager
     * @param {string} [organizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationNoTenantApi
     */
    public apiOrganizationNoTenantForSessionManagerGet(organizationId?: string, options?: AxiosRequestConfig) {
        return OrganizationNoTenantApiFp(this.configuration).apiOrganizationNoTenantForSessionManagerGet(organizationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationODataApi - axios parameter creator
 * @export
 */
export const OrganizationODataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataOrganizationOData: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataOrganizationOData', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/OrganizationOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataOrganizationODataCount: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataOrganizationODataCount', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/OrganizationOData/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationODataGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationODataGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/OrganizationOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationODataApi - functional programming interface
 * @export
 */
export const OrganizationODataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationODataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataOrganizationOData(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataOrganizationOData(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataOrganizationODataCount(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataOrganizationODataCount(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationODataGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationODataGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationODataApi - factory interface
 * @export
 */
export const OrganizationODataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationODataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataOrganizationOData(tenantId: string, options?: any): AxiosPromise<Array<OrganizationRM>> {
            return localVarFp.apiTenantIdOdataOrganizationOData(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataOrganizationODataCount(tenantId: string, options?: any): AxiosPromise<Array<OrganizationRM>> {
            return localVarFp.apiTenantIdOdataOrganizationODataCount(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationODataGet(tenantId: string, options?: any): AxiosPromise<Array<OrganizationRM>> {
            return localVarFp.apiTenantIdOrganizationODataGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationODataApi - object-oriented interface
 * @export
 * @class OrganizationODataApi
 * @extends {BaseAPI}
 */
export class OrganizationODataApi extends BaseAPI {
    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationODataApi
     */
    public apiTenantIdOdataOrganizationOData(tenantId: string, options?: AxiosRequestConfig) {
        return OrganizationODataApiFp(this.configuration).apiTenantIdOdataOrganizationOData(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationODataApi
     */
    public apiTenantIdOdataOrganizationODataCount(tenantId: string, options?: AxiosRequestConfig) {
        return OrganizationODataApiFp(this.configuration).apiTenantIdOdataOrganizationODataCount(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationODataApi
     */
    public apiTenantIdOrganizationODataGet(tenantId: string, options?: AxiosRequestConfig) {
        return OrganizationODataApiFp(this.configuration).apiTenantIdOrganizationODataGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationSyncSettingApi - axios parameter creator
 * @export
 */
export const OrganizationSyncSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Usuń ustawienia synchronizacji dla organizacji
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncSettingIdDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdOrganizationSyncSettingIdDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationSyncSettingIdDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/OrganizationSyncSetting/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz wszsytkie ustawienia synchronizacji organizacji
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncSettingOrganizationSyncSettingsGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationSyncSettingOrganizationSyncSettingsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/OrganizationSyncSetting/OrganizationSyncSettings`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stwórz ustawienia synchronizacji dla organizacji
         * @param {string} tenantId 
         * @param {AddOrganizationSyncSettingDto} [addOrganizationSyncSettingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncSettingPost: async (tenantId: string, addOrganizationSyncSettingDto?: AddOrganizationSyncSettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationSyncSettingPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/OrganizationSyncSetting`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrganizationSyncSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edytuj ustawienia synchronizacji dla organizacji
         * @param {string} tenantId 
         * @param {EditOrganizationSyncSettingDto} [editOrganizationSyncSettingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncSettingPut: async (tenantId: string, editOrganizationSyncSettingDto?: EditOrganizationSyncSettingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationSyncSettingPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/OrganizationSyncSetting`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editOrganizationSyncSettingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ustaw zlecenie synchronizacji
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncSettingSyncRequestedPut: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationSyncSettingSyncRequestedPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/OrganizationSyncSetting/SyncRequested`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationSyncSettingApi - functional programming interface
 * @export
 */
export const OrganizationSyncSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationSyncSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Usuń ustawienia synchronizacji dla organizacji
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationSyncSettingIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationSyncSettingIdDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz wszsytkie ustawienia synchronizacji organizacji
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationSyncSettingOrganizationSyncSettingsGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationSyncSettingRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationSyncSettingOrganizationSyncSettingsGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stwórz ustawienia synchronizacji dla organizacji
         * @param {string} tenantId 
         * @param {AddOrganizationSyncSettingDto} [addOrganizationSyncSettingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationSyncSettingPost(tenantId: string, addOrganizationSyncSettingDto?: AddOrganizationSyncSettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationSyncSettingPost(tenantId, addOrganizationSyncSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edytuj ustawienia synchronizacji dla organizacji
         * @param {string} tenantId 
         * @param {EditOrganizationSyncSettingDto} [editOrganizationSyncSettingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationSyncSettingPut(tenantId: string, editOrganizationSyncSettingDto?: EditOrganizationSyncSettingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationSyncSettingPut(tenantId, editOrganizationSyncSettingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ustaw zlecenie synchronizacji
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationSyncSettingSyncRequestedPut(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationSyncSettingSyncRequestedPut(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationSyncSettingApi - factory interface
 * @export
 */
export const OrganizationSyncSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationSyncSettingApiFp(configuration)
    return {
        /**
         * 
         * @summary Usuń ustawienia synchronizacji dla organizacji
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncSettingIdDelete(id: string, tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationSyncSettingIdDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz wszsytkie ustawienia synchronizacji organizacji
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncSettingOrganizationSyncSettingsGet(tenantId: string, options?: any): AxiosPromise<Array<OrganizationSyncSettingRM>> {
            return localVarFp.apiTenantIdOrganizationSyncSettingOrganizationSyncSettingsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stwórz ustawienia synchronizacji dla organizacji
         * @param {string} tenantId 
         * @param {AddOrganizationSyncSettingDto} [addOrganizationSyncSettingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncSettingPost(tenantId: string, addOrganizationSyncSettingDto?: AddOrganizationSyncSettingDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationSyncSettingPost(tenantId, addOrganizationSyncSettingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edytuj ustawienia synchronizacji dla organizacji
         * @param {string} tenantId 
         * @param {EditOrganizationSyncSettingDto} [editOrganizationSyncSettingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncSettingPut(tenantId: string, editOrganizationSyncSettingDto?: EditOrganizationSyncSettingDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationSyncSettingPut(tenantId, editOrganizationSyncSettingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ustaw zlecenie synchronizacji
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationSyncSettingSyncRequestedPut(tenantId: string, id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationSyncSettingSyncRequestedPut(tenantId, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationSyncSettingApi - object-oriented interface
 * @export
 * @class OrganizationSyncSettingApi
 * @extends {BaseAPI}
 */
export class OrganizationSyncSettingApi extends BaseAPI {
    /**
     * 
     * @summary Usuń ustawienia synchronizacji dla organizacji
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSyncSettingApi
     */
    public apiTenantIdOrganizationSyncSettingIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return OrganizationSyncSettingApiFp(this.configuration).apiTenantIdOrganizationSyncSettingIdDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz wszsytkie ustawienia synchronizacji organizacji
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSyncSettingApi
     */
    public apiTenantIdOrganizationSyncSettingOrganizationSyncSettingsGet(tenantId: string, options?: AxiosRequestConfig) {
        return OrganizationSyncSettingApiFp(this.configuration).apiTenantIdOrganizationSyncSettingOrganizationSyncSettingsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stwórz ustawienia synchronizacji dla organizacji
     * @param {string} tenantId 
     * @param {AddOrganizationSyncSettingDto} [addOrganizationSyncSettingDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSyncSettingApi
     */
    public apiTenantIdOrganizationSyncSettingPost(tenantId: string, addOrganizationSyncSettingDto?: AddOrganizationSyncSettingDto, options?: AxiosRequestConfig) {
        return OrganizationSyncSettingApiFp(this.configuration).apiTenantIdOrganizationSyncSettingPost(tenantId, addOrganizationSyncSettingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edytuj ustawienia synchronizacji dla organizacji
     * @param {string} tenantId 
     * @param {EditOrganizationSyncSettingDto} [editOrganizationSyncSettingDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSyncSettingApi
     */
    public apiTenantIdOrganizationSyncSettingPut(tenantId: string, editOrganizationSyncSettingDto?: EditOrganizationSyncSettingDto, options?: AxiosRequestConfig) {
        return OrganizationSyncSettingApiFp(this.configuration).apiTenantIdOrganizationSyncSettingPut(tenantId, editOrganizationSyncSettingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ustaw zlecenie synchronizacji
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSyncSettingApi
     */
    public apiTenantIdOrganizationSyncSettingSyncRequestedPut(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return OrganizationSyncSettingApiFp(this.configuration).apiTenantIdOrganizationSyncSettingSyncRequestedPut(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PipelineApi - axios parameter creator
 * @export
 */
export const PipelineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get dispatch types
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelineDispatchTypesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPipelineDispatchTypesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Pipeline/DispatchTypes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get pipelines
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelineGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPipelineGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Pipeline`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete pipeline
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelineIdDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdPipelineIdDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPipelineIdDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Pipeline/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add pipeline
         * @param {string} tenantId 
         * @param {PipelineDto} [pipelineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelinePost: async (tenantId: string, pipelineDto?: PipelineDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPipelinePost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Pipeline`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pipelineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary edit pipeline
         * @param {string} tenantId 
         * @param {PipelineDto} [pipelineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelinePut: async (tenantId: string, pipelineDto?: PipelineDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPipelinePut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Pipeline`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pipelineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get reception types
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelineReceptionTypesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPipelineReceptionTypesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Pipeline/ReceptionTypes`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PipelineApi - functional programming interface
 * @export
 */
export const PipelineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PipelineApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get dispatch types
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPipelineDispatchTypesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineTypeRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPipelineDispatchTypesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get pipelines
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPipelineGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPipelineGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete pipeline
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPipelineIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPipelineIdDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary add pipeline
         * @param {string} tenantId 
         * @param {PipelineDto} [pipelineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPipelinePost(tenantId: string, pipelineDto?: PipelineDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPipelinePost(tenantId, pipelineDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary edit pipeline
         * @param {string} tenantId 
         * @param {PipelineDto} [pipelineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPipelinePut(tenantId: string, pipelineDto?: PipelineDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPipelinePut(tenantId, pipelineDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get reception types
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPipelineReceptionTypesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineTypeRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPipelineReceptionTypesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PipelineApi - factory interface
 * @export
 */
export const PipelineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PipelineApiFp(configuration)
    return {
        /**
         * 
         * @summary get dispatch types
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelineDispatchTypesGet(tenantId: string, options?: any): AxiosPromise<Array<PipelineTypeRM>> {
            return localVarFp.apiTenantIdPipelineDispatchTypesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get pipelines
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelineGet(tenantId: string, options?: any): AxiosPromise<Array<PipelineDto>> {
            return localVarFp.apiTenantIdPipelineGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete pipeline
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelineIdDelete(id: string, tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdPipelineIdDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary add pipeline
         * @param {string} tenantId 
         * @param {PipelineDto} [pipelineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelinePost(tenantId: string, pipelineDto?: PipelineDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdPipelinePost(tenantId, pipelineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary edit pipeline
         * @param {string} tenantId 
         * @param {PipelineDto} [pipelineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelinePut(tenantId: string, pipelineDto?: PipelineDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdPipelinePut(tenantId, pipelineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get reception types
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPipelineReceptionTypesGet(tenantId: string, options?: any): AxiosPromise<Array<PipelineTypeRM>> {
            return localVarFp.apiTenantIdPipelineReceptionTypesGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PipelineApi - object-oriented interface
 * @export
 * @class PipelineApi
 * @extends {BaseAPI}
 */
export class PipelineApi extends BaseAPI {
    /**
     * 
     * @summary get dispatch types
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public apiTenantIdPipelineDispatchTypesGet(tenantId: string, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration).apiTenantIdPipelineDispatchTypesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get pipelines
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public apiTenantIdPipelineGet(tenantId: string, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration).apiTenantIdPipelineGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete pipeline
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public apiTenantIdPipelineIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration).apiTenantIdPipelineIdDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary add pipeline
     * @param {string} tenantId 
     * @param {PipelineDto} [pipelineDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public apiTenantIdPipelinePost(tenantId: string, pipelineDto?: PipelineDto, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration).apiTenantIdPipelinePost(tenantId, pipelineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary edit pipeline
     * @param {string} tenantId 
     * @param {PipelineDto} [pipelineDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public apiTenantIdPipelinePut(tenantId: string, pipelineDto?: PipelineDto, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration).apiTenantIdPipelinePut(tenantId, pipelineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get reception types
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public apiTenantIdPipelineReceptionTypesGet(tenantId: string, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration).apiTenantIdPipelineReceptionTypesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivilegesApi - axios parameter creator
 * @export
 */
export const PrivilegesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return token for user
         * @param {string} login 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPrivilegesApiUserLoginGet: async (login: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesApiUserLoginGet', 'login', login)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesApiUserLoginGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Privileges/api_user/{login}`
                .replace(`{${"login"}}`, encodeURIComponent(String(login)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return privileges for m2m token
         * @param {string} token 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPrivilegesTokenTokenGet: async (token: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesTokenTokenGet', 'token', token)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesTokenTokenGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Privileges/token/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return token for user
         * @param {string} userId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPrivilegesTokenUserIdUserIdGet: async (userId: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesTokenUserIdUserIdGet', 'userId', userId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesTokenUserIdUserIdGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Privileges/token_user_id/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return privileges for user
         * @param {string} userId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPrivilegesUserIdUserIdGet: async (userId: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesUserIdUserIdGet', 'userId', userId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesUserIdUserIdGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Privileges/user_id/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return privileges for user
         * @param {string} oid 
         * @param {string} mail 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPrivilegesUserOidMailGet: async (oid: string, mail: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oid' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesUserOidMailGet', 'oid', oid)
            // verify required parameter 'mail' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesUserOidMailGet', 'mail', mail)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdPrivilegesUserOidMailGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Privileges/user/{oid}/{mail}`
                .replace(`{${"oid"}}`, encodeURIComponent(String(oid)))
                .replace(`{${"mail"}}`, encodeURIComponent(String(mail)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivilegesApi - functional programming interface
 * @export
 */
export const PrivilegesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivilegesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return token for user
         * @param {string} login 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPrivilegesApiUserLoginGet(login: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KsefixUserContext>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPrivilegesApiUserLoginGet(login, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return privileges for m2m token
         * @param {string} token 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPrivilegesTokenTokenGet(token: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KsefixUserContext>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPrivilegesTokenTokenGet(token, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return token for user
         * @param {string} userId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPrivilegesTokenUserIdUserIdGet(userId: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPrivilegesTokenUserIdUserIdGet(userId, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return privileges for user
         * @param {string} userId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPrivilegesUserIdUserIdGet(userId: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KsefixUserContext>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPrivilegesUserIdUserIdGet(userId, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return privileges for user
         * @param {string} oid 
         * @param {string} mail 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdPrivilegesUserOidMailGet(oid: string, mail: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KsefixUserContext>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdPrivilegesUserOidMailGet(oid, mail, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivilegesApi - factory interface
 * @export
 */
export const PrivilegesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivilegesApiFp(configuration)
    return {
        /**
         * 
         * @summary Return token for user
         * @param {string} login 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPrivilegesApiUserLoginGet(login: string, tenantId: string, options?: any): AxiosPromise<KsefixUserContext> {
            return localVarFp.apiTenantIdPrivilegesApiUserLoginGet(login, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return privileges for m2m token
         * @param {string} token 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPrivilegesTokenTokenGet(token: string, tenantId: string, options?: any): AxiosPromise<KsefixUserContext> {
            return localVarFp.apiTenantIdPrivilegesTokenTokenGet(token, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return token for user
         * @param {string} userId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPrivilegesTokenUserIdUserIdGet(userId: string, tenantId: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdPrivilegesTokenUserIdUserIdGet(userId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return privileges for user
         * @param {string} userId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPrivilegesUserIdUserIdGet(userId: string, tenantId: string, options?: any): AxiosPromise<KsefixUserContext> {
            return localVarFp.apiTenantIdPrivilegesUserIdUserIdGet(userId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return privileges for user
         * @param {string} oid 
         * @param {string} mail 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdPrivilegesUserOidMailGet(oid: string, mail: string, tenantId: string, options?: any): AxiosPromise<KsefixUserContext> {
            return localVarFp.apiTenantIdPrivilegesUserOidMailGet(oid, mail, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrivilegesApi - object-oriented interface
 * @export
 * @class PrivilegesApi
 * @extends {BaseAPI}
 */
export class PrivilegesApi extends BaseAPI {
    /**
     * 
     * @summary Return token for user
     * @param {string} login 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivilegesApi
     */
    public apiTenantIdPrivilegesApiUserLoginGet(login: string, tenantId: string, options?: AxiosRequestConfig) {
        return PrivilegesApiFp(this.configuration).apiTenantIdPrivilegesApiUserLoginGet(login, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return privileges for m2m token
     * @param {string} token 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivilegesApi
     */
    public apiTenantIdPrivilegesTokenTokenGet(token: string, tenantId: string, options?: AxiosRequestConfig) {
        return PrivilegesApiFp(this.configuration).apiTenantIdPrivilegesTokenTokenGet(token, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return token for user
     * @param {string} userId 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivilegesApi
     */
    public apiTenantIdPrivilegesTokenUserIdUserIdGet(userId: string, tenantId: string, options?: AxiosRequestConfig) {
        return PrivilegesApiFp(this.configuration).apiTenantIdPrivilegesTokenUserIdUserIdGet(userId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return privileges for user
     * @param {string} userId 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivilegesApi
     */
    public apiTenantIdPrivilegesUserIdUserIdGet(userId: string, tenantId: string, options?: AxiosRequestConfig) {
        return PrivilegesApiFp(this.configuration).apiTenantIdPrivilegesUserIdUserIdGet(userId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return privileges for user
     * @param {string} oid 
     * @param {string} mail 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivilegesApi
     */
    public apiTenantIdPrivilegesUserOidMailGet(oid: string, mail: string, tenantId: string, options?: AxiosRequestConfig) {
        return PrivilegesApiFp(this.configuration).apiTenantIdPrivilegesUserOidMailGet(oid, mail, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReadyApi - axios parameter creator
 * @export
 */
export const ReadyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReadyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ready`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReadyApi - functional programming interface
 * @export
 */
export const ReadyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReadyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReadyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReadyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReadyApi - factory interface
 * @export
 */
export const ReadyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReadyApiFp(configuration)
    return {
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReadyGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiReadyGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReadyApi - object-oriented interface
 * @export
 * @class ReadyApi
 * @extends {BaseAPI}
 */
export class ReadyApi extends BaseAPI {
    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadyApi
     */
    public apiReadyGet(options?: AxiosRequestConfig) {
        return ReadyApiFp(this.configuration).apiReadyGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get available dataSources
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportAvailableDataSourcesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReportAvailableDataSourcesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Report/AvailableDataSources`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete report
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportDelete: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReportDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Report`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get report
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportGet: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReportGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Report`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add report
         * @param {string} tenantId 
         * @param {AddReportDto} [addReportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportPost: async (tenantId: string, addReportDto?: AddReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReportPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Report`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit report
         * @param {string} tenantId 
         * @param {EditReportDto} [editReportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportPut: async (tenantId: string, editReportDto?: EditReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReportPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Report`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get available dataSources
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReportAvailableDataSourcesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Int32StringKeyValuePair>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReportAvailableDataSourcesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete report
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReportDelete(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReportDelete(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get report
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReportGet(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReportGet(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add report
         * @param {string} tenantId 
         * @param {AddReportDto} [addReportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReportPost(tenantId: string, addReportDto?: AddReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReportPost(tenantId, addReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit report
         * @param {string} tenantId 
         * @param {EditReportDto} [editReportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReportPut(tenantId: string, editReportDto?: EditReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReportPut(tenantId, editReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Get available dataSources
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportAvailableDataSourcesGet(tenantId: string, options?: any): AxiosPromise<Array<Int32StringKeyValuePair>> {
            return localVarFp.apiTenantIdReportAvailableDataSourcesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete report
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportDelete(tenantId: string, id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdReportDelete(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get report
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportGet(tenantId: string, id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdReportGet(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add report
         * @param {string} tenantId 
         * @param {AddReportDto} [addReportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportPost(tenantId: string, addReportDto?: AddReportDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdReportPost(tenantId, addReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit report
         * @param {string} tenantId 
         * @param {EditReportDto} [editReportDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportPut(tenantId: string, editReportDto?: EditReportDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdReportPut(tenantId, editReportDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @summary Get available dataSources
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiTenantIdReportAvailableDataSourcesGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiTenantIdReportAvailableDataSourcesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete report
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiTenantIdReportDelete(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiTenantIdReportDelete(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get report
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiTenantIdReportGet(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiTenantIdReportGet(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add report
     * @param {string} tenantId 
     * @param {AddReportDto} [addReportDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiTenantIdReportPost(tenantId: string, addReportDto?: AddReportDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiTenantIdReportPost(tenantId, addReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit report
     * @param {string} tenantId 
     * @param {EditReportDto} [editReportDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiTenantIdReportPut(tenantId: string, editReportDto?: EditReportDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiTenantIdReportPut(tenantId, editReportDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportODataApi - axios parameter creator
 * @export
 */
export const ReportODataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataReportOData: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataReportOData', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/ReportOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataReportODataCount: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataReportODataCount', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/ReportOData/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportODataGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdReportODataGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/ReportOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportODataApi - functional programming interface
 * @export
 */
export const ReportODataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportODataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataReportOData(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataReportOData(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataReportODataCount(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataReportODataCount(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdReportODataGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdReportODataGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportODataApi - factory interface
 * @export
 */
export const ReportODataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportODataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataReportOData(tenantId: string, options?: any): AxiosPromise<Array<ReportRM>> {
            return localVarFp.apiTenantIdOdataReportOData(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataReportODataCount(tenantId: string, options?: any): AxiosPromise<Array<ReportRM>> {
            return localVarFp.apiTenantIdOdataReportODataCount(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdReportODataGet(tenantId: string, options?: any): AxiosPromise<Array<ReportRM>> {
            return localVarFp.apiTenantIdReportODataGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportODataApi - object-oriented interface
 * @export
 * @class ReportODataApi
 * @extends {BaseAPI}
 */
export class ReportODataApi extends BaseAPI {
    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportODataApi
     */
    public apiTenantIdOdataReportOData(tenantId: string, options?: AxiosRequestConfig) {
        return ReportODataApiFp(this.configuration).apiTenantIdOdataReportOData(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportODataApi
     */
    public apiTenantIdOdataReportODataCount(tenantId: string, options?: AxiosRequestConfig) {
        return ReportODataApiFp(this.configuration).apiTenantIdOdataReportODataCount(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportODataApi
     */
    public apiTenantIdReportODataGet(tenantId: string, options?: AxiosRequestConfig) {
        return ReportODataApiFp(this.configuration).apiTenantIdReportODataGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Usuń rolę
         * @param {string} roleId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRoleDelete: async (roleId: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('apiTenantIdRoleDelete', 'roleId', roleId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdRoleDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Role`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz informacje o roli
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRoleGet: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdRoleGet', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdRoleGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Role`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dodaj rolę
         * @param {string} tenantId 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRolePost: async (tenantId: string, roleDto?: RoleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdRolePost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Role`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz wszystkie uprawnienia
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRolePrivilegesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdRolePrivilegesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Role/Privileges`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edytuj rolę
         * @param {string} tenantId 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRolePut: async (tenantId: string, roleDto?: RoleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdRolePut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Role`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz wszystkie role
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRoleRolesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdRoleRolesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Role/Roles`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz wszystkie role wraz z przypisanymi użytkownikami i uprawnieniami
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRoleRolesUsersPrivilegesGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdRoleRolesUsersPrivilegesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Role/RolesUsersPrivileges`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Usuń rolę
         * @param {string} roleId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdRoleDelete(roleId: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdRoleDelete(roleId, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz informacje o roli
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdRoleGet(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicRoleInfoResponseState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdRoleGet(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dodaj rolę
         * @param {string} tenantId 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdRolePost(tenantId: string, roleDto?: RoleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdRolePost(tenantId, roleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz wszystkie uprawnienia
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdRolePrivilegesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicPrivilegeInfoResponseIEnumerableState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdRolePrivilegesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edytuj rolę
         * @param {string} tenantId 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdRolePut(tenantId: string, roleDto?: RoleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdRolePut(tenantId, roleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz wszystkie role
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdRoleRolesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicRoleInfoResponseIEnumerableState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdRoleRolesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz wszystkie role wraz z przypisanymi użytkownikami i uprawnieniami
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdRoleRolesUsersPrivilegesGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdRoleRolesUsersPrivilegesGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 
         * @summary Usuń rolę
         * @param {string} roleId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRoleDelete(roleId: string, tenantId: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdRoleDelete(roleId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz informacje o roli
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRoleGet(id: string, tenantId: string, options?: any): AxiosPromise<BasicRoleInfoResponseState> {
            return localVarFp.apiTenantIdRoleGet(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dodaj rolę
         * @param {string} tenantId 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRolePost(tenantId: string, roleDto?: RoleDto, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdRolePost(tenantId, roleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz wszystkie uprawnienia
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRolePrivilegesGet(tenantId: string, options?: any): AxiosPromise<BasicPrivilegeInfoResponseIEnumerableState> {
            return localVarFp.apiTenantIdRolePrivilegesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edytuj rolę
         * @param {string} tenantId 
         * @param {RoleDto} [roleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRolePut(tenantId: string, roleDto?: RoleDto, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdRolePut(tenantId, roleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz wszystkie role
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRoleRolesGet(tenantId: string, options?: any): AxiosPromise<BasicRoleInfoResponseIEnumerableState> {
            return localVarFp.apiTenantIdRoleRolesGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz wszystkie role wraz z przypisanymi użytkownikami i uprawnieniami
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdRoleRolesUsersPrivilegesGet(tenantId: string, options?: any): AxiosPromise<Array<RoleDto>> {
            return localVarFp.apiTenantIdRoleRolesUsersPrivilegesGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @summary Usuń rolę
     * @param {string} roleId 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiTenantIdRoleDelete(roleId: string, tenantId: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiTenantIdRoleDelete(roleId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz informacje o roli
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiTenantIdRoleGet(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiTenantIdRoleGet(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dodaj rolę
     * @param {string} tenantId 
     * @param {RoleDto} [roleDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiTenantIdRolePost(tenantId: string, roleDto?: RoleDto, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiTenantIdRolePost(tenantId, roleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz wszystkie uprawnienia
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiTenantIdRolePrivilegesGet(tenantId: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiTenantIdRolePrivilegesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edytuj rolę
     * @param {string} tenantId 
     * @param {RoleDto} [roleDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiTenantIdRolePut(tenantId: string, roleDto?: RoleDto, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiTenantIdRolePut(tenantId, roleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz wszystkie role
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiTenantIdRoleRolesGet(tenantId: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiTenantIdRoleRolesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz wszystkie role wraz z przypisanymi użytkownikami i uprawnieniami
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiTenantIdRoleRolesUsersPrivilegesGet(tenantId: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiTenantIdRoleRolesUsersPrivilegesGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecurityLogApi - axios parameter creator
 * @export
 */
export const SecurityLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataSecurityLog: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataSecurityLog', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/SecurityLog`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataSecurityLogCount: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataSecurityLogCount', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/SecurityLog/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSecurityLogGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdSecurityLogGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/SecurityLog`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecurityLogApi - functional programming interface
 * @export
 */
export const SecurityLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecurityLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataSecurityLog(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SecurityEventLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataSecurityLog(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataSecurityLogCount(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SecurityEventLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataSecurityLogCount(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdSecurityLogGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SecurityEventLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdSecurityLogGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecurityLogApi - factory interface
 * @export
 */
export const SecurityLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecurityLogApiFp(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataSecurityLog(tenantId: string, options?: any): AxiosPromise<Array<SecurityEventLog>> {
            return localVarFp.apiTenantIdOdataSecurityLog(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataSecurityLogCount(tenantId: string, options?: any): AxiosPromise<Array<SecurityEventLog>> {
            return localVarFp.apiTenantIdOdataSecurityLogCount(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSecurityLogGet(tenantId: string, options?: any): AxiosPromise<Array<SecurityEventLog>> {
            return localVarFp.apiTenantIdSecurityLogGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecurityLogApi - object-oriented interface
 * @export
 * @class SecurityLogApi
 * @extends {BaseAPI}
 */
export class SecurityLogApi extends BaseAPI {
    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityLogApi
     */
    public apiTenantIdOdataSecurityLog(tenantId: string, options?: AxiosRequestConfig) {
        return SecurityLogApiFp(this.configuration).apiTenantIdOdataSecurityLog(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityLogApi
     */
    public apiTenantIdOdataSecurityLogCount(tenantId: string, options?: AxiosRequestConfig) {
        return SecurityLogApiFp(this.configuration).apiTenantIdOdataSecurityLogCount(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityLogApi
     */
    public apiTenantIdSecurityLogGet(tenantId: string, options?: AxiosRequestConfig) {
        return SecurityLogApiFp(this.configuration).apiTenantIdSecurityLogGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SelfInvoicingGrantedApi - axios parameter creator
 * @export
 */
export const SelfInvoicingGrantedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [objectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdSelfInvoicingGrantedBusinessLogGet: async (tenantId: string, organizationId: string, objectId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdSelfInvoicingGrantedBusinessLogGet', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdSelfInvoicingGrantedBusinessLogGet', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/SelfInvoicingGranted/business-log`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (objectId !== undefined) {
                localVarQueryParameter['objectId'] = objectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {string} [fileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdSelfInvoicingGrantedCreateReportPost: async (organizationId: string, tenantId: string, fileType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdSelfInvoicingGrantedCreateReportPost', 'organizationId', organizationId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdSelfInvoicingGrantedCreateReportPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/{organizationId}/SelfInvoicingGranted/create-report`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (fileType !== undefined) {
                localVarQueryParameter['fileType'] = fileType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {Array<CredentialsRoleResponseTokenType>} [credentialsRoleResponseTokenType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdSelfInvoicingGrantedPersistPut: async (tenantId: string, organizationId: string, credentialsRoleResponseTokenType?: Array<CredentialsRoleResponseTokenType>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdSelfInvoicingGrantedPersistPut', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdSelfInvoicingGrantedPersistPut', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/SelfInvoicingGranted/persist`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentialsRoleResponseTokenType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SelfInvoicingGrantedApi - functional programming interface
 * @export
 */
export const SelfInvoicingGrantedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SelfInvoicingGrantedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [objectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdSelfInvoicingGrantedBusinessLogGet(tenantId: string, organizationId: string, objectId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdSelfInvoicingGrantedBusinessLogGet(tenantId, organizationId, objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {string} [fileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdSelfInvoicingGrantedCreateReportPost(organizationId: string, tenantId: string, fileType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdSelfInvoicingGrantedCreateReportPost(organizationId, tenantId, fileType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {Array<CredentialsRoleResponseTokenType>} [credentialsRoleResponseTokenType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdSelfInvoicingGrantedPersistPut(tenantId: string, organizationId: string, credentialsRoleResponseTokenType?: Array<CredentialsRoleResponseTokenType>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdSelfInvoicingGrantedPersistPut(tenantId, organizationId, credentialsRoleResponseTokenType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SelfInvoicingGrantedApi - factory interface
 * @export
 */
export const SelfInvoicingGrantedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SelfInvoicingGrantedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {string} [objectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdSelfInvoicingGrantedBusinessLogGet(tenantId: string, organizationId: string, objectId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiTenantIdOrganizationIdSelfInvoicingGrantedBusinessLogGet(tenantId, organizationId, objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {string} tenantId 
         * @param {string} [fileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdSelfInvoicingGrantedCreateReportPost(organizationId: string, tenantId: string, fileType?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdSelfInvoicingGrantedCreateReportPost(organizationId, tenantId, fileType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {Array<CredentialsRoleResponseTokenType>} [credentialsRoleResponseTokenType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdSelfInvoicingGrantedPersistPut(tenantId: string, organizationId: string, credentialsRoleResponseTokenType?: Array<CredentialsRoleResponseTokenType>, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdOrganizationIdSelfInvoicingGrantedPersistPut(tenantId, organizationId, credentialsRoleResponseTokenType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SelfInvoicingGrantedApi - object-oriented interface
 * @export
 * @class SelfInvoicingGrantedApi
 * @extends {BaseAPI}
 */
export class SelfInvoicingGrantedApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {string} [objectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfInvoicingGrantedApi
     */
    public apiTenantIdOrganizationIdSelfInvoicingGrantedBusinessLogGet(tenantId: string, organizationId: string, objectId?: string, options?: AxiosRequestConfig) {
        return SelfInvoicingGrantedApiFp(this.configuration).apiTenantIdOrganizationIdSelfInvoicingGrantedBusinessLogGet(tenantId, organizationId, objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {string} tenantId 
     * @param {string} [fileType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfInvoicingGrantedApi
     */
    public apiTenantIdOrganizationIdSelfInvoicingGrantedCreateReportPost(organizationId: string, tenantId: string, fileType?: string, options?: AxiosRequestConfig) {
        return SelfInvoicingGrantedApiFp(this.configuration).apiTenantIdOrganizationIdSelfInvoicingGrantedCreateReportPost(organizationId, tenantId, fileType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {Array<CredentialsRoleResponseTokenType>} [credentialsRoleResponseTokenType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfInvoicingGrantedApi
     */
    public apiTenantIdOrganizationIdSelfInvoicingGrantedPersistPut(tenantId: string, organizationId: string, credentialsRoleResponseTokenType?: Array<CredentialsRoleResponseTokenType>, options?: AxiosRequestConfig) {
        return SelfInvoicingGrantedApiFp(this.configuration).apiTenantIdOrganizationIdSelfInvoicingGrantedPersistPut(tenantId, organizationId, credentialsRoleResponseTokenType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SelfInvoicingGrantedOdataApi - axios parameter creator
 * @export
 */
export const SelfInvoicingGrantedOdataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdata: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdata', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdata', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/SelfInvoicingGrantedOdata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdataCount: async (tenantId: string, organizationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdataCount', 'tenantId', tenantId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdataCount', 'organizationId', organizationId)
            const localVarPath = `/api/{tenantId}/{organizationId}/odata/SelfInvoicingGrantedOdata/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSelfInvoicingGrantedOdataGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdSelfInvoicingGrantedOdataGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/SelfInvoicingGrantedOdata`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SelfInvoicingGrantedOdataApi - functional programming interface
 * @export
 */
export const SelfInvoicingGrantedOdataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SelfInvoicingGrantedOdataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdata(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelfInvoicingGrantedRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdata(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdataCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelfInvoicingGrantedRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdataCount(tenantId, organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdSelfInvoicingGrantedOdataGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelfInvoicingGrantedRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdSelfInvoicingGrantedOdataGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SelfInvoicingGrantedOdataApi - factory interface
 * @export
 */
export const SelfInvoicingGrantedOdataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SelfInvoicingGrantedOdataApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdata(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<SelfInvoicingGrantedRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdata(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdataCount(tenantId: string, organizationId: string, options?: any): AxiosPromise<Array<SelfInvoicingGrantedRM>> {
            return localVarFp.apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdataCount(tenantId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSelfInvoicingGrantedOdataGet(tenantId: string, options?: any): AxiosPromise<Array<SelfInvoicingGrantedRM>> {
            return localVarFp.apiTenantIdSelfInvoicingGrantedOdataGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SelfInvoicingGrantedOdataApi - object-oriented interface
 * @export
 * @class SelfInvoicingGrantedOdataApi
 * @extends {BaseAPI}
 */
export class SelfInvoicingGrantedOdataApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfInvoicingGrantedOdataApi
     */
    public apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdata(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return SelfInvoicingGrantedOdataApiFp(this.configuration).apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdata(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfInvoicingGrantedOdataApi
     */
    public apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdataCount(tenantId: string, organizationId: string, options?: AxiosRequestConfig) {
        return SelfInvoicingGrantedOdataApiFp(this.configuration).apiTenantIdOrganizationIdOdataSelfInvoicingGrantedOdataCount(tenantId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfInvoicingGrantedOdataApi
     */
    public apiTenantIdSelfInvoicingGrantedOdataGet(tenantId: string, options?: AxiosRequestConfig) {
        return SelfInvoicingGrantedOdataApiFp(this.configuration).apiTenantIdSelfInvoicingGrantedOdataGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSettingsDisabledViewsPost: async (tenantId: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdSettingsDisabledViewsPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Settings/DisabledViews`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSettingsSecurityGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdSettingsSecurityGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Settings/security`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSettingsSecurityLogGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdSettingsSecurityLogGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Settings/SecurityLog`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {SecurityLogSettings} [securityLogSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSettingsSecurityLogPost: async (tenantId: string, securityLogSettings?: SecurityLogSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdSettingsSecurityLogPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Settings/SecurityLog`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(securityLogSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSettingsSecurityPost: async (tenantId: string, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdSettingsSecurityPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/Settings/security`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdSettingsDisabledViewsPost(tenantId: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdSettingsDisabledViewsPost(tenantId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdSettingsSecurityGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdSettingsSecurityGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdSettingsSecurityLogGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityLogSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdSettingsSecurityLogGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {SecurityLogSettings} [securityLogSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdSettingsSecurityLogPost(tenantId: string, securityLogSettings?: SecurityLogSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdSettingsSecurityLogPost(tenantId, securityLogSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdSettingsSecurityPost(tenantId: string, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdSettingsSecurityPost(tenantId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tenantId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSettingsDisabledViewsPost(tenantId: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdSettingsDisabledViewsPost(tenantId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSettingsSecurityGet(tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdSettingsSecurityGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSettingsSecurityLogGet(tenantId: string, options?: any): AxiosPromise<SecurityLogSettings> {
            return localVarFp.apiTenantIdSettingsSecurityLogGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {SecurityLogSettings} [securityLogSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSettingsSecurityLogPost(tenantId: string, securityLogSettings?: SecurityLogSettings, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdSettingsSecurityLogPost(tenantId, securityLogSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tenantId 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdSettingsSecurityPost(tenantId: string, body?: any, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdSettingsSecurityPost(tenantId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @param {string} tenantId 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiTenantIdSettingsDisabledViewsPost(tenantId: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiTenantIdSettingsDisabledViewsPost(tenantId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiTenantIdSettingsSecurityGet(tenantId: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiTenantIdSettingsSecurityGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiTenantIdSettingsSecurityLogGet(tenantId: string, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiTenantIdSettingsSecurityLogGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {SecurityLogSettings} [securityLogSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiTenantIdSettingsSecurityLogPost(tenantId: string, securityLogSettings?: SecurityLogSettings, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiTenantIdSettingsSecurityLogPost(tenantId, securityLogSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tenantId 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiTenantIdSettingsSecurityPost(tenantId: string, body?: any, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiTenantIdSettingsSecurityPost(tenantId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary All with organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantAllWithOrganizationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Tenant/all-with-organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary All with organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantAllWithOrganizationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantAllWithOrganizationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * 
         * @summary All with organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantAllWithOrganizationsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantAllWithOrganizationsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @summary All with organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public apiTenantAllWithOrganizationsGet(options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).apiTenantAllWithOrganizationsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Pobierz systemy dostępne dla użytkownika
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserAccountingSystemsGet: async (tenantId: string, nip?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserAccountingSystemsGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/User/AccountingSystems`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (nip !== undefined) {
                localVarQueryParameter['nip'] = nip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetApiUsersReport
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserApiReportGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserApiReportGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/User/api-report`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usuń użytkownika
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserDelete: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/User`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz informacje o użytkowniku
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserGet: async (tenantId: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/User`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Utwórz użytkownika
         * @param {string} tenantId 
         * @param {AddUserRequest} [addUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserPost: async (tenantId: string, addUserRequest?: AddUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/User`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edytuj użytkownika
         * @param {string} tenantId 
         * @param {EditUserRequest} [editUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserPut: async (tenantId: string, editUserRequest?: EditUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/User`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz efektywne uprawnienia dla użytkownika
         * @param {string} tenantId 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserUserPrivilegesGet: async (tenantId: string, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserUserPrivilegesGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/User/UserPrivileges`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pobierz wszystkich użytkowników z bazy
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserUsersGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserUsersGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/User/Users`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Pobierz systemy dostępne dla użytkownika
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserAccountingSystemsGet(tenantId: string, nip?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserAccountingSystemsGet(tenantId, nip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetApiUsersReport
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserApiReportGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserApiReportGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Usuń użytkownika
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserDelete(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserDelete(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz informacje o użytkowniku
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserGet(tenantId: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserGet(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Utwórz użytkownika
         * @param {string} tenantId 
         * @param {AddUserRequest} [addUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserPost(tenantId: string, addUserRequest?: AddUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserPost(tenantId, addUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edytuj użytkownika
         * @param {string} tenantId 
         * @param {EditUserRequest} [editUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserPut(tenantId: string, editUserRequest?: EditUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<State>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserPut(tenantId, editUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz efektywne uprawnienia dla użytkownika
         * @param {string} tenantId 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserUserPrivilegesGet(tenantId: string, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<UserPrivilegeWithRestrictionsDto>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserUserPrivilegesGet(tenantId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pobierz wszystkich użytkowników z bazy
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserUsersGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWithRolesResponseIEnumerableState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserUsersGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Pobierz systemy dostępne dla użytkownika
         * @param {string} tenantId 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserAccountingSystemsGet(tenantId: string, nip?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiTenantIdUserAccountingSystemsGet(tenantId, nip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetApiUsersReport
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserApiReportGet(tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdUserApiReportGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Usuń użytkownika
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserDelete(tenantId: string, id?: string, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdUserDelete(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz informacje o użytkowniku
         * @param {string} tenantId 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserGet(tenantId: string, id?: string, options?: any): AxiosPromise<UserState> {
            return localVarFp.apiTenantIdUserGet(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Utwórz użytkownika
         * @param {string} tenantId 
         * @param {AddUserRequest} [addUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserPost(tenantId: string, addUserRequest?: AddUserRequest, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdUserPost(tenantId, addUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edytuj użytkownika
         * @param {string} tenantId 
         * @param {EditUserRequest} [editUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserPut(tenantId: string, editUserRequest?: EditUserRequest, options?: any): AxiosPromise<State> {
            return localVarFp.apiTenantIdUserPut(tenantId, editUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz efektywne uprawnienia dla użytkownika
         * @param {string} tenantId 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserUserPrivilegesGet(tenantId: string, userId?: string, options?: any): AxiosPromise<{ [key: string]: Array<UserPrivilegeWithRestrictionsDto>; }> {
            return localVarFp.apiTenantIdUserUserPrivilegesGet(tenantId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pobierz wszystkich użytkowników z bazy
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserUsersGet(tenantId: string, options?: any): AxiosPromise<UserWithRolesResponseIEnumerableState> {
            return localVarFp.apiTenantIdUserUsersGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Pobierz systemy dostępne dla użytkownika
     * @param {string} tenantId 
     * @param {string} [nip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiTenantIdUserAccountingSystemsGet(tenantId: string, nip?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiTenantIdUserAccountingSystemsGet(tenantId, nip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetApiUsersReport
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiTenantIdUserApiReportGet(tenantId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiTenantIdUserApiReportGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Usuń użytkownika
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiTenantIdUserDelete(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiTenantIdUserDelete(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz informacje o użytkowniku
     * @param {string} tenantId 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiTenantIdUserGet(tenantId: string, id?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiTenantIdUserGet(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Utwórz użytkownika
     * @param {string} tenantId 
     * @param {AddUserRequest} [addUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiTenantIdUserPost(tenantId: string, addUserRequest?: AddUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiTenantIdUserPost(tenantId, addUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edytuj użytkownika
     * @param {string} tenantId 
     * @param {EditUserRequest} [editUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiTenantIdUserPut(tenantId: string, editUserRequest?: EditUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiTenantIdUserPut(tenantId, editUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz efektywne uprawnienia dla użytkownika
     * @param {string} tenantId 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiTenantIdUserUserPrivilegesGet(tenantId: string, userId?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiTenantIdUserUserPrivilegesGet(tenantId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pobierz wszystkich użytkowników z bazy
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiTenantIdUserUsersGet(tenantId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiTenantIdUserUsersGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserODataApi - axios parameter creator
 * @export
 */
export const UserODataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataUserOData: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataUserOData', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/UserOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataUserODataCount: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdOdataUserODataCount', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/odata/UserOData/$count`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserODataGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserODataGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/UserOData`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserODataApi - functional programming interface
 * @export
 */
export const UserODataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserODataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataUserOData(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataUserOData(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdOdataUserODataCount(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdOdataUserODataCount(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserODataGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserODataGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserODataApi - factory interface
 * @export
 */
export const UserODataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserODataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataUserOData(tenantId: string, options?: any): AxiosPromise<Array<UserRM>> {
            return localVarFp.apiTenantIdOdataUserOData(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdOdataUserODataCount(tenantId: string, options?: any): AxiosPromise<Array<UserRM>> {
            return localVarFp.apiTenantIdOdataUserODataCount(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserODataGet(tenantId: string, options?: any): AxiosPromise<Array<UserRM>> {
            return localVarFp.apiTenantIdUserODataGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserODataApi - object-oriented interface
 * @export
 * @class UserODataApi
 * @extends {BaseAPI}
 */
export class UserODataApi extends BaseAPI {
    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserODataApi
     */
    public apiTenantIdOdataUserOData(tenantId: string, options?: AxiosRequestConfig) {
        return UserODataApiFp(this.configuration).apiTenantIdOdataUserOData(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserODataApi
     */
    public apiTenantIdOdataUserODataCount(tenantId: string, options?: AxiosRequestConfig) {
        return UserODataApiFp(this.configuration).apiTenantIdOdataUserODataCount(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserODataApi
     */
    public apiTenantIdUserODataGet(tenantId: string, options?: AxiosRequestConfig) {
        return UserODataApiFp(this.configuration).apiTenantIdUserODataGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserSettingsApi - axios parameter creator
 * @export
 */
export const UserSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Zwróć wszystkie ustawienia dla autoodświeżania
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserSettingsAutorefreshGet: async (tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserSettingsAutorefreshGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/UserSettings/Autorefresh`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Usuń opcje dla użytkownika
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserSettingsIdDelete: async (id: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdUserSettingsIdDelete', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserSettingsIdDelete', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/UserSettings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edytuj opcje dla użytkownika
         * @param {string} id 
         * @param {string} tenantId 
         * @param {UserSettingsDto} [userSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserSettingsIdPut: async (id: string, tenantId: string, userSettingsDto?: UserSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTenantIdUserSettingsIdPut', 'id', id)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserSettingsIdPut', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/UserSettings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stwórz opcje dla użytkownika
         * @param {string} tenantId 
         * @param {UserSettingsDto} [userSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserSettingsPost: async (tenantId: string, userSettingsDto?: UserSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserSettingsPost', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/UserSettings`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zwróć ustawienia użytkownika
         * @param {string} userId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserSettingsUserIdGet: async (userId: string, tenantId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiTenantIdUserSettingsUserIdGet', 'userId', userId)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('apiTenantIdUserSettingsUserIdGet', 'tenantId', tenantId)
            const localVarPath = `/api/{tenantId}/UserSettings/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSettingsApi - functional programming interface
 * @export
 */
export const UserSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Zwróć wszystkie ustawienia dla autoodświeżania
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserSettingsAutorefreshGet(tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserSettingsAutorefreshGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Usuń opcje dla użytkownika
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserSettingsIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserSettingsIdDelete(id, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edytuj opcje dla użytkownika
         * @param {string} id 
         * @param {string} tenantId 
         * @param {UserSettingsDto} [userSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserSettingsIdPut(id: string, tenantId: string, userSettingsDto?: UserSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserSettingsIdPut(id, tenantId, userSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stwórz opcje dla użytkownika
         * @param {string} tenantId 
         * @param {UserSettingsDto} [userSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserSettingsPost(tenantId: string, userSettingsDto?: UserSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserSettingsPost(tenantId, userSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Zwróć ustawienia użytkownika
         * @param {string} userId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTenantIdUserSettingsUserIdGet(userId: string, tenantId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTenantIdUserSettingsUserIdGet(userId, tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserSettingsApi - factory interface
 * @export
 */
export const UserSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Zwróć wszystkie ustawienia dla autoodświeżania
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserSettingsAutorefreshGet(tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdUserSettingsAutorefreshGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Usuń opcje dla użytkownika
         * @param {string} id 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserSettingsIdDelete(id: string, tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdUserSettingsIdDelete(id, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edytuj opcje dla użytkownika
         * @param {string} id 
         * @param {string} tenantId 
         * @param {UserSettingsDto} [userSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserSettingsIdPut(id: string, tenantId: string, userSettingsDto?: UserSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdUserSettingsIdPut(id, tenantId, userSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stwórz opcje dla użytkownika
         * @param {string} tenantId 
         * @param {UserSettingsDto} [userSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserSettingsPost(tenantId: string, userSettingsDto?: UserSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdUserSettingsPost(tenantId, userSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Zwróć ustawienia użytkownika
         * @param {string} userId 
         * @param {string} tenantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTenantIdUserSettingsUserIdGet(userId: string, tenantId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTenantIdUserSettingsUserIdGet(userId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSettingsApi - object-oriented interface
 * @export
 * @class UserSettingsApi
 * @extends {BaseAPI}
 */
export class UserSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Zwróć wszystkie ustawienia dla autoodświeżania
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public apiTenantIdUserSettingsAutorefreshGet(tenantId: string, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).apiTenantIdUserSettingsAutorefreshGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Usuń opcje dla użytkownika
     * @param {string} id 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public apiTenantIdUserSettingsIdDelete(id: string, tenantId: string, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).apiTenantIdUserSettingsIdDelete(id, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edytuj opcje dla użytkownika
     * @param {string} id 
     * @param {string} tenantId 
     * @param {UserSettingsDto} [userSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public apiTenantIdUserSettingsIdPut(id: string, tenantId: string, userSettingsDto?: UserSettingsDto, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).apiTenantIdUserSettingsIdPut(id, tenantId, userSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stwórz opcje dla użytkownika
     * @param {string} tenantId 
     * @param {UserSettingsDto} [userSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public apiTenantIdUserSettingsPost(tenantId: string, userSettingsDto?: UserSettingsDto, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).apiTenantIdUserSettingsPost(tenantId, userSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Zwróć ustawienia użytkownika
     * @param {string} userId 
     * @param {string} tenantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsApi
     */
    public apiTenantIdUserSettingsUserIdGet(userId: string, tenantId: string, options?: AxiosRequestConfig) {
        return UserSettingsApiFp(this.configuration).apiTenantIdUserSettingsUserIdGet(userId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


