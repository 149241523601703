import { batchPackageGridType } from "./const"

export const toolbarButtonsBatchPackages = ({ deleteBatch, dataGridRef, organizationId, selectedItem, processingDisabled, failedDisabled,
  repositoryDisabled, setSignPopupVisible, setEventLogsVisible, navigate, dataSourceOptions, setSelectedItem,
  reloadGrid, gridType, currentOrganizationDisabled, t }) => {
  return [
    {
      id: "select-columns", icon: 'grid', text: t('button-column-management'),
      onClick: async () => { dataGridRef.current.instance.showColumnChooser() },
      requireMultiSelection: true
    },
    { icon: 'folderPlusIcon', text: t("#_toolbarbuttonsbatchpackages_1"), disabled: selectedItem === null || processingDisabled, 
    onClick: () => navigate
    ({ 
      pathname: gridType === batchPackageGridType.DispatchType ? '/dispatch-sale-processing' :
                gridType === batchPackageGridType.SelfInvoicingType ? '/dispatch-selfinvoicing-processing' : 
                '/dispatch-other-processing', 
      search: '?BatchId=' + selectedItem?.Id 
    })},
    { icon: 'folderPlusIcon', text: t("#_toolbarbuttonsbatchpackages_2"), disabled: selectedItem === null || failedDisabled, 
    onClick: () => navigate
    ({ 
      pathname: gridType === batchPackageGridType.DispatchType ? '/repository-dispatcherrors-failed' :
                gridType === batchPackageGridType.SelfInvoicingType ? '/repository-dispatcherrors-selfinvoicing' : 
                '/repository-dispatcherrors-other', 
      search: '?BatchId=' + selectedItem?.Id 
    })},
    { icon: 'folderPlusIcon', text: t("#_toolbarbuttonsbatchpackages_3"), disabled: selectedItem === null || repositoryDisabled, 
    onClick: () => navigate
    ({ 
      pathname: gridType === batchPackageGridType.DispatchType ? '/repository-sales' :
                gridType === batchPackageGridType.SelfInvoicingType ? '/repository-purchase' : 
                '/repository-other', 
      search: '?BatchId=' + selectedItem?.Id 
    })},
    {
      icon: 'send', text: t("#_toolbarbuttonsbatchpackages_4"), onClick: async () => {
        setSignPopupVisible(true)
      }, disabled: currentOrganizationDisabled || selectedItem === null || !selectedItem.CanBeSigned
    },
    {
      icon: 'trash', text: t("#_toolbarbuttonsbatchpackages_5"), onClick: async () => {
        await deleteBatch()
      }, disabled: currentOrganizationDisabled || selectedItem === null || !selectedItem.CanBeDeleted
    },
    { icon: 'textdocument', text: t("#_toolbarbuttonsbatchpackages_6"), disabled: selectedItem === null, onClick: async () => { setEventLogsVisible(true) } }
  ]
}
