import { convertToColumns } from './GenericGrid'
import { useState, useEffect, useMemo } from "react"
import GenericToolbar from '../settings/Toolbars/GenericToolbar';
import { ToolbarItem } from 'devextreme-react/popup';
import { XfXApi } from '../../api/XfXApi';
import DataGrid, {
  Paging,
  Selection,
  Scrolling,
  FilterRow,
  HeaderFilter,
  Search
} from 'devextreme-react/data-grid';
import { DateTimeFormat } from 'utils/cellRenderers';
import { bpmnProcessKeyToLabel, moduleResultToLabel, businessEventToLabel, timestampUtcToLabel, businessErrorToLabel, UserDetailsPopup, BusinessErrorDetailsPopup } from './EventLogsTools';
import notify from 'devextreme/ui/notify';
import GenericPopup from "components/popup/GenericPopup";
import { useUserSettingsContext } from '../../contexts/UserSettingsProvider';
import { useTranslation } from 'react-i18next';
import { useOrganizations } from 'contexts/OrganizationContext';

const bpmnProcessKeyPrefix = "#_BpmnProcessKey_"
const businessEventPrefix = "#_BusinessEvent_"

const renderDetails = (x, t, organizations) => {
  if (x.businessEvent.startsWith("Migrated")) {
    try {
      const migrationDetails = JSON.parse(x.details)
      return `${t("Migration_moved_to")}: ${organizations?.find(x => x.nip === migrationDetails.ToOrganization)?.fullName} ${migrationDetails.ToOrganization}`
    }
    catch (error) {
      return "?"
    }
  }
  return t(x.details)
}



export const EventLogsTypes = {
  Dispatch: 0,
  Reception: 1,
  Batch: 2,
  ReceptionCheckups: 3,
  Sessions: 4,
  Jobs: 5,
  SelfInvoicingGranted: 6
}

const EventLogsGrid = (columns, dataSource, setSelectedItem) => {
  const grid =
    <>
      <DataGrid
        dataSource={dataSource}
        showBorders={true}
        height={580}
        rowAlternationEnabled={true}
        showColumnLines={true}
        onSelectionChanged={s => {
          setSelectedItem(s.selectedRowsData.length > 0 ? s.selectedRowsData[0] : null)
        }}
      >
        <Scrolling mode="virtual" preloadEnabled={true} />
        <Paging defaultPageSize={100} />
        <HeaderFilter>
          <Search
            enabled={true}
          >
          </Search>
        </HeaderFilter>
        <FilterRow visible={true} applyFilter={true} />
        <Selection mode="single" />
        {columns}
      </DataGrid>
    </>
  return grid
}

export const EventLogsPopup = ({ type, guid, setEventLogsVisible, eventLogsVisible, setBlockAutoRefresh }) => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState(null)
  const [eventLogs, setEventLogs] = useState([])
  const [userDetails, setUserDetails] = useState([])
  const [userDetailsVisible, setUserDetailsVisible] = useState(false)
  const [businessErrorDetails, setBusinessErrorDetails] = useState([])
  const [businessErrorDetailsVisible, setBusinessErrorDetailsVisible] = useState(false)
  const { organizations } = useOrganizations()

  const data = useMemo(() => [
    { dataField: "id", caption: t("#_eventlogspopup_2"), visible: false },
    { dataField: "objectId", caption: t("#_eventlogspopup_3"), visible: false },
    { dataField: "timestampUtc", dataType: "datetime", caption: t("#_eventlogspopup_4"), format: DateTimeFormat, width: "auto", calculateCellValue: timestampUtcToLabel },
    { dataField: "businessEvent", caption: t("#_eventlogspopup_6"), width: "auto", calculateCellValue: x => businessEventToLabel(x, t, businessEventPrefix) },
    { dataField: "bpmnProcessKey", caption: t("#_eventlogspopup_7"), width: "auto", calculateCellValue: x => bpmnProcessKeyToLabel(x, t, bpmnProcessKeyPrefix) },
    { dataField: "moduleId", caption: t("#_eventlogspopup_8"), width: "auto" },
    { dataField: "moduleResult", caption: t("#_eventlogspopup_9"), width: "auto", calculateCellValue: x => moduleResultToLabel(x, t) },
    { dataField: "details", caption: t("#_eventlogspopup_15"), calculateCellValue: x => renderDetails(x, t, organizations) },
    { dataField: "businessError", caption: t("#_eventlogspopup_10"), calculateCellValue: businessErrorToLabel },
    { dataField: "userId", caption: t("#_eventlogspopup_5"), visible: false }, //tu trzeba będzie jeżeli będziemy chcieli to pokazywać łączyć do tabeli użytkowników i zwracać mail czy cuś a nie id
  ], [t, organizations])

  const objectId = guid.hasOwnProperty("_value") ? guid._value : guid;

  const { getOrganizationContextSettings } = useUserSettingsContext()
  const organizationId = getOrganizationContextSettings()?.NIP

  const fetchEventLogs = async () => {
    try {
      const data =
        type === EventLogsTypes.Dispatch ? await XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesDispatchBusinessLogGet(XfXApi.GetTenantId(), organizationId, objectId) :
          type === EventLogsTypes.Reception ? await XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesReceptionBusinessLogGet(XfXApi.GetTenantId(), organizationId, objectId) :
            type === EventLogsTypes.Sessions ? await XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesSessionsBusinessLogGet(XfXApi.GetTenantId(), organizationId, objectId) :
              type === EventLogsTypes.Batch ? await XfXApi.BatchApi.apiTenantIdOrganizationIdBatchBusinessLogGet(objectId, XfXApi.GetTenantId(), organizationId) :
                type === EventLogsTypes.ReceptionCheckups ? await XfXApi.InvoiceApi.apiTenantIdOrganizationIdInvoicesReceptioncheckupsBusinessLogGet(XfXApi.GetTenantId(), organizationId, objectId) :
                  type === EventLogsTypes.SelfInvoicingGranted ? await XfXApi.SelfInvoicingGranted.apiTenantIdOrganizationIdSelfInvoicingGrantedBusinessLogGet(XfXApi.GetTenantId(), organizationId, objectId) :
                    await XfXApi.JobsApi.apiTenantIdOrganizationIdJobsBusinessLogGet(XfXApi.GetTenantId(), organizationId, objectId)

      setEventLogs(data.data)
    } catch (error) {
      //console.error(error)
    }
  }

  useEffect(() => {
    fetchEventLogs()
  }, [])

  const columns = convertToColumns(data)

  const getUserDetails = async () => {
    try {
      if (selectedItem?.userId !== -1) {
        const data = await XfXApi.User.apiTenantIdUserGet(XfXApi.GetTenantId(), selectedItem?.userId)
        setUserDetails(data?.data?.stateObject)
      }
      else {
        const maintenanceModeDetails = {
          login: "admin", //Tu pytanie czy nie trzeba by potem zmienić jednak na to co jest w appsettings maintenancemode:Username jak ktoś sobie to zmieni
          firstName: "",
          lastName: "",
          type: 0 //admin to w sumie też typ NaturalPerson
        }
        setUserDetails(maintenanceModeDetails)
      }
      setUserDetailsVisible(true)
    } catch (error) {
      notify({
        message: t("#_eventlogspopup_11"),
        position: {
          my: 'center bottom',
          at: 'center bottom',
        },
      }, 'error', 2000);
    }
  }

  const getBusinessErrorDetails = async () => {
    setBusinessErrorDetails(selectedItem?.businessError)
    setBusinessErrorDetailsVisible(true)
  }

  const toolbarButtons = [
    { icon: 'user', text: t("#_eventlogspopup_12"), disabled: selectedItem?.userId === null || selectedItem?.userId === undefined, onClick: getUserDetails },
    { icon: 'warning', text: t("#_eventlogspopup_13"), disabled: selectedItem?.businessError === null || selectedItem?.businessError === undefined, onClick: getBusinessErrorDetails }
  ]

  const popup = GenericPopup({
    onHiding: () => popup.close(),
    showTitle: false,
    content:
      <div>
        <div id="dataGrid-eventLogs" className="dataGrid">
          <GenericToolbar className="xfx-toolbar-bigbuttons" header={t("#_eventlogspopup_1")} buttons={toolbarButtons} showIcon={true} icon='dx-icon-textdocument' showSimpleHeader={true}></GenericToolbar>
          {EventLogsGrid(columns, eventLogs, setSelectedItem)}
        </div>
      </div>,
    toolbarItems: [<ToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="after"
      options={{
        text: t("#_eventlogspopup_14"),
        onClick: () => {
          popup.close()
        }
      }} />],
    title: "",
    width: "80%",
    height: "80%",
    isVisible: eventLogsVisible,
    setIsVisible: setEventLogsVisible,
    setBlockAutoRefresh: setBlockAutoRefresh
  })

  return (
    <>
      {popup.popup}
      {userDetailsVisible &&
        <UserDetailsPopup
          user={userDetails}
          setUserDetailsVisible={setUserDetailsVisible}
          userDetailsVisible={userDetailsVisible}>
        </UserDetailsPopup>
      }
      {businessErrorDetailsVisible &&
        <BusinessErrorDetailsPopup
          businessError={businessErrorDetails}
          setBusinessErrorDetailsVisible={setBusinessErrorDetailsVisible}
          businessErrorDetailsVisible={businessErrorDetailsVisible}>
        </BusinessErrorDetailsPopup>
      }
    </>
  );
}

export default EventLogsPopup 
