import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { XfXApi } from 'api/XfXApi';
import { useAuth } from 'contexts/auth';
import { BasicOrganizationInfoResponse } from "api/openapi";

interface OrganizationsContextType {
    organizations: BasicOrganizationInfoResponse[];
    fetchOrganizations: () => void;
    refreshOrganizations: () => void;
    getOrganizationFullNameByNip: (nip: string) => string | null;
    isOrganizationDisabled: (organizationNip: string) => boolean;
}

const OrganizationsContext = createContext<OrganizationsContextType | undefined>(undefined);

export const useOrganizations = () => {
    const context = useContext(OrganizationsContext);
    if (!context) {
        throw new Error('useOrganizations must be used within an OrganizationsProvider');
    }
    return context;
};

export const OrganizationsProvider = ({ children }: { children: ReactNode }) => {
    const [organizations, setOrganizations] = useState<BasicOrganizationInfoResponse[]>([]);
    const { currentTenantId }: any = useAuth();

    const fetchOrganizations = useCallback(async () => {
        try {
            if (!currentTenantId) return;
            const { data } = await XfXApi.Organization.apiTenantIdOrganizationUserOrganizationsGet(XfXApi.GetTenantId())
            setOrganizations(data);
        } catch (error) {
            console.error(error);
        }
    }, [currentTenantId]);

    const refreshOrganizations = useCallback(() => {
        fetchOrganizations();
    }, [fetchOrganizations]);

    const getOrganizationFullNameByNip = useCallback((nip: string): string | null => {
        const organization = organizations.find(org => org.nip === nip);
        return organization?.fullName || null;
    }, [organizations]);

    const isOrganizationDisabled = useCallback((organizationNip: string) => {
        return !organizations.find((organization) => organization.nip === organizationNip)?.isActive;
    }, [organizations]);

    useEffect(() => {
        fetchOrganizations();
    }, [fetchOrganizations]);

    return (
        <OrganizationsContext.Provider value={{ organizations, fetchOrganizations, refreshOrganizations, getOrganizationFullNameByNip, isOrganizationDisabled }}>
            {children}
        </OrganizationsContext.Provider>
    );
};