import { InvoicesEndpoints } from 'api/XfXApi'
import { draftColumns, sendInvoiceColumns, sendInvoiceAcceptanceColumns, receivedInvoiceColumns, receivedInvoiceAcceptanceColumns, repositoryDispatchColumns, repositoryReceivedInvoiceColumns, dispatchFailedInvoiceColumns, receivedFailedInvoiceColumns } from 'pages/Invoices/OneGridColumns'
import OneInvoiceGrid from 'pages/Invoices/OneInvoiceGrid'
import { subjectTypes } from 'pages/Invoices/Popups/CreateBatchPopup'
export const InvoiceDraftGrid = () => OneInvoiceGrid({
	id: 'InvoiceDraft',
	endpoint: InvoicesEndpoints.InvoiceDraft,
	breadCrumbs: ['menu/drafts'],
	buttonsIds: ['draft-import','new-invoice','edit-draft','delete-draft','send-draft','preview-draft-xml','download-draft-xml'],
	privileges: ['dispatch_invoice_draft'],
	getColumns: draftColumns,
	custom: {draft: true, subjectType: subjectTypes.Subject1}
})
export const DispatchSaleProcessingGrid = () => OneInvoiceGrid({
	id: 'DispatchSaleProcessing',
	endpoint: InvoicesEndpoints.DispatchSaleProcessing,
	breadCrumbs: ['menu/dispatch', 'menu/dispatch/sale', 'menu/dispatch/sale/processing'],
	buttonsIds: ['select-all','import','import-from-csv','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','batch-send','logs','attachments','tags','export-rows-data'],
	privileges: ['dispatch_sales_processing'],
	getColumns: sendInvoiceColumns,
	custom: {subjectType: subjectTypes.Subject1}
})
export const DispatchSaleAcceptanceGrid = () => OneInvoiceGrid({
	id: 'DispatchSaleAcceptance',
	endpoint: InvoicesEndpoints.DispatchSaleAcceptance,
	breadCrumbs: ['menu/dispatch', 'menu/dispatch/sale', 'menu/dispatch/sale/acceptance'],
	buttonsIds: ['select-all','revoke-selected','accept-selected','show-accepters','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','logs','attachments','tags','export-rows-data'],
	privileges: ['dispatch_sales_acceptance'],
	getColumns: sendInvoiceAcceptanceColumns,
})
export const DispatchSaleBatchToSendGrid = () => OneInvoiceGrid({
	id: 'DispatchSaleBatchToSend',
	endpoint: InvoicesEndpoints.DispatchSaleBatchToSend,
	breadCrumbs: ['menu/dispatch', 'menu/dispatch/sale', 'menu/dispatch/sale/create-batch-package'],
	buttonsIds: ['select-all','batch-create','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','logs','attachments','tags','export-rows-data'],
	privileges: ['dispatch_sales_batchsend'],
	getColumns: sendInvoiceColumns,
	custom: {subjectType: subjectTypes.Subject1}
})
export const DispatchSelfInvoicingProcessingGrid = () => OneInvoiceGrid({
	id: 'DispatchSelfInvoicingProcessing',
	endpoint: InvoicesEndpoints.DispatchSelfInvoicingProcessing,
	breadCrumbs: ['menu/dispatch', 'menu/dispatch/selfinvoicing', 'menu/dispatch/selfinvoicing/processing'],
	buttonsIds: ['select-all','import','import-from-csv','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','batch-send','logs','attachments','tags','export-rows-data'],
	privileges: ['dispatch_selfinvoicing_processing'],
	getColumns: sendInvoiceColumns,
	custom: {subjectType: subjectTypes.Subject2}
})
export const DispatchSelfInvoicingAcceptanceGrid = () => OneInvoiceGrid({
	id: 'DispatchSelfInvoicingAcceptance',
	endpoint: InvoicesEndpoints.DispatchSelfInvoicingAcceptance,
	breadCrumbs: ['menu/dispatch', 'menu/dispatch/selfinvoicing', 'menu/dispatch/selfinvoicing/acceptance'],
	buttonsIds: ['select-all','revoke-selected','accept-selected','show-accepters','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','logs','attachments','tags','export-rows-data'],
	privileges: ['dispatch_selfinvoicing_acceptance'],
	getColumns: sendInvoiceAcceptanceColumns,
})
export const DispatchSelfInvoicingBatchToSendGrid = () => OneInvoiceGrid({
	id: 'DispatchSelfInvoicingBatchToSend',
	endpoint: InvoicesEndpoints.DispatchSelfInvoicingBatchToSend,
	breadCrumbs: ['menu/dispatch', 'menu/dispatch/selfinvoicing', 'menu/dispatch/selfinvoicing/create-batch-package2'],
	buttonsIds: ['select-all','batch-create','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','logs','attachments','tags','export-rows-data'],
	privileges: ['dispatch_selfinvoicing_batchsend'],
	getColumns: sendInvoiceColumns,
	custom: {subjectType: subjectTypes.Subject2}
})
export const ReceptionPurchaseProcessingGrid = () => OneInvoiceGrid({
	id: 'ReceptionPurchaseProcessing',
	endpoint: InvoicesEndpoints.ReceptionPurchaseProcessing,
	breadCrumbs: ['menu/reception', 'menu/reception/purchase', 'menu/reception/purchase/processing'],
	buttonsIds: ['select-all','sync','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','logs','tags','export-rows-data'],
	privileges: ['reception_purchaser_processing'],
	getColumns: receivedInvoiceColumns,
})
export const ReceptionPurchaseAlertGrid = () => OneInvoiceGrid({
	id: 'ReceptionPurchaseAlert',
	endpoint: InvoicesEndpoints.ReceptionPurchaseAlert,
	breadCrumbs: ['menu/reception', 'menu/reception/purchase', 'menu/reception/purchase/alert'],
	buttonsIds: ['select-all','revoke-selected','accept-selected','show-accepters','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','logs','attachments','tags','export-rows-data'],
	privileges: ['reception_purchaser_acceptance'],
	getColumns: receivedInvoiceAcceptanceColumns,
})
export const ReceptionPurchaseP3ProcessingGrid = () => OneInvoiceGrid({
	id: 'ReceptionPurchaseP3Processing',
	endpoint: InvoicesEndpoints.ReceptionPurchaseP3Processing,
	breadCrumbs: ['menu/reception', 'menu/reception/purchasep3', 'menu/reception/purchasep3/processing'],
	buttonsIds: ['select-all','sync','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','logs','tags','export-rows-data'],
	privileges: ['reception_additional_buyer_processing'],
	getColumns: receivedInvoiceColumns,
})
export const ReceptionPurchaseP3AlertGrid = () => OneInvoiceGrid({
	id: 'ReceptionPurchaseP3Alert',
	endpoint: InvoicesEndpoints.ReceptionPurchaseP3Alert,
	breadCrumbs: ['menu/reception', 'menu/reception/purchasep3', 'menu/reception/purchasep3/alert'],
	buttonsIds: ['select-all','revoke-selected','accept-selected','show-accepters','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','logs','attachments','tags','export-rows-data'],
	privileges: ['reception_additional_buyer_acceptance'],
	getColumns: receivedInvoiceAcceptanceColumns,
})
export const ReceptionSelfInvoicingSaleGrid = () => OneInvoiceGrid({
	id: 'ReceptionSelfInvoicingSale',
	endpoint: InvoicesEndpoints.ReceptionSelfInvoicingSale,
	breadCrumbs: ['menu/reception', 'menu/reception/selfinvoicing', 'menu/reception/selfinvoicing/sale'],
	buttonsIds: ['select-all','sync','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','logs','tags','export-rows-data'],
	privileges: ['reception_selfinvoicing_processing'],
	getColumns: receivedInvoiceColumns,
})
export const ReceptionSelfInvoicingAlertGrid = () => OneInvoiceGrid({
	id: 'ReceptionSelfInvoicingAlert',
	endpoint: InvoicesEndpoints.ReceptionSelfInvoicingAlert,
	breadCrumbs: ['menu/reception', 'menu/reception/selfinvoicing', 'menu/reception/selfinvoicing/alert'],
	buttonsIds: ['select-all','revoke-selected','accept-selected','show-accepters','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','logs','attachments','tags','export-rows-data'],
	privileges: ['reception_selfinvoicing_acceptance'],
	getColumns: receivedInvoiceAcceptanceColumns,
})
export const ReceptionRepresentationProcessingGrid = () => OneInvoiceGrid({
	id: 'ReceptionRepresentationProcessing',
	endpoint: InvoicesEndpoints.ReceptionRepresentationProcessing,
	breadCrumbs: ['menu/reception', 'menu/reception/representation', 'menu/reception/representation/processing'],
	buttonsIds: ['select-all','sync','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','logs','tags','export-rows-data'],
	privileges: ['reception_ext_issuer_processing'],
	getColumns: receivedInvoiceColumns,
})
export const ReceptionSaleProcessingGrid = () => OneInvoiceGrid({
	id: 'ReceptionSaleProcessing',
	endpoint: InvoicesEndpoints.ReceptionSaleProcessing,
	breadCrumbs: ['menu/reception', 'menu/reception/sale', 'menu/reception/sale/processing'],
	buttonsIds: ['select-all','sync','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','logs','tags','export-rows-data'],
	privileges: ['reception_ext_sales_processing'],
	getColumns: receivedInvoiceColumns,
})
export const ReceptionOtherProcessingGrid = () => OneInvoiceGrid({
	id: 'ReceptionOtherProcessing',
	endpoint: InvoicesEndpoints.ReceptionOtherProcessing,
	breadCrumbs: ['menu/reception', 'menu/reception/other', 'menu/reception/other/processing'],
	buttonsIds: ['select-all','sync','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','logs','tags','export-rows-data'],
	privileges: ['reception_other_processing'],
	getColumns: receivedInvoiceColumns,
})
export const RepositorySalesGrid = () => OneInvoiceGrid({
	id: 'RepositorySales',
	endpoint: InvoicesEndpoints.RepositorySales,
	breadCrumbs: ['menu/repository', 'menu/repository/sales'],
	buttonsIds: ['select-all','correct','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','preview-upo-xml','download-upo-xml','preview-upo-pdf','download-upo-pdf','batch-send','logs','attachments','tags','export-rows-data','mark-as-received','get-upo-on-demand','migrate-sale-invoice'],
	privileges: ['sales'],
	getColumns: repositoryDispatchColumns,
})
export const RepositoryPurchaseGrid = () => OneInvoiceGrid({
	id: 'RepositoryPurchase',
	endpoint: InvoicesEndpoints.RepositoryPurchase,
	breadCrumbs: ['menu/repository', 'menu/repository/purchase'],
	buttonsIds: ['select-all','correct-self-invoice','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','preview-upo-xml','download-upo-xml','preview-upo-pdf','download-upo-pdf','batch-send','logs','attachments','tags','export-rows-data','mark-as-received','get-upo-on-demand','migrate-purchase-invoice'],
	privileges: ['purchaser'],
	getColumns: repositoryReceivedInvoiceColumns,
})
export const RepositoryOtherGrid = () => OneInvoiceGrid({
	id: 'RepositoryOther',
	endpoint: InvoicesEndpoints.RepositoryOther,
	breadCrumbs: ['menu/repository', 'menu/repository/other'],
	buttonsIds: ['select-all','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','preview-upo-xml','download-upo-xml','preview-upo-pdf','download-upo-pdf','batch-send','logs','attachments','tags','export-rows-data','mark-as-received'],
	privileges: ['other'],
	getColumns: repositoryDispatchColumns,
})
export const DispatchSaleFailedGrid = () => OneInvoiceGrid({
	id: 'DispatchSaleFailed',
	endpoint: InvoicesEndpoints.DispatchSaleFailed,
	breadCrumbs: ['menu/repository', 'menu/repository/dispatcherrors', 'menu/repository/dispatcherrors/failed'],
	buttonsIds: ['select-all','preview-error','download-error','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','batch-send','logs','attachments','tags','export-rows-data','resend-invoice'],
	privileges: ['dispatch_sales_failed'],
	getColumns: dispatchFailedInvoiceColumns,
})
export const DispatchSelfInvoicingFailedGrid = () => OneInvoiceGrid({
	id: 'DispatchSelfInvoicingFailed',
	endpoint: InvoicesEndpoints.DispatchSelfInvoicingFailed,
	breadCrumbs: ['menu/repository', 'menu/repository/dispatcherrors', 'menu/repository/dispatcherrors/selfinvoicing'],
	buttonsIds: ['select-all','preview-error','download-error','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','batch-send','logs','attachments','tags','export-rows-data','resend-invoice'],
	privileges: ['dispatch_selfinvoicing_failed'],
	getColumns: dispatchFailedInvoiceColumns,
})
export const ReceptionPurchaseRevokedGrid = () => OneInvoiceGrid({
	id: 'ReceptionPurchaseRevoked',
	endpoint: InvoicesEndpoints.ReceptionPurchaseRevoked,
	breadCrumbs: ['menu/repository', 'menu/repository/receptionerrors', 'menu/repository/receptionerrors/revoked'],
	buttonsIds: ['select-all','preview-error','download-error','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','logs','tags','export-rows-data'],
	privileges: ['reception_purchaser_failed'],
	getColumns: receivedFailedInvoiceColumns,
})
export const ReceptionPurchaseP3RevokedGrid = () => OneInvoiceGrid({
	id: 'ReceptionPurchaseP3Revoked',
	endpoint: InvoicesEndpoints.ReceptionPurchaseP3Revoked,
	breadCrumbs: ['menu/repository', 'menu/repository/receptionerrors', 'menu/repository/receptionerrors/failed'],
	buttonsIds: ['select-all','preview-error','download-error','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','logs','tags','export-rows-data'],
	privileges: ['reception_additional_buyer_failed'],
	getColumns: receivedFailedInvoiceColumns,
})
export const ReceptionSelfInvoicingRevokedGrid = () => OneInvoiceGrid({
	id: 'ReceptionSelfInvoicingRevoked',
	endpoint: InvoicesEndpoints.ReceptionSelfInvoicingRevoked,
	breadCrumbs: ['menu/repository', 'menu/repository/receptionerrors', 'menu/repository/receptionerrors/selfinvoicing'],
	buttonsIds: ['select-all','preview-error','download-error','preview-xml','download-xml','preview-pdf','download-pdf','preview-html','copy-ksef-number','logs','tags','export-rows-data'],
	privileges: ['reception_selfinvoicing_failed'],
	getColumns: receivedFailedInvoiceColumns,
})
